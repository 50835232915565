import { makeStyles, styled } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        backgroundColor: 'transparent',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    grid_item_smartsolos:{
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 20
    },
    grid_item_embrapa:{
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 20
    },
});

export const ImageSmartSolos = styled('img')({
    maxWidth: '250px',
    maxHeight: 'auto',
});

export const ImageEmbrapa = styled('img')({
    maxWidth: '250px',
    maxHeight: 'auto',
});

export default useStyles;