import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
    IFilterHorizons
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    horizonFilters: IFilterHorizons
    number: '1' | '2'
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonMottledItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [mottledQuantity, setMottledQuantity] = useState<string>('selecione');
    const [mottledSize, setMottledSize] = useState<string>('selecione');
    const [mottledContrast, setMottledContrast] = useState<string>('selecione');
    const [mottledUpdate, setMottledUpdate] = useState<boolean>(false);

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            switch (props.number) {
                case '1':
                    if (
                        mottledQuantity !== null &&
                        mottledQuantity !== 'selecione'
                    ) {
                        request_data['mottled_quantity_one'] = `${mottledQuantity}`;
                    }
                    if (
                        mottledSize !== null &&
                        mottledSize !== 'selecione'
                    ) {
                        request_data['mottled_size_one'] = `${mottledSize}`;
                    }
                    if (
                        mottledContrast !== null &&
                        mottledContrast !== 'selecione'
                    ) {
                        request_data['mottled_contrast_one'] = `${mottledContrast}`;
                    }
                    break;
                case '2':
                    if (
                        mottledQuantity !== null &&
                        mottledQuantity !== 'selecione'
                    ) {
                        request_data['mottled_quantity_two'] = `${mottledQuantity}`;
                    }
                    if (
                        mottledSize !== null &&
                        mottledSize !== 'selecione'
                    ) {
                        request_data['mottled_size_two'] = `${mottledSize}`;
                    }
                    if (
                        mottledContrast !== null &&
                        mottledContrast !== 'selecione'
                    ) {
                        request_data['mottled_contrast_two'] = `${mottledContrast}`;
                    }
                    break;
                default:
                    break;
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                    }
                }).catch(() => {
                });
            } catch (e) {
            }
        }
    }

    useEffect(() => {
        if (mottledUpdate === true) {
            updateHorizon();
            setMottledUpdate(false);
        }
        // eslint-disable-next-line
    }, [mottledUpdate === true]);

    useEffect(() => {
        if (props.horizon !== undefined) {
            switch (props.number) {
                case '1':
                    setMottledQuantity((props.horizon.mottled_quantity_one === null ? 'selecione' : props.horizon.mottled_quantity_one));
                    setMottledSize((props.horizon.mottled_size_one === null ? 'selecione' : props.horizon.mottled_size_one));
                    setMottledContrast((props.horizon.mottled_contrast_one === null ? 'selecione' : props.horizon.mottled_contrast_one));
                    break;
                case '2':
                    setMottledQuantity((props.horizon.mottled_quantity_two === null ? 'selecione' : props.horizon.mottled_quantity_two));
                    setMottledSize((props.horizon.mottled_size_two === null ? 'selecione' : props.horizon.mottled_size_two));
                    setMottledContrast((props.horizon.mottled_contrast_two === null ? 'selecione' : props.horizon.mottled_contrast_two));
                    break;
            }
        }
        // eslint-disable-next-line
    }, [props.horizon !== undefined]);

    return (
        <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={`${props.horizon.id}${props.horizon.name}mottled_quantity_one`}
            className={styles.view_horizon_mottled}
        >
            <Grid item xs={12} className={styles.view_horizon_mottled_title}>
                <Typography className={styles.text_horizon_mottled}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} className={styles.view_horizon_mottled_subtitle}>
                        <Typography className={styles.text_horizon_mottled_subtitle}>
                            Quantidade
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.view_horizon_mottled_subtitle}>
                        <TextField
                            className={styles.picker_select_type}
                            select
                            value={mottledQuantity ? mottledQuantity : "selecione"}
                            defaultValue={'selecione'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMottledQuantity(`${event.target.value}`);
                                setMottledUpdate(true);
                            }}
                        >
                            <MenuItem className={styles.picker_select_item} key={'selecione'} value={'selecione'}>Selecione</MenuItem>
                            {props.horizonFilters.mottled_quantity.map((element, key) => (
                                <MenuItem className={styles.picker_select_item} key={key} value={`${key + 1}`}>{`${element}`}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} className={styles.view_horizon_mottled_subtitle}>
                        <Typography className={styles.text_horizon_mottled_subtitle}>
                            Tamanho
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.view_horizon_mottled_subtitle}>
                        <TextField
                            className={styles.picker_select_type}
                            select
                            value={mottledSize ? mottledSize : "selecione"}
                            defaultValue={'selecione'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMottledSize(`${event.target.value}`);
                                setMottledUpdate(true);
                            }}
                        >
                            <MenuItem className={styles.picker_select_item} key={'selecione'} value={'selecione'}>Selecione</MenuItem>
                            {props.horizonFilters.mottled_size.map((element, key) => (
                                <MenuItem className={styles.picker_select_item} key={key} value={`${key + 1}`}>{`${element}`}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={styles.last_grid_data}>
                <Grid container>
                    <Grid item xs={12} className={styles.view_horizon_mottled_subtitle}>
                        <Typography className={styles.text_horizon_mottled_subtitle}>
                            Contraste
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.view_horizon_mottled_subtitle}>
                        <TextField
                            className={styles.picker_select_type}
                            select
                            value={mottledContrast ? mottledContrast : "selecione"}
                            defaultValue={'selecione'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMottledContrast(`${event.target.value}`);
                                setMottledUpdate(true);
                            }}
                        >
                            <MenuItem className={styles.picker_select_item} key={'selecione'} value={'selecione'}>Selecione</MenuItem>
                            {props.horizonFilters.mottled_contrast.map((element, key) => (
                                <MenuItem className={styles.picker_select_item} key={key} value={`${key + 1}`}>{`${element}`}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}