import axios from 'axios';

const authApi = () => ({
    validateToken: async (token: string) => {
        try {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('token', token);

            return await axios({
                url: `${process.env.REACT_APP_API}check_auth`,
                method: "POST",
                timeout: 15000,
                headers: {
                    'Accept': "application/json",
                    'Authorization': `Bearer ${token}`,
                },
                data: data
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                if (error.request.status === 401) {
                    localStorage.removeItem('@SmartSolos:userData');
                    localStorage.removeItem('@SmartSolos:accessToken');
                }
                return Promise.reject(error);
            });
        } catch ({ message }) {
            return Promise.reject(message);
        }
    },
    signIn: async (email: string, password: string) => {
        try {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('email', email);
            data.append('password', password);

            return await axios({
                url: `${process.env.REACT_APP_API}login`,
                method: "POST",
                timeout: 15000,
                headers: {
                    'Accept': "application/json",
                },
                data: data
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        } catch ({ message }) {
            return Promise.reject(message);
        }
    },
    signSocialIn: async (email: string, token: string) => {
        try {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('email', email);
            data.append('token', token);

            return await axios({
                url: `${process.env.REACT_APP_API}login_social`,
                method: "POST",
                timeout: 15000,
                headers: {
                    'Accept': "application/json",
                },
                data: data
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        } catch ({ message }) {
            return Promise.reject(message);
        }
    },
    forgot: async (email: string) => {
        try {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('username', email);

            return await axios({
                url: `${process.env.REACT_APP_API}forgot`,
                method: "POST",
                timeout: 25000,
                headers: {
                    'Accept': "application/json"
                },
                data: data
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        } catch ({ message }) {
            return Promise.reject(message);
        }
    },
    textEntry: async (code: string) => {
        try {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('recovery_code', code);

            return await axios({
                url: `${process.env.REACT_APP_API}text_entry`,
                method: "POST",
                timeout: 15000,
                headers: {
                    'Accept': "application/json"
                },
                data: data
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        } catch ({ message }) {
            return Promise.reject(message);
        }
    },
    changePassword: async (recovery_access: string, password: string, confirm_password: string) => {
        try {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('recovery_access', recovery_access);
            data.append('password', password);
            data.append('confirm_password', confirm_password);

            return await axios({
                url: `${process.env.REACT_APP_API}change_password`,
                method: "POST",
                timeout: 15000,
                headers: {
                    'Accept': "application/json"
                },
                data: data
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        } catch ({ message }) {
            return Promise.reject(message);
        }
    },
    signOut: async () => {
        try {
            const token = localStorage.getItem('@SmartSolos:accessToken');
            var FormData = require('form-data');
            var data = new FormData();
            data.append('token', token);

            return await axios({
                url: `${process.env.REACT_APP_API}logout`,
                method: "POST",
                timeout: 15000,
                headers: {
                    'Accept': "application/json",
                    'Authorization': `Bearer ${token}`,
                },
                data: data
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                if (error.request.status === 401) {
                    localStorage.removeItem('userData');
                    localStorage.removeItem('@SmartSolos:accessToken');
                }
                return Promise.reject(error);
            });
        } catch ({ message }) {
            return Promise.reject(message);
        }
    },
    register: async (
        name: string,
        email: string,
        password: string,
        about: string,
        job_position: string
    ) => {
        try {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('name', name);
            data.append('email', email);
            data.append('password', password);
            data.append('about', about);
            data.append('job_position', job_position);

            return await axios({
                url: `${process.env.REACT_APP_API}register`,
                method: "POST",
                timeout: 15000,
                headers: {
                    'Accept': "application/json"
                },
                data: data
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        } catch ({ message }) {
            return Promise.reject(message);
        }
    }
});

export default authApi;