import React, { useState, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import authApi from '../../hooks/authApi';
import { User } from '../../types/User';
import { AuthContextType } from '../../types/index'

interface AuthState {
    token: string;
    user: User | null;
}

const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const api = authApi();
    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState<AuthState>(() => {
        const user = localStorage.getItem('@SmartSolos:userData');
        const token = localStorage.getItem('@SmartSolos:accessToken');

        if (token && user) {
            return { token, user: JSON.parse(user) };
        }

        return {} as AuthState;
    });

    const signIn = async (email: string, password: string) => {
        const data = await api.signIn(email, password);
        if (data.status === 200 && data.data.user_data) {
            // setUser(data.data.user_data);
            localStorage.setItem('@SmartSolos:userData', JSON.stringify(data.data.user_data));
            localStorage.setItem('@SmartSolos:accessToken', data.data.user_data.access_token);
            const user = data.data.user_data;
            const token = data.data.user_data.access_token;
            setData({ token, user });
            return true;
        }

        return false;
    }

    const signSocialIn = async (email: string, token: string) => {
        const data = await api.signSocialIn(email, token);
        if (data.status === 200 && data.data.user_data) {
            localStorage.setItem('@SmartSolos:userData', JSON.stringify(data.data.user_data));
            localStorage.setItem('@SmartSolos:accessToken', data.data.user_data.access_token);
            const user = data.data.user_data;
            const token = data.data.user_data.access_token;
            setData({ token, user });
            return true;
        }

        return false;
    }

    const signOut = async (requestApi: boolean = false) => {
        localStorage.removeItem('@SmartSolos:userData');
        localStorage.removeItem('@SmartSolos:accessToken');
        setData({ token: '', user: null } as AuthState);

        if (requestApi !== undefined && requestApi === true) {
            await api.signOut();
        }
    }

    const validateToken = async () => {
        const storageAccessData = localStorage.getItem('@SmartSolos:accessToken');
        if (storageAccessData !== null) {
            setLoading(true);
            await api.validateToken(
                storageAccessData
            ).then((responseToken) => {
                if (responseToken.status !== 200) {
                    signOut(true);
                    setLoading(false);
                }
            }).catch(() => {
                signOut(false);
                setLoading(false);
            });
        } else {
            signOut(false);
        }
    }

    React.useEffect(() => {
        if (data !== undefined && data.user !== undefined) {
            (async () => {
                await validateToken();
            })()
        }
        // eslint-disable-next-line
    }, []);

    return (
        <AuthContext.Provider value={{
            user: data.user,
            loading,
            signIn,
            signSocialIn,
            signOut,
            validateToken
        }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth(): AuthContextType {
    const context = useContext(AuthContext);
    if (!context) throw new Error('useAuth must be used within an AuthProvider');
    return context;
}

export { useAuth, AuthProvider };
