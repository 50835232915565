import React, { useState } from 'react';
import useStyles from './styles';
import {
    IClassificationExam,
} from '../../types';
import { formatDateToString } from '../../formatters';
import apiService from '../../services/apiService';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import ShowLoader from '../../components/ShowLoaderComponent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import GradingIcon from '@mui/icons-material/Grading';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'react-toastify';

interface IReceiptPros {
    show: boolean
    examId: string | undefined
    examName: string | undefined
    examClassification?: Array<IClassificationExam>
}

export default function ExamClassification(props: IReceiptPros) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);

    const [examClassificationOrder, setExamClassificationOrder] = useState<string | undefined>(undefined);
    const [examClassificationSubOrder, setExamClassificationSubOrder] = useState<string | undefined>(undefined);
    const [examClassificationSubGroup, setExamClassificationSubGroup] = useState<string | undefined>(undefined);
    const [examClassificationGdeGroup, setExamClassificationGdeGroup] = useState<string | undefined>(undefined);
    const [examClassificationJson, setExamClassificationJson] = useState<any | undefined>(undefined);
    const [examClassificationCreatedAt, setExamClassificationCreatedAt] = useState<string | undefined>(undefined);
    const [loaderClassificationExam, setLoaderClassificationExam] = useState(false);
    const [loaderClassificationJson, setLoaderClassificationJson] = useState(false);
    const [loaderBox, setLoaderBox] = useState<boolean>(false);
    const [messageLoader, setMessageLoader] = useState<string | undefined>(undefined);

    function setStateLoader(status: boolean, message: string | undefined = undefined) {
        if (status === true) {
            if (message !== undefined) {
                setMessageLoader(message);
            }
            setLoaderBox(true);
        } else {
            setLoaderBox(false);
            setMessageLoader(undefined);
        }
    }

    async function handleClassificationJson() {
        if (examClassificationJson !== undefined && props.examName !== undefined) {
            try {
                setLoaderClassificationJson(true);
                const url = window.URL.createObjectURL(new Blob([JSON.stringify(examClassificationJson)]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `JSON_${props.examName}_${Date.now()}.json`);
                document.body.appendChild(link);
                link.click();
                setLoaderClassificationJson(false);
            } catch (e) {
                console.log('handleClassificationJson: ', e);
            }
        } else {
            toast.info('JSON da requisição indisponível.');
        }
    }

    async function handleClassificationExam() {
        if (auth !== undefined && props.examId !== undefined) {
            setLoaderClassificationExam(true);
            try {
                await apiService.post(
                    'exams/sent',
                    { 'exam_id': parseInt(props.examId) }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setExamClassificationOrder(response.data.data.specialist_system.response.items[0].ORDEM);
                        setExamClassificationSubOrder(response.data.data.specialist_system.response.items[0].SUBORDEM);
                        setExamClassificationGdeGroup(response.data.data.specialist_system.response.items[0].GDE_GRUPO);
                        setExamClassificationSubGroup(response.data.data.specialist_system.response.items[0].SUBGRUPO);
                        setExamClassificationJson(response.data.data.specialist_system.request);
                        setExamClassificationCreatedAt(new Date().toUTCString());
                    }
                    setLoaderClassificationExam(false);
                }).catch((error) => {
                    console.log('getExamClassification-error: ', error);
                    setLoaderClassificationExam(false);
                });
            } catch (e) {
                console.log('getExamClassification-catch: ', e);
                setStateLoader(false);
            }
        }
    }

    async function getExamClassification() {
        if (auth !== undefined && props.examId !== undefined) {
            setLoaderClassificationExam(true);
            try {
                await apiService.get(
                    'exams/details',
                    { exam_id: parseInt(props.examId) }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        const count_classifications: number = response.data.data.classification.length;
                        setExamClassificationOrder(response.data.data.classification[count_classifications - 1].order);
                        setExamClassificationSubOrder(response.data.data.classification[count_classifications - 1].sub_order);
                        setExamClassificationGdeGroup(response.data.data.classification[count_classifications - 1].gde_group);
                        setExamClassificationSubGroup(response.data.data.classification[count_classifications - 1].sub_group);
                        setExamClassificationCreatedAt(response.data.data.classification[count_classifications - 1].created_at);
                    }
                    setLoaderClassificationExam(false);
                }).catch((error) => {
                    console.log('getExamClassification-error: ', error);
                    setLoaderClassificationExam(false);
                });
            } catch (e) {
                console.log('getExamClassification-catch: ', e);
                setStateLoader(false);
            }
        }
    }

    React.useEffect(() => {
        if (props.show === true && props.examClassification !== undefined && props.examClassification.length > 0) {
            setStateLoader(true);
            const count_classifications: number = props.examClassification.length;
            setExamClassificationOrder(props.examClassification[count_classifications - 1].order);
            setExamClassificationSubOrder(props.examClassification[count_classifications - 1].sub_order);
            setExamClassificationGdeGroup(props.examClassification[count_classifications - 1].gde_group);
            setExamClassificationSubGroup(props.examClassification[count_classifications - 1].sub_group);
            setExamClassificationCreatedAt(props.examClassification[count_classifications - 1].created_at);
            setStateLoader(false);
        } else {
            getExamClassification();
        }
        // eslint-disable-next-line
    }, [props]);

    if (props.show === true && loaderBox === false) {
        return (
            <Grid container className={styles.grid_root}>
                <Grid item xs={12} className={styles.view_exam_exam_classification}>
                    {(loaderClassificationExam === false && examClassificationOrder !== undefined && examClassificationCreatedAt !== undefined) && (
                        <Grid container className={styles.view_exam_classification_root_data}>
                            <Grid item xs={12} className={styles.view_exam_classification_data}>
                                <Typography className={styles.text_exam_classification_order}>
                                    <strong>Ordem:</strong> {examClassificationOrder?.trim() === 'unknown' ? '?' : examClassificationOrder}
                                </Typography>
                                <Typography className={styles.text_exam_classification_order}>
                                    <strong>Subordem:</strong> {examClassificationSubOrder?.trim() === 'unknown' ? '?' : examClassificationSubOrder}
                                </Typography>
                                <Typography className={styles.text_exam_classification_group}>
                                    <strong>GDE grupo:</strong> {examClassificationGdeGroup?.trim() === 'unknown' ? '?' : examClassificationGdeGroup}
                                </Typography>
                                <Typography className={styles.text_exam_classification_group}>
                                    <strong>Subgrupo:</strong> {examClassificationSubGroup?.trim() === 'unknown' ? '?' : examClassificationSubGroup}
                                </Typography>
                                <Typography className={styles.text_exam_classification_date}>
                                    <strong>Classificado em:</strong> {formatDateToString(examClassificationCreatedAt)}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container>
                        <Grid item xs={12}>
                            <LoadingButton
                                className={styles.button_exam_exam_classification}
                                loading={loaderClassificationExam}
                                loadingPosition="end"
                                endIcon={<GradingIcon className={styles.icon_classification} />}
                                variant="outlined"
                                onClick={handleClassificationExam}
                            >
                                {props.examClassification !== undefined && props.examClassification.length > 0 ? 'Obter Reclassificação' : 'Obter Classificação'}
                            </LoadingButton>
                            {examClassificationJson !== undefined && (
                                <LoadingButton
                                    className={styles.button_exam_exam_classification_json}
                                    loading={loaderClassificationJson}
                                    loadingPosition="end"
                                    endIcon={<DownloadIcon className={styles.icon_download} />}
                                    variant="outlined"
                                    onClick={handleClassificationJson}
                                >
                                    JSON
                                </LoadingButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <ShowLoader message={messageLoader} />
        );
    }
}
