export const MunsellColors: any = {
    '10R': {
        '8': {
            '1': '#E9EBEA',
            '2': '#BBB9BA',
            '3': '#E4E4E4',
            '4': '#F7CBC7',
        },
        '7': {
            '1': '#B0A6A6',
            '2': '#BCA7A6',
            '3': '#C29F9D',
            '4': '#D1A59C',
            '6': '#D48E8E',
            '8': '#E0867E',
        },
        '6': {
            '1': '#9A908F',
            '2': '#948185',
            '3': '#997B7D',
            '4': '#F8F9F9',
            '6': '#B37172',
            '8': '#BA6568',
        },
        '5': {
            '1': '#776D6E',
            '2': '#816D6C',
            '3': '#836268',
            '4': '#EDE8E9',
            '6': '#965559',
            '8': '#A4484D',
        },
        '4': {
            '1': '#605357',
            '2': '#664D53',
            '3': '#6D4A50',
            '4': '#73424C',
            '6': '#7F393D',
            '8': '#8B3538',
        },
        '3': {
            '1': '#483B40',
            '2': '#52353A',
            '3': '#5A3334',
            '4': '#5D2E34',
            '6': '#662124',
        },
        '2,5': {
            '1': '#D7D3D2',
            '2': '#442F32',
        },
    },
    '2,5YR': {
        '8': {
            '1': '#ECE2E0',
            '2': '#EBD7D6',
            '3': '#EDCFCB',
            '4': '#F6CEC5',
        },
        '7': {
            '1': '#B8AEAD',
            '2': '#C1ADAC',
            '3': '#BFA19F',
            '4': '#C89C95',
            '6': '#D7948C',
            '8': '#E08D7D',
        },
        '6': {
            '1': '#968C8B',
            '2': '#9B8788',
            '3': '#9D7F7E',
            '4': '#A77A75',
            '6': '#B1736E',
            '8': '#BD6B5F',
        },
        '5': {
            '1': '#7A7071',
            '2': '#806B6F',
            '3': '#836462',
            '4': '#8D5F5F',
            '6': '#975352',
            '8': '#A14E46',
        },
        '4': {
            '1': '#F9F9F9',
            '2': '#664E52',
            '3': '#6D4A4E',
            '4': '#744242',
            '6': '#7F3D3B',
            '8': '#863631',
        },
        '3': {
            '1': '#4A3F43',
            '2': '#533639',
            '3': '#573336',
            '4': '#613431',
            '6': '#5C2023',
        },
        '2,5': {
            '1': '#DBD7D7',
            '2': '#482B2D',
            '3': '#492524',
            '4': '#D3D0D0',
        },
    },
    '5YR': {
        '8': {
            '1': '#E9DFDD',
            '2': '#E1DAD6',
            '3': '#FDFDFE',
            '4': '#F4CCC2',
        },
        '7': {
            '1': '#BEB4B2',
            '2': '#BEA9A8',
            '3': '#C4A39C',
            '4': '#C49D92',
            '6': '#D49685',
            '8': '#DF9276',
        },
        '6': {
            '1': '#988E8D',
            '2': '#9D8887',
            '3': '#9F817B',
            '4': '#A77E77',
            '6': '#B07264',
            '8': '#BC6F53',
        },
        '5': {
            '1': '#7C7271',
            '2': '#816C6B',
            '3': '#876662',
            '4': '#89625B',
            '6': '#985B4F',
            '8': '#9A523B',
        },
        '4': {
            '1': '#605456',
            '2': '#665052',
            '3': '#6E4C4B',
            '4': '#734742',
            '6': '#733C39',
        },
        '3': {
            '1': '#473C40',
            '2': '#523B37',
            '3': '#553332',
            '4': '#532623',
        },
        '2,5': {
            '1': '#3E3337',
            '2': '#433333',
        },
    },
    '7,5YR': {
        '8': {
            '1': '#E2D8D6',
            '2': '#EBD7CE',
            '3': '#FDFDFC',
            '4': '#F5D2BF',
            '6': '#FCCAAA',
        },
        '7': {
            '1': '#B6ACAA',
            '2': '#B9A9A2',
            '3': '#C0A498',
            '4': '#C29F8D',
            '6': '#CC967B',
            '8': '#D5916C',
        },
        '6': {
            '1': '#938D8C',
            '2': '#97837D',
            '3': '#9C8075',
            '4': '#A37F73',
            '6': '#AF7B63',
            '8': '#B57350',
        },
        '5': {
            '1': '#796F6E',
            '2': '#806B6A',
            '3': '#806B6A',
            '4': '#886458',
            '6': '#925E48',
            '8': '#925E48',
        },
        '4': {
            '1': '#5F5556',
            '2': '#64504F',
            '3': '#6E5048',
            '4': '#6F493C',
            '6': '#744837',
        },
        '3': {
            '1': '#463C3C',
            '2': '#503C37',
            '3': '#4B2D26',
            '4': '#57322B',
        },
        '2,5': {
            '1': '#363233',
            '2': '#412D2E',
            '3': '#46292B',
        },
    },
    '10YR': {
        '8': {
            '1': '#E7DED9',
            '2': '#EADBCE',
            '3': '#EFD6C1',
            '4': '#FDFEFE',
            '6': '#F8CDA2',
            '8': '#F5BD84',
        },
        '7': {
            '1': '#BAB0AE',
            '2': '#BAA99F',
            '3': '#BFA594',
            '4': '#BBA085',
            '6': '#C69C74',
            '8': '#CE9664',
        },
        '6': {
            '1': '#988E8D',
            '2': '#958881',
            '3': '#9C8178',
            '4': '#9D7D6A',
            '6': '#AA7C58',
            '8': '#B07847',
        },
        '5': {
            '1': '#787272',
            '2': '#7B6D6A',
            '3': '#83695C',
            '4': '#886853',
            '6': '#8D6038',
            '8': '#965D33',
        },
        '4': {
            '1': '#5D5758',
            '2': '#605251',
            '3': '#694F40',
            '4': '#6C4C37',
            '6': '#714928',
        },
        '3': {
            '1': '#454142',
            '2': '#463A3A',
            '3': '#482E23',
            '4': '#CECCCD',
            '6': '#5A361F',
        },
        '2': {
            '1': '#2B292A',
            '2': '#31262A',
        },
    },
    '2,5Y': {
        '8': {
            '1': '#E4E1DC',
            '2': '#E7DCC8',
            '3': '#EDDCC0',
            '4': '#EED7B6',
            '6': '#F3CD9C',
            '8': '#F1C483',
        },
        '7': {
            '1': '#B8B4AF',
            '2': '#B9AE9C',
            '3': '#B8A78F',
            '4': '#BFA686',
            '6': '#C49E6F',
            '8': '#CA9A5A',
        },
        '6': {
            '1': '#938F8C',
            '2': '#91877D',
            '3': '#958571',
            '4': '#9A806A',
            '6': '#A68053',
            '8': '#AA7A3A',
        },
        '5': {
            '1': '#787775',
            '2': '#7A7167',
            '3': '#7E6C58',
            '4': '#846A4C',
            '6': '#8C6539',
        },
        '4': {
            '1': '#5E5A59',
            '2': '#63574B',
            '3': '#66513D',
            '4': '#6B5137',
        },
        '3': {
            '1': '#433D3C',
            '2': '#4A3D3A',
            '3': '#46311F',
        },
        '2,5': {
            '1': '#373334',
        },
    },
    '5Y': {
        '8': {
            '1': '#E5E1D7',
            '2': '#EAE4CE',
            '3': '#E8DDBF',
            '4': '#EBDCB1',
            '6': '#EDD49B',
            '8': '#F4D47D',
        },
        '7': {
            '1': '#BBB8B0',
            '2': '#BBB49F',
            '3': '#BDB294',
            '4': '#BCAE87',
            '6': '#C1A872',
            '8': '#C5A154',
        },
        '6': {
            '1': '#92918F',
            '2': '#97907E',
            '3': '#958C75',
            '4': '#9B8A66',
            '6': '#9D854F',
            '8': '#A58135',
        },
        '5': {
            '1': '#787570',
            '2': '#7C7467',
            '3': '#F9F9F7',
            '4': '#846F4C',
            '6': '#846A35',
        },
        '4': {
            '1': '#5E5A5B',
            '2': '#615749',
            '3': '#645542',
            '4': '#665738',
        },
        '3': {
            '1': '#413F40',
            '2': '#49403B',
        },
        '2,5': {
            '1': '#383637',
            '2': '#3D3331',
        },
    },
    'N': {
        '0': {
            '2,5': '#38373C',
            '3': '#414149',
            '4': '#585B5F',
            '5': '#707578',
            '6': '#8E9295',
            '7': '#B4B6B5',
            '8': '#E1E1E1'
        }
    },
    '10Y': {
        '1': {
            '2,5': '#3B393A',
            '3': '#3F3D3E',
            '4': '#5B5A58',
            '5': '#74736E',
            '6': '#8E8E86',
            '7': '#ACADA8',
            '8': '#DCDDD6'
        }
    },
    '5GY': {
        '1': {
            '2,5': '#383838',
            '3': '#3F3F3D',
            '4': '#5B5C57',
            '5': '#737570',
            '6': '#8E9188',
            '7': '#B0B3AB',
            '8': '#E2E6D8'
        }
    },
    '10GY': {
        '1': {
            '2,5': '#383A3A',
            '3': '#3E4342',
            '4': '#585D5B',
            '5': '#6E7774',
            '6': '#8B948F',
            '7': '#B4B6B3',
            '8': '#E5EAE4'
        }
    },
    '5G': {
        '1': {
            '2,5': '#353B3B',
            '3': '#3D4444',
            '4': '#545C5E',
            '5': '#6F7877',
            '6': '#8A9692',
            '7': '#B4B9B5',
            '8': '#E4E9E3'
        },
        '2': {
            '2,5': '#343D3C',
            '3': '#374C45',
            '4': '#4F6260',
            '5': '#667D76',
            '6': '#819D91',
            '7': '#ACBFB9',
            '8': '#D1F0E3'
        }
    },
    '10G': {
        '1': {
            '2,5': '#33363A',
            '3': '#394244',
            '4': '#555D5F',
            '5': '#6D7979',
            '6': '#879691',
            '7': '#FAFAFA',
            '8': '#DDE2E1'
        }
    },
    '5BG': {
        '1': {
            '2,5': '#31373A',
            '3': '#3C4448',
            '4': '#515E64',
            '5': '#6D7578',
            '6': '#899695',
            '7': '#B4B8B7',
            '8': '#DEE2E1'
        }
    },
    '10BG': {
        '1': {
            '2,5': '#33363B',
            '3': '#39444A',
            '4': '#525D63',
            '5': '#6D787E',
            '6': '#88959B',
            '7': '#B3B9BB',
            '8': '#DDE2E5'
        }
    },
    '5B': {
        '1': {
            '2,5': '#30363E',
            '3': '#3A454E',
            '4': '#535D66',
            '5': '#6F767E',
            '6': '#88959C',
            '7': '#B8BCBD',
            '8': '#DDE2E5'
        }
    },
    '10B': {
        '1': {
            '2,5': '#33363F',
            '3': '#3B414D',
            '4': '#F2F4F4',
            '5': '#6E757E',
            '6': '#8B939A',
            '7': '#B9BCBD',
            '8': '#E1E5E8'
        }
    },
    '5PB': {
        '1': {
            '2,5': '#353743',
            '3': '#3C3F4D',
            '4': '#595C67',
            '5': '#727681',
            '6': '#8C9099',
            '7': '#B7BABF',
            '8': '#E5E9EC'
        }
    }
}

export interface IMunsellColor {
    matiz: string
    value: string
    chroma: string
}

export default class MusellColorsTypes {
    static getMunsellHexColor(color: IMunsellColor) {

        if (!color) {
            return null;
        }

        const gley_color_matiz = ["N","10Y","5GY","10GY","5G","10G","5BG","10BG","5B","10B ","5PB"];

        const matiz = color.matiz;
        const value = color.value;
        const chroma = color.chroma;

        if (gley_color_matiz.includes(matiz)) {
            return MunsellColors[matiz] &&
                MunsellColors[matiz][chroma] &&
                MunsellColors[matiz][chroma][value];
        } else {
            return MunsellColors[matiz] &&
                MunsellColors[matiz][value] &&
                MunsellColors[matiz][value][chroma];
        }
    }
}
