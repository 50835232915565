import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
    IFilterHorizons,
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const label = { inputProps: { 'aria-label': 'Checkbox' } };

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    horizonFilters: IFilterHorizons
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonCompressionSurfacesItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [compressionSurfaces, setCompressionSurfaces] = useState<string>('0');
    const [startUpdateHorizon, setStartUpdateHorizon] = useState<boolean>(false);

    function handleSetCompressionSurfaces(key: string) {
        setCompressionSurfaces(`${( key === compressionSurfaces ? '0' : key )}`);
        setStartUpdateHorizon(true);
    }

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            if (compressionSurfaces !== undefined && compressionSurfaces !== null) {
                request_data['compression_surfaces'] = `${compressionSurfaces}`;
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                        setStartUpdateHorizon(false);
                    } else {
                        setStartUpdateHorizon(false);
                    }
                }).catch(() => {
                    setStartUpdateHorizon(false);
                });
            } catch (e) {
                setStartUpdateHorizon(false);
            }
        }
    }

    useEffect(() => {
        if (props.horizon !== undefined) {
            if (startUpdateHorizon === true) {
                updateHorizon();
                setStartUpdateHorizon(false);
            }
        }
        // eslint-disable-next-line
    }, [startUpdateHorizon === true]);

    useEffect(() => {
        if (props.horizon !== undefined) {
            if (props.horizon.compression_surfaces !== undefined && props.horizon.compression_surfaces !== null) {
                setCompressionSurfaces(`${props.horizon.compression_surfaces}`);
            }
        }
        // eslint-disable-next-line
    }, [props.horizon]);

    return (
        <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={`${props.horizon.id}${props.horizon.name}compression_surfaces`}
            className={styles.view_horizon_compression_surfaces}
        >
            <Grid item xs={12} className={styles.view_horizon_compression_surfaces_title}>
                <Typography className={styles.text_horizon_compression_surfaces}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            {props.horizonFilters.compression_surfaces.map((element, key) => (
                <Grid item xs={4} className={styles.view_horizon_compression_surfaces_item} key={`${key + 1}${element.compression_surfaces}`}>
                    <Button onClick={() => handleSetCompressionSurfaces(`${key + 1}`)} className={styles.button_checkobox_compression_surfaces_content}>
                        <Checkbox
                            {...label}
                            sx={{
                                color: '#CC6B3B',
                                '&.Mui-checked': {
                                    color: '#CC6B3B',
                                },
                            }}
                            checked={(parseInt(compressionSurfaces) === key + 1 || parseInt(compressionSurfaces) === 3) ? true : false}
                            onClick={() => handleSetCompressionSurfaces(`${key + 1}`)}
                        />
                        <Typography className={styles.text_horizon_character_data}>{`${element}`}</Typography>
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}