import React from 'react';
import useStyles, { ImageSmartSolos, ImageEmbrapa } from './styles';
import Grid from '@mui/material/Grid';
import smartsoloslogo from '../../assets/images/logo/logo_white.png';
import logoembrapa from '../../assets/images/logo/logo_embrapa.png';


export default function BannerSmartSolos() {
    const styles = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            className={styles.grid_root}
        >
            <Grid item xs={12} className={styles.grid_item_smartsolos}>
                <ImageSmartSolos alt="Smart Solos" src={`${smartsoloslogo}`} />
            </Grid>
            <Grid item xs={12} className={styles.grid_item_embrapa}>
                <ImageEmbrapa alt="Embrapa" src={`${logoembrapa}`} />
            </Grid>
        </Grid>
    );
}
