import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const label = { inputProps: { 'aria-label': 'Checkbox' } };

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    number: '1' | '2' | '3'
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonMunsellItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [colorsType, setColorsType] = useState<string>('0');
    const [colorsMatriz, setColorsMatriz] = useState<string | undefined>(undefined);
    const [colorsValue, setColorsValue] = useState<string | undefined>(undefined);
    const [colorsChroma, setColorsChroma] = useState<string | undefined>(undefined);
    const [colorsMatrizExtra1, setColorsMatrizExtra1] = useState<string | undefined>(undefined);
    const [colorsValueExtra1, setColorsValueExtra1] = useState<string | undefined>(undefined);
    const [colorsChromaExtra1, setColorsChromaExtra1] = useState<string | undefined>(undefined);
    const [colorsMatrizExtra2, setColorsMatrizExtra2] = useState<string | undefined>(undefined);
    const [colorsValueExtra2, setColorsValueExtra2] = useState<string | undefined>(undefined);
    const [colorsChromaExtra2, setColorsChromaExtra2] = useState<string | undefined>(undefined);
    const [horizonUpdate, setHorizonUpdate] = useState<boolean>(false);
    const [showDialogHorizonColorMottled, setShowDialogHorizonColorMottled] = useState<boolean>(false);
    const [showDialogHorizonColorVariegated, setShowDialogHorizonColorVariegated] = useState<boolean>(false);

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            switch (props.number) {
                case '1':
                    if (colorsType !== undefined && colorsType !== null) {
                        request_data['musell_colors_type_one'] = `${colorsType}`;
                    }
                    if (colorsMatriz !== undefined && colorsMatriz !== null) {
                        request_data['musell_colors_matriz_one'] = `${colorsMatriz}`;
                    }
                    if (colorsValue !== undefined && colorsValue !== null) {
                        request_data['musell_colors_value_one'] = `${colorsValue}`;
                    }
                    if (colorsChroma !== undefined && colorsChroma !== null) {
                        request_data['musell_colors_chroma_one'] = `${colorsChroma}`;
                    }
                    if (colorsMatrizExtra1 !== undefined && colorsMatrizExtra1 !== null) {
                        request_data['musell_colors_matriz_one_extra_1'] = `${colorsMatrizExtra1}`;
                    }
                    if (colorsValueExtra1 !== undefined && colorsValueExtra1 !== null) {
                        request_data['musell_colors_value_one_extra_1'] = `${colorsValueExtra1}`;
                    }
                    if (colorsChromaExtra1 !== undefined && colorsChromaExtra1 !== null) {
                        request_data['musell_colors_chroma_one_extra_1'] = `${colorsChromaExtra1}`;
                    }
                    if (colorsMatrizExtra2 !== undefined && colorsMatrizExtra2 !== null) {
                        request_data['musell_colors_matriz_one_extra_2'] = `${colorsMatrizExtra2}`;
                    }
                    if (colorsValueExtra2 !== undefined && colorsValueExtra2 !== null) {
                        request_data['musell_colors_value_one_extra_2'] = `${colorsValueExtra2}`;
                    }
                    if (colorsChromaExtra2 !== undefined && colorsChromaExtra2 !== null) {
                        request_data['musell_colors_chroma_one_extra_2'] = `${colorsChromaExtra2}`;
                    }
                    break;
                case '2':
                    if (colorsType !== undefined && colorsType !== null) {
                        request_data['musell_colors_type_two'] = `${colorsType}`;
                    }
                    if (colorsMatriz !== undefined && colorsMatriz !== null) {
                        request_data['musell_colors_matriz_two'] = `${colorsMatriz}`;
                    }
                    if (colorsValue !== undefined && colorsValue !== null) {
                        request_data['musell_colors_value_two'] = `${colorsValue}`;
                    }
                    if (colorsChroma !== undefined && colorsChroma !== null) {
                        request_data['musell_colors_chroma_two'] = `${colorsChroma}`;
                    }
                    if (colorsMatrizExtra1 !== undefined && colorsMatrizExtra1 !== null) {
                        request_data['musell_colors_matriz_two_extra_1'] = `${colorsMatrizExtra1}`;
                    }
                    if (colorsValueExtra1 !== undefined && colorsValueExtra1 !== null) {
                        request_data['musell_colors_value_two_extra_1'] = `${colorsValueExtra1}`;
                    }
                    if (colorsChromaExtra1 !== undefined && colorsChromaExtra1 !== null) {
                        request_data['musell_colors_chroma_two_extra_1'] = `${colorsChromaExtra1}`;
                    }
                    if (colorsMatrizExtra2 !== undefined && colorsMatrizExtra2 !== null) {
                        request_data['musell_colors_matriz_two_extra_2'] = `${colorsMatrizExtra2}`;
                    }
                    if (colorsValueExtra2 !== undefined && colorsValueExtra2 !== null) {
                        request_data['musell_colors_value_two_extra_2'] = `${colorsValueExtra2}`;
                    }
                    if (colorsChromaExtra2 !== undefined && colorsChromaExtra2 !== null) {
                        request_data['musell_colors_chroma_two_extra_2'] = `${colorsChromaExtra2}`;
                    }
                    break;
                case '3':
                    if (colorsType !== undefined && colorsType !== null) {
                        request_data['musell_colors_type_three'] = `${colorsType}`;
                    }
                    if (colorsMatriz !== undefined && colorsMatriz !== null) {
                        request_data['musell_colors_matriz_three'] = `${colorsMatriz}`;
                    }
                    if (colorsValue !== undefined && colorsValue !== null) {
                        request_data['musell_colors_value_three'] = `${colorsValue}`;
                    }
                    if (colorsChroma !== undefined && colorsChroma !== null) {
                        request_data['musell_colors_chroma_three'] = `${colorsChroma}`;
                    }
                    if (colorsMatrizExtra1 !== undefined && colorsMatrizExtra1 !== null) {
                        request_data['musell_colors_matriz_three_extra_1'] = `${colorsMatrizExtra1}`;
                    }
                    if (colorsValueExtra1 !== undefined && colorsValueExtra1 !== null) {
                        request_data['musell_colors_value_three_extra_1'] = `${colorsValueExtra1}`;
                    }
                    if (colorsChromaExtra1 !== undefined && colorsChromaExtra1 !== null) {
                        request_data['musell_colors_chroma_three_extra_1'] = `${colorsChromaExtra1}`;
                    }
                    if (colorsMatrizExtra2 !== undefined && colorsMatrizExtra2 !== null) {
                        request_data['musell_colors_matriz_three_extra_2'] = `${colorsMatrizExtra2}`;
                    }
                    if (colorsValueExtra2 !== undefined && colorsValueExtra2 !== null) {
                        request_data['musell_colors_value_three_extra_2'] = `${colorsValueExtra2}`;
                    }
                    if (colorsChromaExtra2 !== undefined && colorsChromaExtra2 !== null) {
                        request_data['musell_colors_chroma_three_extra_2'] = `${colorsChromaExtra2}`;
                    }
                    break;
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                    }
                }).catch(() => {
                });
            } catch (e) {
            }
        }
    }

    function handleHorizonColorMottled() {
        setShowDialogHorizonColorMottled(!showDialogHorizonColorMottled);
        setShowDialogHorizonColorVariegated(false);
        setColorsType(showDialogHorizonColorMottled === true ? '0' : '1');
        setHorizonUpdate(true);
    }

    function handleHorizonColorVariegated() {
        setShowDialogHorizonColorVariegated(!showDialogHorizonColorVariegated);
        setShowDialogHorizonColorMottled(false);
        setColorsType(showDialogHorizonColorVariegated === true ? '0' : '2');
        setHorizonUpdate(true);
    }

    useEffect(() => {
        if (props.horizon !== undefined) {
            switch (props.number) {
                case '1':
                    if (props.horizon.musell_colors_type_one !== undefined) {
                        if (props.horizon.musell_colors_type_one === '1') {
                            setShowDialogHorizonColorMottled(true);
                            setShowDialogHorizonColorVariegated(false);
                        } else if (props.horizon.musell_colors_type_one === '2') {
                            setShowDialogHorizonColorVariegated(true);
                            setShowDialogHorizonColorMottled(false);
                        }
                    }
                    setColorsType(props.horizon.musell_colors_type_one);
                    setColorsMatriz(props.horizon.musell_colors_matriz_one);
                    setColorsValue(props.horizon.musell_colors_value_one);
                    setColorsChroma(props.horizon.musell_colors_chroma_one);
                    setColorsMatrizExtra1(props.horizon.musell_colors_matriz_one_extra_1);
                    setColorsValueExtra1(props.horizon.musell_colors_value_one_extra_1);
                    setColorsChromaExtra1(props.horizon.musell_colors_chroma_one_extra_1);
                    setColorsMatrizExtra2(props.horizon.musell_colors_matriz_one_extra_2);
                    setColorsValueExtra2(props.horizon.musell_colors_value_one_extra_2);
                    setColorsChromaExtra2(props.horizon.musell_colors_chroma_one_extra_2);
                    break;
                case '2':
                    if (props.horizon.musell_colors_type_two !== undefined) {
                        if (props.horizon.musell_colors_type_two === '1') {
                            setShowDialogHorizonColorMottled(true);
                            setShowDialogHorizonColorVariegated(false);
                        } else if (props.horizon.musell_colors_type_two === '2') {
                            setShowDialogHorizonColorVariegated(true);
                            setShowDialogHorizonColorMottled(false);
                        }
                    }
                    setColorsType(props.horizon.musell_colors_type_two);
                    setColorsMatriz(props.horizon.musell_colors_matriz_two);
                    setColorsValue(props.horizon.musell_colors_value_two);
                    setColorsChroma(props.horizon.musell_colors_chroma_two);
                    setColorsMatrizExtra1(props.horizon.musell_colors_matriz_two_extra_1);
                    setColorsValueExtra1(props.horizon.musell_colors_value_two_extra_1);
                    setColorsChromaExtra1(props.horizon.musell_colors_chroma_two_extra_1);
                    setColorsMatrizExtra2(props.horizon.musell_colors_matriz_two_extra_2);
                    setColorsValueExtra2(props.horizon.musell_colors_value_two_extra_2);
                    setColorsChromaExtra2(props.horizon.musell_colors_chroma_two_extra_2);
                    break;
                case '3':
                    if (props.horizon.musell_colors_type_three !== undefined) {
                        if (props.horizon.musell_colors_type_three === '1') {
                            setShowDialogHorizonColorMottled(true);
                            setShowDialogHorizonColorVariegated(false);
                        } else if (props.horizon.musell_colors_type_three === '2') {
                            setShowDialogHorizonColorVariegated(true);
                            setShowDialogHorizonColorMottled(false);
                        }
                    }
                    setColorsType(props.horizon.musell_colors_type_three);
                    setColorsMatriz(props.horizon.musell_colors_matriz_three);
                    setColorsValue(props.horizon.musell_colors_value_three);
                    setColorsChroma(props.horizon.musell_colors_chroma_three);
                    setColorsMatrizExtra1(props.horizon.musell_colors_matriz_three_extra_1);
                    setColorsValueExtra1(props.horizon.musell_colors_value_three_extra_1);
                    setColorsChromaExtra1(props.horizon.musell_colors_chroma_three_extra_1);
                    setColorsMatrizExtra2(props.horizon.musell_colors_matriz_three_extra_2);
                    setColorsValueExtra2(props.horizon.musell_colors_value_three_extra_2);
                    setColorsChromaExtra2(props.horizon.musell_colors_chroma_three_extra_2);
                    break;
            }
        }
        // eslint-disable-next-line
    }, [props.horizon, props.number]);

    useEffect(() => {
        if (horizonUpdate === true) {
            updateHorizon();
            setHorizonUpdate(false);
        }
        return () => setHorizonUpdate(false);
        // eslint-disable-next-line
    }, [horizonUpdate === true]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={`${props.horizon.id}${props.horizon.name}musell_colors_type_one`} className={styles.view_horizon_munsell}
        >
            <Grid item xs={12} className={styles.view_horizon_munsell_title}>
                <Typography className={styles.text_horizon_munsell}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            <Grid item xs={8} className={styles.view_horizon_munsell_content}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.view_horizon_munsell_checkobx_content}
                >
                    <Grid item xs={6}>
                        <Button onClick={() => handleHorizonColorMottled()} className={styles.button_checkobox_munsell_mottled_variegated}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={2}>
                                    <Checkbox
                                        {...label}
                                        sx={{
                                            color: '#CC6B3B',
                                            '&.Mui-checked': {
                                                color: '#CC6B3B',
                                            },
                                        }}
                                        checked={showDialogHorizonColorMottled}
                                        onClick={() => handleHorizonColorMottled()}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={styles.text_check_item}>Mosqueado</Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => handleHorizonColorVariegated()} className={styles.button_checkobox_munsell_mottled_variegated}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={2}>
                                    <Checkbox
                                        {...label}
                                        sx={{
                                            color: '#CC6B3B',
                                            '&.Mui-checked': {
                                                color: '#CC6B3B',
                                            },
                                        }}
                                        checked={showDialogHorizonColorVariegated}
                                        onClick={() => handleHorizonColorVariegated()}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={styles.text_check_item}>Variegado</Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8} className={styles.view_horizon_munsell_inputs_content}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.view_horizon_munsell_checkobx_content}
                >
                    <Grid item xs={4} className={styles.grid_input_data_munsell}>
                        <TextField
                            className={styles.input_data_munsell}
                            value={colorsMatriz}
                            placeholder='Matiz'
                            onChange={
                                (value: React.ChangeEvent<HTMLInputElement>) => {
                                    setColorsMatriz(value.target.value);
                                    setHorizonUpdate(true);
                                }
                            }
                        />
                    </Grid>
                    <Grid item xs={4} className={styles.grid_input_data_munsell}>
                        <TextField
                            className={styles.input_data_munsell}
                            value={colorsValue}
                            placeholder='Valor'
                            onChange={
                                (value: React.ChangeEvent<HTMLInputElement>) => {
                                    setColorsValue(value.target.value);
                                    setHorizonUpdate(true);
                                }
                            }
                        />
                    </Grid>
                    <Grid item xs={4} className={styles.grid_input_data_munsell}>
                        <TextField
                            className={styles.input_data_munsell}
                            value={colorsChroma}
                            placeholder='Croma'
                            onChange={
                                (value: React.ChangeEvent<HTMLInputElement>) => {
                                    setColorsChroma(value.target.value);
                                    setHorizonUpdate(true);
                                }
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            {(showDialogHorizonColorMottled === true || showDialogHorizonColorVariegated === true) && (
                <Grid item xs={8}>
                    <Grid container className={styles.view_horizon_extra_munsell_view}>
                        <Grid item xs={12}>
                            {(showDialogHorizonColorMottled === true) && (
                                <Typography className={styles.text_horizon_munsell_subtitle}>
                                    Mosqueado
                                </Typography>
                            )}
                        </Grid>
                        <Grid container className={styles.view_horizon_munsell_inputs_content}>
                            <Grid item xs={4} className={styles.grid_input_data_munsell}>
                                <TextField
                                    className={styles.input_data_munsell}
                                    value={colorsMatrizExtra1}
                                    placeholder='Matiz'
                                    onChange={
                                        (value: React.ChangeEvent<HTMLInputElement>) => {
                                            setColorsMatrizExtra1(value.target.value);
                                            setHorizonUpdate(true);
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} className={styles.grid_input_data_munsell}>
                                <TextField
                                    className={styles.input_data_munsell}
                                    value={colorsValueExtra1}
                                    placeholder='Valor'
                                    onChange={
                                        (value: React.ChangeEvent<HTMLInputElement>) => {
                                            setColorsValueExtra1(value.target.value);
                                            setHorizonUpdate(true);
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} className={styles.grid_input_data_munsell}>
                                <TextField
                                    className={styles.input_data_munsell}
                                    value={colorsChromaExtra1}
                                    placeholder='Croma'
                                    onChange={
                                        (value: React.ChangeEvent<HTMLInputElement>) => {
                                            setColorsChromaExtra1(value.target.value);
                                            setHorizonUpdate(true);
                                        }
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={styles.view_horizon_munsell_inputs_content}>
                            <Grid item xs={4} className={styles.grid_input_data_munsell}>
                                <TextField
                                    className={styles.input_data_munsell}
                                    value={colorsMatrizExtra2}
                                    placeholder='Matiz'
                                    onChange={
                                        (value: React.ChangeEvent<HTMLInputElement>) => {
                                            setColorsMatrizExtra2(value.target.value);
                                            setHorizonUpdate(true);
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} className={styles.grid_input_data_munsell}>
                                <TextField
                                    className={styles.input_data_munsell}
                                    value={colorsValueExtra2}
                                    placeholder='Valor'
                                    onChange={
                                        (value: React.ChangeEvent<HTMLInputElement>) => {
                                            setColorsValueExtra2(value.target.value);
                                            setHorizonUpdate(true);
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} className={styles.grid_input_data_munsell}>
                                <TextField
                                    className={styles.input_data_munsell}
                                    value={colorsChromaExtra2}
                                    placeholder='Croma'
                                    onChange={
                                        (value: React.ChangeEvent<HTMLInputElement>) => {
                                            setColorsChromaExtra2(value.target.value);
                                            setHorizonUpdate(true);
                                        }
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}