import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CalculateIcon from '@mui/icons-material/Calculate';
import {
    IRequestData,
    IHorizon,
    IChemicalMenuOptions,
} from '../../../types';
import apiService from '../../../services/apiService';
import { AuthContext } from '../../../contexts/Auth/AuthContext';

interface IItemProps {
    examId: string | undefined
    options: IChemicalMenuOptions
    horizon: IHorizon
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function ChemicalItem(props: IItemProps) {
    const auth = React.useContext(AuthContext);
    const styles = useStyles();
    const [value, setValue] = useState<string>('');
    const [valueCalculator, setValueCalculator] = useState<boolean>(false);

    function handleTextField(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setValue(event.target.value.toString());
        updateChemical(event.target.value.toString());
    }

    async function updateChemical(dataValue: string) {
        if (
            auth !== null &&
            auth.user !== undefined &&
            props.examId !== undefined &&
            props.horizon !== undefined &&
            props.horizon.chemical !== undefined &&
            props.horizon.chemical?.id !== undefined &&
            dataValue.toLocaleLowerCase() !== 'nan'
        ) {
            let request_data: IRequestData = {
                chemical_id: props.horizon.chemical.id,
                exam_id: parseFloat(props.examId),
            };
            const objectArray = Object.entries(props.horizon.chemical);
            const arrayValues = Object.fromEntries(objectArray);
            if (arrayValues[props.options.keyData] !== dataValue) {
                request_data[`${props.options.keyData}`] = `${dataValue}`;

                try {
                    await apiService.put(
                        'exams/chemical',
                        request_data
                    ).then((response) => {
                        if (response !== undefined && response.status === 200) {
                            props.renewHorizon(response.data.data as Array<IHorizon>);
                        }
                    }).catch((error) => {
                        console.log('updateChemical-error: ', error.response);
                    });
                } catch (e) {
                    console.log('updateChemical-e: ', e)
                }
            }
        }
    }

    useEffect(() => {
        if (props.horizon !== undefined && props.horizon.chemical !== undefined && props.options.keyData !== undefined) {
            const objectArray = Object.entries(props.horizon.chemical);
            const arrayValues = Object.fromEntries(objectArray);
            if ([7, 10, 11, 12, 14, 17, 24, 25, 26].includes(props.options.id)) {
                setValueCalculator(true);
                let valueCalculate1 = 0;
                let valueCalculate2 = 0;
                let valueCalculate3 = 0;
                switch (props.options.id) {
                    case 7: // AssortedComplexSValue: OK
                        valueCalculate1 = parseFloat((arrayValues['AssortedComplexCa2']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexMg2']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexK']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexNa']).replace(",", "."));

                        if (value !== `${(valueCalculate1).toFixed(props.options.decimalLimit)}`) {
                            setValue(`${(valueCalculate1).toFixed(props.options.decimalLimit)}`);
                            updateChemical(`${(valueCalculate1).toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 10: // AssortedComplexTValue: OK
                        valueCalculate1 = parseFloat((arrayValues['AssortedComplexCa2']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexMg2']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexK']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexNa']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexAl3']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexH']).replace(",", "."));

                        if (value !== `${(valueCalculate1).toFixed(props.options.decimalLimit)}`) {
                            setValue(`${(valueCalculate1).toFixed(props.options.decimalLimit)}`);
                            updateChemical(`${(valueCalculate1).toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 11: // AssortedComplexVValue: OK
                        valueCalculate1 = parseFloat((arrayValues['AssortedComplexCa2']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexMg2']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexK']).replace(",", "."));
                        valueCalculate1 = valueCalculate1 + parseFloat((arrayValues['AssortedComplexNa']).replace(",", "."));

                        valueCalculate2 = parseFloat((arrayValues['AssortedComplexCa2']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexMg2']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexK']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexNa']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexAl3']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexH']).replace(",", "."));

                        if (valueCalculate1 <= 0 || valueCalculate2 <= 0) {
                            valueCalculate3 = 0;
                        } else {
                            valueCalculate3 = (valueCalculate1 / valueCalculate2);
                            valueCalculate3 = 100 * valueCalculate3;
                        }

                        if (value !== `${(valueCalculate3).toFixed(props.options.decimalLimit)}`) {
                            setValue(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                            updateChemical(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 12: // AssortedComplexAluminumSaturation: OK
                        // AssortedComplexAl3
                        valueCalculate1 = parseFloat((arrayValues['AssortedComplexAl3']).replace(",", "."));

                        // AssortedComplexSValue
                        valueCalculate2 = parseFloat((arrayValues['AssortedComplexCa2']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexMg2']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexK']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexNa']).replace(",", "."));

                        // (AssortedComplexAl3 + AssortedComplexSValue)
                        valueCalculate3 = valueCalculate1 + valueCalculate2;

                        // AssortedComplexAl3 / (AssortedComplexAl3 + AssortedComplexSValue)
                        valueCalculate3 = valueCalculate3 <= 0 ? 0 : valueCalculate1 / valueCalculate3;

                        // 100 * (AssortedComplexAl3 / (AssortedComplexAl3 + AssortedComplexSValue) )
                        valueCalculate3 = 100 * valueCalculate3;

                        if (value !== `${(valueCalculate3).toFixed(props.options.decimalLimit)}`) {
                            setValue(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                            updateChemical(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 17: // CN: OK
                        valueCalculate1 = parseFloat((arrayValues['COrganic']).replace(",", "."));
                        valueCalculate2 = parseFloat((arrayValues['N']).replace(",", "."));

                        valueCalculate3 = ((valueCalculate1 === 0 || valueCalculate2 === 0) ? 0 : (valueCalculate1 / valueCalculate2));

                        if (value !== `${(valueCalculate3).toFixed(props.options.decimalLimit)}`) {
                            setValue(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                            updateChemical(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 24: // MolecularRelationsSiO2AI2O3: OK
                        valueCalculate1 = parseFloat((arrayValues['SulfuricAttackSiO2']).replace(",", "."));
                        valueCalculate2 = parseFloat((arrayValues['SulfuricAttackAl2O3']).replace(",", "."));

                        if (valueCalculate1 <= 0 || valueCalculate2 <= 0) {
                            valueCalculate3 = 0;
                        } else {
                            valueCalculate3 = (valueCalculate1 / valueCalculate2);
                            valueCalculate3 = 1.7 * valueCalculate3;
                        }

                        if (value !== `${(valueCalculate3).toFixed(props.options.decimalLimit)}`) {
                            setValue(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                            updateChemical(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 25: // MolecularRelationsSiO2R2O3: OK
                        valueCalculate1 = parseFloat((arrayValues['SulfuricAttackAl2O3']).replace(",", ".")) / 1.02;
                        valueCalculate2 = parseFloat((arrayValues['SulfuricAttackFe2O3']).replace(",", ".")) / 1.6;

                        valueCalculate1 = parseFloat((valueCalculate1 + valueCalculate2).toFixed(props.options.decimalLimit));

                        valueCalculate2 = parseFloat((parseFloat((arrayValues['SulfuricAttackSiO2']).replace(",", ".")) / 0.6).toFixed(props.options.decimalLimit));

                        valueCalculate3 = valueCalculate2 / valueCalculate1;

                        if (value !== `${(valueCalculate3).toFixed(props.options.decimalLimit)}`) {
                            setValue(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                            updateChemical(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 26: // MolecularRelationsAl2O3Fe2O3: OK
                        valueCalculate1 = parseFloat((arrayValues['SulfuricAttackAl2O3']).replace(",", "."));
                        valueCalculate2 = parseFloat((arrayValues['SulfuricAttackFe2O3']).replace(",", "."));
                        if (valueCalculate1 <= 0 || valueCalculate2 <= 0) {
                            valueCalculate3 = 0;
                        } else {
                            valueCalculate3 = (valueCalculate1 / valueCalculate2);
                            valueCalculate3 = 1.57 * valueCalculate3;
                        }

                        if (value !== `${(valueCalculate3).toFixed(props.options.decimalLimit)}`) {
                            setValue(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                            updateChemical(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 14: // AssortedComplexSodiumSaturation: OK
                        valueCalculate1 = parseFloat((arrayValues['AssortedComplexNa']).replace(",", "."));

                        valueCalculate2 = parseFloat((arrayValues['AssortedComplexCa2']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexMg2']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexK']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexNa']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexAl3']).replace(",", "."));
                        valueCalculate2 = valueCalculate2 + parseFloat((arrayValues['AssortedComplexH']).replace(",", "."));

                        if (valueCalculate1 <= 0 || valueCalculate2 <= 0) {
                            valueCalculate3 = 0;
                        } else {
                            valueCalculate3 = (valueCalculate1 / valueCalculate2);
                            valueCalculate3 = 100 * valueCalculate3;
                        }

                        if (value !== `${(valueCalculate3).toFixed(props.options.decimalLimit)}`) {
                            setValue(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                            updateChemical(`${(valueCalculate3).toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                }
            } else {
                if (arrayValues[props.options.keyData] !== undefined && arrayValues[props.options.keyData] !== 0) {
                    setValue(arrayValues[props.options.keyData]);
                } else {
                    setValue('');
                }
            }
        }
        // eslint-disable-next-line
    }, [props]);

    return (
        <Grid container key={`${props.horizon.chemical.id}${props.options.name}-value`} className={styles.view_chemical_value}>
            <Grid item xs={12} className={styles.view_chemical_value_title}>
                <Grid container>
                    <Grid item xs={12} className={styles.grid_item_name_horizon}>
                        <Typography className={styles.text_chemical_value}>
                            {props.horizon.name} ({props.options.label})
                        </Typography>
                        {(valueCalculator === true) && (
                            <CalculateIcon className={styles.icon_calculator} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={styles.view_chemical_value_content}>
                {(valueCalculator === true) && (
                    <Typography className={styles.typography_input_data_value}>
                        {`${(value.toLowerCase() === 'nan' ? '-' : value)}`}
                    </Typography>
                )}
                {(valueCalculator !== true) && (
                    <TextField
                        className={styles.input_data_value}
                        value={value}
                        placeholder={``}
                        onChange={handleTextField}
                    />
                )}
            </Grid>
        </Grid>
    );
}