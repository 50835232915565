import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles, { ImageSmartSolos } from './styles';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import Footer from '../Footer';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import smartsoloslogo from '../../assets/images/logo/logo_white.png';

const AuthBase = ({ children }: { children: JSX.Element }) => {
    const navigate = useNavigate();
    const auth = React.useContext(AuthContext);
    const styles = useStyles();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }

    // const gotToPageBreadcrumbs = (page: string) => {
    //     navigate(`/${page}`);
    // }

    const handleLinkMenu = (page: string) => {
        navigate(`/${page}`);
        handleCloseNavMenu()
    }

    const handleMenuUser = (page: string) => {
        navigate(`/${page}`);
        handleCloseUserMenu();
    }

    async function handleLogout() {
        await auth?.signOut();
        navigate('/');
        handleCloseUserMenu();
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={0}
            className={styles.grid_root}
        >
            <Grid item xs={12} className={styles.grid_menu_bar}>
                <AppBar position="static">
                    <Toolbar className={styles.app_bar}>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                            <ImageSmartSolos alt="Smart Solos" src={`${smartsoloslogo}`} />
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuItem key={`item001`} onClick={() => handleLinkMenu('projetos')}>
                                    <Typography textAlign="center">Projetos</Typography>
                                </MenuItem>
                                <MenuItem key={`item002`} onClick={() => handleLinkMenu('grupos')}>
                                    <Typography textAlign="center">Grupos</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                            <ImageSmartSolos alt="Smart Solos" src={`${smartsoloslogo}`} />
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <Button
                                key={`item0001`}
                                onClick={() => handleLinkMenu('projetos')}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Projetos
                            </Button>
                            <Button
                                key={`item0002`}
                                onClick={() => handleLinkMenu('grupos')}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Grupos
                            </Button>
                            <Button
                                key={`item0003`}
                                onClick={() => handleLinkMenu('creditos')}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Créditos
                            </Button>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title={`${auth?.user?.name}`}>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt={`${auth?.user?.name}`} src="/static/images/avatar/2.jpg" />
                                    <Typography textAlign="center" color={'#FFF'} marginLeft={1}>{`${auth?.user?.name}`}</Typography>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key={`item00001`} onClick={() => handleMenuUser('perfil')}>
                                    <Typography textAlign="center">Perfil</Typography>
                                </MenuItem>
                                {(auth !== null && auth.user !== null && auth.user.type === 'admin') && (
                                    <MenuItem key={`item00001-1`} onClick={() => handleMenuUser('usuarios')}>
                                        <Typography textAlign="center">Usuários</Typography>
                                    </MenuItem>
                                )}
                                {(auth !== null && auth.user !== null && auth.user.type === 'admin') && (
                                    <MenuItem key={`item00001-2`} onClick={() => handleMenuUser('logs')}>
                                        <Typography textAlign="center">Logs</Typography>
                                    </MenuItem>
                                )}
                                {(auth !== null && auth.user !== null && auth.user.type === 'admin') && (
                                    <MenuItem key={`item00002`} onClick={() => handleMenuUser('configuracoes')}>
                                        <Typography textAlign="center">Configurações</Typography>
                                    </MenuItem>
                                )}
                                <MenuItem key={`item00003`} onClick={handleLogout}>
                                    <Typography textAlign="center">Sair</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item xs={12} className={styles.grid_item_container}>
                <Container className={styles.container}>
                    <ToastContainer />
                    {children}
                </Container>
            </Grid>
            <Grid item xs={12} className={styles.grid_modal_item_botton}>
                <Footer />
            </Grid>
        </Grid>
    );
}

export default AuthBase;