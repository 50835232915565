import { makeStyles, DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        flexWrap: 'nowrap',
        flexDirection: 'column',
    },
    grid_item: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
    },
    text: {
        paddingTop: 10,
        paddingBottom: 10,
        color: '#000000',
        textAlign: 'center',
        fontSize: 18,
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        alignItems: 'center',
    },
    circular_progress: {
        color: '#06617c !important',
    },
});

export default useStyles;