import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
    IFilterHorizons,
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    horizonFilters: IFilterHorizons
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonPoresItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [poresSize, setPoresSize] = useState<string>('0');
    const [poresQuantity, setPoresQuantity] = useState<string>('0');

    const [poresUpdate, setPoresUpdate] = useState<boolean>(false);

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            if (
                poresSize !== null &&
                poresSize !== '0' &&
                props.horizon.pores_size !== `${poresSize}`
            ) {
                request_data['pores_size'] = `${poresSize}`;
            }
            if (
                poresQuantity !== null &&
                poresQuantity !== '0' &&
                props.horizon.pores_quantity !== `${poresQuantity}`
            ) {
                request_data['pores_quantity'] = `${poresQuantity}`;
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                    }
                }).catch(() => {
                });
            } catch (e) {
            }
        }
    }

    useEffect(() => {
        if (poresUpdate === true) {
            updateHorizon();
            setPoresUpdate(false);
        }
        return () => setPoresUpdate(false);
        // eslint-disable-next-line
    }, [poresUpdate]);

    useEffect(() => {
        if (props.horizon !== undefined) {
            setPoresSize((props.horizon.pores_size === null ? '0' : props.horizon.pores_size));
            setPoresQuantity((props.horizon.pores_quantity === null ? '0' : props.horizon.pores_quantity));
        }
        // eslint-disable-next-line
    }, [props]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={`${props.horizon.id}${props.horizon.name}pores_size`}
            className={styles.view_horizon_pores}
        >
            <Grid item xs={12} className={styles.view_horizon_pores_title}>
                <Typography className={styles.text_horizon_pores}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_pores_content}>
                <Typography className={styles.text_horizon_pores_subtitle}>
                    Tamanho
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_pores_content}>
                <TextField
                    className={styles.picker_select_type}
                    select
                    value={poresSize ? poresSize : "0"}
                    defaultValue={'0'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPoresSize(`${event.target.value}`);
                        setPoresUpdate(true);
                    }}
                >
                    <MenuItem className={styles.picker_select_item} key={'0'} value={'0'}>Selecione</MenuItem>
                    {props.horizonFilters.pores_size.map((element, key) => (
                        <MenuItem className={styles.picker_select_item} key={key} value={`${key + 1}`}>{`${element}`}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_pores_content}>
                <Typography className={styles.text_horizon_pores_subtitle}>
                    Quantidade
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_pores_content}>
                <TextField
                    className={styles.picker_select_type}
                    select
                    value={poresQuantity ? poresQuantity : "0"}
                    defaultValue={'0'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPoresQuantity(`${event.target.value}`);
                        setPoresUpdate(true);
                    }}
                >
                    <MenuItem className={styles.picker_select_item} key={'0'} value={'0'}>Selecione</MenuItem>
                    {props.horizonFilters.pores_quantity.map((element, key) => (
                        <MenuItem className={styles.picker_select_item} key={key} value={`${key + 1}`}>{`${element}`}</MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    );
}