import * as React from 'react';
import useStyles from './styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

interface IReceiptProps {
    message?: string
}

export default function ShowLoader(props: IReceiptProps): JSX.Element {
    const styles = useStyles();
    return (
        <Grid
            container
            className={styles.grid_root}
        >
            <Grid item xs={12} className={styles.grid_item}>
                <CircularProgress className={styles.circular_progress} />
            </Grid>
            <Grid item xs={12} className={styles.grid_item}>
                {(props.message !== undefined) && (
                    <Typography className={styles.text}>{props.message}</Typography>
                )}
            </Grid>
        </Grid>
    );
}