import * as React from 'react';
import useStyles, { ImageSmartSolos } from './styles';
import BannerSmartSolos from '../BannerSmartSolos';
import smartsoloslogo from '../../assets/images/logo/logo_colored.png';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import Footer from '../Footer';
import Grid from '@mui/material/Grid';

const Base = ({ children }: { children: JSX.Element }) => {
    const styles = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            className={styles.grid_root}
        >
            <Hidden mdDown>
                <Grid item md={7} className={styles.grid_logo}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        className={styles.grid_logo_root}
                    >
                        <Grid item xs={12} className={styles.grid_logo_item}>
                            <BannerSmartSolos />
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Grid item xs={12} md={5} className={styles.grid_modal_root}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    className={styles.grid_modal}
                >
                    <Grid item xs={12} className={styles.grid_modal_item_top}>
                        <Typography
                            component="h1"
                            color="white"
                            hidden
                        >
                            smart solos
                        </Typography>
                        <ImageSmartSolos alt="Smart Solos" src={`${smartsoloslogo}`} />
                    </Grid>
                    <Grid item xs={12} className={styles.grid_modal_item_content}>
                        {children}
                    </Grid>
                    <Grid item xs={12} className={styles.grid_modal_item_botton}>
                        <Footer />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Base;