import React, { useState } from 'react';
import useStyles, { BootstrapDialog } from './styles';
import { useParams, useNavigate } from 'react-router-dom';
import { IGroup, IUser, IUserGroup, IUserMessageGroup, IUserMessageGroupDetail } from '../../types';
import apiService from '../../services/apiService';
import { cutString, formatDateToString } from '../../formatters';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import ShowLoader from '../../components/ShowLoaderComponent';
import BootstrapDialogTitle from '../../components/BootstrapDialogTitle';
import AuthBase from '../../components/AuthBase';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';

let photoProfile = require('../../assets/images/tab_bar/profile.png');

export default function GroupDetailsPage() {
    const auth = React.useContext(AuthContext);
    let { groupId } = useParams();
    const styles = useStyles();
    const navigate = useNavigate();

    const [groupName, setGroupName] = useState<string>('');
    const [errorGroupName, setErrorGroupName] = useState<boolean>(false);

    const [userId, setUserId] = useState<number | undefined>(undefined);
    const [userName, setUserName] = useState<string>('');

    const [email, setEmail] = useState<string | undefined>(undefined);
    const [errorEmail, setErrorEmail] = useState<boolean>(false);

    const [message, setMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<boolean>(false);

    const [lockData, setlockData] = React.useState<boolean>(false);

    const [userSelected, setUserSelected] = useState<IUser | undefined>(undefined);

    const [getGroupDetailsData, setGetGroupDetailsData] = useState<Array<IUserGroup> | undefined>(undefined);
    const [getGroupMessageData, setGetGroupMessageData] = useState<Array<IUserMessageGroup> | undefined>(undefined);
    const [getGroupMessageDetailData, setGetGroupMessageDetailData] = useState<Array<IUserMessageGroupDetail> | undefined>(undefined);

    const [arUser, setArUser] = React.useState<Array<IUser> | null | undefined>(undefined);
    const [group, setGroup] = React.useState<IGroup | null | undefined>(undefined);

    const [viewMessagesDetail, setViewMessagesDetail] = useState<boolean>(false);

    const [showDialogUpdateNameGroup, setShowDialogUpdateNameGroup] = useState<boolean>(false);
    const [showDialogInviteUserGroup, setShowDialogInviteUserGroup] = useState<boolean>(false);
    const [showDialogDeleteUserGroup, setShowDialogDeleteUserGroup] = useState<boolean>(false);
    const [showDialogDeleteGroup, setShowDialogDeleteGroup] = useState<boolean>(false);

    const [loaderBox, setLoaderBox] = useState<boolean>(false);
    const [loadingDialog, setLoadingDialog] = React.useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = React.useState<boolean>(false);
    const [messageLoader, setMessageLoader] = useState<string | undefined>(undefined);

    function setStateLoader(
        status: boolean,
        message: string | undefined = undefined
    ) {
        if (status === true) {
            if (message !== undefined) {
                setMessageLoader(message);
            }
            setLoaderBox(true);
        } else {
            setLoaderBox(false);
            setMessageLoader(undefined);
        }
    }

    function showUserChat(userMessage: IUserMessageGroup) {
        let newUserMessage: IUser = {
            id: userMessage.user_id,
            name: userMessage.user_name !== undefined ? userMessage.user_name : '-',
            email: userMessage.user_name !== undefined ? userMessage.user_name : '-',
            job_position: userMessage.user_name !== undefined ? userMessage.user_name : '-',
            about: userMessage.user_name !== undefined ? userMessage.user_name : '-'
        };
        setUserSelected(newUserMessage);
        getGroupMessageDetail(userMessage.user_id);
        setReadMessage(userMessage.user_id);
    }

    function changeUserInvite(itemValue: string) {
        if (itemValue === 'undefined') {
            setUserId(undefined);
        } else {
            setUserId(parseInt(itemValue));
        }
    }

    function handleSetGroupName(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setGroupName(event.target.value);
    }

    function handleSetEmail(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setEmail(event.target.value);
    }

    function handleSetMessage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setMessage(event.target.value);
    }

    function handleShowRemoveUserByGroup(userName: string, userId: number) {
        console.log('userId:', userId);
        setShowDialogDeleteUserGroup(true);
        setGroupName(`${group?.name}`);
        setUserId(userId);
        setUserName(userName);
    }

    function handleHideRemoveUserByGroup() {
        setShowDialogDeleteUserGroup(false);
        setLoadingDialog(false);
        setTimeout(() => {
            setGroupName('');
            setUserId(undefined);
            setUserName('');
        }, 400);
    }

    function handleShowUpdateGroupName() {
        setGroupName(`${group?.name}`);
        setShowDialogUpdateNameGroup(true);
    }

    function handleHideUpdateGroupName() {
        setShowDialogUpdateNameGroup(false);
        setTimeout(() => {
            setGroupName('');
        }, 400);
    }

    function handleShowBoxRemoveGroup() {
        setShowDialogDeleteGroup(true);
    }

    function handleShowInviteUsers() {
        setShowDialogInviteUserGroup(true);
    }

    function sentMessage() {
        storeMessage();
    }

    function goToBack() {
        // eslint-disable-next-line
        navigate(-1);
    }

    function validateSentMessage() {
        let error = false;
        setErrorMessage(false);
        if (message === '') {
            error = true;
            setErrorMessage(true);
        }

        return error;
    }

    function validateUpdateGroupName() {
        let error = false;
        setErrorGroupName(false);
        if (groupName === '') {
            error = true;
            setErrorGroupName(true);
        }

        return error;
    }

    function validateEmail() {
        let error = false;
        setErrorEmail(false);
        if (email === '') {
            error = true;
            setErrorEmail(true);
        }

        return error;
    }

    async function storeMessage() {
        if (auth !== undefined && group !== undefined && group !== null && userSelected !== undefined && message !== '') {
            if (validateSentMessage()) {
                toast.warn('O conteúdo da mensagem é inválido.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false;
            }
            try {
                setlockData(true);
                setLoadingMessage(true);
                await apiService.post(
                    'groups/messages',
                    {
                        group_id: group!.id,
                        user_id: userSelected.id,
                        message: message
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200 && response.data !== undefined) {
                        setMessage('');
                        setGetGroupMessageDetailData(response.data.messages as Array<IUserMessageGroupDetail>);
                        setLoadingMessage(false);
                    } else {
                        setMessage('');
                        toast.error('Houve um erro com o envio da mensagem...', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setLoadingMessage(false);
                    }
                    setlockData(false);
                }).catch(() => {
                    setMessage('');
                    setLoadingMessage(false);
                    toast.error('Houve um erro com o envio da mensagem...', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            } catch (e) {
                toast.error(`Houve um erro com a exclusão do grupo ${groupName}...`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setMessage('');
                setLoadingMessage(false);
            }
        }
    }

    async function requestUpdateGroupName() {
        if (auth !== undefined && group !== undefined) {
            if (validateUpdateGroupName()) {
                toast.warning('Nome do grupo inválido.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false;
            }
            setLoadingDialog(true);
            try {
                await apiService.put(
                    'groups',
                    {
                        group_id: group!.id,
                        name: groupName
                    },
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        toast.success('Autlizado com sucesso.', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        getGroupDetails();
                    }
                    setShowDialogUpdateNameGroup(false);
                    setLoadingDialog(false);
                    setGroupName('');
                }).catch((error) => {
                    console.log('requestUpdateGroupName-error: ', error);
                    setShowDialogUpdateNameGroup(false);
                    setLoadingDialog(false);
                    setGroupName('');
                });
            } catch (e) {
                setShowDialogUpdateNameGroup(false);
                setLoadingDialog(false);
                setGroupName('');
            }
        }
    }

    async function RemoveGroup() {
        if (auth !== undefined && group !== undefined && group !== null) {
            try {
                setLoadingDialog(true);
                await apiService.delete(
                    'groups',
                    { group_id: groupId }
                ).then((response) => {
                    if (response !== undefined && response.status === 200 && response.data !== undefined) {
                        setGroup(undefined);
                        setShowDialogDeleteGroup(false);
                        setLoadingDialog(false);
                        toast.success('Grudo excluído com sucesso!', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // eslint-disable-next-line
                        navigate(-1);
                    } else {
                        toast.error('Houve um erro com a exclusão deste grupo...', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // eslint-disable-next-line
                        navigate(-1);
                        setLoadingDialog(false);
                    }
                }).catch((error) => {
                    setLoadingDialog(false);
                    if (error.response.status === 409) {
                        toast.warning(`Você não pode excluir este grupo, pois ele não lhe pertence.`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error('Houve um erro com a exclusão deste grupo...', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    // eslint-disable-next-line
                    navigate(-1);
                    setGroup(undefined);
                    setShowDialogDeleteGroup(false);
                    setLoadingDialog(false);
                });
            } catch (e) {
                toast.error('Houve um erro com a exclusão deste grupo...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // eslint-disable-next-line
                navigate(-1);
                setGroup(undefined);
                setShowDialogDeleteGroup(false);
                setLoadingDialog(false);
            }
        }
    }

    async function setReadMessage(userId: number) {
        if (auth !== undefined) {
            try {
                await apiService.put(
                    `groups/messages`,
                    {
                        group_id: groupId,
                        user_id: userId
                    }
                );
            } catch (e) {
                toast.error('Houve um erro com o carregamento dos dados deste grupo...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    async function removeUserGroup() {
        if (auth !== null && userId !== undefined && group !== undefined) {
            setLoadingDialog(true);
            await apiService.delete(
                'groups/delete_invite',
                {
                    user_id: userId,
                    group_id: group!.id
                },
            ).then((response) => {
                if (response !== undefined && response.status === 201) {
                    toast.success('Usuário removido com sucesso!', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    getGroupDetails();
                } else {
                    toast.error('Houve um erro com a remover do usuário no grupo...', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                handleHideRemoveUserByGroup();
            }).catch((error) => {
                toast.error('Houve um erro com a remover do usuário no grupo...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleHideRemoveUserByGroup();
            });
        } else {
            alert(userId);
        }
    }

    async function inviteGroup() {
        if (auth !== undefined && email !== '' && email !== undefined) {
            if (validateEmail() === true) {
                toast.warning('Para realizar o convite por e-mail, deve-se inserir o e-mail corretamente.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setLoadingDialog(true);
                await apiService.post(
                    'groups/invite_to',
                    {
                        email: email,
                        group_id: group!.id
                    },
                ).then((response) => {
                    if (response !== undefined && response.status === 201) {
                        toast.success('Convite enviado com sucesso!', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setShowDialogInviteUserGroup(false);
                        setEmail(undefined);
                        getGroupDetails();
                    } else {
                        toast.error('Houve um erro com o convite o usuário para o grupo...', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setShowDialogInviteUserGroup(false);
                    }
                    setLoadingDialog(false);
                }).catch((error) => {
                    if (error.response.status === 302) {
                        toast.warning('Este e-mail possui cadastro no App. Por favor, selecione o mesmo para acelerar a participação do mesmo.', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setShowDialogInviteUserGroup(false);
                        setEmail(undefined);
                    } else if (error.response.status === 301) {
                        toast.warning('Este e-mail já possui convite em andamento para este grupo.', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setShowDialogInviteUserGroup(false);
                        setEmail(undefined);
                    } else {
                        toast.error('Houve um erro com o convite do usuário para o grupo...', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    setShowDialogInviteUserGroup(false);
                    setEmail(undefined);
                    setLoadingDialog(false);
                });
            }
        } else if (userId !== undefined) {
            setLoadingDialog(true);
            await apiService.post(
                'groups/added_to',
                {
                    user_id: userId,
                    group_id: group!.id
                }
            ).then((response) => {
                if (response !== undefined && response.status === 201) {
                    toast.success('Convite enviado com sucesso!', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setShowDialogInviteUserGroup(false);
                    getGroupDetails();
                } else {
                    toast.error('Houve um erro com o convite o usuário para o grupo...', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setUserId(undefined);
                setLoadingDialog(false);
            }).catch((error) => {
                if (error.response.status === 302) {
                    alert('.');
                    toast.warning('Este usuário não possui cadastro no App. Por favor, relize o convite pelo envio de email.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (error.response.status === 301) {
                    toast.warning('Este usuário já está convidado para este grupo.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error('Houve um erro com o convite o usuário para o grupo...', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setUserId(undefined);
                setLoadingDialog(false);
            });
        }
    }

    async function getUsers() {
        if (auth !== undefined && arUser === undefined) {
            try {
                await apiService.get(
                    'users',
                    {}
                ).then((response) => {
                    if (response !== undefined) {
                        setArUser(response.data.data);
                    } else {
                        setArUser(null);
                    }
                }).catch((error) => {
                    toast.error('Houve um erro com o carregamento dos dados deste grupo...', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setArUser(null);
                });
            } catch (e) {
                toast.error('Houve um erro com o carregamento dos dados deste grupo...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setStateLoader(false);
            }
        }
    }

    async function getGroupDetails() {
        if (auth !== undefined) {
            try {
                setStateLoader(true, `Carregando...`);
                await apiService.get(
                    `groups/detail?group_id=${groupId}&returned_group_data=true`,
                    {},
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setGetGroupDetailsData(response.data.users as Array<IUserGroup>);
                        setGroup(response.data.group as IGroup);
                    }
                    setStateLoader(false);
                }).catch((error) => {
                    alert(error);
                    setStateLoader(false);
                });
            } catch (e) {
                toast.error('Houve um erro com o carregamento dos dados deste grupo...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setStateLoader(false);
            }
        }
    }

    async function getGroupMessage() {
        if (auth !== undefined) {
            try {
                setLoadingMessage(true);
                await apiService.get(
                    `groups/messages?group_id=${groupId}`,
                    {},
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setGetGroupMessageData(response.data.messages as Array<IUserMessageGroup>);
                    }
                    setLoadingMessage(false);
                }).catch((error) => {
                    toast.error('Houve um erro com o carregamento das mensagens deste grupo...', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoadingMessage(false);
                });
            } catch (e) {
                toast.error('Houve um erro com o carregamento das mensagens deste grupo...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoadingMessage(false);
            }
        }
    }

    async function getGroupMessageDetail(userId: number) {
        if (auth !== undefined) {
            try {
                setLoadingMessage(true);
                await apiService.get(
                    `groups/messages/details?group_id=${groupId}&user_id=${userId}`,
                    {},
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setGetGroupMessageDetailData(response.data.messages as Array<IUserMessageGroupDetail>);
                        setViewMessagesDetail(true);
                    }
                    setLoadingMessage(false);
                }).catch(() => {
                    toast.error('Houve um erro com o carregamento das mensagens deste grupo...', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoadingMessage(false);
                });
            } catch (e) {
                toast.error('Houve um erro com o carregamento das mensagens deste grupo...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoadingMessage(false);
            }
        }
    }

    function userMessageItemChat(userMessage: IUserMessageGroupDetail) {
        return (
            <Grid container key={`${userMessage.id}${userMessage.to}${userMessage.to_id}`} className={
                userMessage.from_id !== auth?.user!.id ? styles.view_user_message_to : styles.view_user_message_from
            }>
                <Grid item xs={12} className={
                    userMessage.from_id !== auth?.user!.id ? styles.view_user_message_message_to : styles.view_user_message_message_from
                }>
                    <Typography className={
                        userMessage.from_id !== auth?.user!.id ? styles.text_user_message_message_to : styles.text_user_message_message_from
                    }>
                        {userMessage.text}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={
                    userMessage.from_id !== auth?.user!.id ? styles.view_user_message_date_to : styles.view_user_message_date_from
                }>
                    <Typography className={
                        userMessage.from_id !== auth?.user!.id ? styles.text_user_message_datetime_to : styles.text_user_message_datetime_from
                    }>
                        {formatDateToString(userMessage.created_at)}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    function userItem(userMessage: IUserGroup) {
        return (
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                key={`${userMessage.id}${userMessage.name}`}
                className={userMessage.status === 1 ? styles.view_user : styles.view_user_opacity}
            >
                <Grid item xs={2} className={styles.view_user_image}>
                    <img alt={`${userMessage.name}`} className={styles.user_img} src={photoProfile} />
                </Grid>
                <Grid item xs={9} className={styles.view_user_name}>
                    <Grid container>
                        <Grid item xs={12} className={styles.view_user_name}>
                            <Typography className={styles.text_user_name}>
                            {cutString(userMessage.name, 30)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.view_user_name}>
                            <Typography className={styles.text_user_name}>
                                {cutString(userMessage.email, 30)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.view_user_name}>
                            <Typography className={styles.text_user_name}>
                                {cutString(userMessage.job_position, 30)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title="Excluir Participante do Grupo" className={styles.button_action}>
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => handleShowRemoveUserByGroup(userMessage.name, userMessage.id)}
                            color="error"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        );
    }

    function userMessageItem(userMessage: IUserMessageGroup) {
        return (
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                onClick={() => showUserChat(userMessage)}
                key={`${userMessage.user_name}${userMessage.server_sent_at}`}
                className={styles.view_user_message}
            >
                <Grid item xs={2} className={styles.view_user_message_image}>
                    <img alt={`${userMessage.user_name}`} className={styles.user_message_img} src={photoProfile} />
                </Grid>
                <Grid item xs={10} className={styles.view_user_data}>
                    <Grid container>
                        <Grid item xs={12} className={styles.view_user_message_name}>
                            <Typography className={styles.text_user_message_name}>
                                {userMessage.user_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={11} className={styles.view_user_message_message}>
                            <Typography className={styles.text_user_message_message}>
                                {(userMessage.message !== '' ? userMessage.message : '-')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className={styles.view_user_message_message}>
                            {(userMessage.dont_reading_count > 0) && (
                                <Grid container className={styles.view_base_user_message_dont_reading}>
                                    <Grid item xs={12} className={styles.view_user_message_dont_reading}>
                                        <Typography className={styles.text_user_message_dont_reading}>
                                            {userMessage.dont_reading_count}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        );
    }

    React.useEffect(() => {
        getUsers();
        getGroupDetails();
        getGroupMessage();
        // eslint-disable-next-line
    }, []);

    return (
        <AuthBase>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                className={styles.grid_root}
            >
                <Grid item xs={1} className={styles.grid_item_container}>
                    <Button className={styles.button_back_page} onClick={goToBack}>Voltar</Button>
                </Grid>
                <Grid item xs={11}></Grid>
                <Grid item xs={12} className={styles.grid_title_actions}>
                    {(group === undefined && loaderBox === true) && (
                        <ShowLoader message='' />
                    )}
                    {(group !== undefined && auth !== null && loaderBox !== true) && (
                        <>
                            <Typography className={styles.title}>Grupo <strong>{group?.name}</strong></Typography>
                            {(group !== undefined && auth !== null && (group?.created_by === auth?.user?.id || auth!.user?.type === 'admin')) && (
                                <>
                                    <Tooltip title="Editar Nome" className={styles.button_action}>
                                        <IconButton
                                            size="small"
                                            aria-haspopup="true"
                                            onClick={() => handleShowUpdateGroupName()}
                                            color="primary"
                                        >
                                            <CreateIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Excluir Grupo" className={styles.button_action}>
                                        <IconButton
                                            size="small"
                                            aria-haspopup="true"
                                            onClick={() => handleShowBoxRemoveGroup()}
                                            color="error"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </>
                    )}
                </Grid>
                <Grid item xs={12} className={styles.grid_item_container_data}>
                    {(group !== undefined && group !== null && loaderBox !== true) && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                            className={styles.grid_root}
                        >
                            <Grid item xs={4} className={styles.grid_participants}>
                                <Paper className={styles.paper}>
                                    <Grid container className={styles.grid_root}>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography className={styles.title}><strong>Participantes</strong></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button className={styles.button_back_page} onClick={() => handleShowInviteUsers()}>Convidar</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container className={styles.grid_container_users}>
                                                <Grid item xs={12} className={styles.view_invite}>
                                                    {(getGroupDetailsData !== undefined && getGroupDetailsData !== null) && (
                                                        <Grid className={styles.view_group_users_list}>
                                                            {getGroupDetailsData.map(item => (
                                                                userItem(item as IUserGroup)
                                                            ))}
                                                        </Grid>
                                                    )}
                                                    {(getGroupDetailsData === undefined || getGroupDetailsData === null) && (
                                                        <ShowLoader message={(messageLoader !== undefined ? messageLoader : 'Carrregando...')} />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} className={styles.grid_posts}>
                                <Paper className={styles.paper}>
                                    <Grid
                                        container
                                        direction="column"
                                        className={styles.grid_root}
                                    >
                                        <Grid item xs={12}>
                                            <Typography className={styles.title}><strong>Mensagens</strong></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {(getGroupMessageData !== undefined) && (
                                                <div className={styles.view_group_users_list}>
                                                    {getGroupMessageData.map(item => (
                                                        userMessageItem(item)
                                                    ))}
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} className={styles.grid_invitations}>
                                {(viewMessagesDetail === true && group !== undefined && userSelected !== undefined) && (
                                    <Paper className={styles.paper}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="stretch"
                                            className={styles.grid_root}
                                        >
                                            <Grid item xs={12}>
                                                <Typography className={styles.title}><strong>Conversas</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} className={styles.view_user_message_details}>
                                                        <Grid container>
                                                            <Grid item xs={2} className={styles.view_user_image_message_details}>
                                                                <img alt={`${userSelected.name}`} className={styles.user_img_message_details} src={photoProfile} />
                                                            </Grid>
                                                            <Grid item xs={10} className={styles.view_user_name_message_details}>
                                                                <Typography className={styles.text_user_name_message_details}>
                                                                    {cutString(userSelected.name, 30)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {(getGroupMessageDetailData !== undefined && loadingMessage !== true && getGroupMessageDetailData.length > 0) && (
                                                        <Grid item xs={12} className={styles.view_group_users_list_chat}>
                                                            {getGroupMessageDetailData.map(item => (
                                                                userMessageItemChat(item)
                                                            ))}
                                                        </Grid>
                                                    )}
                                                    {(getGroupMessageDetailData !== undefined && loadingMessage !== true && getGroupMessageDetailData.length <= 0) && (
                                                        <Grid item xs={12} className={styles.view_group_message_is_empty}>
                                                            <Typography className={styles.text_group_message_is_empty}>
                                                                Sem mensagens
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {(loadingMessage === true) && (
                                                        <Grid item xs={12} className={styles.view_loading}>
                                                            <ShowLoader message={''} />
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item xs={9} className={styles.view_input_sent_message}>
                                                        <TextField
                                                            className={styles.input_sent_message}
                                                            value={message}
                                                            disabled={lockData}
                                                            multiline={true}
                                                            minRows={3}
                                                            placeholder='Digite a mensagem'
                                                            onChange={handleSetMessage}
                                                            error={(errorMessage !== false ? true : false)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} className={styles.view_button_sent_message}>
                                                        <Button onClick={sentMessage} className={styles.button_group_name_input_sent_message}>
                                                            <Typography className={styles.text_input_sent_message}>
                                                                Enviar
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                )}
                                {(viewMessagesDetail !== true && group !== undefined && userSelected !== undefined && getGroupMessageDetailData === undefined) && (
                                    <ShowLoader message={''} />
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {(group !== undefined && group === null && loaderBox !== true) && (
                        <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                    )}
                </Grid>
                {(loadingDialog === true) && (
                    <BootstrapDialog
                        open={loadingDialog}
                    >
                        <ShowLoader message='' />
                    </BootstrapDialog>
                )}
                {(loadingDialog !== true) && (
                    <>
                        <BootstrapDialog
                            onClose={() => handleHideUpdateGroupName()}
                            aria-labelledby="customized-dialog-title"
                            open={showDialogUpdateNameGroup}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleHideUpdateGroupName()}>
                                Alterar Nome do Grupo
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={styles.modal_view}
                                >
                                    <Grid item xs={12} className={styles.view_box_content}>
                                        <TextField
                                            className={styles.input_project_name}
                                            value={groupName}
                                            label='Nome do Grupo'
                                            disabled={loadingDialog}
                                            onChange={handleSetGroupName}
                                            error={errorGroupName}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button className={styles.button_close} onClick={() => setShowDialogUpdateNameGroup(!showDialogUpdateNameGroup)}>
                                    Voltar
                                </Button>
                                <Button className={styles.button_update} onClick={() => requestUpdateGroupName()}>
                                    Atualizar
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                            onClose={() => setShowDialogDeleteGroup(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showDialogDeleteGroup}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setShowDialogDeleteGroup(false)}>
                                Excluir Grupo
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Typography className={styles.title}>Deseja excluir o grupo <strong>{group?.name}</strong>?</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setShowDialogDeleteGroup(false)}>
                                    Não
                                </Button>
                                <Button disabled={loadingDialog} onClick={RemoveGroup}>
                                    Sim
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                            onClose={() => setShowDialogInviteUserGroup(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showDialogInviteUserGroup}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setShowDialogInviteUserGroup(false)}>
                                Convide Seus Amigos
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={styles.modal_view}
                                >
                                    {(arUser !== undefined && arUser !== null && arUser.length > 0 && auth?.user!.type === 'admin') && (
                                        <>
                                            <Grid item xs={12} className={styles.view_text_select_user}>
                                                <Typography>Selecione um convidado.</Typography>
                                            </Grid>
                                            <Grid item xs={12} className={styles.view_invite_select_users}>
                                                <TextField
                                                    className={styles.picker_invite_select_users}
                                                    value={userId ?? 0}
                                                    select
                                                    defaultValue={0}
                                                    disabled={loadingDialog}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        changeUserInvite(`${event.target.value}`);
                                                    }}
                                                >
                                                    <MenuItem className={styles.picker_invite_select_users_item} value={0}>Selecione</MenuItem>
                                                    {(arUser.map((element) => {
                                                        return (
                                                            <MenuItem className={styles.picker_invite_select_users_item} value={element.id}>{element.name}</MenuItem>
                                                        );
                                                    }))}
                                                </TextField>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} className={styles.view_text_input_email}>
                                        <Typography>Convide por e-mail!</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={styles.view_invite_email}>
                                        <TextField
                                            className={styles.input_email}
                                            value={email}
                                            disabled={loadingDialog}
                                            label='Insira o E-mail do convidado'
                                            onChange={handleSetEmail}
                                            error={(errorEmail !== false ? true : false)}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button className={styles.button_close} onClick={() => setShowDialogInviteUserGroup(false)}>
                                    Cancelar
                                </Button>
                                <Button disabled={loadingDialog} className={styles.button_update} onClick={() => inviteGroup()}>
                                    Enviar Convite
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                            onClose={() => handleHideRemoveUserByGroup()}
                            aria-labelledby="customized-dialog-title"
                            open={showDialogDeleteUserGroup}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleHideRemoveUserByGroup()}>
                                Remover {userName} do Grupo
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Grid container className={styles.modal_view}>
                                    <Grid item xs={12} className={styles.view_box_content}>
                                        <Typography className={styles.text_project_input}>
                                            Tem certeza que deseja excluir '{userName}' do grupo '{groupName}'?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button className={styles.button_close} onClick={() => handleHideRemoveUserByGroup()}>
                                    Cancelar
                                </Button>
                                <Button className={styles.button_update} onClick={() => removeUserGroup()}>
                                    Remover
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                    </>
                )}
            </Grid>
        </AuthBase >
    );
}