import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        border: '1px solid #cddee9',
        padding: '20px 25px',
        borderRadius: '10px',
    },
    grid_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        alignContent: 'center',
    },
    grid_title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        marginBotton: '15px',
    },
    grid_input: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        marginTop: '15px !important',
    },
    input_base: {
        marginTop: '10px !important',
    },
    grid_group_buttons: {
        marginTop: '25px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    button_update: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        borderColor: '#06617c !important',
    },
    icon_pen_edit: {
        backgroundColor: 'transparent',
        color: '#06617c',
    },
    grid_delete_account: {
        marginTop: '75px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    button_delete: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        borderColor: '#06617c !important',
    },
    icon_delete_account: {
        backgroundColor: 'transparent',
        color: '#06617c',
    },
});

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default useStyles;