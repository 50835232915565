import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
    IFilterHorizons,
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    horizonFilters: IFilterHorizons
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonGravelItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [gravel, setGravel] = useState<string>('0');
    const [gravelUpdate, setGravelUpdate] = useState<boolean>(false);

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            if (
                gravel !== null &&
                gravel !== '0' &&
                props.horizon.gravel !== gravel.toString()
            ) {
                request_data['gravel'] = gravel.toString();
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                    }
                }).catch(() => {
                });
            } catch (e) {
            }
        }
    }

    useEffect(() => {
        if (gravelUpdate === true) {
            updateHorizon();
            setGravelUpdate(false);
        }
        return () => setGravelUpdate(false);
        // eslint-disable-next-line
    },[gravelUpdate]);

    useEffect(() => {
        if (props.horizon !== undefined && props.horizon.gravel !== undefined) {
            setGravel(( (props.horizon.gravel === null || props.horizon.gravel === '0') ? '0' : props.horizon.gravel.toString()));
        }
        // eslint-disable-next-line
    }, [props]);

    return (
        <Grid container key={`${props.horizon.id}${props.horizon.name}gravel`} className={styles.view_horizon_mottled}>
            <Grid item xs={12} className={styles.view_horizon_mottled_title}>
                <Typography className={styles.text_horizon_mottled}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_mottled_content}>
                <Grid container className={styles.view_horizon_mottled_content}>
                    <Grid item xs={12} className={styles.view_horizon_mottled_subtitle}>
                        <TextField
                            className={styles.picker_select_type}
                            select
                            value={gravel ? gravel : "0"}
                            defaultValue={'0'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setGravel(event.target.value.toString());
                                setGravelUpdate(true);
                            }}
                        >
                            <MenuItem className={styles.picker_select_item} key={'0-gravel-HorizonGravelItem'} value={'0'}>Selecione</MenuItem>
                            {props.horizonFilters.gravel.map((element, key) => (
                                <MenuItem className={styles.picker_select_item} key={`${key}-gravel-HorizonGravelItem`} value={`${key+1}`}>{`${element}`}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}