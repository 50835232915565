import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    scroll_view: {
        backgroundColor: '#F8F8FF',
    },
    container: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F8F8FF',
    },
    container_top: {
        width: '100%',
        height: 180,
    },
    view_select_views: {
        width: '90%',
        height: 20,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: '#F8F8FF',
        marginLeft: 20,
        marginBottom: 10,
    },
    button_select_views: {
        flexDirection: 'column',
        marginRight: 20,
        backgroundColor: '#F8F8FF',
    },
    button_exam_input_localization_description: {
        flexDirection: 'column',
        marginRight: 20,
        backgroundColor: '#F8F8FF',
    },
    text_select_view: {
        color: '#939393',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 20,
    },
    text_select_views_actived: {
        color: '#000 !important000 !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 20,
    },
    container_body: {
        width: '100%',
        marginTop: 20,
        backgroundColor: '#F8F8FF',
    },
    view_first_route: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#F8F8FF',
        marginBottom: 100,
    },
    view_second_route: {
        width: '100%',
        backgroundColor: '#F8F8FF',
        marginBottom: 100,
    },
    view_projects: {
        width: '100%',
        backgroundColor: '#F8F8FF',
    },
    view_exams: {
        width: '100%',
        backgroundColor: '#F8F8FF',
        padding: '0px 10px',
    },
    text_about: {
        textAlign: 'left',
        backgroundColor: 'transparent',
        marginTop: 20,
        marginBottom: 10,
        paddingLeft: 30,
        paddingRight: 30,
        color: 'gray',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 20,
    },
    button_topics: {
        marginTop: 20,
        flexDirection: 'row',
        width: '80%',
        borderColor: '#06617c !important',
        borderWidth: 1,
        borderRadius: 15,
        backgroundColor: '#cddee9',
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    text_button_topics: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 0px',
        color: '#000 !important000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    plus_icon: {
        textAlign: 'right',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        padding: '20px 0px',
        fontSize: 30,
        alignItems: 'flex-end',
    },
    button_logout: {
        marginTop: 20,
        marginRight: 30,
        flexDirection: 'row',
        width: 150,
        borderColor: '#06617c !important',
        padding: '5px 20px',
        borderWidth: 1,
        borderRadius: 15,
        backgroundColor: '#cddee9',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
    text_button_logout: {
        width: 90,
        textAlign: 'left',
        flexDirection: 'column',
        padding: '10px 0px',
        color: '#000 !important000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-end',
    },
    logout_icon: {
        width: 30,
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        fontSize: 25,
        alignItems: 'center',
    },
    view_project: {
        width: 150,
        padding: '10px',
        margin: '10px 10px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#cddee9',
        borderColor: '#06617c !important',
        borderWidth: 1,
        borderRadius: 15,
    },
    view_project_image: {
        width: 70,
        height: 70,
        backgroundColor: '#06617c !important',
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    project_img: {
        width: 46,
        height: 32,
    },
    view_project_name: {
        width: '100%',
    },
    project_name: {
        textAlign: 'center',
        padding: '10px 0px',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    view_group_image: {
        width: 70,
        height: 70,
        backgroundColor: '#06617c !important',
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    group_img: {
        width: 46,
        height: 35,
    },
    analise_morfologica: {
        width: 27,
        height: 30,
    },
    view_horizon_name: {
        width: '100%',
        height: 50,
    },
    view_horizon_name_item: {
        width: '100%',
        height: 60,
        backgroundColor: 'transparent',
        marginBottom: 10,
    },
    horizon_name: {
        height: 50,
        textAlign: 'center',
        padding: '15px 0px',
        color: '#06617c !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        alignItems: 'flex-start',
        textTransform: 'none',
    },
    horizon_name_active: {
        height: 50,
        textAlign: 'center',
        padding: '15px 0px',
        color: '#FFF !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        alignItems: 'flex-start',
        textTransform: 'none',
    },
    view_list_groups: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 10px',
        backgroundColor: '#F8F8FF',
        marginBottom: 10,
    },
    view_groups_action: {
        marginTop: 30,
        flexDirection: 'row',
        width: '35%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
    },
    button_actions: {
        width: 40,
        flexDirection: 'column',
        backgroundColor: '#06617c !important',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25,
        padding: '5px',
    },
    action_icon: {
        width: 25,
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#FFF !important',
        fontSize: 30,
        alignItems: 'flex-end',
    },
    view_groups_list: {
        width: '100%',
        height: 100,
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 150,
        padding: '10px',
        backgroundColor: 'transparent',
    },
    view_horizon_menu_item: {
        width: 'auto',
        height: 40,
        padding: '5px 10px !important',
        margin: '5px !important',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#cddee9 !important',
        border: '1px solid #06617c !important',
        borderRadius: '10px !"important',
    },
    view_horizon_menu_item_active: {
        width: 'auto',
        height: 40,
        padding: '5px 10px',
        margin: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#06617c !important',
        border: '1px solid #06617c !important',
        borderRadius: '10px !"important',
    },
    text_group_name: {
        textAlign: 'center',
        padding: '10px 0px',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    view_invite: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#F8F8FF',
        marginBottom: 10,
    },
    view_invite_title: {
        textAlign: 'center',
        backgroundColor: 'transparent',
        marginTop: 10,
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        color: 'gray',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 20,
    },
    text_invite_title: {
        padding: '10px 0px',
        color: '#000 !important000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'center',
    },
    view_group_users_list: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        margin: '10px 0px',
        padding: '10px',
        backgroundColor: 'transparent',
    },
    view_group_users_list_messages: {
        width: '100%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        backgroundColor: '#F8F8FF',
        marginBottom: 120,
    },
    button_group_users_list_messages: {
        marginTop: 10,
        width: 200,
        borderRadius: 12,
        backgroundColor: '#06617c !important',
        alignItems: 'center',
    },
    text_group_users_list_messages: {
        padding: '15px 0px',
        color: '#FFF !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    view_invite_people_circ: {
        width: 100,
    },
    view_invite_select_users: {
        color: '#000 !important000 !important',
        backgroundColor: '#FFF !importantFFF !important',
        width: '100%',
        borderRadius: 5,
        padding: '8px 0px',
        marginTop: 10,
        fontSize: 24,
        alignItems: 'center',
        textAlign: 'center',
        borderWidth: 1,
    },
    picker_invite_select_users: {
        color: '#000 !important',
        backgroundColor: 'transparent',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'serca-condensed-text',
        fontSize: 18,
    },
    picker_invite_select_users_item: {
        color: '#000 !important',
        backgroundColor: 'transparent',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'serca-condensed-text',
        fontSize: 16,
    },
    view_invite_email: {
        width: '100%',
        marginBottom: 100,
    },
    input_email: {
        color: '#000 !important000 !important',
        backgroundColor: '#FFF !importantFFF !important',
        width: '100%',
        borderRadius: 5,
        padding: '12px 0px',
        marginTop: 10,
        fontSize: 24,
        alignItems: 'center',
        textAlign: 'center',
        borderWidth: 1,
    },
    icon_show_hide_password: {
        flexDirection: 'column',
        color: '#000 !important000 !important',
        backgroundColor: '#FFF !important',
        width: 30,
        borderRadius: 5,
        textAlign: 'right',
        marginTop: '-10.5%',
        marginLeft: '85%',
        marginBottom: 25
    },
    view_user: {
        flexDirection: 'row',
        padding: '10px',
        margin: '10px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        borderColor: '#CCC !important',
        borderWidth: 1,
        borderRadius: 15,
    },
    view_user_image: {
        width: 70,
        height: 70,
        backgroundColor: '#06617c !important',
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.75,
    },
    user_img: {
        width: 52,
        height: 52,
    },
    view_user_name: {
        width: '100%',
        alignItems: 'flex-start',
        padding: '10px',
    },
    text_user_name: {
        textAlign: 'center',
        padding: '1px 0px !important',
        color: '#06617c !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: '22px !important',
        alignItems: 'flex-start',
        marginTop: '30px !important',
        marginBottom: '30px !important',
        fontWeight: 'bold',
    },
    button_horizon_name: {
        flexDirection: 'row',
        width: '100%',
        backgroundColor: 'transparent',
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    button_group_name: {
        marginTop: 20,
        flexDirection: 'row',
        width: '80%',
        borderRadius: 15,
        backgroundColor: 'transparent',
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    text_button_group_name: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    back_icon: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#000 !important',
        padding: '10px',
        fontSize: 25,
        alignItems: 'center',
    },
    view_list_exams: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 10px',
        backgroundColor: '#F8F8FF',
        marginBottom: 10,
    },
    view_exams_action: {
        flexDirection: 'row',
        width: '35%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        padding: '20px 0px',
    },
    view_exams_options: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        padding: '20px',
    },
    view_exams_list: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10,
        padding: '10px',
        backgroundColor: 'transparent',
    },
    text_button_horizon_name: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 20px',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    text_button_exam_name: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 20px',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    view_exam_exam: {
        width: '100%',
        padding: '20px 10px',
    },
    view_exam_date_and_type: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    exam_date: {
        width: '50%',
        height: 60,
        flexDirection: 'column',
        padding: '10px 0px',
    },
    picker_select_type: {
        width: '50%',
        height: 60,
        flexDirection: 'column',
        padding: '10px 0px',
        borderRadius: 10,
    },
    picker_select_default: {
        width: '100%',
        height: 50,
        flexDirection: 'column',
        padding: '10px 0px',
        paddingLeft: 10,
        borderRadius: 10,
        backgroundColor: 'transparent',
    },
    view_exam_localization: {
        width: '100%',
        padding: '10px',
    },
    text_exam_localization_title: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    text_exam_localization_input_title: {
        padding: '5px 0px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        color: '#AFB2BD',
        backgroundColor: 'transparent',
    },
    text_exam_localization_input_sub_title: {
        padding: '5px 0px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        color: '#000 !important',
        backgroundColor: 'transparent',
    },
    map_view: {
        width: '100%',
        height: 400,
        margin: '10px 0px',
        borderRadius: 10,
    },
    view_exam_alitude: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    view_exam_description_localization_button: {
        flexDirection: 'column',
        marginTop: 20,
    },
    button_description_localization_button: {
        flexDirection: 'row',
    },
    view_exam_input_base: {
        margin: '10px 0px',
    },
    text_exam_stoniness_title: {
    },
    view_exam_input: {
        backgroundColor: '#F8F8FF',
        borderRadius: 10,
        padding: '10px',
    },
    text_horizon_input: {
        padding: '10px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    input_data_name: {
        flexDirection: 'column',
        borderColor: 'transparent',
        borderWidth: 1,
        padding: '10px',
        borderRadius: 10,
        verticalAlign: 'top'
    },
    input_exam_input_altitude: {
        flexDirection: 'column',
        width: 100,
        borderColor: '#F1F2F4 !important',
        borderWidth: 1,
        padding: '10px',
        borderRadius: 10,
        backgroundColor: '#F1F2F4 !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        color: '#000 !important',
    },
    input_exam_input_localization_description: {
        flexDirection: 'column',
        borderColor: '#CCC !important',
        borderWidth: 1,
        padding: '10px',
        borderRadius: 10,
    },
    icon_exam_input_localization_description: {
        flexDirection: 'column',
    },
    text_exam_name: {
        padding: '5px 0px',
    },
    view_exam_description: {
    },
    view_list_projects: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 10px',
        backgroundColor: '#F8F8FF',
        marginBottom: 10,
    },
    view_projects_action: {
        marginTop: 10,
        flexDirection: 'row',
        width: '35%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
    },
    view_projects_list: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 70,
        padding: '10px',
        backgroundColor: 'transparent',
    },
    view_exam_morphological_analysis: {
        width: '100%',
        padding: '10px 30px',
        alignItems: 'center',
    },
    view_exam_button_morphological_analysis: {
        width: '100%',
        padding: '10px 30px',
        alignItems: 'center',
    },
    button_morphological_analysis: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
        width: '100%',
        padding: '10px',
        borderRadius: 15,
        backgroundColor: '#06617c !important',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    icon_morphological_analysis: {
        textAlign: 'right',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#FFF !important',
        padding: '10px 0px',
        fontSize: 30,
        alignItems: 'center',
    },
    text_morphological_analysis: {
        textAlign: 'center',
        flexDirection: 'column',
        color: '#FFF !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'center',
    },
    view_box_out: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        justifyContent: 'space-around',
    },
    view_box_out_slim: {
        width: '50%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        justifyContent: 'space-around',
    },
    view_box_out_buttons: {
        width: '10%',
        flexDirection: 'column',
    },
    button_box_out_buttons: {
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'space-around',
    },
    view_box: {
        width: '90%',
        flexDirection: 'row',
        borderRadius: 10,
        padding: '10px',
        backgroundColor: '#F1F2F4 !important',
    },
    view_box_orange: {
        width: 60,
        height: 60,
        flexDirection: 'column',
        backgroundColor: '#06617c',
        borderRadius: 15,
        marginRight: 10,
    },
    view_box_content: {
        width: '80%',
        flexDirection: 'column',
    },
    view_box_full: {
        width: '100%',
        flexDirection: 'row',
        borderRadius: 10,
        padding: '10px',
        backgroundColor: '#F1F2F4 !important',
    },
    view_box_content_full: {
        width: '100%',
        flexDirection: 'column',
    },
    view_horizon_delete: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
        padding: '10px',
        backgroundColor: 'transparent',
    },
    view_horizon_list_delete: {
        backgroundColor: 'transparent',
    },
    text_horizon_name: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 20px',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'center',
    },
    view_horizon_action_delete: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        backgroundColor: 'transparent',
    },
    button_actions_transparent: {
        width: 70,
        flexDirection: 'column',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25,
    },
    action_confirm_icon: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#00FD38 !important',
        fontSize: 40,
        alignItems: 'flex-end',
    },
    action_decline_icon: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#EA0909 !important',
        fontSize: 40,
        alignItems: 'flex-end',
    },
    action_icon_active: {
        width: 25,
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#000 !important',
        fontSize: 30,
        alignItems: 'flex-end',
    },
    view_exam_horizon_home: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    view_exam_horizon: {
        width: '100%',
        padding: '10px 0px',
        alignItems: 'center',
        marginBottom: '50px !important',
    },
    view_exam_horizon_content: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        borderColor: '#CCC !important',
        borderWidth: 0.7,
        backgroundColor: 'transparent',
    },
    view_exam_horizon_session_two_sessions: {
        width: '100%',
        flexDirection: 'row',
    },
    view_exam_horizon_session: {
        flexDirection: 'column',
        width: '100%',
        minHeight: 100,
        marginBottom: 20,
        backgroundColor: 'transparent',
    },
    view_exam_horizon_itens: {
        flexDirection: 'column',
        paddingBottom: 20,
        backgroundColor: 'transparent',
    },
    view_exam_chemical_session: {
        flexDirection: 'column',
        width: '100%',
        minHeight: 100,
        backgroundColor: 'transparent',
    },
    view_exam_chemical_itens: {
        flexDirection: 'column',
        paddingBottom: 20,
        backgroundColor: 'transparent',
    },
    view_exam_physical_session: {
        flexDirection: 'column',
        width: '100%',
        minHeight: 100,
        backgroundColor: 'transparent',
    },
    view_exam_physical_itens: {
        flexDirection: 'column',
        paddingBottom: 20,
        backgroundColor: 'transparent',
    },
    view_exam_horizon_list: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        padding: '5px',
        backgroundColor: 'transparent',
    },
    view_exam_morphological_buttons: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    view_exam_morphological: {
        width: '100%',
        alignItems: 'center',
        backgroundColor: 'transparent',
    },
    view_exam_morphological_home: {
    },
    view_exam_physics: {
        width: '100%',
        padding: '10px 30px',
        alignItems: 'center',
    },
    view_exam_physics_content: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    view_exam_chemistry: {
        width: '100%',
        padding: '10px 30px',
        alignItems: 'center',
    },
    view_exam_chemistry_content: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    view_exam_horizon_actions: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        padding: '0px 10px',
        justifyContent: 'space-evenly'
    },
    buttons_horizon: {
        width: '100%',
        color: '#FFF !important',
        margin: '10px 5px',
        flexDirection: 'column',
        padding: '15px 25px',
        borderRadius: 10,
        backgroundColor: '#cddee9 !important',
        border: '1px solid #06617c !important',
        alignItems: 'center',
    },
    buttons_horizon_active: {
        width: '100%',
        color: '#FFF !important',
        margin: '10px 5px',
        flexDirection: 'column',
        padding: '15px 25px',
        borderRadius: 10,
        backgroundColor: '#06617c !important',
        border: '1px solid #06617c !important',
        borderWidth: 1,
        alignItems: 'center',
    },
    buttons_horizon_create: {
        height: 50,
        color: '#FFF !important',
        margin: '10px 5px',
        flexDirection: 'column',
        padding: '15px 20px',
        borderRadius: 10,
        backgroundColor: '#06617c !important',
        borderColor: '#06617c !important',
        borderWidth: 1,
        alignItems: 'center',
    },
    buttons_horizon_create_icon: {
        height: 50,
        color: '#FFF !important',
        flexDirection: 'column',
        padding: '10px 20px',
        borderRadius: 10,
        backgroundColor: '#06617c !important',
        alignItems: 'center',
        margin: '0px 5px',
    },
    text_horizon: {
        textAlign: 'center',
        flexDirection: 'column',
        color: '#FFF !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        alignItems: 'center',
    },
    button_register: {
        width: '100%',
        color: '#FFF !importantFFF !important',
        padding: '5px 0px',
        borderRadius: 5,
        backgroundColor: '#F1F2F4 !important',
        marginTop: 20,
        marginBottom: 10,
    },
    icon_map_view: {
        width: 250,
        height: 250,
        backgroundColor: 'transparent',
        position: 'absolute',
        padding: '100px',
    },
    view_exam_horizon_morphology: {
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
        justifyContent: 'space-evenly'
    },
    text_exam_horizon_morphology_title: {
        width: 50,
        textAlign: 'center',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-end',
    },
    input_morphology_observation: {
        width: 250,
        flexDirection: 'column',
        borderColor: '#CCC !important',
        borderWidth: 1,
        padding: '10px 10px',
        borderRadius: 10,
        verticalAlign: 'top'
    },
    view_exam_horizon_chemistry: {
        marginTop: 10,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'transparent',
        justifyContent: 'space-evenly'
    },
    text_exam_horizon_chemistry_title: {
        margin: '5px 0px',
        textAlign: 'center',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-end',
    },
    view_exam_horizon_physics: {
        marginTop: 10,
        alignItems: 'center',
        backgroundColor: 'transparent',
        justifyContent: 'center'
    },
    view_exam_horizon_physics_title: {
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
        justifyContent: 'center'
    },
    view_exam_horizon_physics_input: {
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
        justifyContent: 'center'
    },
    text_exam_horizon_physics_title: {
        margin: '5px 0px',
        textAlign: 'center',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'center',
    },
    view_horizon_names_menu_item: {
        width: 'auto',
        height: 40,
        padding: '10px 20px',
        margin: '10px 5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#06617c !important',
        borderColor: '#06617c !important',
        borderWidth: 1,
        borderRadius: 15,
    },
    view_centered: {
        width: '100%',
        justifyContent: "flex-end",
        alignItems: "center",
        opacity: 0.55,
        backgroundColor: 'transparent',
        marginBottom: 5,
        margin: '0px 5px',
    },
    view_centered_content: {
        width: '100%',
        opacity: 0.85,
        backgroundColor: '#CDCDCD !important',
        alignItems: "center",
        justifyContent: "center",
    },
    modal_modal: {
        width: '100%',
        marginBottom: 5,
        margin: '0px 5px',
        backgroundColor: '#F8F8FF',
        opacity: 1,
    },
    modal_view: {
        opacity: 1,
        width: '100%',
        height: 270,
        backgroundColor: "#F8F8FF",
        borderRadius: 20,
        padding: '20px 30px',
        alignItems: "center",
        shadowColor: "#000 !important",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        marginTop: 20,
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    view_box_buttons: {
        marginTop: 20,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    button_update: {
        width: 120,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "#06617c !important",
        padding: '10px',
        borderRadius: 15
    },
    button_close: {
        width: 120,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "#CDCDCD !important",
        padding: '10px',
        borderRadius: 15
    },
    text_style: {
        color: "#000 !important",
        fontWeight: "bold",
        textAlign: "center"
    },
    text_description: {
        width: '100%',
        minHeight: 120,
        textAlign: 'left',
        verticalAlign: 'top',
        alignItems: 'flex-start',
        padding: '20px 0px',
    },
    text_button_update: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#FFF !important",
        fontWeight: "bold",
        textAlign: "center"
    },
    text_button_close: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000 !important",
        fontWeight: "bold",
        textAlign: "center"
    },
    modal_text: {
        marginBottom: 15,
        textAlign: "center"
    },
    view_system_specialist_modal: {
        width: '100%',
        justifyContent: "flex-end",
        alignItems: "center",
        opacity: 0.55,
        backgroundColor: 'transparent',
        marginBottom: 5,
        margin: '0px 5px',
    },
    view_system_specialist_modal_content: {
        width: '100%',
        opacity: 0.85,
        backgroundColor: '#CDCDCD !important',
        alignItems: "center",
        justifyContent: "flex-end",
    },
    view_system_specialist_modal_box_content: {
        width: '80%',
        flexDirection: 'column',
    },
    text_data_title_type: {
        padding: '10px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    text_data_title_value: {
        padding: '10px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        fontWeight: 'bold',
    },
    input_data: {
        backgroundColor: 'transparent',
        borderColor: '#CDCDCD !important',
        borderWidth: 1,
        marginTop: 10,
        padding: '10px',
        borderRadius: 10,
        verticalAlign: 'top'
    },
    input_data_physics: {
        width: 70,
        backgroundColor: 'transparent',
        borderColor: '#CDCDCD !important',
        borderWidth: 1,
        marginTop: 10,
        padding: '10px',
        borderRadius: 10,
        textAlign: 'center',
        verticalAlign: 'center',
    },
    input_data_chemical: {
        width: 70,
        backgroundColor: 'transparent',
        borderColor: '#CDCDCD !important',
        borderWidth: 1,
        marginTop: 10,
        padding: '10px',
        borderRadius: 10,
        textAlign: 'center',
        verticalAlign: 'center',
    },
    view_horizon_limit: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    view_horizon_limit_title: {
        backgroundColor: 'transparent',
        marginBottom: 10,
    },
    text_horizon_limit: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000 !important000 !important",
        fontWeight: "bold",
        textAlign: "center"
    },
    view_horizon_limit_subtitle: {
        flexDirection: 'row',
        backgroundColor: 'transparent',
        justifyContent: 'space-around',
    },
    text_horizon_limit_subtitle: {
        flexDirection: 'column',
        backgroundColor: 'transparent',
        alignItems: 'center',
    },
    view_horizon_limit_content: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginBottom: 10,
        justifyContent: 'space-around',
    },
    view_horizon_limit_min_content: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    view_horizon_limit_max_content: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    view_horizon_extra_view: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginBottom: 10,
        justifyContent: 'space-around',
    },
    view_horizon_extra_content: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginBottom: 10,
        justifyContent: 'space-around',
    },
    button_checkobox_limit_min_content: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginBottom: 5,
    },
    button_checkobox_limit_max_content: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginBottom: 5,
    },
    input_data_limit: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        borderColor: '#CDCDCD !important !important',
        borderWidth: 1,
        padding: '8px 0px !important',
        margin: '0px 5px !important',
        borderRadius: 10,
        textAlign: 'center',
        verticalAlign: 'center'
    },
    view_horizon_munsell: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    view_horizon_munsell_title: {
        backgroundColor: 'transparent',
        marginBottom: 10,
    },
    text_horizon_munsell: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000 !important000 !important !important",
        fontWeight: "bold",
        textAlign: "center"
    },
    view_horizon_munsell_content: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    view_horizon_munsell_checkobx_content: {
        flexDirection: 'row',
        backgroundColor: 'transparent',
    },
    button_checkobox_munsell_mottled_variegated: {
        flexDirection: 'column',
        backgroundColor: 'transparent',
        marginBottom: 5,
    },
    view_horizon_munsell_inputs_content: {
        flexDirection: 'row',
        backgroundColor: 'transparent',
    },
    input_data_munsell: {
        backgroundColor: 'transparent',
        borderColor: '#CDCDCD !important !important',
        borderWidth: 1,
        padding: '8px 15px !important',
        margin: '0px 5px !important',
        borderRadius: 10,
        textAlign: 'center',
        verticalAlign: 'center'
    },
    view_horizon_extra_munsell_view: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        marginTop: 15,
        marginBottom: 10,
        justifyContent: 'space-around',
    },
    grid_show_loader: {
        width: '100%',
        minHeight: 110
    },
});

export default useStyles;