import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    next_horizon: number
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonObservationItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [observation, setObservation] = useState<string | undefined>(undefined);

    async function updateHorizon(dataValue: string) {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            if (dataValue !== undefined && dataValue !== null  && dataValue !== '' && props.horizon.observation !== dataValue) {
                request_data['observation'] = `${dataValue}`;
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                    }
                }).catch(() => {
                });
            } catch (e) {
            }
        }
    }

    useEffect(() => {
        if (props.horizon !== undefined) {
            setObservation(props.horizon.observation);
        }
        // eslint-disable-next-line
    }, [props.horizon]);

    return (
        <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={`${props.horizon.id}${props.horizon.name}observation`}
            className={styles.view_exam_horizon_morphology}
        >
            <Grid item xs={12} className={styles.view_horizon_observation_subtitle}>
                <Typography className={styles.text_exam_horizon_morphology_title}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_observation_subtitle}>
                <TextField
                    className={styles.input_morphology_observation}
                    value={observation}
                    placeholder={'Observação'}
                    multiline={true}
                    minRows={3}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setObservation(event.target.value);
                        updateHorizon(event.target.value);
                    }}
                />
            </Grid>
        </Grid>
    );
}