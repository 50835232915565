import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IHorizon,
    IRequestData,
    IHorizonLimitsProps,
} from '../../../types';
import ShowLoader from '../../../components/ShowLoaderComponent';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const label = { inputProps: { 'aria-label': 'Checkbox' } };
const numberPattern = /\D/g;

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    lastLimit: boolean
    horizonLimitsLowerActions: Array<IHorizonLimitsProps> | undefined
    handleHorizonLimitsLowerActions: (horizonLimits: IHorizonLimitsProps) => void
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonLimitItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [limitsUpper, setLimitsUpper] = useState<string | undefined>(undefined);
    const [limitsUpperUndulating, setLimitsUpperUndulating] = useState<string | undefined>(undefined);
    const [limitsLower, setLimitsLower] = useState<string | undefined>(undefined);
    const [limitsLowerUndulating, setLimitsLowerUndulating] = useState<string | undefined>(undefined);
    const [startUpdateData, setStartUpdateData] = useState<boolean>(false);
    const [limitsInfinity, setLimitsInfinity] = useState<boolean>(false);
    const [showDialogHorizonUpperUndulating, setShowDialogHorizonUpperUndulating] = useState<boolean>(false);
    const [showDialogHorizonLowerUndulating, setShowDialogHorizonLowerUndulating] = useState<boolean>(false);

    function handleSetLimitsInfinity() {
        setLimitsInfinity(!limitsInfinity);
        setStartUpdateData(true);
    }

    function handleSetNewLimitsUpper(value: React.ChangeEvent<HTMLInputElement>) {
        setLimitsUpper(value.target.value.replace(numberPattern, ''));
        if (props.horizonLimitsLowerActions !== undefined && props.horizonLimitsLowerActions.length > 0) {
            let nowHorizonLimitsLowerActions: IHorizonLimitsProps | undefined = undefined;
            props.horizonLimitsLowerActions.forEach((element, key) => {
                if (element.horizon_id === props.horizon.id) {
                    nowHorizonLimitsLowerActions = {
                        horizon_id: element.horizon_id,
                        next_horizon_id: element.next_horizon_id,
                        horizon_limit_lower: (value.target.value !== undefined ? value.target.value : element.horizon_limit_lower),
                        horizon_limit_lower_undulating: (limitsUpperUndulating !== undefined ? limitsUpperUndulating : element.horizon_limit_lower_undulating),
                        horizon_show_box_limit_lower_undulating: showDialogHorizonUpperUndulating
                    };
                }
            });
            if (nowHorizonLimitsLowerActions !== undefined) {
                props.handleHorizonLimitsLowerActions(nowHorizonLimitsLowerActions);
            }
        }
        setStartUpdateData(true);
    }

    function handleSetNewLimitsUpperUndulating(value: React.ChangeEvent<HTMLInputElement>) {
        setLimitsUpperUndulating(value.target.value.replace(numberPattern, ''));
        if (props.horizonLimitsLowerActions !== undefined && props.horizonLimitsLowerActions.length > 0) {
            let nowHorizonLimitsLowerActions: IHorizonLimitsProps | undefined = undefined;
            props.horizonLimitsLowerActions.forEach((element, key) => {
                if (element.horizon_id === props.horizon.id) {
                    nowHorizonLimitsLowerActions = {
                        horizon_id: element.horizon_id,
                        next_horizon_id: element.next_horizon_id,
                        horizon_limit_lower: (limitsUpper !== undefined ? limitsUpper : element.horizon_limit_lower),
                        horizon_limit_lower_undulating: (value.target.value !== undefined ? value.target.value : element.horizon_limit_lower_undulating),
                        horizon_show_box_limit_lower_undulating: showDialogHorizonUpperUndulating
                    };
                }
            });
            if (nowHorizonLimitsLowerActions !== undefined) {
                props.handleHorizonLimitsLowerActions(nowHorizonLimitsLowerActions);
            }
        }
        setStartUpdateData(true);
    }

    function handleSetShowDialogHorizonUpperUndulating() {
        let showBoxBoolean: boolean = (showDialogHorizonUpperUndulating === true ? false : true);
        if (showBoxBoolean === false) {
            setLimitsUpperUndulating('');
        }
        if (props.horizonLimitsLowerActions !== undefined && props.horizonLimitsLowerActions.length > 0) {
            let nowHorizonLimitsLowerActions: IHorizonLimitsProps | undefined = undefined;
            props.horizonLimitsLowerActions.forEach((element, key) => {
                if (element.horizon_id === props.horizon.id) {
                    nowHorizonLimitsLowerActions = {
                        horizon_id: element.horizon_id,
                        next_horizon_id: element.next_horizon_id,
                        horizon_limit_lower: (limitsUpper !== undefined ? limitsUpper : element.horizon_limit_lower),
                        horizon_limit_lower_undulating: (limitsUpperUndulating !== undefined && showDialogHorizonUpperUndulating !== true ? limitsUpperUndulating : ''),
                        horizon_show_box_limit_lower_undulating: showBoxBoolean
                    };
                }
            });
            if (nowHorizonLimitsLowerActions !== undefined) {
                props.handleHorizonLimitsLowerActions(nowHorizonLimitsLowerActions);
            }
        }
        setShowDialogHorizonUpperUndulating(showBoxBoolean);
        setStartUpdateData(true);
    }

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            if (limitsUpper !== undefined && limitsUpper !== null) {
                request_data['limits_upper'] = `${limitsUpper}`;
            }
            if (limitsUpperUndulating !== undefined && limitsUpperUndulating !== null) {
                request_data['limits_upper_undulating'] = `${limitsUpperUndulating}`;
            }
            if (limitsLower !== undefined && limitsLower !== null) {
                request_data['limits_lower'] = `${limitsLower}`;
            }
            if (limitsLowerUndulating !== undefined && limitsLowerUndulating !== null) {
                request_data['limits_lower_undulating'] = `${limitsLowerUndulating}`;
            }
            if (limitsInfinity !== undefined && limitsInfinity !== null && (parseInt(props.horizon.limits_infinity) === 0 ? false : true) !== limitsInfinity) {
                request_data['limits_infinity'] = `${limitsInfinity}`;
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                    }
                }).catch((error) => {
                    console.log('error', error);
                });
            } catch (e) {
                console.log('Catch e', e);
            }
        }
    }

    useEffect(() => {
        if (props.horizon !== undefined) {
            if (
                props.horizon.limits_upper_undulating !== undefined &&
                props.horizon.limits_upper_undulating !== null &&
                props.horizon.limits_upper_undulating !== ''
            ) {
                setShowDialogHorizonUpperUndulating(true);
                setLimitsUpperUndulating(props.horizon.limits_upper_undulating);
            }
            if (
                props.horizon.limits_lower_undulating !== undefined &&
                props.horizon.limits_lower_undulating !== null &&
                props.horizon.limits_lower_undulating !== ''
            ) {
                setShowDialogHorizonLowerUndulating(true);
                setLimitsLowerUndulating(props.horizon.limits_lower_undulating);
            }
            setLimitsUpper(props.horizon.limits_upper);
            setLimitsLower(props.horizon.limits_lower);
            setLimitsInfinity(`${props.horizon.limits_infinity}` === '0' ? false : true);
        }
        // eslint-disable-next-line
    }, [props.horizon !== undefined]);

    useEffect(() => {
        if (props.horizonLimitsLowerActions !== undefined && props.horizonLimitsLowerActions.length > 0) {
            props.horizonLimitsLowerActions.forEach((element, key) => {
                if (element.horizon_id === props.horizon.id) {
                    setLimitsLower(element.horizon_limit_lower);
                    setLimitsLowerUndulating(element.horizon_limit_lower_undulating);
                    setShowDialogHorizonLowerUndulating(element.horizon_show_box_limit_lower_undulating);
                    setStartUpdateData(true);
                }
            });
        }
        // eslint-disable-next-line
    }, [props.horizonLimitsLowerActions]);

    useEffect(() => {
        if (startUpdateData === true) {
            updateHorizon();
            setStartUpdateData(false);
        }
        // eslint-disable-next-line
    }, [startUpdateData === true]);

    if (props.horizon !== undefined) {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                key={`${props.horizon.id}${props.horizon.name}_limits_upper`}
                className={styles.view_horizon_limit}
            >
                <Grid item xs={12} className={styles.view_horizon_limit_title}>
                    <Typography className={styles.text_horizon_limit}>
                        <strong>{props.horizon.name} (cm)</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={3} className={styles.view_horizon_limit_subtitle}>
                            <Typography className={styles.text_horizon_limit_subtitle}>
                                Superior
                            </Typography>
                        </Grid>
                        <Grid item xs={3} className={styles.view_horizon_limit_subtitle}>
                            <Typography className={styles.text_horizon_limit_subtitle}>
                                Inferior
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid 
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={3} className={styles.view_horizon_limit_min_content}>
                            <Grid container>
                                <Grid item xs={12} className={styles.button_checkobox_limit_min_content}>&nbsp;</Grid>
                                <Grid item xs={12} className={styles.grid_input_data_limit}>
                                    <TextField
                                        className={styles.input_data_limit}
                                        value={limitsLower}
                                        placeholder=''
                                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                                            setLimitsLower(value.target.value);
                                            setStartUpdateData(true);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={styles.view_horizon_limit_max_content}>
                            <Grid 
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <Button onClick={handleSetShowDialogHorizonUpperUndulating} className={styles.button_checkobox_limit_max_content}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item xs={2}>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        color: '#CC6B3B',
                                                        '&.Mui-checked': {
                                                            color: '#CC6B3B',
                                                        },
                                                    }}
                                                    checked={showDialogHorizonUpperUndulating}
                                                    onClick={handleSetShowDialogHorizonUpperUndulating}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography className={styles.text_check_item}>Ondulado</Typography>
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </Grid>
                                <Grid item xs={12} className={styles.grid_input_data_limit}>
                                    <TextField
                                        className={styles.input_data_limit}
                                        value={limitsUpper}
                                        placeholder=''
                                        onChange={handleSetNewLimitsUpper}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={styles.view_horizon_extra_view}>
                    <Grid 
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {(showDialogHorizonLowerUndulating === true) && (
                            <Grid item xs={3} className={styles.view_horizon_limit_min_content}>
                                <TextField
                                    className={styles.input_data_limit}
                                    value={limitsLowerUndulating}
                                    placeholder=''
                                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => { setLimitsLowerUndulating(value.target.value) }}
                                />
                            </Grid>
                        )}
                        {(showDialogHorizonLowerUndulating === true && showDialogHorizonUpperUndulating !== true) && (
                            <Grid item xs={3} className={styles.view_horizon_limit_max_content}>
                            </Grid>
                        )}
                        {(showDialogHorizonUpperUndulating === true && showDialogHorizonLowerUndulating !== true) && (
                            <Grid item xs={3} className={styles.view_horizon_limit_min_content}>
                            </Grid>
                        )}
                        {(showDialogHorizonUpperUndulating === true) && (
                            <Grid item xs={3} className={styles.view_horizon_limit_max_content}>
                                <TextField
                                    className={styles.input_data_limit}
                                    value={limitsUpperUndulating}
                                    placeholder=''
                                    onChange={handleSetNewLimitsUpperUndulating}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {(props.lastLimit === true) && (
                    <Grid item xs={12} className={styles.button_checkobox_limit_infinity_content}>
                        <Button onClick={handleSetLimitsInfinity} className={styles.button_checkobox_limit_infinity_content}>
                            <Box sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
                                <Checkbox
                                    {...label}
                                    sx={{
                                        color: '#CC6B3B',
                                        '&.Mui-checked': {
                                            color: '#CC6B3B',
                                        },
                                    }}
                                    checked={limitsInfinity}
                                    onClick={handleSetLimitsInfinity}
                                />
                                <Typography className={styles.text_check_item}>+</Typography>
                            </Box>
                        </Button>
                    </Grid>
                )}
            </Grid>
        );
    } else {
        return (
            <ShowLoader message={''} />
        );
    }
}