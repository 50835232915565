import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        backgroundColor: 'transparent',
    },
    grid_datagrid: {
        height: 'auto',
        margin: '5px 10px !important',
    },
    datagrid: {
        minHeight: '371px',
    },
    button_option_data: {
        color: '#FFF !important',
        width: '100% !important',
        marginTop: '10px !important',
        marginBottom: '10px !important',
        flexDirection: 'column',
        padding: '30px 30px !important',
        borderRadius: '10px !important',
        backgroundColor: '#06617c !important',
        alignItems: 'center',
    },
    dialog_root: {
        width: '100%',
        '& .MuiDialog-paperWidthSm': {
            minWidth: '1280px !important',
            maxWidth: '1280px !important'
        }
    },
    datatable: {
        width: '100%',
        margin: "15px 5px !important"
    },
    select_users_item: {
        color: '#000',
        backgroundColor: 'transparent',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'serca-condensed-text',
        fontSize: 16,
    },
    paper: {
        width: '100%',
    },
    data_is_empty: {
        width: '100%',
        display: 'flex',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '50px !important',
    },
});

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        width: '100% !important',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default useStyles;