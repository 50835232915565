import React, { useState } from 'react';
import useStyles from './styles';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import apiService from '../../services/apiService';
// import { ISettingsData } from '../../types';
import AuthBase from '../../components/AuthBase';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

export default function ConfigurationPage() {
    const auth = React.useContext(AuthContext);
    const styles = useStyles();

    const [settingsId, setSettingsId] = React.useState<number | undefined>(undefined);
    // const [settingsName, setSettingsName] = React.useState<string>('');
    const [settingsUrlBase, setSettingsUrlBase] = React.useState<string>('');
    const [settingsUrlAuth, setSettingsUrlAuth] = React.useState<string>('');
    const [settingsPrefixClassification, setSettingsPrefixClassification] = React.useState<string>('');
    const [settingsMethodClassification, setSettingsMethodClassification] = React.useState<'POST' | 'PUT' | 'GET' | 'DELETE' | undefined>(undefined);
    const [settingsPrefixVerification, setSettingsPrefixVerification] = React.useState<string>('');
    const [settingsMethodVerification, setSettingsMethodVerification] = React.useState<'POST' | 'PUT' | 'GET' | 'DELETE' | undefined>(undefined);
    const [settingsPrefixStatus, setSettingsPrefixStatus] = React.useState<string>('');
    const [settingsMethodStatus, setSettingsMethodStatus] = React.useState<'POST' | 'PUT' | 'GET' | 'DELETE' | undefined>(undefined);
    const [settingsKey, setSettingsKey] = React.useState<string>('');
    const [settingsSecret, setSettingsSecret] = React.useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    function handleClearAllData() {
        setLoading(false);
    }

    function validateInputs() {
        let error = true;
        if (settingsUrlBase === '') {
            error = false;
        }
        if (settingsUrlAuth === '') {
            error = false;
        }
        if (settingsPrefixClassification === '') {
            error = false;
        }
        if (settingsPrefixVerification === '') {
            error = false;
        }
        if (settingsPrefixStatus === '') {
            error = false;
        }
        if (settingsKey === '') {
            error = false;
        }
        if (settingsSecret === '') {
            error = false;
        }

        return error;
    }

    // const handlesetSettingsName = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    //     setSettingsName(element.target.value);
    // };
    const handlesetSettingsUrlBase = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSettingsUrlBase(element.target.value);
    };
    const handlesetSettingsUrlAuth = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSettingsUrlAuth(element.target.value);
    };
    const handlesetSettingsPrefixClassification = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSettingsPrefixClassification(element.target.value);
    };
    const handlesetSettingsMethodClassification = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (
            element.target.value === 'POST' ||
            element.target.value === 'GET' ||
            element.target.value === 'PUT' ||
            element.target.value === 'DELETE'
        ) {
            setSettingsMethodClassification(element.target.value);
        } else {
            setSettingsMethodClassification(undefined);
        }
    };
    const handlesetSettingsPrefixVerification = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSettingsPrefixVerification(element.target.value);
    };
    const handlesetSettingsMethodVerification = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (
            element.target.value === 'POST' ||
            element.target.value === 'GET' ||
            element.target.value === 'PUT' ||
            element.target.value === 'DELETE'
        ) {
            setSettingsMethodVerification(element.target.value);
        } else {
            setSettingsMethodVerification(undefined);
        }
    };
    const handlesetSettingsPrefixStatus = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSettingsPrefixStatus(element.target.value);
    };
    const handlesetSettingsMethodStatus = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (
            element.target.value === 'POST' ||
            element.target.value === 'GET' ||
            element.target.value === 'PUT' ||
            element.target.value === 'DELETE'
        ) {
            setSettingsMethodStatus(element.target.value);
        } else {
            setSettingsMethodStatus(undefined);
        }
    };
    const handlesetSettingsKey = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSettingsKey(element.target.value);
    };
    const handlesetSettingsSecret = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSettingsSecret(element.target.value);
    };

    async function updateSettings() {
        if (auth !== undefined) {
            if (!validateInputs()) {
                setLoading(true);
                await apiService.put(
                    'settings',
                    {
                        settings_id: settingsId,
                        name: '',
                        uri_base: settingsUrlBase,
                        uri_auth: settingsUrlAuth,
                        classification_prefix: settingsPrefixClassification,
                        classification_method: settingsMethodClassification,
                        validation_prefix: settingsPrefixVerification,
                        validation_method: settingsMethodVerification,
                        status_prefix: settingsPrefixStatus,
                        status_method: settingsMethodStatus,
                        consumer_key: settingsKey,
                        consumer_secret: settingsSecret
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setSettingsId(response.data.data.id);
                        // setSettingsName(response.data.data.name);
                        setSettingsUrlBase(response.data.data.uri_base);
                        setSettingsUrlAuth(response.data.data.uri_auth);
                        setSettingsPrefixClassification(response.data.data.classification_prefix);
                        setSettingsMethodClassification(response.data.data.classification_method);
                        setSettingsPrefixVerification(response.data.data.validation_prefix);
                        setSettingsMethodVerification(response.data.data.validation_method);
                        setSettingsPrefixStatus(response.data.data.status_prefix);
                        setSettingsMethodStatus(response.data.data.status_method);
                        setSettingsKey(response.data.data.consumer_key);
                        setSettingsSecret(response.data.data.consumer_secret);
                        handleClearAllData();
                    } else {
                        console.log('response: ', response);
                        setSettingsUrlBase('')
                        handleClearAllData();
                    }
                }).catch((error) => {
                    console.log('error: ', error);
                    setSettingsUrlBase('')
                    handleClearAllData();
                });
            }
        }
    }

    async function getSettings() {
        if (auth !== undefined) {
            setLoading(true);
            await apiService.get(
                'settings',
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setSettingsId(response.data.data.id);
                    // setSettingsName(response.data.data.name);
                    setSettingsUrlBase(response.data.data.uri_base);
                    setSettingsUrlAuth(response.data.data.uri_auth);
                    setSettingsPrefixClassification(response.data.data.classification_prefix);
                    setSettingsMethodClassification(response.data.data.classification_method);
                    setSettingsPrefixVerification(response.data.data.validation_prefix);
                    setSettingsMethodVerification(response.data.data.validation_method);
                    setSettingsPrefixStatus(response.data.data.status_prefix);
                    setSettingsMethodStatus(response.data.data.status_method);
                    setSettingsKey(response.data.data.consumer_key);
                    setSettingsSecret(response.data.data.consumer_secret);
                    handleClearAllData();
                } else {
                    console.log('response: ', response);
                    setSettingsUrlBase('')
                    handleClearAllData();
                }
            }).catch((error) => {
                console.log('error: ', error);
                setSettingsUrlBase('')
                handleClearAllData();
            });
        }
    }

    React.useEffect(() => {
        if (settingsId === undefined) {
            getSettings();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <AuthBase>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                className={styles.grid_root}
            >
                <Grid item xs={12} className={styles.grid_title}>
                    <Typography>Configurações - Sistema Especialista</Typography>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={settingsUrlBase}
                            label='URL da API'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsUrlBase}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={settingsUrlAuth}
                            label='URL de Autenticação'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsUrlAuth}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={settingsPrefixClassification}
                            label='Prefixo da Classificação'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsPrefixClassification}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={`${settingsMethodClassification}`}
                            label='Método da classificação'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsMethodClassification}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={settingsPrefixVerification}
                            label='Prefixo de validação da classificação'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsPrefixVerification}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={`${settingsMethodVerification}`}
                            label='Método da validação da classificação'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsMethodVerification}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={settingsPrefixStatus}
                            label='Prefixo de status'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsPrefixStatus}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={`${settingsMethodStatus}`}
                            label='Método do status'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsMethodStatus}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={settingsKey}
                            label='Chave de Acesso'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsKey}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.grid_input}>
                    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                        <TextField
                            value={settingsSecret}
                            label='Chave Secreta'
                            required
                            disabled={loading}
                            onChange={handlesetSettingsSecret}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} className={styles.grid_button}>
                    <LoadingButton
                        className={styles.button_update}
                        loading={loading}
                        loadingPosition="end"
                        endIcon={<SaveIcon className={styles.icon_pen_edit} />}
                        variant="outlined"
                        onClick={() => updateSettings()}
                    >
                        Atualizar Dados
                    </LoadingButton>
                </Grid>
            </Grid>
        </AuthBase>
    );
}
