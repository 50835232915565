import React from 'react';
import useStyles, { BootstrapDialog } from './styles';
import { useNavigate } from 'react-router-dom';
import {
    IUser,
    ISystemLogsProject,
    ISystemLogsExams,
    ISystemLogsGroups,
    ISystemLogsHorizon,
    ISystemLogsChemical,
    ISystemLogsPhysical,
    ISystemLogsSystem,
} from '../../types';
import { formatDateToLocaleString } from '../../formatters';
import apiService from '../../services/apiService';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import AuthBase from '../../components/AuthBase';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DataTable, { TableColumn } from 'react-data-table-component';

export default function SystemLogsPage() {
    const styles = useStyles();
    const navigate = useNavigate();
    const auth = React.useContext(AuthContext);
    const [projectLog, setProjectLog] = React.useState<Array<ISystemLogsProject> | undefined>(undefined);
    const [examsLog, setExamsLog] = React.useState<Array<ISystemLogsExams> | undefined>(undefined);
    const [groupsLog, setGroupsLog] = React.useState<Array<ISystemLogsGroups> | undefined>(undefined);
    const [horizonLog, setHorizonLog] = React.useState<Array<ISystemLogsHorizon> | undefined>(undefined);
    const [chemicalLog, setChemicalLog] = React.useState<Array<ISystemLogsChemical> | undefined>(undefined);
    const [physicalLog, setPhysicalLog] = React.useState<Array<ISystemLogsPhysical> | undefined>(undefined);
    const [systemLog, setSystemLog] = React.useState<Array<ISystemLogsSystem> | undefined>(undefined);
    const [arUsers, setArUsers] = React.useState<Array<IUser> | undefined>(undefined);

    const [showProjectLog, setShowProjectLog] = React.useState<boolean>(false);
    const [showExamsLog, setShowExamsLog] = React.useState<boolean>(false);
    const [showGroupsLog, setShowGroupsLog] = React.useState<boolean>(false);
    const [showHorizonLog, setShowHorizonLog] = React.useState<boolean>(false);
    const [showChemicalLog, setShowChemicalLog] = React.useState<boolean>(false);
    const [showPhysicalLog, setShowPhysicalLog] = React.useState<boolean>(false);
    const [showSystemLog, setShowSystemLog] = React.useState<boolean>(false);
    const [showOptionsUserLogs, setShowOptionsUserLogs] = React.useState<boolean>(false);

    const columnsProject: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsProject) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            cell: (row: ISystemLogsProject) => (
                <>
                    <Tooltip title={`${row.description}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.description}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsProject) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Nome do Projeto',
            width: '200px',
            cell: (row: ISystemLogsProject) => (
                <>
                    <Tooltip title={`${row.project?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.project?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsProject) => (
                <>
                    <Tooltip title={`${row.author?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.author?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        }
        // eslint-disable-next-line
    ], []);

    const columnsExams: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsExams) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            cell: (row: ISystemLogsExams) => (
                <>
                    <Tooltip title={`${row.description}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.description}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsExams) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Nome do Exame',
            cell: (row: ISystemLogsExams) => (
                <>
                    <Tooltip title={`${row.exam?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.exam?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsExams) => (
                <>
                    <Tooltip title={`${row.author?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.author?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        }
        // eslint-disable-next-line
    ], []);

    const columnsGroups: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsGroups) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            cell: (row: ISystemLogsGroups) => (
                <>
                    <Tooltip title={`${row.description}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.description}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsGroups) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Nome do Grupo',
            cell: (row: ISystemLogsGroups) => (
                <>
                    <Tooltip title={`${row.group?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.group?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsGroups) => (
                <>
                    <Tooltip title={`${row.author?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.author?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        }
        // eslint-disable-next-line
    ], []);

    const columnsHorizons: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsHorizon) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            cell: (row: ISystemLogsHorizon) => (
                <>
                    <Tooltip title={`${row.description}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.description}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsHorizon) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Nome do Horizonte',
            cell: (row: ISystemLogsHorizon) => (
                <>
                    <Tooltip title={`${row.horizon?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.horizon?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsHorizon) => (
                <>
                    <Tooltip title={`${row.author?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.author?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        }
        // eslint-disable-next-line
    ], []);

    const columnsChemical: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsChemical) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            cell: (row: ISystemLogsChemical) => (
                <>
                    <Tooltip title={`${row.description}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.description}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsChemical) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsChemical) => (
                <>
                    <Tooltip title={`${row.author?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.author?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        }
        // eslint-disable-next-line
    ], []);

    const columnsPhysical: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsPhysical) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            cell: (row: ISystemLogsPhysical) => (
                <>
                    <Tooltip title={`${row.description}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.description}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsPhysical) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsPhysical) => (
                <>
                    <Tooltip title={`${row.author?.name}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.author?.name}
                        </Typography>
                    </Tooltip>
                </>
            ),
        }
        // eslint-disable-next-line
    ], []);

    const columnsSystem: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsSystem) => row.id,
        },
        {
            name: 'Descrição',
            cell: (row: ISystemLogsSystem) => (
                <>
                    <Tooltip title={`${row.description}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            {row.description}
                        </Typography>
                    </Tooltip>
                </>
            ),
        },
        {
            name: 'JSON - REQUEST',
            width: '150px',
            cell: (row: ISystemLogsSystem) => (
                <>
                    <Tooltip title={`${row.json_request}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            VER JSON
                        </Typography>
                    </Tooltip>
                </>
            ),
            ignoreRowClick: true,
        },
        {
            name: 'JSON - RESPONSE',
            width: '150px',
            cell: (row: ISystemLogsSystem) => (
                <>
                    <Tooltip title={`${row.json_response}`}>
                        <Typography style={{ fontSize: '12px'}}>
                            VER JSON
                        </Typography>
                    </Tooltip>
                </>
            ),
            ignoreRowClick: true,
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsSystem) => formatDateToLocaleString(`${row.created_at}`),
        }
        // eslint-disable-next-line
    ], []);

    function handleClose() {
        setShowProjectLog(false);
        setShowExamsLog(false);
        setShowGroupsLog(false);
        setShowHorizonLog(false);
        setShowChemicalLog(false);
        setShowPhysicalLog(false);
        setShowSystemLog(false);
        setShowOptionsUserLogs(false);
    }

    function handleSetUserModal(userId: number) {
        navigate(`/usuarios/historico/${userId}`);
    }

    const getProjectLog = async() => {
        if (projectLog === undefined && auth !== undefined) {
            await apiService.get(
                'project/logs',
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setProjectLog(response.data.data);
                }
            }).catch((error) => {
                console.log('getProjectLog-error', error);
                setProjectLog([]);
            });
        }
    }

    const getExamLog = async() => {
        if (examsLog === undefined && auth !== undefined) {
            await apiService.get(
                'exams/logs',
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setExamsLog(response.data.data);
                }
            }).catch((error) => {
                console.log('getExamLog-error', error);
                setExamsLog([]);
            });
        }
    }

    const getGroupsLog = async() => {
        if (groupsLog === undefined && auth !== undefined) {
            await apiService.get(
                'groups/logs',
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setGroupsLog(response.data.data);
                }
            }).catch((error) => {
                console.log('getGroupsLog-error', error);
                setGroupsLog([]);
            });
        }
    }

    const getHorizonLog = async() => {
        if (horizonLog === undefined && auth !== undefined) {
            await apiService.get(
                'exams/horizon/logs',
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setHorizonLog(response.data.data);
                }
            }).catch((error) => {
                console.log('getHorizonLog-error', error);
                setHorizonLog([]);
            });
        }
    }

    const getChemicalLog = async() => {
        if (chemicalLog === undefined && auth !== undefined) {
            await apiService.get(
                'exams/chemical/logs',
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setChemicalLog(response.data.data);
                }
            }).catch((error) => {
                console.log('getChemicalLog-error', error);
                setChemicalLog([]);
            });
        }
    }

    const getPhysicalLog = async() => {
        if (physicalLog === undefined && auth !== undefined) {
            await apiService.get(
                'exams/physical/logs',
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setPhysicalLog(response.data.data);
                }
            }).catch((error) => {
                console.log('getPhysicalLog-error', error);
                setPhysicalLog([]);
            });
        }
    }

    const getSystemLog = async() => {
        if (physicalLog === undefined && auth !== undefined) {
            await apiService.get(
                'settings/logs',
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setSystemLog(response.data.data);
                }
            }).catch((error) => {
                console.log('getSystemLog-error', error);
                setSystemLog([]);
            });
        }
    }

    const getUsers = async() => {
        if (arUsers === undefined && auth !== undefined) {
            await apiService.get(
                'users',
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setArUsers(response.data.data);
                }
            }).catch((error) => {
                console.log('getUsers-error', error);
                setArUsers([]);
            });
        }
    }

    React.useEffect(() => {
        getUsers();
        getProjectLog();
        getExamLog();
        getGroupsLog();
        getHorizonLog();
        getChemicalLog();
        getPhysicalLog();
        getSystemLog();
        // eslint-disable-next-line
    }, []);

    return (
        <AuthBase>
            <>
                <Grid container className={styles.grid_root}>
                    <Grid item xs={2} className={styles.grid_datagrid}>
                        <Button className={styles.button_option_data} onClick={() => setShowProjectLog(true)}>Projetos</Button>
                    </Grid>
                    <Grid item xs={2} className={styles.grid_datagrid}>
                        <Button className={styles.button_option_data} onClick={() => setShowExamsLog(true)}>Exames</Button>
                    </Grid>
                    <Grid item xs={2} className={styles.grid_datagrid}>
                        <Button className={styles.button_option_data} onClick={() => setShowGroupsLog(true)}>Grupos</Button>
                    </Grid>
                    <Grid item xs={2} className={styles.grid_datagrid}>
                        <Button className={styles.button_option_data} onClick={() => setShowHorizonLog(true)}>Horizontes</Button>
                    </Grid>
                    <Grid item xs={2} className={styles.grid_datagrid}>
                        <Button className={styles.button_option_data} onClick={() => setShowChemicalLog(true)}>Química</Button>
                    </Grid>
                    <Grid item xs={2} className={styles.grid_datagrid}>
                        <Button className={styles.button_option_data} onClick={() => setShowPhysicalLog(true)}>Física</Button>
                    </Grid>
                    <Grid item xs={2} className={styles.grid_datagrid}>
                        <Button className={styles.button_option_data} onClick={() => setShowSystemLog(true)}>Sistema</Button>
                    </Grid>
                    <Grid item xs={2} className={styles.grid_datagrid}>
                        <Button className={styles.button_option_data} onClick={() => setShowOptionsUserLogs(true)}>Usuários</Button>
                    </Grid>
                </Grid>
                {(arUsers !== undefined && arUsers.length > 0) && (
                    <Popover
                        open={showOptionsUserLogs}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        {arUsers.map((element) => (
                            <MenuItem className={styles.select_users_item} key={0} onClick={() => handleSetUserModal(element.id)} value={`${element.id}`}>{element.name}</MenuItem>
                        ))}
                    </Popover>
                )}
                <BootstrapDialog
                    onClose={() => handleClose()}
                    className={styles.dialog_root}
                    open={showProjectLog}
                    aria-describedby="modal-modal-description"
                >
                    <DialogContent dividers>
                        {(projectLog !== undefined && projectLog.length > 0) && (
                            <Paper className={styles.paper}>
                                <DataTable
                                    title="Log dos Projetos"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsProject}
                                    className={styles.datatable}
                                    data={projectLog}
                                />
                            </Paper>
                        )}
                        {(projectLog !== undefined && projectLog.length === 0) && (
                            <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
                <BootstrapDialog
                    onClose={() => handleClose()}
                    className={styles.dialog_root}
                    open={showExamsLog}
                    aria-describedby="modal-modal-description"
                >
                    <DialogContent dividers>
                        {(examsLog !== undefined && examsLog.length > 0) && (
                            <Paper className={styles.paper}>
                                <DataTable
                                    title="Log dos Exames"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsExams}
                                    className={styles.datatable}
                                    data={examsLog}
                                />
                            </Paper>
                        )}
                        {(examsLog !== undefined && examsLog.length === 0) && (
                            <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
                <BootstrapDialog
                    onClose={() => handleClose()}
                    className={styles.dialog_root}
                    open={showGroupsLog}
                    aria-describedby="modal-modal-description"
                >
                    <DialogContent dividers>
                        {(groupsLog !== undefined && groupsLog.length > 0) && (
                            <Paper className={styles.paper}>
                                <DataTable
                                    title="Log de Grupos"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsGroups}
                                    className={styles.datatable}
                                    data={groupsLog}
                                />
                            </Paper>
                        )}
                        {(groupsLog !== undefined && groupsLog.length === 0) && (
                            <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
                <BootstrapDialog
                    onClose={() => handleClose()}
                    className={styles.dialog_root}
                    open={showHorizonLog}
                    aria-describedby="modal-modal-description"
                >
                    <DialogContent dividers>
                        {(horizonLog !== undefined && horizonLog.length > 0) && (
                            <Paper className={styles.paper}>
                                <DataTable
                                    title="Log dos Horizontes"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsHorizons}
                                    className={styles.datatable}
                                    data={horizonLog}
                                />
                            </Paper>
                        )}
                        {(horizonLog !== undefined && horizonLog.length === 0) && (
                            <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
                <BootstrapDialog
                    onClose={() => handleClose()}
                    className={styles.dialog_root}
                    open={showPhysicalLog}
                    aria-describedby="modal-modal-description"
                >
                    <DialogContent dividers>
                        {(physicalLog !== undefined && physicalLog.length > 0) && (
                            <Paper className={styles.paper}>
                                <DataTable
                                    title="Log dos dados de Física"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsPhysical}
                                    className={styles.datatable}
                                    data={physicalLog}
                                />
                            </Paper>
                        )}
                        {(physicalLog !== undefined && physicalLog.length === 0) && (
                            <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
                <BootstrapDialog
                    onClose={() => handleClose()}
                    className={styles.dialog_root}
                    open={showChemicalLog}
                    aria-describedby="modal-modal-description"
                >
                    <DialogContent dividers>
                        {(chemicalLog !== undefined && chemicalLog.length > 0) && (
                            <Paper className={styles.paper}>
                                <DataTable
                                    title="Log dos dados de Química"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsChemical}
                                    className={styles.datatable}
                                    data={chemicalLog}
                                />
                            </Paper>
                        )}
                        {(chemicalLog !== undefined && chemicalLog.length === 0) && (
                            <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
                <BootstrapDialog
                    onClose={() => handleClose()}
                    className={styles.dialog_root}
                    open={showSystemLog}
                    aria-describedby="modal-modal-description"
                >
                    <DialogContent dividers>
                        {(systemLog !== undefined && systemLog.length > 0) && (
                            <Paper className={styles.paper}>
                                <DataTable
                                    title="Log do Sistema"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsSystem}
                                    className={styles.datatable}
                                    data={systemLog}
                                />
                            </Paper>
                        )}
                        {(systemLog !== undefined && systemLog.length === 0) && (
                            <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </>
        </AuthBase>
    );
}
