import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import UsersPage from './pages/UsersPage';
import LoginPage from './pages/LoginPage';
import ForgotPage from './pages/ForgotPage';
import ProfilePage from './pages/ProfilePage';
import ExamDetailsPage from './pages/ExamDetailsPage';
import ConfigurationPage from './pages/ConfigurationPage';
import SystemLogsPage from './pages/SystemLogsPage';
import ProjectsPage from './pages/ProjectsPage';
import GroupsPage from './pages/GroupsPage';
import GroupDetailsPage from './pages/GroupDetailsPage';
import UserLogsPage from './pages/UserLogsPage';
import RegisterAccountPage from './pages/RegisterAccountPage';

import { useAuth } from "./contexts/Auth/AuthProvider";
import CreditsPage from "./pages/CreditsPage";
import axios from "axios";
import { Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { BootstrapDialog } from "./pages/UsersPage/styles";


function App() {
    const { user, signOut } = useAuth();
    const [showDialogDelete, setShowDialogDelete] = useState(false);

    const setAlert = () => {
        return new Promise(async (resolve, reject) => {
            try {
                setShowDialogDelete(true);
            } catch (err) {
                reject(err);
            }
        });
    }

    const detectStatusCode = (error: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (error.response && error.response.status === 401) {
                    reject()
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    useEffect(() => {

        axios.interceptors.request.use(async (config) => {
            const date = new Date();
            const authorization = config.headers?.Authorization as string;
            const token = authorization?.replace('Bearer ', '');
            const payload = token && window.atob(token.split('.')[1]);
            const exp = payload && JSON.parse(payload).exp * 1000;
            const isInvalidToken = (Number(date.getTime()) > Number(exp));

            if (isInvalidToken) {
                await setAlert()
                return config;
            }

            return config;
        }, (error) => {
            if (error.response && error.response.status === 401) return
            Promise.reject(error);
        });

        // Add a response interceptor
        axios.interceptors.response.use((response) =>
            response,
            async (error) => {
                await detectStatusCode(error);
                return Promise.reject(error);
            });

    }, [])

    const PrivateRoute = ({ children }: { children: JSX.Element }) => {
        return !!user ? children : <Navigate to="/" />;
    };

    return (
        <>
            <Routes>
                <Route path={"*"} element={<LoginPage />} />
                <Route path={"/"} element={<LoginPage />} />
                <Route path="/entrar" element={<LoginPage />} />
                <Route path="/recuperar-senha" element={<ForgotPage />} />
                <Route path="/cadastrar" element={<RegisterAccountPage />} />
                <Route path="/projetos" element={<PrivateRoute><ProjectsPage /></PrivateRoute>} />
                <Route path="/projetos/exames/detalhe/:examId" element={<PrivateRoute><ExamDetailsPage /></PrivateRoute>} />
                <Route path="/perfil" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
                <Route path="/creditos" element={<PrivateRoute><CreditsPage /></PrivateRoute>} />
                <Route path="/usuarios" element={<PrivateRoute><UsersPage /></PrivateRoute>} />
                <Route path="/usuarios/historico/:userId" element={<PrivateRoute><UserLogsPage /></PrivateRoute>} />
                <Route path="/grupos" element={<PrivateRoute><GroupsPage /></PrivateRoute>} />
                <Route path="/grupos/detalhes/:groupId" element={<PrivateRoute><GroupDetailsPage /></PrivateRoute>} />
                <Route path="/configuracoes" element={<PrivateRoute><ConfigurationPage /></PrivateRoute>} />
                <Route path="/logs" element={<PrivateRoute><SystemLogsPage /></PrivateRoute>} />

            </Routes>
            <BootstrapDialog
                onClose={() => setShowDialogDelete(false)}
                aria-labelledby="customized-dialog-title"
                open={showDialogDelete}
            >

                <DialogContent dividers>
                    <Typography>Devido à inatividade, o SmartSolos foi fechado. Favor acessar novamente.
                    </Typography>
                </DialogContent>
                <DialogActions>

                    <Button onClick={() => { signOut(); setShowDialogDelete(false); }}>
                        <Typography >OK</Typography>
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}

export default App;
