import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        display: 'flex',
        marginTop: '10px !important',
        backgroundColor: 'transparent',
    },
    grid_exam_classification: {
        marginTop: '10px !important',
        display: 'flex',
        alignItems: 'flex-end',
        textAlign: 'left',
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    grid_exam_details_field_classification: {
        marginTop: '10px !important',
        display: 'flex',
        backgroundColor: 'transparent',
    },
    grid_exam_details: {
        display: 'flex',
        backgroundColor: 'transparent',
    },
    group_tabs: {
        height: '60px !important',
        marginTop: '25px !important',
        backgroundColor: '#cddee9 !important',
        color: '#06617c !important',
        alignItems: 'center',
        '& button.MuiButtonBase-root.MuiTab-textColorPrimary': {
            color: '#06617c !important',
        },
        '& .MuiTabs-scroller span.MuiTabs-indicator': {
            height: '100% !important',
            backgroundColor: '#06617c !important',
            opacity: 0.35,
        }
    },
    paper: {
        width: '100%',
    },
    data_is_empty: {
        width: '100%',
        display: 'flex',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '50px !important',
    },
    grid_item_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        alignContent: 'center',
    },
    button_back_page: {
        color: '#FFF !important',
        width: '100% !important',
        marginTop: '10px !important',
        marginBottom: '10px !important',
        flexDirection: 'column',
        padding: '10px 10px !important',
        borderRadius: '10px !important',
        backgroundColor: '#06617c !important',
        alignItems: 'center',
    },
    grid_button_report_data: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        alignContent: 'center',
    },
    button_report_data: {
        width: '350px !important',
        backgroundColor: '#06617c !important',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px !important',
        padding: '12px 35px !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 13,
        color: '#FFFFFF !important',
    },
    grid_title_exam: {
        display: 'flex',
        marginTop: '10px !important',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
    },
    icon_download: {
        backgroundColor: 'transparent',
        color: '#FFF',
    },
    button_action: {
        margin: '0px 10px !important',
    },
});

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default useStyles;