import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        display: 'flex',
        backgroundColor: 'transparent',
        alignItems: 'center',
    },
    grid_container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
    },
    grid_container_register: {
        display: 'flex',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 15%',
    },
    grid_input: {
        backgroundColor: 'transparent',
        marginTop: 10,
    },
    grid_button: {
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 10,
    },
    grid_button_space: {
        marginTop: 70,
        alignItems: 'center',
        textAlign: 'center',
    },
    text_aling: {
        textAlign: 'center'
    },
    button_action: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        marginTop: 10,
    },
    button_color_border: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        border: '1px solid #06617c !important',
    },
});

export default useStyles;