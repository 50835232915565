import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_horizon_color_item: {
        minWidth: '100%',
        height: 120,
        flexDirection: 'column',
        borderRadius: 5,
        marginBottom: '15px !important',
    },
    view_horizon_name_top: {
        width: '100%',
        height: 80,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    view_horizon_name_item: {
        width: '100%',
        height: 40,
        opacity: 0.75,
        flexDirection: 'row',
        backgroundColor: '#38373C',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    view_item_box_center: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    input_horizon_name: {
        height: '40px !important',
        flexDirection: 'column',
        borderWidth: 1,
        color: '#FFF !important',
        fontWeight: 'bold',
        borderRadius: 10,
        verticalAlign: 'center',
        padding: '0px 10px',
        '& .MuiOutlinedInput-root': {
            border: 'none !important',
            color: '#FFF !important',
            height: '40px !important',
        }
    },
    icon_remove_horizon: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#FFF !important',
        padding: '0px !important',
        fontSize: 20,
        alignItems: 'center',
    },
    view_horizon_root_item: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    view_horizon_delete: {
        width: '100%',
        height: 130,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px !important',
        backgroundColor: 'transparent',
    },
    view_horizon_list_delete: {
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_horizon_name: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 20px !important',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'center',
    },
    view_horizon_action_delete: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    action_decline_icon: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#EA0909 !important',
        fontSize: 40,
        alignItems: 'flex-end',
    },
    button_actions_transparent: {
        width: 70,
        flexDirection: 'column',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25,
    },
    action_confirm_icon: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#00FD38 !important',
        fontSize: 40,
        alignItems: 'flex-end',
    },
});

export default useStyles;