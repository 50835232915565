import * as React from 'react';
import useStyles from './styles';
import AlertComponent from '../AlertComponent';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
    show: boolean
    type: 'loader' | 'decision' | undefined
    status: 'error' | 'success' | 'warning' | 'info' | undefined
    message: string | undefined
    title: string | undefined
}

const LoaderComponent = (props: IProps): JSX.Element => {
    const styles = useStyles();
    const [open, setOpen] = React.useState<boolean>(false);
    const [timeOut, setTimeOut] = React.useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
        document.location.reload();
    };

    const handleCloseTimeOut = () => {
        setOpen(false);
        document.location.reload();
    };

    React.useEffect(() => {
        setOpen(props.show);
    },[props]);

    React.useEffect(() => {
        setTimeout(() => {
            setTimeOut(true);
        }, 15000);
    },[]);

    return (
        <>
            {(props.show === true) && (
                <div className={styles.grid_div}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        className={styles.grid_backdrop}
                    >
                        {(props.message === undefined && props.status === undefined) && (
                            <CircularProgress
                                className={styles.grid_circular}
                                color="inherit"
                            />
                        )}
                        {(props.message !== undefined && props.status !== undefined) && (
                            <div className={styles.grid_div_message}>
                                <AlertComponent
                                    show={true}
                                    status={props.status}
                                    message={props.message}
                                    title={props.title}
                                />
                                <Button className={styles.button_ok} onClick={handleClose}>Ok</Button>
                            </div>
                        )}
                        {(timeOut !== false && props.message === undefined && props.status === undefined) && (
                            <div className={styles.grid_div_message}>
                                <Button className={styles.button_cancel} onClick={handleCloseTimeOut} color="error">Cancelar</Button>
                            </div>
                        )}
                    </Backdrop>
                </div>
            )}
        </>
    );
}

export default LoaderComponent;