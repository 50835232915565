import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
    IFilterHorizons,
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    horizonFilters: IFilterHorizons
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonPlasticityItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [plasticity, setPlasticity] = useState<string>('0');
    const [plasticityUpdate, setPlasticityUpdate] = useState<boolean>(false);

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            if (
                plasticity !== null &&
                plasticity !== '0' &&
                props.horizon.plasticity !== `${plasticity}`
            ) {
                request_data['plasticity'] = `${plasticity}`;
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                    }
                }).catch(() => {
                });
            } catch (e) {
            }
        }
    }

    useEffect(() => {
        if (plasticityUpdate === true) {
            updateHorizon();
            setPlasticityUpdate(false);
        }
        return () => setPlasticityUpdate(false);
        // eslint-disable-next-line
    }, [plasticityUpdate]);

    useEffect(() => {
        if (props.horizon !== undefined) {
            setPlasticity((props.horizon.plasticity === null ? '0' : props.horizon.plasticity));
        }
        // eslint-disable-next-line
    }, [props]);

    return (
        <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={`${props.horizon.id}${props.horizon.name}plasticity`}
            className={styles.view_horizon_plasticity}
        >
            <Grid item xs={12} className={styles.view_horizon_plasticity_title}>
                <Typography className={styles.text_horizon_plasticity}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_plasticity_content}>
                <TextField
                    className={styles.picker_select_type}
                    select
                    value={plasticity ? plasticity : "0"}
                    defaultValue={'0'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPlasticity(`${event.target.value}`);
                        setPlasticityUpdate(true);
                    }}
                >
                    <MenuItem className={styles.picker_select_item} key={'0'} value={'0'}>Selecione</MenuItem>
                    {props.horizonFilters.plasticity.map((element, key) => (
                        <MenuItem className={styles.picker_select_item} key={key} value={`${key + 1}`}>{`${element}`}</MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    );
}