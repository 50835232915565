import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_horizon_thickness: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    view_horizon_thickness_title: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
    },
    text_horizon_thickness: {
        flexDirection: 'column',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000000 !important",
        fontWeight: "bold",
        textAlign: "center",
        alignItems: 'flex-start',
    },
    view_horizon_thickness_content: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginTop: '10px !important',
        marginBottom: '15px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    typography_input_data_value: {
        color: '#06617c !important',
        backgroundColor: 'transparent',
        borderRadius: 10,
        marginBottom: '20px !important',
        textAlign: 'center',
        verticalAlign: 'center',
        justifyContent: 'center',
    },
    grid_item_name_horizon: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        textAlign: "center",
        alignItems: 'center',
        justifyContent: 'center',
    },
    input_data_thickness: {
        width: 150,
        height: 45,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        borderColor: '#CDCDCD !important',
        borderWidth: 1,
        padding: '8px 0px !important',
        margin: '0px 10px !important',
        borderRadius: 10,
        textAlign: 'center',
        verticalAlign: 'center'
    },
    icon_calculator: {
        margin: '0px 10px !important',
        verticalAlign: 'center',
        flexDirection: 'column',
        alignItems: 'flex-end',
        color: '#06617c !important'
    },
});

export default useStyles;