import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_horizon_compression_surfaces: {
        width: '100%',
        backgroundColor: 'transparent',
        marginBottom: '20px !important',
    },
    view_horizon_compression_surfaces_title: {
        backgroundColor: 'transparent',
    },
    text_horizon_compression_surfaces: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000000",
        fontWeight: "bold",
        textAlign: "center"
    },
    view_horizon_compression_surfaces_item: {
        width: '100%',
        backgroundColor: '#FFF',
        borderColor: '#CDCDCD',
        borderWidth: 0.5,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button_checkobox_compression_surfaces_content: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    text_horizon_character_data: {
        width: '100%',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000000 !important",
        textAlign: "left",
        textTransform: 'none',
    },
});

export default useStyles;