import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        backgroundColor: 'transparent',
    },
    grid_title_buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        margin: '10px 0px 15px 0px !important'
    },
    button_create: {
        borderRadius: '10px !important',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        border: '1px solid #06617c !important',
    },
    grid_datagrid: {
        height: 'auto',
        margin: '10px 0px !important',
    },
    paper: {
        width: '100%',
    },
    grid_input: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        margin: '10px 0px !important',
    },
    data_is_empty: {
        width: '100%',
        display: 'flex',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        margin: '25px 0px !important',
    },
    datagrid: {
        minHeight: '634px',
        '& .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row': {
            maxHeight: 'max-content !important',
        },
        '& .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell': {
            maxHeight: 'max-content !important',
        }
    },
    title: {
        margin: '10px 0px 20px 0px !important'
    },
});

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default useStyles;