import React from 'react';
import useStyles from './styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AuthBase from '../../components/AuthBase';
import logoCredits from '../../assets/images/smartSolosCredit.png'

export default function CreditsPage() {
    const styles = useStyles();
    const marginBottom = (value: number) => ({ marginBottom: value })
    return (
        <AuthBase>
            <>
                <Grid container className={styles.view_centered_content} direction='column'>
                    <img className={styles.logo_credits} src={logoCredits} alt='logo' />
                    <Typography className={styles.bold_text} style={{ ...marginBottom(15), marginTop: '-45px', fontWeight: 'bold' }}>Equipe de Desenvolvimento Embrapa</Typography>
                    <Typography>Luís de França da Silva Neto</Typography>
                    <Typography className={styles.small_text} style={marginBottom(10)}>Líder de Projeto (Embrapa Solos UEP Recife)</Typography>
                    <Typography>Glauber José Vaz</Typography>
                    <Typography className={styles.small_text} style={marginBottom(10)}>Sistema Especialista de Classificação (Embrapa Agricultura Digital)</Typography>
                    <Typography>Adalberto Francisco da Silva Júnior</Typography>
                    <Typography className={styles.small_text} style={marginBottom(10)}>Tester (estagiário UFRPE, bolsista CNPq)</Typography>
                    <Typography>Flávio Adriano Marques</Typography>
                    <Typography>José Carlos Pereira dos Santos</Typography>
                    <Typography>José Coelho de Araújo Filho</Typography>
                    <Typography className={styles.small_text} style={marginBottom(10)}>Consultores Pedologia e Classificação de Solos (Embrapa Solos UEP Recife)</Typography>
                    <Typography>Alexandre Marcolino</Typography>
                    <Typography className={styles.small_text} style={marginBottom(15)}>Apoio Infraestrutura, Máquinas Virtuais e Publicação Mobile (Embrapa Solos)</Typography>
                    <Typography>Guilherme Bannwart Santos</Typography>
                    <Typography className={styles.small_text} style={marginBottom(25)}>Apoio Infraestrutura, Máquinas Virtuais (Embrapa Agricultura Digital)</Typography>
                    <Typography className={styles.bold_text} style={{ ...marginBottom(15), fontWeight: 'bold' }}>Equipe de Desenvolvimento Messier</Typography>
                    <Typography>Renan Ramos Morais de Olivera</Typography>
                    <Typography className={styles.small_text} style={marginBottom(10)}>Desenvolvedor Fullstack</Typography>
                    <Typography>Gabriel Monte Zehyr</Typography>
                    <Typography>Warlen Alves</Typography>
                    <Typography className={styles.small_text} style={marginBottom(30)}>Desenvolvedores Front-end</Typography>
                    <Typography className={styles.bold_text} style={{ ...marginBottom(15), fontWeight: 'bold' }}>Agradecimentos Especiais</Typography>
                    <Typography className={styles.text_center} style={marginBottom(20)}>Damiana de Matos, Carmem Lucia, Gustavo Vasques, Etelvino Novotny, Vinícius Benites, Maria Sonia Lopes, Evaldo Lima, Alexandre Hugo, Estêvão Ramos, Maria Regina Laforet, Maria Ortiz.</Typography>
                </Grid>
            </>
        </AuthBase>
    );
}
