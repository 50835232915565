import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
    IFilterHorizons,
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Multiselect from 'multiselect-react-dropdown';
import Grid from '@mui/material/Grid';

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    horizonFilters: IFilterHorizons
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonRootsItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [optionsFilter, setOptionsFilter] = React.useState<Array<any>>([]);
    const [startUpdateHorizon, setStartUpdateHorizon] = React.useState<boolean>(false);

    function isBigEnough(value: number) {
        return value > 0;
    }

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId)
            };

            let filtered: string[] = [];
            if (selectedItems !== undefined && selectedItems !== null && selectedItems.length > 0) {
                props.horizonFilters.roots.forEach((elementRoot) => {
                    elementRoot.children.forEach((elementItem) => {
                        selectedItems.forEach((element) => {
                            if (element.group_name === elementRoot.name && element.data_name === elementItem.name) {
                                filtered.push(`${elementItem.id}`);
                            }
                        })
                    })
                });
            }

            if (filtered.length > 0) {
                request_data['roots'] = `${filtered.join(',')}`;
            } else {
                request_data['roots'] = '0';
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                    }
                }).catch(() => {
                });
            } catch (e) {
            }
        }
    }

    useEffect(() => {
        if (props.horizonFilters !== undefined && props.horizonFilters.roots !== undefined && optionsFilter !== undefined) {
            let opt: Array<any> = [];
            props.horizonFilters.roots.forEach((elementRoot, keyRoot) => {
                elementRoot.children.forEach((elementItem, keyItem) => {
                    opt[opt.length] = {
                        'group_name': elementRoot.name,
                        'data_name': elementItem.name,
                    };
                });
            });
            setOptionsFilter(opt);
        }
        // eslint-disable-next-line
    }, [props.horizonFilters]);

    useEffect(() => {
        if (props.horizon !== undefined && props.horizon.roots !== null && props.horizon.roots.split(',').map(Number).filter(isBigEnough).length > 0) {
            let filtered: Array<any> = [];
            props.horizonFilters.roots.forEach((elementRoot) => {
                elementRoot.children.forEach((elementItem) => {
                    props.horizon.roots.split(',').forEach((element) => {
                        if (element !== undefined && element !== null && element !== '' && `${element}` === `${elementItem.id}`) {
                            filtered[filtered.length] = {
                                'group_name': elementRoot.name,
                                'data_name': elementItem.name,
                            };
                        }
                    })
                })
            });
            setSelectedItems(filtered);
        } else {
            setSelectedItems([]);
        }
        // eslint-disable-next-line
    }, [props.horizon]);

    useEffect(() => {
        if (startUpdateHorizon === true) {
            updateHorizon();
            setStartUpdateHorizon(false);
        }
        // eslint-disable-next-line
    }, [startUpdateHorizon]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={`${props.horizon.id}${props.horizon.name}roots`}
            className={styles.view_horizon_roots}
        >
            <Grid item xs={12} className={styles.view_horizon_roots_title}>
                <Typography className={styles.text_horizon_roots}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_roots_content}>
                <FormControl sx={{ width: 300 }}>
                    <Multiselect
                        id="css_custom"
                        displayValue="data_name"
                        groupBy="group_name"
                        options={optionsFilter}
                        showCheckbox
                        selectedValues={selectedItems}
                        style={{
                            chips: {
                                "background": "#06617c"
                            },
                            searchBox: {
                              "border": "1px solid #06617c",
                              "border-radius": "5px"
                            },
                            multiselectContainer: {
                              "color": "#000",
                            }
                        }}
                        onRemove={(selectedList: any, selectedItem: any) => {
                            setSelectedItems(selectedList as any[]);
                            setStartUpdateHorizon(true);
                        }}
                        onSelect={(selectedList: any, selectedItem: any) => {
                            setSelectedItems(selectedList as any[]);
                            setStartUpdateHorizon(true);
                        }}
                        emptyRecordMsg={"Nenhuma raíz selecionada"}
                        placeholder={"Selecione"}
                        className={styles.picker_select_item}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}