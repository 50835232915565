/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IHorizon,
    IFilterHorizons,
    IHorizonLimitsProps,
    IHorizonMenuOptions,
} from '../../types';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import apiService from '../../services/apiService';
import ShowLoader from '../../components/ShowLoaderComponent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import HorizonNameItem from './HorizonNameItem';
import HorizonLimitItem from './HorizonLimitItem';
import HorizonThicknessItem from './HorizonThicknessItem';
import HorizonMunsellItem from './HorizonMunsellItem';
import HorizonMottledItem from './HorizonMottledItem';
import HorizonTextureItem from './HorizonTextureItem';
import HorizonGravelItem from './HorizonGravelItem';
import HorizonStructureItem from './HorizonStructureItem';
import HorizonWaxinessItem from './HorizonWaxinessItem';
import HorizonConsistencyItem from './HorizonConsistencyItem';
import HorizonPoresItem from './HorizonPoresItem';
import HorizonTransitionItem from './HorizonTransitionItem';
import HorizonPlasticityItem from './HorizonPlasticityItem';
import HorizonStickinessItem from './HorizonStickinessItem';
import HorizonCompressionSurfacesItem from './HorizonCompressionSurfacesItem';
import HorizonCharacterItem from './HorizonCharacterItem';
import HorizonRootsItem from './HorizonRootsItem';
import HorizonObservationItem from './HorizonObservationItem';

interface IHorizonDetail {
    examId: string | undefined
}

const ListMenuItem: Array<IHorizonMenuOptions> = [
    { id: 1, name: 'Designação do Horizonte' },
    { id: 2, name: 'Limites' },
    { id: 3, name: 'Espessura' },
    { id: 4, name: 'Cor Munsel - Úmida' },
    { id: 5, name: 'Cor Munsel - Seca' },
    { id: 6, name: 'Cor Munsel - Úmida Amassada' },
    { id: 7, name: 'Mosqueado' },
    // { id: 7, name: 'Mosqueado 1' },
    // { id: 8, name: 'Mosqueado 2' },
    { id: 9, name: 'Textura' },
    { id: 10, name: 'Cascalhos' },
    { id: 11, name: 'Estrutura' },
    // { id: 11, name: 'Estrutura 1' },
    // { id: 12, name: 'Estrutura 2' },
    { id: 13, name: 'Cerosidade' },
    { id: 14, name: 'Superficie de Compressão' },
    { id: 15, name: 'Consistência' },
    { id: 16, name: 'Plasticidade' },
    { id: 17, name: 'Pegajosidade' },
    { id: 18, name: 'Porosidade' },
    { id: 19, name: 'Transição' },
    // { id: X, name: 'Lamela' },
    { id: 20, name: 'Caracteres' },
    { id: 21, name: 'Raízes' },
    { id: 22, name: 'Observações' }
];

export default function HorizonComponent(props: IHorizonDetail) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [horizonData, setHorizonData] = useState<Array<IHorizon> | undefined>(undefined);
    const [tmpHorizonData, setTmpHorizonData] = useState<Array<IHorizon> | undefined>(undefined);
    const [getHorizonFiltersData, setGetHorizonFiltersData] = useState<IFilterHorizons | undefined>(undefined);
    const [horizonModal, setHorizonModal] = useState<IHorizonMenuOptions>(ListMenuItem[0]);

    const [horizonLimitsLowerActions, setHorizonLimitsLowerActions] = useState<Array<IHorizonLimitsProps> | undefined>(undefined);

    const [loaderBox, setLoaderBox] = useState<boolean>(false);
    const [messageLoader, setMessageLoader] = useState<string | undefined>(undefined);
    const [locaLoaderBox, setLocalLoaderBox] = useState<boolean>(false);
    const [localMessageLoader, setLocalMessageLoader] = useState<string | undefined>(undefined);

    function setStateLoader(status: boolean, message: string | undefined = undefined) {
        if (status === true) {
            if (message !== undefined) {
                setMessageLoader(message);
            }
            setLoaderBox(true);
        } else {
            setLoaderBox(false);
            setMessageLoader(undefined);
        }
    }

    function setLocalStateLoader(status: boolean, message: string | undefined = undefined) {
        setLocalLoaderBox(status);
        if (status === true) {
            if (message !== undefined) {
                setLocalMessageLoader(message);
            }
        } else {
            setLocalMessageLoader(undefined);
        }
    }

    function changeAddedHorizon() {
        createHorizon();
    }

    function showHorizonDetails(horizonMenuOptions: IHorizonMenuOptions) {
        if (tmpHorizonData !== undefined) {
            setBaseHorizonLimitsLowerActions(tmpHorizonData as Array<IHorizon>);
            setHorizonData(tmpHorizonData);
            setTmpHorizonData(undefined);
        } else {
            renewHorizon(undefined, false);
        }
        setHorizonModal(horizonMenuOptions);
    }

    function handleHorizonLimitsLowerActions(horizonLimits: IHorizonLimitsProps) {
        if (horizonLimits.next_horizon_id !== undefined && horizonLimitsLowerActions !== undefined) {
            let tmpHorizonLimitsLowerActions: Array<IHorizonLimitsProps> = [];
            horizonLimitsLowerActions.forEach((element, key) => {
                if (element.horizon_id === horizonLimits.next_horizon_id) {
                    tmpHorizonLimitsLowerActions.push({
                        horizon_id: horizonLimits.next_horizon_id,
                        next_horizon_id: element.next_horizon_id,
                        horizon_limit_lower: horizonLimits.horizon_limit_lower,
                        horizon_limit_lower_undulating: horizonLimits.horizon_limit_lower_undulating,
                        horizon_show_box_limit_lower_undulating: horizonLimits.horizon_show_box_limit_lower_undulating
                    });
                } else {
                    tmpHorizonLimitsLowerActions.push(element);
                }
            });
            setHorizonLimitsLowerActions(tmpHorizonLimitsLowerActions);
        }
    }

    function setBaseHorizonLimitsLowerActions(ArHorizonData: Array<IHorizon>) {
        let tmpHorizonLimitsLowerActions: Array<IHorizonLimitsProps> = [];
        ArHorizonData.forEach((element, key) => {
            let next_horizon_data: IHorizon | undefined = undefined;
            if (ArHorizonData[key + 1] !== undefined) {
                next_horizon_data = ArHorizonData[key + 1];
            }
            let tmpShowBoxLimitLowerUndulating: boolean = false;
            if (element.limits_lower_undulating !== undefined && parseInt(element.limits_lower_undulating) >= 0) {
                tmpShowBoxLimitLowerUndulating = true;
            }
            tmpHorizonLimitsLowerActions.push({
                horizon_id: element.id,
                next_horizon_id: (next_horizon_data !== undefined ? next_horizon_data.id : 0),
                horizon_limit_lower: element.limits_lower,
                horizon_limit_lower_undulating: element.limits_lower_undulating,
                horizon_show_box_limit_lower_undulating: tmpShowBoxLimitLowerUndulating
            });
        });
        if (tmpHorizonLimitsLowerActions !== undefined) {
            setHorizonLimitsLowerActions(tmpHorizonLimitsLowerActions);
        }
    }

    function renewHorizon(ArHorizon: Array<IHorizon> | undefined, now?: boolean) {
        if (now !== undefined && now === true) {
            getHorizon(false);
            return true;
        }

        if (ArHorizon === undefined) {
            getHorizon(true);
        } else {
            setTmpHorizonData(ArHorizon);
        }
    }

    async function createHorizon() {
        if (props.examId !== undefined) {
            setStateLoader(true, `Criando o Horizonte...`);
            try {
                await apiService.post(
                    'exams/horizon',
                    {
                        exam_id: parseInt(props.examId),
                        name: 'Novo Horizonte'
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setBaseHorizonLimitsLowerActions(response.data.data as Array<IHorizon>);
                        setHorizonData(response.data.data as Array<IHorizon>);
                    } else {
                        alert(`Houve um erro com a criação do horizonte...`);
                    }
                    setStateLoader(false);
                }).catch((error) => {
                    if (error.response.status === 409) {
                        alert('Você atingiu o limite máximo de 10 horizontes por exame.');
                    } else {
                        alert(`Houve um erro com a criação do horizonte...`);
                    }
                    setStateLoader(false);
                });
            } catch (e) {
                alert(`Houve um erro com a criação do horizonte...`);
                setStateLoader(false);
            }
        }
    }

    async function getHorizon(temp: boolean) {
        if (props.examId !== undefined && auth !== undefined) {
            try {
                if (temp === false) {
                    setStateLoader(true, `Carregando...`);
                } else {
                    setLocalStateLoader(true, `Carregando...`);
                }
                await apiService.get(
                    `exams/horizon?exam_id=${props.examId}`,
                    {}
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        if (temp === true) {
                            setBaseHorizonLimitsLowerActions(response.data.data as Array<IHorizon>);
                            setTmpHorizonData(response.data.data);
                        } else {
                            setBaseHorizonLimitsLowerActions(response.data.data as Array<IHorizon>);
                            setHorizonData(response.data.data as Array<IHorizon>);
                        }
                    }
                }).catch((error) => {
                    console.log('getHorizon ERROR: ', error);
                }).finally(() => {
                    if (temp === false) {
                        setStateLoader(false);
                    } else {
                        setLocalStateLoader(false);
                    }
                });
            } catch (e) {
                console.log('getHorizon ERROR: ', e);
                if (temp === false) {
                    setStateLoader(false);
                } else {
                    setLocalStateLoader(false);
                }
            }
        }
    }

    useEffect(() => {
        async function getFilters() {
            if (auth !== undefined && getHorizonFiltersData === undefined) {
                try {
                    await apiService.get(
                        'exams/horizon/filters',
                        {}
                    ).then((response) => {
                        if (response !== undefined && response.status === 200) {
                            setGetHorizonFiltersData(response.data.data);
                        }
                    }).catch((error) => {
                        console.log('REQUEST getHorizons ERROR: ', error);
                    });
                } catch (e) {
                    console.log('REQUEST getHorizons ERROR-try: ');
                }
            }
        }
        if (horizonData === undefined) {
            getHorizon(false);
        }
        getFilters();
        // eslint-disable-next-line
    }, [props, horizonData]);

    function horizonMenuItem(item: IHorizonMenuOptions) {
        return (
            <Button onClick={() => showHorizonDetails(item)} className={
                (item.id !== horizonModal.id ? styles.view_horizon_menu_item : styles.view_horizon_menu_item_active)
            }>
                <Grid className={styles.view_horizon_name}>
                    <Typography className={
                        (`${item.id}` === `${horizonModal.id}` ? styles.horizon_name : styles.horizon_name_active)
                    }>
                        {item.name}
                    </Typography>
                </Grid>
            </Button>
        );
    }

    const ShowDataHorizonteSelected = React.useMemo((): JSX.Element => {
        if (horizonData !== undefined && horizonData.length > 0) {
            switch (horizonModal.id) {
                default:
                case 1:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {horizonData.map((item, key) => (
                                    <HorizonNameItem
                                        key={`${key}${item.name}`}
                                        horizon={item}
                                        examId={props.examId}
                                        renewHorizon={renewHorizon}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
                case 2:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {horizonData.map((item, key) => (
                                    <HorizonLimitItem
                                        key={`${key}${item.name}`}
                                        horizon={item}
                                        examId={props.examId}
                                        horizonLimitsLowerActions={horizonLimitsLowerActions}
                                        lastLimit={key === (horizonData.length - 1)}
                                        handleHorizonLimitsLowerActions={handleHorizonLimitsLowerActions}
                                        renewHorizon={renewHorizon}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
                case 3:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {horizonData.map((item, key) => (
                                    <HorizonThicknessItem
                                        key={`${key}${item.name}`}
                                        horizon={item}
                                        examId={props.examId}
                                        renewHorizon={renewHorizon}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
                case 4:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {horizonData.map((item, key) => (
                                    <HorizonMunsellItem
                                        key={`${key}${item.name}`}
                                        horizon={item}
                                        examId={props.examId}
                                        number={'1'}
                                        renewHorizon={renewHorizon}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
                case 5:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {horizonData.map((item, key) => (
                                    <HorizonMunsellItem
                                        key={`${key}${item.name}`}
                                        horizon={item}
                                        examId={props.examId}
                                        number={'2'}
                                        renewHorizon={renewHorizon}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
                case 6:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {horizonData.map((item, key) => (
                                    <HorizonMunsellItem
                                        key={`${key}${item.name}`}
                                        horizon={item}
                                        examId={props.examId}
                                        number={'3'}
                                        renewHorizon={renewHorizon}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
                case 7:
                    return (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={4} key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                                <Typography className={styles.text_user_name}>
                                    {`${horizonModal.name} 1`}
                                </Typography>
                                <Grid className={styles.view_exam_horizon_itens}>
                                    {(getHorizonFiltersData !== undefined) &&
                                        horizonData.map((item, key) => (
                                            <HorizonMottledItem
                                                key={`${key}${item.name}`}
                                                horizon={item}
                                                horizonFilters={getHorizonFiltersData}
                                                examId={props.examId}
                                                number={'1'}
                                                renewHorizon={renewHorizon}
                                            />
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={4}  key={`${horizonModal.id+1}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                                <Typography className={styles.text_user_name}>
                                {`${horizonModal.name} 2`}
                                </Typography>
                                <Grid className={styles.view_exam_horizon_itens}>
                                    {(getHorizonFiltersData !== undefined) &&
                                        horizonData.map((item, key) => (
                                            <HorizonMottledItem
                                                key={`${key}${item.name}`}
                                                horizon={item}
                                                horizonFilters={getHorizonFiltersData}
                                                examId={props.examId}
                                                number={'2'}
                                                renewHorizon={renewHorizon}
                                            />
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                // case 8:
                //     return (
                //     );
                case 9:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonTextureItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 10:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonGravelItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 11:
                    return (
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={4} key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                                <Typography className={styles.text_user_name}>
                                    {`${horizonModal.name} 1`}
                                </Typography>
                                <Grid className={styles.view_exam_horizon_itens}>
                                    {(getHorizonFiltersData !== undefined) &&
                                        horizonData.map((item, key) => (
                                            <HorizonStructureItem
                                                key={`${key}${item.name}`}
                                                horizon={item}
                                                horizonFilters={getHorizonFiltersData}
                                                examId={props.examId}
                                                number={'1'}
                                                renewHorizon={renewHorizon}
                                            />
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={4} key={`${horizonModal.id+1}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                                <Typography className={styles.text_user_name}>
                                    {`${horizonModal.name} 2`}
                                </Typography>
                                <Grid className={styles.view_exam_horizon_itens}>
                                    {(getHorizonFiltersData !== undefined) &&
                                        horizonData.map((item, key) => (
                                            <HorizonStructureItem
                                                key={`${key}${item.name}`}
                                                horizon={item}
                                                horizonFilters={getHorizonFiltersData}
                                                examId={props.examId}
                                                number={'2'}
                                                renewHorizon={renewHorizon}
                                            />
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                // case 12:
                //     return (
                //     );
                case 13:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonWaxinessItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 14:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonCompressionSurfacesItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 15:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonConsistencyItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 16:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonPlasticityItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 17:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonStickinessItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 18:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonPoresItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 19:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => {
                                        if (key < (horizonData.length - 1)) {
                                            return (
                                                <HorizonTransitionItem
                                                    key={`${key}${item.name}`}
                                                    horizon={item}
                                                    horizonFilters={getHorizonFiltersData}
                                                    examId={props.examId}
                                                    renewHorizon={renewHorizon}
                                                />
                                            )
                                        }
                                    })
                                }
                            </Grid>
                        </Grid>
                    );
                case 20:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonCharacterItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 21:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {(getHorizonFiltersData !== undefined) &&
                                    horizonData.map((item, key) => (
                                        <HorizonRootsItem
                                            key={`${key}${item.name}`}
                                            horizon={item}
                                            horizonFilters={getHorizonFiltersData}
                                            examId={props.examId}
                                            renewHorizon={renewHorizon}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    );
                case 22:
                    return (
                        <Grid key={`${horizonModal.id}${horizonModal.name}`} className={styles.view_exam_horizon_session}>
                            <Typography className={styles.text_user_name}>
                                {horizonModal.name}
                            </Typography>
                            <Grid className={styles.view_exam_horizon_itens}>
                                {horizonData.map((item, key) => (
                                    <HorizonObservationItem
                                        key={`${key}${item.name}`}
                                        horizon={item}
                                        next_horizon={(horizonData[key + 1] !== undefined ? horizonData[key + 1].id : 0)}
                                        examId={props.examId}
                                        renewHorizon={renewHorizon}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
            }
        } else {
            return (<></>);
        }
        // eslint-disable-next-line
    }, [horizonModal, horizonData, horizonLimitsLowerActions]);


    // - Margin por horizonte    margin: 5px 0px;
    if (loaderBox === false && auth !== undefined) {
        return (
            <Grid container className={styles.view_exam_horizon}>
                <Grid item xs={12}>
                    <Button onClick={changeAddedHorizon} className={styles.view_exam_morphological_buttons}>
                        <Grid className={styles.buttons_horizon_create}>
                            <Typography className={styles.text_horizon}>
                                Adicionar um Novo Horizonte
                            </Typography>
                        </Grid>
                        <Grid className={styles.buttons_horizon_create_icon}>
                            <img alt="Adicionar um Novo Horizonte" className={styles.analise_morfologica} src={require('../../assets/images/btns/analise_morfologica.png')} />
                        </Grid>
                    </Button>
                </Grid>
                <Grid item xs={3} className={styles.view_exam_horizon_content_description}>
                    <Typography className={styles.text_horizon_description}>
                        Horizontes cadastrados:
                    </Typography>
                    <Typography className={styles.text_horizon_description_total}>
                        {horizonData?.length}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={styles.view_exam_horizon_content}>
                    {ListMenuItem.map((element) => (
                        horizonMenuItem(element)
                    ))}
                    {(locaLoaderBox === true) && (
                        <ShowLoader message={localMessageLoader} />
                    )}
                    {(locaLoaderBox !== true && horizonData !== undefined) && (
                        ShowDataHorizonteSelected
                    )}
                </Grid>
            </Grid>
        );
    } else if (horizonData === undefined) {
        return (
            <ShowLoader message={messageLoader} />
        );
    } else {
        return (
            <ShowLoader message={'Carregando...'} />
        );
    }
}