import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        border: '1px solid #cddee9',
        padding: '20px 25px',
        borderRadius: '10px',
    },
    grid_title: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        borderBottom: '1px solid #cddee9',
        padding: '10px 15px',
        borderRadius: '10px',
        margin: '0px 0px 25px 0px !important',
    },
    grid_input: {
        display: 'flex',
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
    },
    grid_button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px 0px 25px 0px !important',
    },
    datagrid: {
        minHeight: '371px',
    },
    button_update: {
        borderRadius: '10px !important',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        border: '1px solid #06617c !important',
    }
});

export default useStyles;