import React, { useState } from 'react';
import useStyles, { BootstrapDialog } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
    IFilterHorizons,
    IFilterExams,
    IExam,
    IGroup,
    IProject,
} from '../../types';
import apiService from '../../services/apiService';
import AuthBase from '../../components/AuthBase';
import { useAuth } from "../../contexts/Auth/AuthProvider";
import ExamClassification from '../../components/ExamClassification';
import HorizonComponent from '../../components/HorizonComponent';
import AnalyticalDescriptionComponent from '../../components/AnalyticalDescriptionComponent';
import ExameDetalhesComponent from '../../components/ExameDetalhesComponent';
import ExamFieldClassificationComponent from '../../components/ExamFieldClassificationComponent';
import ShowLoader from '../../components/ShowLoaderComponent';
import TabPanel from '../../components/TabPanel';
import BootstrapDialogTitle from '../../components/BootstrapDialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';

export default function ExamDetailsPage() {
    let { examId } = useParams();
    const styles = useStyles();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [tabValue, setTabValue] = React.useState(0);
    const [examName, setExamName] = useState<string>('');
    const [errorExamName, setErrorExamName] = useState<boolean>(false);
    const [examData, setExamData] = useState<IExam | undefined>(undefined);
    const [examFiltersData, setExamFiltersData] = useState<IFilterExams | undefined>(undefined);
    const [horizonFiltersData, setHorizonFiltersData] = useState<IFilterHorizons | undefined>(undefined);
    const [groupsData, setGroupsData] = useState<Array<IGroup> | undefined>(undefined);
    const [projectsData, setProjectsData] = useState<Array<IProject> | undefined>(undefined);
    const [showDialogUpdateName, setShowDialogUpdateName] = useState<boolean>(false);
    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);
    const [disableExportData, setDisableExportData] = useState<boolean>(false);
    const [loadingDialog, setLoadingDialog] = React.useState<boolean>(false);
    const [showExam, setShowExam] = React.useState(true);

    
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 0) {
            getExamData();
        }
        setTabValue(newValue);
    };

    function goToBack() {
        // eslint-disable-next-line
        navigate(-1);
    }

    function handleShowUpdateExamName() {
        setExamName(`${examData?.name}`);
        setShowDialogUpdateName(true);
    }

    function handleShowBoxRemove() {
        setShowDialogDelete(true);
    }

    function handleSetExamName(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setExamName(event.target.value);
    }

    function validateUpdateExamName() {
        let error = false;
        setErrorExamName(false);
        if (examName === '') {
            error = true;
            setErrorExamName(true);
        }

        return error;
    }

    function handleHideUpdateExamName() {
        setShowDialogUpdateName(false);
        setTimeout(() => {
            setExamName('');
        }, 400);
    }

    async function requestUpdateExamName() {
        if (user !== undefined && examName !== undefined) {
            if (validateUpdateExamName()) {
                toast.warning('Nome do exame inválido.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false;
            }
            setLoadingDialog(true);
            try {
                await apiService.put(
                    'exams',
                    {
                        exam_id: examId,
                        name: examName
                    },
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        toast.success('Autlizado com sucesso.', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        getExamData();
                    }
                    setShowDialogUpdateName(false);
                    setLoadingDialog(false);
                    setExamName('');
                }).catch((error) => {
                    console.log('requestUpdateExamName-error: ', error);
                    setShowDialogUpdateName(false);
                    setLoadingDialog(false);
                    setExamName('');
                });
            } catch (e) {
                setShowDialogUpdateName(false);
                setLoadingDialog(false);
                setExamName('');
            }
        }
    }

    async function RemoveExam() {
        if (user !== undefined && examId !== undefined && examId !== null) {
            try {
                setLoadingDialog(true);
                await apiService.delete(
                    'exams',
                    { exam_id: examId }
                ).then((response) => {
                    if (response !== undefined && response.status === 200 && response.data !== undefined) {
                        setExamData(undefined);
                        setShowDialogDelete(false);
                        setLoadingDialog(false);
                        toast.success('Exame removido com sucesso!', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // eslint-disable-next-line
                        navigate(-1);
                    } else {
                        toast.error('Houve um erro com a exclusão deste exame...', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // eslint-disable-next-line
                        navigate(-1);
                        setLoadingDialog(false);
                    }
                }).catch((error) => {
                    setLoadingDialog(false);
                    if (error.response.status === 409) {
                        toast.warning(`Você não pode excluir este exame, pois ele não lhe pertence.`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error('Houve um erro com a exclusão deste exame...', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    // eslint-disable-next-line
                    navigate(-1);
                    setExamData(undefined);
                    setShowDialogDelete(false);
                    setLoadingDialog(false);
                });
            } catch (e) {
                toast.error('Houve um erro com a exclusão deste exame...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // eslint-disable-next-line
                navigate(-1);
                setExamName('');
                setShowDialogDelete(false);
                setLoadingDialog(false);
            }
        }
    }

    async function getExamData() {
        setShowExam(false);
        if (user !== undefined && examId !== undefined) {
            await apiService.get(
                'exams/details',
                { exam_id: parseInt(examId) }
            ).then((response) => {
                if (response !== undefined && response.status !== undefined && response.status === 200) {
                    setExamData(response.data.data as IExam);
                }
            }).catch((error) => {
                console.log('getExamFilters ERROR: ', error);
            }).finally(() => {
                setShowExam(true);
            })
        }
    }

    async function getReport() {
        if (user !== undefined && examId !== undefined && examData !== undefined) {
            try {
                setDisableExportData(true);
                await apiService.post(
                    'exams/report',
                    {
                        exam_id: parseInt(examId),
                        json: 0
                    }
                ).then((response) => {
                    if (response !== undefined && response.status !== undefined && response.status === 200) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${examData?.name}-Report ${Date.now()}.xls`);
                        document.body.appendChild(link);
                        link.click();
                    }
                }).catch((error) => {
                    console.log('getExame: ', error.response);
                }).finally(() => {
                    setDisableExportData(false);
                });
            } catch (e) {
                console.log('getExame: ', e);
            }
        }
    }

    async function getExamFilters() {
        if (user !== undefined && examFiltersData === undefined) {
            await apiService.get(
                'exams/filters',
                {}
            ).then((response) => {
                if (response !== undefined && response.status !== undefined && response.status === 200) {
                    setExamFiltersData(response.data.data);
                }
            }).catch((error) => {
                console.log('getExamFilters ERROR: ', error);
            });
        }
    }

    async function getHorizonFilters() {
        if (user !== undefined && horizonFiltersData === undefined) {
            await apiService.get(
                'exams/horizon/filters',
                {}
            ).then((response) => {
                if (response !== undefined && response.status !== undefined && response.status === 200) {
                    setHorizonFiltersData(response.data.data);
                }
            }).catch((error) => {
                console.log('getHorizonFilters ERROR: ', error);
            });
        }
    }

    async function getGroups() {
        if (user !== undefined && groupsData === undefined) {
            await apiService.get(
                `groups`,
                {}
            ).then((response) => {
                if (response !== undefined && response.status !== undefined && response.status === 200) {
                    setGroupsData(response.data.data as Array<IGroup>);
                } else {
                    setGroupsData([] as Array<IGroup>);
                }
            }).catch((error) => {
                setGroupsData([] as Array<IGroup>);
            });
        }
    }

    async function getProjects() {
        if (user !== undefined && projectsData === undefined) {
            await apiService.get(
                `project`,
                {}
            ).then((response) => {
                if (response !== undefined && response.status !== undefined && response.status === 200) {
                    setProjectsData(response.data.data as Array<IProject>);
                } else {
                    setProjectsData([] as Array<IProject>);
                }
            }).catch((error) => {
                setProjectsData([] as Array<IProject>);
            });
        }
    }

    React.useEffect(() => {
        if (examFiltersData === undefined) {
            getExamFilters();
        }
        if (horizonFiltersData === undefined) {
            getHorizonFilters();
        }
        if (groupsData === undefined) {
            getGroups();
        }
        if (projectsData === undefined) {
            getProjects();
        }
        if (examData === undefined && examId !== undefined) {
            getExamData();
        }
        // eslint-disable-next-line
    }, [examId]);


    if (examData !== undefined && examData.name !== undefined) {
        return (
            <AuthBase>
                <Grid container className={styles.grid_root}>
                    <Grid item xs={1} className={styles.grid_item_container}>
                        <Button className={styles.button_back_page} onClick={goToBack}>Voltar</Button>
                    </Grid>
                    <Grid item xs={12} className={styles.grid_title_exam}>
                        <Typography>Exame <strong>{examData.name}</strong></Typography>
                        {(user !== null && (examData.created_by === user.id || user.type === 'admin')) && (
                            <>
                                <Tooltip title="Editar Nome" className={styles.button_action}>
                                    <IconButton
                                        size="small"
                                        aria-haspopup="true"
                                        onClick={() => handleShowUpdateExamName()}
                                        color="primary"
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Excluir Exame" className={styles.button_action}>
                                    <IconButton
                                        size="small"
                                        aria-haspopup="true"
                                        onClick={() => handleShowBoxRemove()}
                                        color="error"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} className={styles.grid_exam_classification}>
                        <ExamClassification
                            show={examId !== undefined && examData !== undefined}
                            examId={examId}
                            examName={`${(examData?.project !== undefined ? `${examData?.project?.name} - ${examData?.name}` : examData?.name)}`}
                            examClassification={examData?.classification}
                        />
                        <LoadingButton
                            className={styles.button_report_data}
                            loading={disableExportData}
                            loadingPosition="end"
                            endIcon={<DownloadIcon className={styles.icon_download} />}
                            variant="outlined"
                            onClick={() => getReport()}
                        >
                            Exportar Relatório
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={6} className={styles.grid_exam_details_field_classification}>
                        <ExamFieldClassificationComponent
                            show={true}
                            examId={examId}
                            examData={examData}
                        />
                    </Grid>
                    <Grid item xs={12} className={styles.grid_exam_details}>
                        <Box sx={{ width: '100%', minHeight: '500px' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs className={styles.group_tabs} value={tabValue} onChange={handleChange}>
                                    <Tab label="Descrição Geral" {...a11yProps(0)} />
                                    <Tab label="Descrição Morfológica" {...a11yProps(1)} />
                                    <Tab label="Descrição Analítica" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={tabValue} index={0}>
                                <ExameDetalhesComponent
                                    show={showExam}
                                    examId={examId}
                                    examData={examData}
                                    examFiltersData={examFiltersData}
                                    horizonFiltersData={horizonFiltersData}
                                    groupsData={groupsData}
                                    projectsData={projectsData}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <HorizonComponent examId={examId} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <AnalyticalDescriptionComponent examId={examId} />
                            </TabPanel>
                        </Box>
                    </Grid>
                    {(loadingDialog !== true && examData !== undefined && examData.name !== undefined) && (
                        <>
                            <BootstrapDialog
                                onClose={() => handleHideUpdateExamName()}
                                aria-labelledby="customized-dialog-title"
                                open={showDialogUpdateName}
                            >
                                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleHideUpdateExamName()}>
                                    Alterar Nome do Grupo
                                </BootstrapDialogTitle>
                                <DialogContent dividers>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        className={styles.modal_view}
                                    >
                                        <Grid item xs={12} className={styles.view_box_content}>
                                            <TextField
                                                className={styles.input_project_name}
                                                value={examName}
                                                label='Nome do Grupo'
                                                disabled={loadingDialog}
                                                onChange={handleSetExamName}
                                                error={errorExamName}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button className={styles.button_close} onClick={() => setShowDialogUpdateName(!showDialogUpdateName)}>
                                        Voltar
                                    </Button>
                                    <Button className={styles.button_update} onClick={() => requestUpdateExamName()}>
                                        Atualizar
                                    </Button>
                                </DialogActions>
                            </BootstrapDialog>
                            <BootstrapDialog
                                onClose={() => setShowDialogDelete(false)}
                                aria-labelledby="customized-dialog-title"
                                open={showDialogDelete}
                            >
                                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setShowDialogDelete(false)}>
                                    Excluir Exame
                                </BootstrapDialogTitle>
                                <DialogContent dividers>
                                    <Typography className={styles.title}>Deseja excluir o exame <strong>{examData?.name}</strong>?</Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setShowDialogDelete(false)}>
                                        Não
                                    </Button>
                                    <Button disabled={loadingDialog} onClick={RemoveExam}>
                                        Sim
                                    </Button>
                                </DialogActions>
                            </BootstrapDialog>
                        </>
                    )}
                </Grid>
            </AuthBase>
        );
    } else {
        return (
            <AuthBase>
                <Grid container className={styles.grid_root}>
                    <Grid item xs={1} className={styles.grid_item_container}>
                        <Button className={styles.button_back_page} onClick={goToBack}>Voltar</Button>
                    </Grid>
                    <Grid item xs={12} className={styles.grid_item_container}>
                        <ShowLoader message={''} />
                    </Grid>
                </Grid>
            </AuthBase>
        );
    }
}
