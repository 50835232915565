import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IExam,
} from '../../types';
import apiService from '../../services/apiService';
import ShowLoader from '../ShowLoaderComponent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import useAutoSave from '../../hooks/autoSave';

interface IReceiptProps {
    show: boolean
    examId: string | undefined
    examData: IExam | undefined
}

export default function ExamFieldClassificationComponent(props: IReceiptProps) {
    const styles = useStyles();
    const [exam, setExam] = useState<IExam | undefined>(undefined);
    const [lockData, setlockData] = React.useState<boolean>(false);
    const [fieldClassification, setFieldClassification] = useState<string | undefined>(undefined);
    const [RequestData, setRequestData] = useState<IRequestData>();

    const CreateRequestData = useCallback((examId?: string, exam_data?: IExam) => {
        let request_data: IRequestData = {};
        if (examId && exam_data) {
            request_data['exam_id'] = parseInt(examId);
            if (fieldClassification !== undefined && fieldClassification !== null) {
                request_data['field_classification'] = `${fieldClassification}`;
            }
        }
        return request_data;
        // eslint-disable-next-line
    }, [
        fieldClassification
    ]);
  
    const delayedSave = useCallback(async () => {
        return await apiService.put(
            'exams',
            RequestData
        ).then(() => {
            setlockData(false);
        }).catch((error) => {
            console.log('ERROR: ', error.response);
            setlockData(false);
        });
    }, [RequestData]);

    useEffect(() => {
        if (props.examId && exam) {
            setRequestData(CreateRequestData(props.examId, exam));
        }
    }, [CreateRequestData, props.examId, exam]);

    useAutoSave<IRequestData>(
        'general_description', RequestData, delayedSave
    );

    React.useEffect(() => {
        (async () => {
            if (props.show === true && props.examData !== undefined) {
                setExam(props.examData);
                if (props.examData.field_classification !== undefined && props.examData.field_classification !== null) {
                    setFieldClassification(props.examData.field_classification);
                }
            }
        })();
        // eslint-disable-next-line
    }, [props.examData]);

    if (props.show === true) {
        return (
            <Grid container className={styles.grid_root}>
                <Grid item xs={12} className={styles.grid_item}>
                    <Typography className={styles.text_exam_type_title}>
                        Classificação de Campo
                    </Typography>
                    <TextField
                        className={styles.input_exam_input}
                        value={fieldClassification === undefined ? '' : fieldClassification}
                        disabled={lockData}
                        minRows={3}
                        multiline={true}
                        placeholder='Classificação de Campo'
                        onChange={(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setFieldClassification(value.target.value);
                        }}
                    />
                </Grid>
            </Grid >
        );
    } else {
        return (
            <ShowLoader message={'Carregando...'} />
        );
    }
}
