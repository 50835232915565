import { useEffect, useRef, useState } from "react";
import { IRequestData } from "../../types";

interface ValuesProps {
    title: string;
    value: any;
}

export default function useAutoSave<T extends IRequestData>(
    storageId: string,
    data?: T,
    savePromise?: () => Promise<void>,
    delay = 1000
    ) {
    const prevData = useRef(data);
    const [saveState, setSaveState] = useState<'saved' | 'waitingToSave' | 'saving'>('saved');

    useEffect(() => {
        if (prevData.current === undefined && data !== undefined) {
            prevData.current = data;
        } else if (prevData.current && data) {
            if (saveState === "saved" && !isEqual(prevData.current, data)) {
                setSaveState("waitingToSave");
            }
            prevData.current = data;
        }
    }, [saveState, data]);

    const hasDataChanged = saveState === "waitingToSave";
    useEffect(() => {
        if (hasDataChanged) {
            const timeoutId = setTimeout(async () => {
                setSaveState("saving");
                if (savePromise)
                    savePromise().finally(() => {
                        setSaveState("saved");
                    });
            }, delay);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [delay, hasDataChanged, savePromise]);
    return saveState;
}

function GetObjectArray(data: IRequestData) {
    const newValues: ValuesProps[] = [];
    for (const [key, value] of Object.entries(data)) {
        newValues.push({
            title: key,
            value: value
        });
    }
    return newValues;
}

export function isEqual(
    prevValues: IRequestData,
    values: IRequestData
) {
    const prevData: ValuesProps[] = GetObjectArray(prevValues);
    const newValues: ValuesProps[] = GetObjectArray(values);
    if (newValues.length !== prevData.length) return false;
    return newValues.every((item, i) => {
        const equivalent = prevData.find(el => el.title === item.title);
        if (!equivalent) return false;
        return JSON.stringify(equivalent.value) === JSON.stringify(item.value);
    });
};
