import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
    IFilterHorizons,
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const label = { inputProps: { 'aria-label': 'Checkbox' } };

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    horizonFilters: IFilterHorizons
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonCharacterItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [character, setCharacter] = useState<any[]>([]);
    const [startUpdateHorizon, setStartUpdateHorizon] = useState<boolean>(false);

    function setValueSomeZero(value: number) {
        return value > 0;
    }

    function handleSetCharacter(key_value: number) {
        let tmp_values = character;
        if (character.find(value => value === key_value)) {
            tmp_values = tmp_values.filter(function (element) {
                return element !== key_value;
            });
        } else {
            tmp_values.push(key_value);
        }
        setCharacter(tmp_values);
        setStartUpdateHorizon(true);
    }

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            var filtered = character.filter(setValueSomeZero);
            if (filtered.length > 0) {
                request_data['character'] = `${filtered.join(',')}`;
            } else {
                request_data['character'] = '0';
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                        setStartUpdateHorizon(false);
                    } else {
                        setStartUpdateHorizon(false);
                    }
                }).catch(() => {
                    setStartUpdateHorizon(false);
                });
            } catch (e) {
                setStartUpdateHorizon(false);
            }
        }
    }

    useEffect(() => {
        if (props.horizon !== undefined) {
            if (startUpdateHorizon === true) {
                updateHorizon();
            }
        }
        // eslint-disable-next-line
    }, [startUpdateHorizon]);

    useEffect(() => {
        if (props.horizon !== undefined && props.horizon.character !== null && props.horizon.character.split(',').map(Number).filter(setValueSomeZero).length > 0) {
            setCharacter(props.horizon.character.split(',').map(Number));
        } else {
            setCharacter([]);
        }
        // eslint-disable-next-line
    }, [props.horizon]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={`${props.horizon.id}${props.horizon.name}character`}
            className={styles.view_horizon_character}
        >
            <Grid item xs={12} className={styles.view_horizon_character_title}>
                <Typography className={styles.text_horizon_character}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            {props.horizonFilters.character.map((element, key) => (
                <Grid item xs={2} className={styles.view_horizon_character_item} key={`${key}${element.id}${props.horizon.name}`}>
                    <Button onClick={() => handleSetCharacter(element.id)} className={styles.button_checkobox_character_content}>
                        <Checkbox
                            {...label}
                            sx={{
                                color: '#CC6B3B',
                                '&.Mui-checked': {
                                    color: '#CC6B3B',
                                },
                            }}
                            checked={character.find(value => value === element.id) ? true : false}
                            onClick={() => handleSetCharacter(element.id)}
                        />
                        <Typography className={styles.text_horizon_character_data}>{`${element.name}`}</Typography>
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}