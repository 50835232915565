import { makeStyles, styled } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        height: '100vh',
        display: 'flex',
        alignContent: 'stretch',
        backgroundColor: 'transparent',
    },
    grid_menu_bar: {
        width: '100%',
        height: '69px',
        backgroundColor: 'transparent',
    },
    app_bar: {
        width: '100%',
        backgroundColor: '#06617c'
    },
    grid_item_container: {
        height: 'auto',
        backgroundColor: 'transparent',
    },
    grid_modal_item_botton: {
        width: '100%',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'end',
    },
    container: {
        backgroundColor: 'transparent',
    },
});

export const ImageSmartSolos = styled('img')({
    maxWidth: '30px',
    maxHeight: 'auto',
});

export default useStyles;