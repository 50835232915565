import React from 'react';
import Typography from '@mui/material/Typography';
import Box  from '@mui/material/Box';
import Container from '@mui/material/Container';

function Footer() {
    return (
        <Container maxWidth="lg">
            <Box
                py={3}
                display={{ xs: 'block', md: 'flex' }}
                alignItems="center"
                textAlign={{ xs: 'center', md: 'center' }}
                justifyContent="center"
                height={75}
            >
                <Typography variant="subtitle1">
                    &copy; 2021-{new Date().getFullYear()} - Plataforma SmartSolos
                </Typography>
            </Box>
        </Container>
    );
}

export default Footer;
