import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CalculateIcon from '@mui/icons-material/Calculate';
import {
    IRequestData,
    IHorizon,
    IPhysicalMenuOptions,
} from '../../../types';
import apiService from '../../../services/apiService';
import { AuthContext } from '../../../contexts/Auth/AuthContext';

interface IItemProps {
    examId: string | undefined
    options: IPhysicalMenuOptions
    horizon: IHorizon
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function PhysicalItem(props: IItemProps) {
    const auth = React.useContext(AuthContext);
    const styles = useStyles();
    const [value, setValue] = useState<string>('');
    const [valueCalculator, setValueCalculator] = useState<boolean>(false);

    async function updatePhysical(dataValue: string) {
        if (
            auth !== null &&
            auth.user !== undefined &&
            props.examId !== undefined &&
            props.horizon !== undefined &&
            props.horizon.physical !== undefined &&
            props.horizon.physical.id !== undefined &&
            dataValue.toLocaleLowerCase() !== 'nan'
        ) {
            let request_data: IRequestData = {
                physical_id: props.horizon.physical.id,
                exam_id: parseFloat(props.examId),
            };
            const objectArray = Object.entries(props.horizon.physical);
            const arrayValues = Object.fromEntries(objectArray);
            if (dataValue !== undefined && dataValue !== null && arrayValues[props.options.keyData] !== dataValue) {
                request_data[`${props.options.keyData}`] = `${dataValue}`;

                try {
                    await apiService.put(
                        'exams/physical',
                        request_data
                    ).then((response) => {
                        if (response !== undefined && response.status === 200) {
                            props.renewHorizon(response.data.data as Array<IHorizon>);
                        }
                    }).catch((error) => {
                        console.log('updatePhysical-error: ', error.response);
                    });
                } catch (e) {
                    console.log('updatePhysical-e: ', e)
                }
            }
        }
    }

    useEffect(() => {
        if (props.horizon !== undefined && props.horizon.physical !== undefined && props.options.keyData !== undefined) {
            const objectArray = Object.entries(props.horizon.physical);
            const arrayValues = Object.fromEntries(objectArray);
            if ([1, 10, 13, 16, 17].includes(props.options.id)) {
                setValueCalculator(true);
                let calc_value: number = 0;;
                let CoarseSand: any = 0.0;
                let ThinSand: any = 0.0;
                let Clay: any = 0.0;
                let Silt: any = 0.0;
                let SoilDensity: any = 0.0;
                let DensityParticles: any = 0.0;
                switch (props.options.id) {
                    case 1: // ThinEarth
                        calc_value = parseFloat((arrayValues['Pebble']).replace(",", ".")) + parseFloat((arrayValues['Gravel']).replace(",", "."));
                        calc_value = 1000 - calc_value;

                        if(value !== `${calc_value.toFixed(props.options.decimalLimit)}`) {
                            setValue(`${calc_value.toFixed(props.options.decimalLimit)}`);
                            updatePhysical(`${calc_value.toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 13: // Silt
                        CoarseSand = arrayValues['CoarseSand'] === '' ? 0 : parseFloat((arrayValues['CoarseSand']).replace(",", "."));
                        ThinSand = arrayValues['ThinSand'] === '' ? 0 : parseFloat((arrayValues['ThinSand']).replace(",", "."));
                        Clay = arrayValues['Clay'] === '' ? 0 : parseFloat((arrayValues['Clay']).replace(",", "."));

                        calc_value = CoarseSand + ThinSand + Clay;
                        calc_value = 1000 - calc_value;

                        if(value !== `${calc_value.toFixed(props.options.decimalLimit)}`) {
                            setValue(`${calc_value.toFixed(props.options.decimalLimit)}`);
                            updatePhysical(`${calc_value.toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 16: // DegreeFlocculation
                        calc_value = parseFloat((arrayValues['Clay']).replace(",", ".")) - parseFloat((arrayValues['ClayDispersedWater']).replace(",", "."));
                        calc_value = ((calc_value <= 0 || parseFloat((arrayValues['Clay']).replace(",", ".")) <= 0) ? 0 : calc_value / parseFloat((arrayValues['Clay']).replace(",", ".")));
                        calc_value = (calc_value <= 0 ? 0 : calc_value * 100);
                        calc_value = (calc_value >= 0 && calc_value <= 100 ? calc_value : 0 );

                        if(value !== `${calc_value.toFixed(props.options.decimalLimit)}`) {
                            setValue(`${calc_value.toFixed(props.options.decimalLimit)}`);
                            updatePhysical(`${calc_value.toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 17: // SiltClay

                        // Silt
                        CoarseSand = arrayValues['CoarseSand'] === '' ? 0.0 : parseFloat((arrayValues['CoarseSand']).replace(",", "."));
                        ThinSand = arrayValues['ThinSand'] === '' ? 0.0 : parseFloat((arrayValues['ThinSand']).replace(",", "."));
                        Clay = arrayValues['Clay'] === '' ? 0.0 : parseFloat((arrayValues['Clay']).replace(",", "."));

                        Silt = CoarseSand + ThinSand + Clay;
                        Silt = 1000 - Silt;

                        if (Clay === 0 || Silt === 0.0) {
                            calc_value = 0;
                        } else {
                            calc_value = Silt / Clay;
                        }

                        if(value !== `${calc_value.toFixed(props.options.decimalLimit)}`) {
                            setValue(`${calc_value.toFixed(props.options.decimalLimit)}`);
                            updatePhysical(`${calc_value.toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                    case 10: // TotalPorosity
                        SoilDensity = arrayValues['SoilDensity'] === '' ? 0.0 : parseFloat((arrayValues['SoilDensity']).replace(",", "."));
                        DensityParticles = arrayValues['DensityParticles'] === '' ? 0.0 : parseFloat((arrayValues['DensityParticles']).replace(",", "."));

                        if (DensityParticles === 0 || SoilDensity === 0.0) {
                            calc_value = 0;
                        } else {
                            calc_value = SoilDensity / DensityParticles;
                            calc_value = calc_value * 100;
                            calc_value = 100 - calc_value;
                        }

                        if(value !== `${calc_value.toFixed(props.options.decimalLimit)}`) {
                            setValue(`${calc_value.toFixed(props.options.decimalLimit)}`);
                            updatePhysical(`${calc_value.toFixed(props.options.decimalLimit)}`);
                        }
                        break;
                }
            } else {
                if (arrayValues[props.options.keyData] !== undefined) {
                    setValue(arrayValues[props.options.keyData]);
                } else {
                    setValue('');
                }
            }
        }
        // eslint-disable-next-line
    }, [props.horizon]);

    return (
        <Grid container key={`${props.horizon.physical.id}${props.options.name}value`} className={styles.view_physical_value}>
            <Grid item xs={12} className={styles.view_physical_value_title}>
                <Grid container>
                    <Grid item xs={12} className={styles.grid_item_name_horizon}>
                        <Typography className={styles.text_physical_value}>
                            {props.horizon.name} ({props.options.label})
                        </Typography>
                        {(valueCalculator === true) && (
                            <CalculateIcon className={styles.icon_calculator} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={styles.grid_input_data_value}>
                <Grid container>
                    <Grid item xs={12}>
                        {(valueCalculator === true) && (
                            <Typography className={styles.typography_input_data_value}>
                                {`${(value.toLowerCase() === 'nan' ? '-' : value)}`}
                            </Typography>
                        )}
                        {(valueCalculator !== true) && (
                            <TextField
                                className={styles.input_data_value}
                                value={value}
                                placeholder={``}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValue(event.target.value.toString());
                                    updatePhysical(event.target.value.toString());
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}