import React from 'react';
import useStyles from './styles';
import {
    IGroup,
} from '../../../types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

let team = require('../../../assets/images/tab_bar/team.png');

interface IReceiptProps {
    data: IGroup;
    deleteGroupAction?: boolean;
    action: (data: IGroup) => void;
}

const GroupItemList = (props: IReceiptProps) => {
    const styles = useStyles();
    return (
        <Button className={styles.view_root} onClick={() => props.action(props.data)}>
            <Grid container className={styles.view_project}>
                <Grid item xs={2} className={styles.view_project_image}>
                    <img width={50} alt={`${props.data.name}`} className={styles.project_img} src={team} />
                </Grid>
                <Grid item xs={9} className={styles.view_project_name}>
                    <Typography className={styles.project_name}>
                        {props.data.name}
                    </Typography>
                </Grid>
                <Grid item xs={1} className={styles.view_icon_trash}>
                    {(props.deleteGroupAction === true) && (
                        <Icon className={styles.icon_trash}>trash</Icon>
                    )}
                </Grid>
            </Grid>
        </Button>
    );
}

export default GroupItemList;
