import moment from 'moment';

export function toDateString(date: string, format: string = "DD/MM/YYYY") {
    return moment(date).format(format);
}

export function stringToDate(dateStr: string, format: string = "DD/MM/YYYY") {
    return moment(dateStr, format).toDate();
}

export function formatDateToString(dateStr: string) {
    return `${moment(dateStr).format('DD/MM/YYYY HH:II:SS')}`;
}

export function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date: Date) {
    let dateObj = new Date(date);
    const dayDate = dateObj.getDate();
    const monthDate = dateObj.getMonth();
    const yaerDate = dateObj.getFullYear();
    const newDate = [
        padTo2Digits(dayDate),
        padTo2Digits(monthDate + 1),
        yaerDate
    ].join('/');
    return newDate;
}

export function cutString(str: string, limit: number) {
    if (str.length > limit)
        return str.slice(0, limit) + '...'
    return str;
}

export function formatDateToLocaleString(str: string) {
   return new Date(Date.parse(`${str}`)).toLocaleString();
}
