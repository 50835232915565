import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import Base from '../../components/Base';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import authApi from '../../hooks/authApi';
import { Button, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

export default function RegisterAccountPage() {
    const navigate = useNavigate();
    const styles = useStyles();
    const api = authApi();
    const auth = useContext(AuthContext);

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [jobPosition, setJobPosition] = React.useState<string>('');
    const [about, setAbout] = React.useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);
    const [registerStep1, setRegisterStep1] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    function clearPasswordsValues() {
        setPassword('');
        setRepeatPassword('');
    }

    function clearAllValues() {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setRepeatPassword('');
        setRegisterStep1(false);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    function validateInputs() {
        let error = true;
        if (password === '') {
            error = false;
            clearPasswordsValues();
        }
        if (repeatPassword === '') {
            error = false;
            clearPasswordsValues();
        }
        if (password !== repeatPassword) {
            error = false;
            clearPasswordsValues();
        }

        return error;
    }

    function LoginAction() {
        navigate('/');
    }

    async function ChangeActionStep1() {
        if (validateInputs() === false) {
            console.log('Erro na senha');
        }
        setLoading(true);
        await api.register(
            `${firstName} ${lastName}`,
            email,
            password,
            about,
            jobPosition
        ).then((response) => {
            if (response !== undefined && response.status === 200) {
                setRegisterStep1(true);
                setLoading(false);
            } else {
                console.log('response-error: ', response);
                setLoading(false);
            }
        }).catch((error) => {
            setLoading(false);
            console.log('error: ', error);
        });
    }

    async function goToProfile() {
        setLoading(true);
        await auth!.signIn(
            email,
            password
        ).then((response) => {
            if (response) {
                navigate('/projetos');
                clearAllValues();
                setLoading(false);
            } else {
                setLoading(false);
                clearAllValues();
            }
        }).catch((error) => {
            setLoading(false);
            clearAllValues();
        });
    }

    return (
        <Base>
            <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={styles.grid_root}
            >
                <Grid item xs={12} className={styles.grid_container}>
                    {(registerStep1 === false) && (
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            className={styles.grid_container_register}
                        >
                            <Grid item xs={12}>
                                <Typography className={styles.text_aling}>
                                    Cadastre-se na plataforma
                                </Typography>
                                <br />
                            </Grid>
                            <Grid item xs={6} className={styles.grid_input}>
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                    <TextField
                                        value={firstName}
                                        label='Primeiro Nome'
                                        autoComplete="firstName"
                                        onChange={(value) => { setFirstName(value.target.value) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} className={styles.grid_input}>
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                    <TextField
                                        value={lastName}
                                        label='Último Nome'
                                        autoComplete="lastName"
                                        onChange={(value) => { setLastName(value.target.value) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_input}>
                                <FormControl sx={{ m: 1, display: 'flex' }} variant="outlined">
                                    <TextField
                                        value={jobPosition}
                                        label='Cargo'
                                        onChange={(value) => { setJobPosition(value.target.value) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_input}>
                                <FormControl sx={{ m: 1, display: 'flex' }} variant="outlined">
                                    <TextField
                                        value={about}
                                        label='Sobre'
                                        autoComplete="about"
                                        onChange={(value) => { setAbout(value.target.value) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_input}>
                                <FormControl sx={{ m: 1, display: 'flex' }} variant="outlined">
                                    <TextField
                                        value={email}
                                        label='seu.email@email.com'
                                        type={'email'}
                                        autoComplete="email"
                                        onChange={(value) => { setEmail(value.target.value) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_input}>
                                <FormControl sx={{ m: 1, display: 'flex' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        value={password}
                                        label='Senha'
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete="current-password"
                                        onChange={(value) => { setPassword(value.target.value) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_input}>
                                <FormControl sx={{ m: 1, display: 'flex' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Confirmação de Senha</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        value={repeatPassword}
                                        label='Confirmação de Senha'
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete=""
                                        disabled={loading}
                                        onChange={(value) => { setRepeatPassword(value.target.value) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_button}>
                                <br />
                                <LoadingButton
                                    className={styles.button_color_border}
                                    loading={loading}
                                    loadingPosition="end"
                                    endIcon={<SaveIcon className={styles.button_login_icon} />}
                                    variant="outlined"
                                    onClick={ChangeActionStep1}
                                >
                                    Criar Conta
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_button_space}>
                                <br />
                                <Button onClick={LoginAction} className={styles.button_action}>
                                    Entrar?
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {(registerStep1 === true) && (
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            className={styles.grid_container_register}
                        >
                            <Grid item xs={12}>
                                <Typography className={styles.text_aling}>
                                    PARABÉNS!
                                </Typography>
                                <Typography className={styles.text_aling}>
                                    O seu cadastro foi realizado com sucesso!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_button}>
                                <br />
                                <Button onClick={goToProfile} className={styles.button_color_border}>
                                    VÁ PARA PERFIL
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Base >
    );
}
