/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ShowLoader from '../ShowLoaderComponent';
import {
    IHorizon,
    IChemicalMenuOptions,
    IPhysicalMenuOptions,
} from '../../types';
import ChemicalItem from './ChemicalItem';
import PhysicalItem from './PhysicalItem';
import apiService from '../../services/apiService';
import parse from 'html-react-parser';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import { Divider } from '@mui/material';

interface IAnalyticalDescriptionProps {
    examId: string | undefined
}

const ListChemicalMenuItem: Array<IChemicalMenuOptions> = [
    { id: 1, keyData: 'PHAwater', name: 'pH (água)', description: 'pH (em água)', label: 'adimensional', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 2, keyData: 'PHKCI', name: 'pH (KCl)', description: 'pH (em KCl)', label: 'adimensional', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 3, keyData: 'AssortedComplexCa2', name: 'Ca²<sup>+</sup>', description: 'Complexo Sortivo (Ca²<sup>+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 4, keyData: 'AssortedComplexMg2', name: 'Mg²<sup>+</sup>', description: 'Complexo Sortivo (Mg²<sup>+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 5, keyData: 'AssortedComplexK', name: 'K<sup>+</sup>', description: 'Complexo Sortivo (K<sup>+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 2 },
    { id: 6, keyData: 'AssortedComplexNa', name: 'Na<sup>+</sup>', description: 'Complexo Sortivo (Na<sup>+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 2 },
    { id: 7, keyData: 'AssortedComplexSValue', name: 'Valor S', description: 'Complexo Sortivo (Valor S)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 8, keyData: 'AssortedComplexAl3', name: 'Al<sup>3+</sup>', description: 'Complexo Sortivo (Al<sup>3+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 9, keyData: 'AssortedComplexH', name: 'H<sup>+</sup>', description: 'Complexo Sortivo (H<sup>+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 10, keyData: 'AssortedComplexTValue', name: 'Valor T', description: 'Complexo Sortivo (Valor T)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 11, keyData: 'AssortedComplexVValue', name: 'Valor V', description: 'Complexo Sortivo (Valor V)', label: '%', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 12, keyData: 'AssortedComplexAluminumSaturation', name: 'Saturação por alumínio', description: 'Complexo Sortivo (Saturação por alumínio)', label: '%', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 13, keyData: 'AssortedComplexPAssimilable', name: 'P Assimilável', description: 'Complexo Sortivo (P Assimilável)', label: 'mg kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 15, keyData: 'COrganic', name: 'C Orgânico', description: 'C Orgânico', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 2 },
    { id: 16, keyData: 'N', name: 'N', description: 'N', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 2 },
    { id: 17, keyData: 'CN', name: 'Relação C/N', description: 'Relação C/N', label: 'adimensional', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 18, keyData: 'SulfuricAttackSiO2', name: 'SiO₂', description: 'Ataque Sulfúrico (SiO₂)', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 19, keyData: 'SulfuricAttackAl2O3', name: 'Al₂O₃', description: 'Ataque Sulfúrico (Al₂O₃)', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 20, keyData: 'SulfuricAttackFe2O3', name: 'Fe₂O₃', description: 'Ataque Sulfúrico (Fe₂O₃)', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 21, keyData: 'SulfuricAttackTiO2', name: 'TiO₂', description: 'Ataque Sulfúrico (TiO₂)', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 22, keyData: 'SulfuricAttackP2O5', name: 'P₂O<sub>5</sub>', description: 'Ataque Sulfúrico (P₂O<sub>5</sub>)', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 23, keyData: 'SulfuricAttackMnO', name: 'MnO', description: 'Ataque Sulfúrico (MnO)', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 24, keyData: 'MolecularRelationsSiO2AI2O3', name: 'Ki - SiO₂/Al₂O₃', description: 'Relações Moleculares (SiO₂/Al₂O₃ (Ki))', label: 'adimensional', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 2 },
    { id: 25, keyData: 'MolecularRelationsSiO2R2O3', name: 'Kr - SiO₂/R₂O₃', description: 'Relações Moleculares (SiO₂/R₂O₃ (Kr))', label: 'adimensional', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 2 },
    { id: 26, keyData: 'MolecularRelationsAl2O3Fe2O3', name: 'Al₂O₃/Fe₂O₃', description: 'Relações Moleculares (Al₂O₃/Fe₂O₃)', label: 'adimensional', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 2 },
    { id: 38, keyData: 'FreeFe2O3', name: 'Fe₂O₃', description: 'Fe₂O₃', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 2 },
    { id: 27, keyData: 'EquivalentCaCO3', name: 'Equivalente de CaCO₃', description: 'Equivalente de CaCO₃', label: 'g kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 14, keyData: 'AssortedComplexSodiumSaturation', name: 'Saturação por sódio', description: 'Complexo Sortivo (Saturação por sódio)', label: '%', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 28, keyData: 'SaturatedPasteCEEExtract', name: 'CE do extrato', description: 'Pasta Saturada (CE do extrato)', label: 'mS cm-1 25oC', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 29, keyData: 'SaturatedPasteWater', name: 'Água', description: 'Pasta Saturada (Água)', label: '%', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 0 },
    { id: 30, keyData: 'SolubleSaltsCa2', name: 'Sais Solúveis - Ca²<sup>+</sup>', description: 'Sais Solúveis (Ca²<sup>+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 31, keyData: 'SolubleSaltsMg2', name: 'Sais Solúveis - Mg2<sup>+</sup>', description: 'Sais Solúveis (Mg2<sup>+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 32, keyData: 'SolubleSaltsK', name: 'Sais Solúveis - K<sup>+</sup>', description: 'Sais Solúveis (K<sup>+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 33, keyData: 'SolubleSaltsNa', name: 'Sais Solúveis - Na<sup>+</sup>', description: 'Sais Solúveis (Na<sup>+</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 34, keyData: 'SolubleSaltsHCO3', name: 'Sais Solúveis - HCO₃', description: 'Sais Solúveis (HCO₃)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 35, keyData: 'SolubleSaltsCO32', name: 'Sais Solúveis - CO<sup>3-2</sup>', description: 'Sais Solúveis (CO<sup>3-2</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 36, keyData: 'SolubleSaltsCl', name: 'Sais Solúveis - Cl<sup>-</sup>', description: 'Sais Solúveis (Cl<sup>-</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
    { id: 37, keyData: 'SolubleSaltsSO42', name: 'Sais Solúveis - SO<sup>4-2</sup>', description: 'Sais Solúveis (SO<sup>4-2</sup>)', label: 'cmolc kg-1', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 1 },
];

const ListPhysicalMenuItem: Array<IPhysicalMenuOptions> = [
    { id: 2, keyData: 'Pebble', name: 'Calhau', description: 'Frações da Amostra Total (Calhau)', label: 'g kg-1', defaultValue: '', maxLength: 1000, minLength: 0, decimalLimit: 0 },
    { id: 3, keyData: 'Gravel', name: 'Cascalho', description: 'Frações da Amostra Total (Cascalho)', label: 'g kg-1', defaultValue: '', maxLength: 1000, minLength: 0, decimalLimit: 0 },
    { id: 1, keyData: 'ThinEarth', name: 'Terra Fina Seca ao Ar', description: 'Frações da Amostra Total (Terra Fina Seca ao Ar)', label: 'g kg-1', defaultValue: '', maxLength: 1000, minLength: 0, decimalLimit: 0 },
    { id: 11, keyData: 'CoarseSand', name: 'Areia Grossa', description: 'Composição Granulométrica da Terra Fina - Areia Grossa (2 - 0,2)', label: 'g kg-1', defaultValue: '', maxLength: 1000, minLength: 0, decimalLimit: 0 },
    { id: 12, keyData: 'ThinSand', name: 'Areia Fina', description: 'Composição Granulométrica da Terra Fina - Areia Fina (<0,2-0,05)', label: 'g kg-1', defaultValue: '', maxLength: 1000, minLength: 0, decimalLimit: 0 },
    { id: 13, keyData: 'Silt', name: 'Silte', description: 'Composição Granulométrica da Terra Fina - Silte (<0,05-0,002)', label: 'g kg-1', defaultValue: '', maxLength: 1000, minLength: 0, decimalLimit: 0 },
    { id: 14, keyData: 'Clay', name: 'Argila', description: 'Composição Granulométrica da Terra Fina - Argila (<0,002)', label: 'g kg-1', defaultValue: '', maxLength: 1000, minLength: 0, decimalLimit: 0 },
    { id: 15, keyData: 'ClayDispersedWater', name: 'Argila Dispersa em Água', description: 'Argila Dispersa em Água', label: 'g kg-1', defaultValue: '', maxLength: 1000, minLength: 0, decimalLimit: 0 },
    { id: 16, keyData: 'DegreeFlocculation', name: 'Grau de Floculação', description: 'Grau de Floculação', label: '%', defaultValue: '', maxLength: 100, minLength: 0, decimalLimit: 0 },
    { id: 17, keyData: 'SiltClay', name: 'Relação Silte / Argila', description: 'Relação Silte / Argila', label: 'adimensional', defaultValue: '', maxLength: 100, minLength: 0, decimalLimit: 2 },
    { id: 8, keyData: 'SoilDensity', name: 'Densidade do Solo', description: 'Constantes Hídricas (Densidade do Solo)', label: 'kg dm-3', defaultValue: '', maxLength: 3, minLength: 0, decimalLimit: 2 },
    { id: 9, keyData: 'DensityParticles', name: 'Densidade das Partículas', description: 'Constantes Hídricas (Densidade das Partículas)', label: 'kg dm-3', defaultValue: '', maxLength: 6, minLength: 0, decimalLimit: 2 },
    { id: 10, keyData: 'TotalPorosity', name: 'Porosidade Total', description: 'Constantes Hídricas (Porosidade Total)', label: 'm3 m-3', defaultValue: '', maxLength: 1, minLength: 0, decimalLimit: 0 },
    { id: 5, keyData: 'WaterConstants01', name: 'Constantes Hídricas (0,01 MPa)', description: 'Constantes Hídricas (0,01 MPa)', label: 'kg kg-1', defaultValue: '', maxLength: 3, minLength: 0, decimalLimit: 2 },
    { id: 4, keyData: 'WaterConstants33', name: 'Constantes Hídricas (0,33 MPa)', description: 'Constantes Hídricas (0,33 MPa)', label: 'kg kg-1', defaultValue: '', maxLength: 3, minLength: 0, decimalLimit: 2 },
    { id: 6, keyData: 'WaterConstants15', name: 'Constantes Hídricas (1,5 MPa)', description: 'Constantes Hídricas (1,5 MPa)', label: 'kg kg-1', defaultValue: '', maxLength: 3, minLength: 0, decimalLimit: 2 },
    { id: 7, keyData: 'WaterAvailable', name: 'Água Disponível', description: 'Constantes Hídricas (Água Disponível)', label: 'kg kg-1', defaultValue: '', maxLength: 3, minLength: 0, decimalLimit: 2 },
    { id: 18, keyData: 'MoistureEquivalent', name: 'Equivalente de umidade', description: 'Equivalente de umidade', label: '%', defaultValue: '', maxLength: 100, minLength: 0, decimalLimit: 2 },
];

export default function AnalyticalDescriptionComponent(props: IAnalyticalDescriptionProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [horizonData, setHorizonData] = useState<Array<IHorizon> | undefined>(undefined);
    const [tmpHorizonData, setTmpHorizonData] = useState<Array<IHorizon> | undefined>(undefined);
    const [chemicalModal, setChemicalModal] = useState<IChemicalMenuOptions>(ListChemicalMenuItem[0]);
    const [physicalModal, setPhysicalModal] = useState<IPhysicalMenuOptions>(ListPhysicalMenuItem[0]);
    const [viewExamHorizonPhysics, setViewExamHorizonPhysics] = useState<boolean>(true);
    const [viewExamHorizonChemistry, setViewExamHorizonChemistry] = useState<boolean>(false);

    function changeSetPhysicsView() {
        if (tmpHorizonData !== undefined) {
            setHorizonData(tmpHorizonData);
            setTmpHorizonData(undefined);
        } else {
            renewHorizon(undefined, true);
        }
        setViewExamHorizonPhysics(true);
        setViewExamHorizonChemistry(false);
    }

    function changeSetChemistryView() {
        if (tmpHorizonData !== undefined) {
            setHorizonData(tmpHorizonData);
            setTmpHorizonData(undefined);
        } else {
            renewHorizon(undefined, true);
        }
        setViewExamHorizonChemistry(true);
        setViewExamHorizonPhysics(false);
    }

    function showChemicalDetails(chemicalMenuOptions: IChemicalMenuOptions) {
        if (tmpHorizonData !== undefined) {
            setHorizonData(tmpHorizonData);
            setTmpHorizonData(undefined);
        } else {
            renewHorizon(undefined, true);
        }
        setChemicalModal(chemicalMenuOptions);
    }

    function showPhysicalDetails(physicalMenuOptions: IPhysicalMenuOptions) {
        if (tmpHorizonData !== undefined) {
            setHorizonData(tmpHorizonData);
            setTmpHorizonData(undefined);
        } else {
            renewHorizon(undefined, true);
        }
        setPhysicalModal(physicalMenuOptions);
    }

    function renewHorizon(ArHorizon: Array<IHorizon> | undefined, now?: boolean) {
        if (now !== undefined && now === true) {
            getHorizon(false);
            return true;
        }

        if (horizonData === undefined) {
            getHorizon(true);
        } else {
            setTmpHorizonData(ArHorizon);
        }
    }

    async function getHorizon(temp: boolean) {
        if (props.examId !== undefined && auth !== null) {
            await apiService.get(
                `exams/horizon?exam_id=${parseInt(props.examId)}`,
                {}
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    if (temp === true) {
                        setTmpHorizonData(response.data.data);
                    } else {
                        setHorizonData(response.data.data);
                    }
                }
            }).catch((error) => {
                console.log('getHorizon ERROR: ', error);
            });
        }
    }

    useEffect(() => {
        if (horizonData === undefined) {
            getHorizon(false);
        }
        // eslint-disable-next-line
    }, [props, horizonData]);

    function chemicalMenuItem(item: IChemicalMenuOptions) {
        return (
            <>
                <Button onClick={() => showChemicalDetails(item)} className={
                    (item.id === chemicalModal.id ? styles.view_horizon_menu_item_active : styles.view_horizon_menu_item)
                }>
                    <Grid className={styles.view_horizon_name}>
                        <Typography className={
                            (`${item.id}` === `${chemicalModal.id}` ? styles.horizon_name_active : styles.horizon_name)
                        }>
                            { parse(item.name) }
                        </Typography>
                    </Grid>
                </Button>
            </>
        );
    }

    function physicalMenuItem(item: IPhysicalMenuOptions) {
        return (
            <>
                <Button onClick={() => showPhysicalDetails(item)} className={
                    (item.id === physicalModal.id ? styles.view_horizon_menu_item_active : styles.view_horizon_menu_item)
                }>
                    <Grid className={styles.view_horizon_name}>
                        <Typography className={
                            (`${item.id}` === `${physicalModal.id}` ? styles.horizon_name_active : styles.horizon_name)
                        }>
                            { parse(item.name) }
                        </Typography>
                    </Grid>
                </Button>
            </>
        );
    }

    const ShowDataChemicalSelected = React.useMemo((): JSX.Element => {
        if (horizonData !== undefined && horizonData.length > 0 && props.examId !== undefined) {
            return (
                <Grid container key={`${chemicalModal.id}${chemicalModal.name}`} className={styles.view_exam_chemical_session}>
                    <Grid item xs={12}>
                        <Typography className={styles.text_user_name}>
                            { parse(chemicalModal.description) }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.view_exam_chemical_itens}>
                        {horizonData.map((item, key) => {
                            if (item !== undefined && item.chemical !== undefined) {
                                return (
                                    <ChemicalItem
                                        key={`${key}${item.name}`}
                                        examId={props.examId}
                                        options={chemicalModal}
                                        horizon={item}
                                        renewHorizon={renewHorizon}
                                    />
                                )
                            }
                        })}
                    </Grid>
                </Grid>
            );
        } else {
            return (<></>);
        }
        // eslint-disable-next-line
    }, [chemicalModal, horizonData]);

    const ShowDataPhysicalSelected = React.useMemo((): JSX.Element => {
        if (horizonData !== undefined && horizonData.length > 0 && props.examId !== undefined) {
            return (
                <Grid container key={`${physicalModal.id}${physicalModal.name}`} className={styles.view_exam_physical_session}>
                    <Grid item xs={12}>
                        <Typography className={styles.text_user_name}>
                            { parse(physicalModal.description) }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.view_exam_physical_itens}>
                        {horizonData.map((item, key) => {
                            if (item !== undefined && item.chemical !== undefined) {
                                return (
                                    <PhysicalItem
                                        key={`${key}${item.name}`}
                                        examId={props.examId}
                                        options={physicalModal}
                                        horizon={item}
                                        renewHorizon={renewHorizon}
                                    />
                                )
                            }
                        })}
                    </Grid>
                </Grid>
            );
        } else {
            return (<></>);
        }
        // eslint-disable-next-line
    }, [physicalModal, horizonData]);

    if (horizonData !== undefined && auth !== null && auth.user !== undefined) {
        return (
            <>
                <Grid container className={styles.view_exam_horizon}>
                    <Grid item xs={4} className={styles.view_exam_horizon_actions}></Grid>
                    <Grid item xs={2} className={styles.view_exam_horizon_actions}>
                        <Button onClick={changeSetPhysicsView} className={
                            viewExamHorizonPhysics === true ? styles.buttons_horizon_active : styles.buttons_horizon
                        }>
                            <Typography className={
                                (viewExamHorizonPhysics === true ? styles.horizon_name_active : styles.horizon_name)
                            }>
                                Física
                            </Typography>
                        </Button>
                    </Grid>    
                    <Grid item xs={2} className={styles.view_exam_horizon_actions}>
                        <Button onClick={changeSetChemistryView} className={
                            viewExamHorizonChemistry === true ? styles.buttons_horizon_active : styles.buttons_horizon
                        }>
                            <Typography className={
                                (viewExamHorizonChemistry === true ? styles.horizon_name_active : styles.horizon_name)
                            }>
                                Química
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={4} className={styles.view_exam_horizon_actions}></Grid>
                    <Grid item xs={12} className={styles.view_exam_horizon_home}>
                        {(viewExamHorizonPhysics === true) && (
                            <Grid className={styles.view_exam_physics}>
                                <Grid className={styles.view_exam_horizon_list}>
                                    {ListPhysicalMenuItem.map(item => (
                                        physicalMenuItem(item)
                                    ))}
                                    <Divider />
                                </Grid>
                                <Grid className={styles.view_exam_physics_content}>
                                    {(horizonData !== undefined) && (
                                        ShowDataPhysicalSelected
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {(viewExamHorizonChemistry === true) && (
                            <Grid className={styles.view_exam_chemistry}>
                                <Grid className={styles.view_exam_horizon_list}>
                                    {ListChemicalMenuItem.map(item => (
                                        chemicalMenuItem(item)
                                    ))}
                                    <Divider />
                                </Grid>
                                <Grid className={styles.view_exam_chemistry_content}>
                                    {(horizonData !== undefined) && (
                                        ShowDataChemicalSelected
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        <Divider />
                    </Grid>
                </Grid>
            </>
        );
    } else {
        return (
            <ShowLoader message={''} />
        );
    }
}