import { makeStyles, DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_div: {
        flexDirection: 'column',
    },
    grid_backdrop: {
        width: '100%',
        flexDirection: 'column',
    },
    grid_div_message: {
        minWidth: '100',
        maxWidth: '400',
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        border: '1px solid #cddee9',
        padding: '10px 15px',
        borderRadius: '10px',
    },
    grid_circular: {
        color: '#06617c !important',
        flexDirection: 'column',
    },
    alert_component: {
        flexDirection: 'column',
    },
    button_ok: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button_cancel: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
});

export default useStyles;