import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_horizon_texture: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    view_horizon_texture_title: {
        backgroundColor: 'transparent',
    },
    text_horizon_texture: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000000",
        fontWeight: "bold",
        textAlign: "center"
    },
    view_horizon_texture_subtitle: {
        width: '100%',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_horizon_texture_subtitle: {
        flexDirection: 'row',
        backgroundColor: 'transparent',
        alignItems: 'center',
    },
    view_horizon_texture_content: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        marginBottom: '20px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    picker_select_type: {
        width: '300px',
        padding: '10px 20px !important',
        backgroundColor: 'transparent',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#06617c !important',
        }
    },
    picker_select_item: {
        color: '#000',
        backgroundColor: 'transparent',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'serca-condensed-text',
        fontSize: 16,
    },
});

export default useStyles;