import React from 'react';
import useStyles, { BootstrapDialog } from './styles';
import { IUserAdmin } from '../../types';
import { useNavigate } from 'react-router-dom';
import DataTable, { TableColumn } from 'react-data-table-component';
import apiService from '../../services/apiService';
import { formatDateToLocaleString } from '../../formatters';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import ShowLoader from '../../components/ShowLoaderComponent';
import AuthBase from '../../components/AuthBase';
import BootstrapDialogTitle from '../../components/BootstrapDialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import KeyIcon from '@mui/icons-material/Key';

export default function UsersPage() {
    const auth = React.useContext(AuthContext);
    const styles = useStyles();
    const navigate = useNavigate();

    const [userId, setUserId] = React.useState<number>(0);
    const [userName, setUserName] = React.useState<string>('');
    const [userPassword, setUserPassword] = React.useState<string>('');
    const [userRepeatPassword, setUserRepeatPassword] = React.useState<string>('');
    const [userEmail, setUserEmail] = React.useState<string>('');
    const [userJob, setUserJob] = React.useState<string>('');
    const [userType, setUserType] = React.useState<'admin' | 'staff' | 'client' | undefined>(undefined);
    const [userAbout, setUserAbout] = React.useState<string>('');

    const [errorUserName, setErrorUserName] = React.useState<boolean>(false);
    const [errorUserPassword, setErrorUserPassword] = React.useState<boolean>(false);
    const [errorUserRepeatPassword, setErrorUserRepeatPassword] = React.useState<boolean>(false);
    const [errorUserEmail, setErrorUserEmail] = React.useState<boolean>(false);
    const [errorUserJob, setErrorUserJob] = React.useState<boolean>(false);
    const [errorUserType, setErrorUserType] = React.useState<boolean>(false);
    const [errorUserAbout, setErrorUserAbout] = React.useState<boolean>(false);

    const [arUser, setArUser] = React.useState<Array<IUserAdmin> | undefined>(undefined);
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [showCreateUser, setShowCreateUser] = React.useState<boolean>(false);
    const [showEditUser, setShowEditUser] = React.useState<boolean>(false);
    const [showDeleteUser, setShowDeleteUser] = React.useState<boolean>(false);
    const [showModalPasswordUser, setShowModalPasswordUser] = React.useState<boolean>(false);
    const [loader, setLoader] = React.useState<boolean>(false);

    const columns: TableColumn<any>[] = [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: IUserAdmin) => row.id,
        },
        {
            sortable: true,
            name: 'Nome',
            selector: (row: IUserAdmin) => row.name,
        },
        {
            sortable: true,
            name: 'E-mail',
            selector: (row: IUserAdmin) => row.email,
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: IUserAdmin) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Última atualização',
            width: '160px',
            selector: (row: IUserAdmin) => (row.updated_at === undefined ? '-' : formatDateToLocaleString(`${row.updated_at}`)),
        },
        {
            name: 'Opções',
            width: '170px',
            cell: (row: IUserAdmin) => (
                <>
                    <Tooltip title="Trocar sehna do Usuário">
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => showModalReplacePasswordUser(row.id)}
                            color="success"
                        >
                            <KeyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar Usuário">
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => editUser(row.id)}
                            color="primary"
                        >
                            <CreateIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir Usuário">
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => deleteUser(row.id)}
                            color="error"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Ver rastreio do usuário">
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => historyUser(row.id)}
                            color="default"
                        >
                            <ManageSearchIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
        // eslint-disable-next-line
    ];

    const handleClose = (loadingUsersData: boolean) => {
        setUserId(0);
        setUserName('');
        setUserPassword('');
        setUserRepeatPassword('');
        setUserEmail('');
        setUserJob('');
        setUserType(undefined);
        setUserAbout('');

        setErrorUserName(false);
        setErrorUserPassword(false);
        setErrorUserRepeatPassword(false);
        setErrorUserEmail(false);
        setErrorUserJob(false);
        setErrorUserType(false);
        setErrorUserAbout(false);

        setShowCreateUser(false);
        setShowEditUser(false);
        setShowDeleteUser(false);
        setShowModalPasswordUser(false);

        setTimeout(() => {
            if (loadingUsersData === true) {
                getUsers();
            }
        }, 600);

        setTimeout(() => {
            setLoader(false);
            setLoadingModal(false);
        }, 400);
    }

    function clearPasswordsValues() {
        setUserPassword('');
        setUserRepeatPassword('');
    }

    function validateUserData() {
        let error = false;
        if (userName.trim() === '') {
            error = true;
            setErrorUserName(true);
        } else {
            setErrorUserName(false);
        }
        if (userEmail.trim() === '') {
            error = true;
            setErrorUserEmail(true);
        } else {
            setErrorUserEmail(false);
        }
        if (userJob.trim() === '') {
            error = true;
            setErrorUserJob(true);
        } else {
            setErrorUserJob(false);
        }
        if (userType === undefined) {
            error = true;
            setErrorUserType(true);
        } else {
            setErrorUserType(false);
        }
        if (userAbout.trim() === '') {
            error = true;
            setErrorUserAbout(true);
        } else {
            setErrorUserAbout(false);
        }

        return error;
    }

    function validateUpdatePassword() {
        let error = true;

        if (userPassword.trim() !== userRepeatPassword.trim()) {
            error = false;
            setErrorUserPassword(true);
            setErrorUserRepeatPassword(true);
            clearPasswordsValues();
        } else {
            setErrorUserPassword(false);
            setErrorUserRepeatPassword(false);
        }

        if (userPassword.trim() === '') {
            error = false;
            setErrorUserPassword(true);
            clearPasswordsValues();
        } else {
            setErrorUserPassword(false);
        }

        if (userRepeatPassword.trim() === '') {
            error = false;
            setErrorUserRepeatPassword(true);
            clearPasswordsValues();
        } else {
            setErrorUserRepeatPassword(false);
        }

        return error;
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handlesetNewUserName = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserName(element.target.value);
    }

    const handlesetNewUserEmail = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserEmail(element.target.value);
    }

    const handlesetNewUserJob = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserJob(element.target.value);
    }

    const handlesetNewUserType = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (element.target.value === 'admin' || element.target.value === 'staff' || element.target.value === 'client') {
            setUserType(element.target.value);
        } else {
            setUserType(undefined);
        }
    }

    const handlesetNewUserAbout = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserAbout(element.target.value);
    }

    function showModalCreateUser() {
        setShowCreateUser(true);
    }

    const handlesetNewUserPassword = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserPassword(element.target.value);
    }

    const handlesetNewUserRepeatPassword = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserRepeatPassword(element.target.value);
    }

    function showModalReplacePasswordUser(userId: number) {
        if (arUser !== undefined) {
            setUserId(userId);
            // eslint-disable-next-line
            Object.keys(arUser).some((key) => {
                if (arUser[parseInt(key)]?.id === userId) {
                    setUserName(arUser[parseInt(key)]?.name);
                    setUserEmail(arUser[parseInt(key)]?.email);
                    setUserJob(arUser[parseInt(key)]?.job_position);
                    setUserType(arUser[parseInt(key)]?.type);
                    setUserAbout(arUser[parseInt(key)]?.about);
                }
            });
            setShowModalPasswordUser(true);
        }
    }

    function editUser(userId: number) {
        if (arUser !== undefined) {
            setUserId(userId);
            // eslint-disable-next-line
            Object.keys(arUser).some((key) => {
                if (arUser[parseInt(key)]?.id === userId) {
                    setUserName(arUser[parseInt(key)]?.name);
                    setUserEmail(arUser[parseInt(key)]?.email);
                    setUserJob(arUser[parseInt(key)]?.job_position);
                    setUserType(arUser[parseInt(key)]?.type);
                    setUserAbout(arUser[parseInt(key)]?.about);
                }
            });
            setShowEditUser(true);
            setShowCreateUser(true);
        }
    }

    function deleteUser(userId: number) {
        if (arUser !== undefined) {
            setUserId(userId);
            // eslint-disable-next-line
            Object.keys(arUser).some((key) => {
                if (arUser[parseInt(key)]?.id === userId) {
                    setUserName(arUser[parseInt(key)]?.name);
                }
            });
            setShowDeleteUser(true);
        }
    }

    function historyUser(userId: number) {
        navigate(`/usuarios/historico/${userId}`);
    }

    async function createUser() {
        if (auth !== undefined) {
            if(validateUserData() === true) {
                alert('Por favor, preencha os campos em vermelho corretamente.');
                return false;
            }
            setLoadingModal(true);
            try {
                await apiService.post(
                    'users',
                    {
                        name: userName,
                        email: userEmail,
                        job_position: userJob,
                        type: userType,
                        about: userAbout
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        handleClose(true);
                    } else {
                        console.log(`ERROR createUser`, response);
                        handleClose(false);
                    }
                }).catch((error) => {
                    console.log(`ERROR createUser`, error);
                    handleClose(false);
                });
            } catch (e) {
                console.log(`ERROR createUser`, e);
                handleClose(false);
            }
        }
    }

    async function updateUser() {
        if (userId !== 0 && auth !== undefined) {
            if(validateUserData() === true) {
                alert('Por favor, preencha os campos em vermelho corretamente.');
                return false;
            }
            setLoadingModal(true);
            try {
                await apiService.put(
                    'users',
                    {
                        user_id: userId,
                        name: userName,
                        email: userEmail,
                        job_position: userJob,
                        type: userType,
                        about: userAbout
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        handleClose(true);
                    } else {
                        console.log(`ERROR updateUser`, response);
                        handleClose(false);
                    }
                }).catch((error) => {
                    console.log(`ERROR updateUser`, error);
                    handleClose(false);
                });
            } catch (e) {
                console.log(`ERROR updateUser`, e);
                handleClose(false);
            }
        }
    }

    async function updatePasswordUser() {
        if (userId !== 0 && auth !== undefined) {
            if (validateUpdatePassword() === false) {
                return false;
            } else {
                setErrorUserPassword(false);
                setErrorUserRepeatPassword(false);
            }

            setLoadingModal(true);
            try {
                await apiService.put(
                    'users',
                    {
                        user_id: userId,
                        password: userPassword.trim()
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        handleClose(true);
                    } else {
                        console.log(`ERROR updateUser`, response);
                        handleClose(false);
                    }
                }).catch((error) => {
                    console.log(`ERROR updateUser`, error);
                    handleClose(false);
                });
            } catch (e) {
                console.log(`ERROR updateUser`, e);
                handleClose(false);
            }
        }
    }

    async function removeUser() {
        if (userId !== 0 && auth !== undefined) {
            setLoadingModal(true);
            try {
                await apiService.delete(
                    `users`,
                    {
                        user_id: userId
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        handleClose(true);
                    } else {
                        console.log(`ERROR removeUser`, response);
                        handleClose(false);
                    }
                }).catch((error) => {
                    console.log(`ERROR removeUser`, error);
                    handleClose(false);
                });
            } catch (e) {
                console.log(`ERROR removeUser`, e);
                handleClose(false);
            }
        }
    }

    async function getUsers() {
        if (auth !== undefined) {
            setLoader(true);
            try {
                await apiService.get(
                    'users/ewyt4',
                    {}
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setArUser(response.data.data as Array<IUserAdmin>);
                        handleClose(false);
                    } else {
                        console.log('response: ', response);
                        setArUser([]);
                        handleClose(false);
                    }
                }).catch((error) => {
                    console.log('error: ', error);
                    setArUser([]);
                    handleClose(false);
                });
            } catch (e) {
                console.log('error: ', e);
                setArUser([]);
                handleClose(false);
            }
        }
    }

    React.useEffect(() => {
        if (arUser === undefined) {
            getUsers();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <AuthBase>
            <Grid container className={styles.grid_root}>
                <Grid item xs={12} className={styles.grid_title_buttons}>
                    <Typography className={styles.title}>
                        {(arUser !== undefined && arUser.length <= 0) && ('Usuários')}
                        {(arUser !== undefined && arUser.length > 0) && (' ')}
                    </Typography>
                    <Tooltip title="Editar">
                        <IconButton
                            className={styles.button_create}
                            size="small"
                            aria-haspopup="true"
                            onClick={() => showModalCreateUser()}
                            color="primary"
                        >
                            Criar Novo Usuário <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} className={styles.grid_datagrid}>
                    {(arUser !== undefined && arUser.length > 0 && loader === false) && (
                        <Paper className={styles.paper}>
                            <DataTable
                                title="Usuários"
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                highlightOnHover={true}
                                pagination={true}
                                responsive={true}
                                striped={true}
                                subHeader={true}
                                columns={columns}
                                data={arUser}
                                disabled={loader}
                            />
                        </Paper>
                    )}
                    {(arUser !== undefined && arUser.length === 0 && loader === false) && (
                        <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {(loader === true) && (
                        <ShowLoader message='' />
                    )}
                </Grid>
                {(loadingModal === true) && (
                    <BootstrapDialog
                        open={loadingModal}
                    >
                        <ShowLoader message='' />
                    </BootstrapDialog>
                )}
                {(loadingModal !== true) && (
                    <>
                        <BootstrapDialog
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showCreateUser}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                {(showEditUser === true) && (
                                    'Editar Usuário'
                                )}
                                {(showEditUser !== true) && (
                                    'Criar Novo Usuário'
                                )}
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} className={styles.grid_input}>
                                        <FormControl sx={{ width: '35ch' }} variant="outlined">
                                            <TextField
                                                value={userName}
                                                label='Nome do Usuários'
                                                required={true}
                                                error={errorUserName}
                                                onChange={handlesetNewUserName}
                                                disabled={loadingModal}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} className={styles.grid_input}>
                                        <FormControl sx={{ width: '35ch' }} variant="outlined">
                                            <TextField
                                                value={userEmail}
                                                label='E-mail'
                                                required={true}
                                                error={errorUserEmail}
                                                onChange={handlesetNewUserEmail}
                                                disabled={loadingModal}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} className={styles.grid_input}>
                                        <FormControl sx={{ width: '35ch' }} variant="outlined">
                                            <TextField
                                                value={userJob}
                                                label='Cargo'
                                                required={true}
                                                error={errorUserJob}
                                                onChange={handlesetNewUserJob}
                                                disabled={loadingModal}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} className={styles.grid_input}>
                                        <FormControl sx={{ width: '35ch' }} variant="outlined">
                                            <TextField
                                                value={userAbout}
                                                label='Sobre vocês:'
                                                required={true}
                                                error={errorUserAbout}
                                                onChange={handlesetNewUserAbout}
                                                disabled={loadingModal}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} className={styles.grid_input}>
                                        <FormControl sx={{ width: '35ch' }} variant="outlined">
                                            <TextField
                                                label='Perfil de Acesso'
                                                select
                                                value={userType ? userType : "0"}
                                                defaultValue={'0'}
                                                required={true}
                                                error={errorUserType}
                                                onChange={handlesetNewUserType}
                                                disabled={loadingModal}
                                            >
                                                <MenuItem value={'0'}>Selecione</MenuItem>
                                                <MenuItem value={'admin'}>Administrador</MenuItem>
                                                <MenuItem value={'client'}>Simples</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)}>
                                    Cancelar
                                </Button>
                                {(showEditUser === true) && (
                                    <Button disabled={loadingModal} onClick={updateUser}>
                                        Salvar
                                    </Button>
                                )}
                                {(showEditUser !== true) && (
                                    <Button disabled={loadingModal} onClick={createUser}>
                                        Criar
                                    </Button>
                                )}
                            </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showDeleteUser}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                Excluir Usuário
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Typography className={styles.title}>Deseja excluir o <strong>{userName}</strong>?</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)}>
                                    Não
                                </Button>
                                <Button disabled={loadingModal} onClick={removeUser}>
                                    Sim
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showModalPasswordUser}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                Trocar Senha
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} className={styles.grid_input}>
                                        <FormControl sx={{ width: '35ch' }} variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Digite a Nova Senha</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                value={userPassword}
                                                label='Digite a Nova Senha'
                                                type={showPassword ? 'text' : 'password'}
                                                autoComplete=""
                                                required={true}
                                                disabled={loadingModal}
                                                onChange={handlesetNewUserPassword}
                                                error={errorUserPassword}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} className={styles.grid_input}>
                                        <FormControl sx={{ width: '35ch' }} variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Repita a Nova Senha</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                value={userRepeatPassword}
                                                label='Repita a Nova Senha'
                                                type={showPassword ? 'text' : 'password'}
                                                autoComplete=""
                                                required={true}
                                                disabled={loadingModal}
                                                onChange={handlesetNewUserRepeatPassword}
                                                error={errorUserRepeatPassword}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)}>
                                    Cancelar
                                </Button>
                                <Button disabled={loadingModal} onClick={updatePasswordUser}>
                                    Salvar
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                    </>
                )}
            </Grid>
        </AuthBase>
    );
}
