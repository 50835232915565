import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_input: {
        backgroundColor: 'transparent',
        marginTop: 10,
    },
    text_aling: {
        textAlign: 'center',
    },
    grid_button_update: {
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 10,
    },
    grid_button_space: {
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 70,
    },
    button_color: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        borderColor: '#06617c !important',
    },
    input_color: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        borderColor: '#06617c !important',
    },
    button_color_border: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        border: '1px solid #06617c !important',
    },
});

export default useStyles;