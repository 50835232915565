import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_exam_horizon_morphology: {
        backgroundColor: 'transparent',
        marginTop: '20px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    view_horizon_observation_subtitle: {
        flexDirection: 'column',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_exam_horizon_morphology_title: {
        width: 50,
        textAlign: 'center',
        marginBottom: '10px !important',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-end',
    },
    input_morphology_observation: {
        width: '400px',
        flexDirection: 'column',
        borderWidth: 1,
        borderRadius: 10,
        verticalAlign: 'top',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#06617c !important',
        }
    },
});

export default useStyles;