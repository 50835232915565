import { makeStyles, styled, DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        backgroundColor: 'transparent',
    },
    grid_logo: {
        backgroundColor: '#06617c',
    },
    grid_logo_root: {
        height: '100vh',
        backgroundColor: 'transparent',
    },
    grid_logo_item: {
        height: '100vh',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    grid_modal_root: {
        height: '100%',
        backgroundColor: '#FFF',
    },
    grid_modal: {
        height: '100%',
        backgroundColor: 'transparent',
    },
    grid_modal_item_top: {
        width: '100%',
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    grid_modal_item_botton: {
        width: '100%',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'flex-end',
    },
    grid_modal_item_content: {
        minWidth: '450px',
        minHeight: '300px',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const ImageSmartSolos = styled('img')({
    maxWidth: '100px',
    maxHeight: 'auto',
});

export default useStyles;