import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IRequestData,
    IHorizon,
    IFilterHorizons,
} from '../../../types';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    horizonFilters: IFilterHorizons
    number: '1' | '2'
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonStructureItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [structureGrade, setStructureGrade] = useState<string | undefined>(undefined);
    const [structureSize, setStructureSize] = useState<string | undefined>(undefined);
    const [structureForm, setStructureFrom] = useState<string | undefined>(undefined);
    const [structureUpdate, setStructureUpdate] = useState<boolean>(false);

    async function updateHorizon() {
        if (auth !== undefined && props.examId !== undefined && props.horizon !== undefined) {
            let request_data: IRequestData = {
                horizon_id: props.horizon.id,
                exam_id: parseInt(props.examId),
            };

            switch (props.number) {
                case '1':
                    if (
                        structureGrade !== null &&
                        structureGrade !== '0'
                    ) {
                        request_data['structure_grade_one'] = `${structureGrade}`;
                    }
                    if (
                        structureSize !== null &&
                        structureSize !== '0'
                    ) {
                        request_data['structure_size_one'] = `${structureSize}`;
                    }
                    if (
                        structureForm !== null &&
                        structureForm !== '0'
                    ) {
                        request_data['structure_form_one'] = `${structureForm}`;
                    }
                    break;
                case '2':
                    if (
                        structureGrade !== null &&
                        structureGrade !== '0'
                    ) {
                        request_data['structure_grade_two'] = `${structureGrade}`;
                    }
                    if (
                        structureSize !== null &&
                        structureSize !== '0'
                    ) {
                        request_data['structure_size_two'] = `${structureSize}`;
                    }
                    if (
                        structureForm !== null &&
                        structureForm !== '0'
                    ) {
                        request_data['structure_form_two'] = `${structureForm}`;
                    }
                    break;
                default:
                    break;
            }

            try {
                await apiService.put(
                    'exams/horizon',
                    request_data
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        props.renewHorizon(response.data.data as Array<IHorizon>);
                    }
                }).catch(() => {
                });
            } catch (e) {
            }
        }
    }

    useEffect(() => {
        if (structureUpdate === true) {
            updateHorizon();
            setStructureUpdate(false);
        }
        return () => setStructureUpdate(false);
        // eslint-disable-next-line
    }, [structureUpdate]);

    useEffect(() => {
        if (props.horizon !== undefined) {
            switch (props.number) {
                case '1':
                    setStructureGrade((props.horizon.structure_grade_one === null ? '0' : props.horizon.structure_grade_one));
                    setStructureSize((props.horizon.structure_size_one === null ? '0' : props.horizon.structure_size_one));
                    setStructureFrom((props.horizon.structure_form_one === null ? '0' : props.horizon.structure_form_one));
                    break;
                case '2':
                    setStructureGrade((props.horizon.structure_grade_two === null ? '0' : props.horizon.structure_grade_two));
                    setStructureSize((props.horizon.structure_size_two === null ? '0' : props.horizon.structure_size_two));
                    setStructureFrom((props.horizon.structure_form_two === null ? '0' : props.horizon.structure_form_two));
                    break;
            }
        }
        // eslint-disable-next-line
    }, [props]);

    return (
        <Grid container key={`${props.horizon.id}${props.horizon.name}structure_grade_one`} className={styles.view_horizon_structure}>
            <Grid item xs={12} className={styles.view_horizon_structure_title}>
                <Typography className={styles.text_horizon_structure}>
                    <strong>{props.horizon.name}</strong>
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_structure_subtitle}>
                <Typography className={styles.text_horizon_structure_subtitle}>
                    Grau
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_structure_subtitle}>
                <TextField
                    className={styles.picker_select_type}
                    select
                    value={structureGrade ? structureGrade : "0"}
                    defaultValue={'0'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setStructureGrade(`${event.target.value}`);
                        setStructureUpdate(true);
                    }}
                >
                    <MenuItem className={styles.picker_select_item} key={'0'} value={'0'}>Selecione</MenuItem>
                    {props.horizonFilters.structure_grade.map((element, key) => (
                        <MenuItem className={styles.picker_select_item} key={key} value={`${key + 1}`}>{`${element}`}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_structure_subtitle}>
                <Typography className={styles.text_horizon_structure_subtitle}>
                    Tamanho
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_structure_subtitle}>
                <TextField
                    className={styles.picker_select_type}
                    select
                    value={structureSize ? structureSize : "0"}
                    defaultValue={'0'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setStructureSize(`${event.target.value}`);
                        setStructureUpdate(true);
                    }}
                >
                    <MenuItem className={styles.picker_select_item} key={'0'} value={'0'}>Selecione</MenuItem>
                    {props.horizonFilters.structure_size.map((element, key) => (
                        <MenuItem className={styles.picker_select_item} key={key} value={`${key + 1}`}>{`${element}`}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_structure_subtitle}>
                <Typography className={styles.text_horizon_structure_subtitle}>
                    Forma
                </Typography>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_structure_subtitle}>
                <TextField
                    className={styles.picker_select_type}
                    select
                    value={structureForm ? structureForm : "0"}
                    defaultValue={'0'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setStructureFrom(`${event.target.value}`);
                        setStructureUpdate(true);
                    }}
                >
                    <MenuItem className={styles.picker_select_item} key={'0'} value={'0'}>Selecione</MenuItem>
                    {props.horizonFilters.structure_form.map((element, key) => (
                        <MenuItem className={styles.picker_select_item} key={key} value={`${key + 1}`}>{`${element}`}</MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    );
}