import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        width: '100% !important',
        marginTop: 15,
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        border: '1px solid #cddee9',
        padding: '20px 25px',
        borderRadius: '10px',
    },
    grid_item_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        alignContent: 'center',
    },
    grid_title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        marginBotton: '15px',
    },
    paper: {
        width: '100%',
    },
    button_back_page: {
        color: '#FFF !important',
        width: '100% !important',
        marginTop: '10px !important',
        marginBottom: '10px !important',
        flexDirection: 'column',
        padding: '10px 10px !important',
        borderRadius: '10px !important',
        backgroundColor: '#06617c !important',
        alignItems: 'center',
    },
    data_is_empty: {
        width: '100%',
        display: 'flex',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '50px !important',
    },
    grid_item_container_log: {
        marginTop: 15,
    },
});

export default useStyles;