import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_horizon_roots: {
        width: '100%',
        backgroundColor: 'transparent',
        marginBottom: '20px !important',
    },
    view_horizon_roots_title: {
        backgroundColor: 'transparent',
        marginBottom: '10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_horizon_roots: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        marginBottom: '10px !important',
        fontSize: 16,
        color: "#000000",
        fontWeight: "bold",
        textAlign: "center"
    },
    view_horizon_roots_subtitle: {
        flexDirection: 'column',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_horizon_roots_subtitle: {
        flexDirection: 'row',
        backgroundColor: 'transparent',
        alignItems: 'center',
    },
    view_horizon_roots_content: {
        width: '300px',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    picker_select_item: {
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        fontSize: 16,
        padding: '10px 20px !important',
        width: '100%',
        '& .searchWrapper': {
            border: '1px solid #06617c !important',
            '& li:hover': {
                background: "#06617c !important",
                color: "#FFF !important"
            }
        }
    },
});

export default useStyles;