import axios, { AxiosError } from "axios";
import { IGetProps, IUserData } from "../../types";

class APISerive {

    user_data_temp: IUserData | null | undefined;

    _objToQueryString = (resources: IGetProps): string => {
        if (resources === undefined) {
            return "";
        }

        const request_data = [];
        for (let value in resources) {
            if (encodeURIComponent(resources[value]) !== "") {
                request_data.push(
                    encodeURIComponent(value) + "=" + encodeURIComponent(resources[value])
                );
            }
        }

        if (request_data.length > 0) {
            return "?" + request_data.join("&");
        } else {
            return "";
        }
    };

    async renewTokenByEmail(email: string) {
        try {
            const token = localStorage.getItem('@SmartSolos:accessToken');
            var FormData = require('form-data');
            var data = new FormData();
            data.append('email', email);
            data.append('access_token', token);

            return await axios({
                url: `${process.env.REACT_APP_API}renew_token`,
                method: "POST",
                headers: {
                    'Accept': "application/json",
                },
                data: data
            }).then((response) => {
                if (response !== undefined && response.status === 200 && response.data !== undefined) {
                    localStorage.setItem('@SmartSolos:userData', JSON.stringify(response.data.user_data));
                    localStorage.setItem('@SmartSolos:accessToken', response.data.user_data.access_token);

                    return Promise.resolve(response);
                }
            }).catch((error) => {
                return Promise.reject(error);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async get(folder: string, request_data: IGetProps) {
        try {
            const params = this._objToQueryString(request_data);
            const token = localStorage.getItem("@SmartSolos:accessToken");
            const data_temp: string | null = (localStorage.getItem('@SmartSolos:userData') !== null ? localStorage.getItem('@SmartSolos:userData') : null);
            this.user_data_temp = (data_temp !== null ? JSON.parse(data_temp) : undefined);
            return await axios({
                url: `${process.env.REACT_APP_API}${folder}${params}`,
                method: "GET",
                headers: {
                    Accept: "application/json;charset=utf-8;",
                    Authorization: `Bearer ${token}`,
                },
                data: request_data,
            }).then((response) => {
                if (response !== undefined && response.status >= 200) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response);
                }
            }).catch((error) => {
                // if (error.request.status === 401) {
                //     if (this.user_data_temp !== undefined && this.user_data_temp !== null) {
                //         this.renewTokenByEmail(
                //             this.user_data_temp?.email
                //         ).then(() => {
                //             this.get(
                //                 folder,
                //                 request_data
                //             ).then((newResponse) => {
                //                 if (newResponse !== undefined && newResponse.status >= 200) {
                //                     return Promise.resolve(newResponse);
                //                 }
                //             }).catch((newError) => {
                //                 return Promise.reject(newError);
                //             });
                //         }).catch((newError) => {
                //             return Promise.reject(newError);
                //         });
                //     } else {
                //         return Promise.reject('ERR_BAD_REQUEST');
                //     }
                // } else {
                    return Promise.reject(error);
                // }
            });
        } catch (error) {
            let catch_error: AxiosError = (error as AxiosError);
            // if (catch_error.request.status === 401) {
            //     if (this.user_data_temp !== undefined && this.user_data_temp !== null) {
            //         this.renewTokenByEmail(
            //             this.user_data_temp?.email
            //         ).then(() => {
            //             this.get(
            //                 folder,
            //                 request_data
            //             ).then((newResponse) => {
            //                 if (newResponse !== undefined && newResponse.status >= 200) {
            //                     return Promise.resolve(newResponse);
            //                 }
            //             }).catch((newError) => {
            //                 return Promise.reject(newError);
            //             });
            //         }).catch((newError) => {
            //             return Promise.reject(newError);
            //         });
            //     } else {
            //         return Promise.reject('ERR_BAD_REQUEST');
            //     }
            // } else {
                return Promise.reject(catch_error);
            // }
        }
    }

    async post(folder: string, request_data: any) {
        try {
            const token = localStorage.getItem("@SmartSolos:accessToken");
            return await axios({
                url: `${process.env.REACT_APP_API}${folder}`,
                method: "POST",
                headers: {
                    Accept: "application/json;charset=utf-8;",
                    Authorization: `Bearer ${token}`,
                },
                data: request_data,
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                // if (error.request.status === 401) {
                //     if (this.user_data_temp !== undefined && this.user_data_temp !== null) {
                //         this.renewTokenByEmail(
                //             this.user_data_temp?.email
                //         ).then(() => {
                //             this.get(
                //                 folder,
                //                 request_data
                //             ).then((newResponse) => {
                //                 if (newResponse !== undefined && newResponse.status >= 200) {
                //                     return Promise.resolve(newResponse);
                //                 }
                //             }).catch((newError) => {
                //                 return Promise.reject(newError);
                //             });
                //         }).catch((newError) => {
                //             return Promise.reject(newError);
                //         });
                //     } else {
                //         return Promise.reject('ERR_BAD_REQUEST');
                //     }
                // } else {
                    return Promise.reject(error);
                // }
            });
        } catch (error) {
            // let catch_error: AxiosError = (error as AxiosError);
            // if (catch_error.request.status === 401) {
            //     if (this.user_data_temp !== undefined && this.user_data_temp !== null) {
            //         this.renewTokenByEmail(
            //             this.user_data_temp?.email
            //         ).then(() => {
            //             this.get(
            //                 folder,
            //                 request_data
            //             ).then((newResponse) => {
            //                 if (newResponse !== undefined && newResponse.status >= 200) {
            //                     return Promise.resolve(newResponse);
            //                 }
            //             }).catch((newError) => {
            //                 return Promise.reject(newError);
            //             });
            //         }).catch((newError) => {
            //             return Promise.reject(newError);
            //         });
            //     } else {
            //         return Promise.reject('ERR_BAD_REQUEST');
            //     }
            // } else {
                return Promise.reject(error);
            // }
        }
    }

    async put(folder: string, request_data: any) {
        try {
            const token = localStorage.getItem("@SmartSolos:accessToken");
            return await axios({
                url: `${process.env.REACT_APP_API}${folder}`,
                method: "PUT",
                headers: {
                    Accept: "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${token}`,
                },
                data: request_data,
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                // if (error.request.status === 401) {
                //     if (this.user_data_temp !== undefined && this.user_data_temp !== null) {
                //         this.renewTokenByEmail(
                //             this.user_data_temp?.email
                //         ).then(() => {
                //             this.get(
                //                 folder,
                //                 request_data
                //             ).then((newResponse) => {
                //                 if (newResponse !== undefined && newResponse.status >= 200) {
                //                     return Promise.resolve(newResponse);
                //                 }
                //             }).catch((newError) => {
                //                 return Promise.reject(newError);
                //             });
                //         }).catch((newError) => {
                //             return Promise.reject(newError);
                //         });
                //     } else {
                //         return Promise.reject('ERR_BAD_REQUEST');
                //     }
                // } else {
                    return Promise.reject(error);
                // }
            });
        } catch (error) {
            let catch_error: AxiosError = (error as AxiosError);
            // if (catch_error.request.status === 401) {
            //     if (this.user_data_temp !== undefined && this.user_data_temp !== null) {
            //         this.renewTokenByEmail(
            //             this.user_data_temp?.email
            //         ).then(() => {
            //             this.get(
            //                 folder,
            //                 request_data
            //             ).then((newResponse) => {
            //                 if (newResponse !== undefined && newResponse.status >= 200) {
            //                     return Promise.resolve(newResponse);
            //                 }
            //             }).catch((newError) => {
            //                 return Promise.reject(newError);
            //             });
            //         }).catch((newError) => {
            //             return Promise.reject(newError);
            //         });
            //     } else {
            //         return Promise.reject('ERR_BAD_REQUEST');
            //     }
            // } else {
                return Promise.reject(catch_error);
            // }
        }
    }

    async delete(folder: string, request_data: any) {
        try {
            const token = localStorage.getItem("@SmartSolos:accessToken");
            return await axios({
                url: `${process.env.REACT_APP_API}${folder}`,
                method: "DELETE",
                headers: {
                    Accept: "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${token}`,
                },
                data: request_data,
            }).then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                // if (error.request.status === 401) {
                //     if (this.user_data_temp !== undefined && this.user_data_temp !== null) {
                //         this.renewTokenByEmail(
                //             this.user_data_temp?.email
                //         ).then(() => {
                //             this.get(
                //                 folder,
                //                 request_data
                //             ).then((newResponse) => {
                //                 if (newResponse !== undefined && newResponse.status >= 200) {
                //                     return Promise.resolve(newResponse);
                //                 }
                //             }).catch((newError) => {
                //                 return Promise.reject(newError);
                //             });
                //         }).catch((newError) => {
                //             return Promise.reject(newError);
                //         });
                //     } else {
                //         return Promise.reject('ERR_BAD_REQUEST');
                //     }
                // } else {
                    return Promise.reject(error);
                // }
            });
        } catch (error) {
            let catch_error: AxiosError = (error as AxiosError);
            // if (catch_error.request.status === 401) {
            //     if (this.user_data_temp !== undefined && this.user_data_temp !== null) {
            //         this.renewTokenByEmail(
            //             this.user_data_temp?.email
            //         ).then(() => {
            //             this.get(
            //                 folder,
            //                 request_data
            //             ).then((newResponse) => {
            //                 if (newResponse !== undefined && newResponse.status >= 200) {
            //                     return Promise.resolve(newResponse);
            //                 }
            //             }).catch((newError) => {
            //                 return Promise.reject(newError);
            //             });
            //         }).catch((newError) => {
            //             return Promise.reject(newError);
            //         });
            //     } else {
            //         return Promise.reject('ERR_BAD_REQUEST');
            //     }
            // } else {
                return Promise.reject(catch_error);
            // }
        }
    }
}

const apiService = new APISerive();

export default apiService;
