import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        backgroundColor: 'transparent',
    },
    view_exam_exam_classification: {
        width: '100%',
        flexDirection: 'column',
    },
    view_exam_classification_root_data: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 20,
    },
    view_exam_classification_data: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_exam_classification_order: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000000',
    },
    text_exam_classification_group: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000000',
    },
    text_exam_classification_date: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000000',
    },
    button_exam_exam_classification: {
        width: '300px !important',
        backgroundColor: '#06617c !important',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px !important',
        padding: '12px 35px !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 13,
        color: '#FFFFFF !important',
    },
    button_exam_exam_classification_json: {
        width: '120px !important',
        backgroundColor: '#cddee9 !important',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px !important',
        padding: '12px 15px !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        margin: '0px 10px !important',
        fontSize: 13,
        border: '1px solid #06617c !important',
        color: '#06617c !important',
    },
    icon_classification: {
        backgroundColor: 'transparent',
        color: '#FFF',
    }
});

export default useStyles;