import React from 'react';
import useStyles from './styles';
import {
    IProject,
} from '../../../types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

let projects = require('../../../assets/images/tab_bar/projects.png');

interface IReceiptProps {
    data: IProject;
    deleteProjectAction?: boolean;
    action: (data: IProject) => void;
}

const ProjectItemList = (props: IReceiptProps) => {
    const styles = useStyles();
    return (
        <Button className={styles.view_root} onClick={() => props.action(props.data)}>
            <Grid container className={styles.view_project}>
                <Grid item xs={2} className={styles.view_project_image}>
                    <img alt={`${props.data.name}`} className={styles.project_img} src={projects} />
                </Grid>
                <Grid item xs={9} className={styles.view_project_name}>
                    <Typography className={styles.project_name}>
                        {props.data.name}
                    </Typography>
                </Grid>
                <Grid item xs={1} className={styles.view_icon_trash}>
                    {(props.deleteProjectAction === true) && (
                        <Icon className={styles.icon_trash}>trash</Icon>
                    )}
                </Grid>
            </Grid>
        </Button>
    );
}

export default ProjectItemList;
