import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_auth_root: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    grid_input: {
        backgroundColor: 'transparent',
        marginTop: 10,
        alignItems: 'center',
    },
    link_space: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        marginTop: 10,
    },
    grid_login: {
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 10,
    },
    button_login: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        borderColor: '#06617c !important',
        marginTop: '15px !important',
        marginBottom: '30px !important'
    },
    button_login_icon: {
        backgroundColor: 'transparent',
        color: '#06617c',
    },
    grid_register: {
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '30px !important',
    },
    button_create_account: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
    },
    grid_google_auth_message: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: '#FF6776',
        color: '#FFF',
        padding: '10px 15px',
        fontVariant: 'bold',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: 10,
        marginBottom: 10,
    },
    grid_google_auth_button: {
        backgroundColor: 'transparent',
        alignItems: 'center',
        textAlign: 'center',
    },
    google_auth_button: {
        backgroundColor: 'purple',
        color: '#000',
        fontSize: '12pt'
    },
});

export default useStyles;