import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import jwt_decode from 'jwt-decode';
import { useAuth } from "../../contexts/Auth/AuthProvider";
import Base from '../../components/Base';
import { CredentialResponse } from '../../types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { Box, CircularProgress } from '@mui/material';

export default function LoginPage() {
    const styles = useStyles();
    const navigate = useNavigate();
    const { signIn, signSocialIn, user } = useAuth();
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingScreen, setLoadingScreen] = useState(true);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loginFailed, setLoginFailed] = useState<boolean>(false);

    useEffect(() => {
        if ((user === undefined) || (user === null)) {
            setLoadingScreen(false);
            return
        }
        setLoadingScreen(false);
        navigate('/projetos');
        // eslint-disable-next-line
    }, []);

    function ForgotPasswordAction() {
        navigate('/recuperar-senha');
    }

    function RegisterAction() {
        navigate('/cadastrar');
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleGoogleSignIn = (response: CredentialResponse) => {
        if (!response.clientId || !response.credential) {
            setLoginFailed(true);
            return false;
        }
        let userObject: any = jwt_decode(response.credential);
        if (userObject.email === '' || userObject.jti === '') {
            setLoginFailed(true);
            return false;
        } else {
            setLoginFailed(false);
            socialLoginAction(userObject.email, userObject.jti);
        }
    }

    async function LoginAction() {
        if (!username || !password) {
            alert('Por favor, preencha os dados corretamente.');
        } else {
            setLoading(true);
            await signIn(
                username,
                password
            ).then((response) => {
                if (response) {
                    navigate('/projetos');
                    setUsername('');
                    setPassword('');
                    setLoading(false);
                } else {
                    alert('Erro no login');
                    setLoading(false);
                    setPassword('');
                }
            }).catch((error) => {
                alert('Erro no login');
                setLoading(false);
                setPassword('');
            });
        }
    }

    async function socialLoginAction(email: string, token: string) {
        setLoading(true);
        await signSocialIn(
            email,
            token
        ).then((response) => {
            if (response) {
                navigate('/projetos');
                setUsername('');
                setPassword('');
                setLoading(false);
            } else {
                alert('Erro no login');
                setLoading(false);
            }
        }).catch((error) => {
            alert('Erro no login');
            setLoading(false);
        });
    }

    useEffect(() => {
        if (gsiScriptLoaded) return

        const initializeGsi = () => {
            // Typescript will complain about window.google
            // Add types to your `react-app-env.d.ts` or //@ts-ignore it.
            //@ts-ignore it
            if (!window.google || gsiScriptLoaded) return

            setGsiScriptLoaded(true);

            //@ts-ignore it
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
                callback: handleGoogleSignIn,
            });
            //@ts-ignore it
            window.google.accounts.id.prompt(); // also display the One Tap dialog
        }

        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client"
        script.onload = initializeGsi
        script.async = true
        script.id = "google-client-script"
        document.querySelector("body")?.appendChild(script)

        return () => {
            // Cleanup function that runs when component unmounts
            //@ts-ignore it
            window.google?.accounts.id.cancel()
            document.getElementById("google-client-script")?.remove()
        }
        // eslint-disable-next-line
    }, []);



    return (
        <>
            {loadingScreen ? (
                <Box
                    sx={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress size={64} disableShrink thickness={3} />
                </Box>
            ) : (
                <Base>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className={styles.grid_auth_root}
                    >
                        <Grid item xs={12}>
                            <Typography>Bem-vindo!</Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.grid_input}>
                            <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
                                <TextField
                                    value={username}
                                    label='seu.email@email.com'
                                    type={'email'}
                                    disabled={loading}
                                    autoComplete="email"
                                    onChange={(value) => { setUsername(value.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={styles.grid_input}>
                            <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    value={password}
                                    label='Senha'
                                    disabled={loading}
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="current-password"
                                    onChange={(value) => { setPassword(value.target.value) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                disabled={loading}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <Button className={styles.link_space} onClick={ForgotPasswordAction} href="#recuperar-senha">
                                    Recuperar senha?
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={styles.grid_login}>
                            <LoadingButton
                                className={styles.button_login}
                                loading={loading}
                                loadingPosition="end"
                                endIcon={<SaveIcon className={styles.button_login_icon} />}
                                variant="outlined"
                                onClick={LoginAction}
                            >
                                Entrar
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} className={styles.grid_register}>
                            <Button
                                onClick={RegisterAction}
                                className={styles.button_create_account}
                            >
                                Criar Conta
                            </Button>
                        </Grid>
                        {(loginFailed === true) && (
                            <Grid item xs={12} className={styles.grid_google_auth_message}>
                                <Typography variant="h6" component="p">
                                    Não foi possível fazer login! Tente novamente.
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} className={styles.grid_google_auth_button}>
                            <Button disabled={loginFailed} className={"g_id_signin"} />
                        </Grid>
                    </Grid>
                </Base>
            )}
        </>
    );
}
