import * as React from 'react';
import useStyles from './styles';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

interface IProps {
    show: boolean
    status: 'error' | 'success' | 'warning' | 'info' | undefined
    message: string | undefined
    title: string | undefined
}

const AlertComponent = (props: IProps): JSX.Element => {
    const styles = useStyles();
    return (
        <Grid 
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={6}>
                {(props.show === true) && (
                    <Stack sx={{ width: '100%' }} spacing={2} className={styles.grid_root}>
                        {(props.status === 'error') && (
                            <Alert severity="error">
                                <AlertTitle>{props.title !== undefined ? `${props.title}` : 'Erro'}</AlertTitle>
                                {(props.message !== undefined ? props.message : '')}
                            </Alert>
                        )}
                        {(props.status === 'warning') && (
                            <Alert severity="warning">
                                <AlertTitle>{props.title !== undefined ? `${props.title}` : 'Alerta'}</AlertTitle>
                                {(props.message !== undefined ? props.message : '')}
                            </Alert>
                        )}
                        {(props.status === 'info') && (
                            <Alert severity="info">
                                <AlertTitle>{props.title !== undefined ? `${props.title}` : 'Informativo'}</AlertTitle>
                                {(props.message !== undefined ? props.message : '')}
                            </Alert>
                        )}
                        {(props.status === 'success') && (
                            <Alert severity="success">
                                <AlertTitle>{props.title !== undefined ? `${props.title}` : 'Sucesso'}</AlertTitle>
                                {(props.message !== undefined ? props.message : '')}
                            </Alert>
                        )}
                    </Stack>
                )}
            </Grid>
        </Grid>
    );
}

export default AlertComponent;