import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        backgroundColor: 'transparent',
        margin: '0px !important',
        padding: '0px !important',
    },
    grid_item: {
        backgroundColor: 'transparent',
    },
    view_select_views: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    button_select_views: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: '#cddee9',
        borderColor: '#06617c',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
    },
    button_select_views_actived: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: '#06617c',
        borderColor: '#06617c',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 2px',
        borderRadius: 10,
    },
    button_select_views_altitude: {
        width: 40,
        height: 40,
        flexDirection: 'column',
        backgroundColor: '#cddee9',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button_update_utm: {
        alignItems: 'flex-end',
        flexDirection: 'column',
        backgroundColor: '#FFF',
    },
    button_exam_input_localization_description: {
        flexDirection: 'column',
        marginRight: 20,
        backgroundColor: '#cddee9',
    },
    text_select_view: {
        color: '#06617c',
        flexDirection: 'column',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_select_views_actived: {
        color: '#FFFFFF',
        flexDirection: 'column',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'center',
        justifyContent: 'center',
    },
    container_body: {
        width: '100%',
        marginTop: 20,
        backgroundColor: '#cddee9',
    },
    view_first_route: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#cddee9',
        marginBottom: 100,
    },
    view_second_route: {
        width: '100%',
        backgroundColor: '#cddee9',
        marginBottom: 100,
    },
    view_projects: {
        width: '100%',
        backgroundColor: '#cddee9',
    },
    view_exams: {
        width: '100%',
        backgroundColor: '#cddee9',
        padding: '0px 10px',
    },
    text_about: {
        textAlign: 'left',
        backgroundColor: 'transparent',
        marginTop: 20,
        marginBottom: 10,
        paddingLeft: 30,
        paddingRight: 30,
        color: 'gray',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 20,
    },
    button_topics: {
        marginTop: 20,
        flexDirection: 'row',
        width: '80%',
        borderColor: '#06617c',
        borderWidth: 1,
        borderRadius: 15,
        backgroundColor: '#cddee9',
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    text_button_topics: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 0px',
        color: '#000000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    plus_icon: {
        textAlign: 'right',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#06617c',
        padding: '20px 0px',
        fontSize: 30,
        alignItems: 'flex-end',
    },
    button_logout: {
        marginTop: 20,
        marginRight: 30,
        flexDirection: 'row',
        width: 150,
        borderColor: '#06617c',
        padding: '5px 20px',
        borderWidth: 1,
        borderRadius: 15,
        backgroundColor: '#cddee9',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
    text_button_logout: {
        width: 90,
        textAlign: 'left',
        flexDirection: 'column',
        padding: '10px 0px',
        color: '#000000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-end',
    },
    logout_icon: {
        width: 30,
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#06617c',
        fontSize: 25,
        alignItems: 'center',
    },
    view_project: {
        width: 150,
        padding: '10px 10px',
        margin: '10x 10px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#cddee9',
        borderColor: '#06617c',
        borderWidth: 1,
        borderRadius: 15,
    },
    view_project_image: {
        width: 70,
        height: 70,
        backgroundColor: '#06617c',
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    project_img: {
        width: 46,
        height: 32,
    },
    view_project_name: {
        width: '100%',
    },
    project_name: {
        textAlign: 'center',
        padding: '10px 0px',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    view_group_image: {
        width: '100% !important',
        backgroundColor: '#06617c',
        borderRadius: '10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon_action_replace: {
        width: 40,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
    },
    group_name: {
        textAlign: 'center',
        padding: '10px 0px',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    view_list_groups: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 10px',
        backgroundColor: '#cddee9',
        marginBottom: 10,
    },
    view_groups_action: {
        marginTop: 30,
        flexDirection: 'row',
        width: '35%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
    },
    button_actions: {
        width: 40,
        flexDirection: 'column',
        backgroundColor: '#06617c',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25,
        padding: '5px',
    },
    action_icon: {
        width: 25,
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#FFF',
        fontSize: 30,
        alignItems: 'flex-end',
    },
    view_groups_list: {
        width: '100%',
        height: 100,
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 150,
        padding: '10px',
        backgroundColor: 'transparent',
    },
    view_group: {
        width: '100% !important',
        height: '64px !important',
        padding: '5px !important',
        margin: '0px 5px !important',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#cddee9 !important',
        borderColor: '#06617c !important',
        borderWidth: 1,
        borderRadius: '10px !important',
    },
    grid_button_groups_exam: {
        height: '44px !important',
    },
    group_img: {
        width: 46,
        height: 35,
    },
    view_group_name: {
        width: '100%',
        display: 'flex',
        paddingLeft: '10px !important',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    text_group_name: {
        textAlign: 'center',
        padding: '10px 0px !important',
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    view_invite: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#cddee9',
        marginBottom: 10,
    },
    view_invite_title: {
        textAlign: 'center',
        backgroundColor: 'transparent',
        marginTop: 10,
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        color: 'gray',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 20,
    },
    text_invite_title: {
        padding: '10px 0px',
        color: '#000000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'center',
    },
    view_group_users_list: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        margin: '10x 0px',
        padding: '10px',
        backgroundColor: 'transparent',
    },
    view_group_users_list_messages: {
        width: '100%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        backgroundColor: '#cddee9',
        marginBottom: 120,
    },
    button_group_users_list_messages: {
        marginTop: 10,
        width: 200,
        borderRadius: 12,
        backgroundColor: '#06617c',
        alignItems: 'center',
    },
    text_group_users_list_messages: {
        padding: '15px 0px',
        color: '#FFF',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    view_invite_people_circ: {
        width: 100,
    },
    view_invite_select_users: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        width: '100%',
        borderRadius: 5,
        padding: '8px 0px',
        marginTop: 10,
        fontSize: 24,
        alignItems: 'center',
        textAlign: 'center',
        borderWidth: 1,
    },
    picker_invite_select_users: {
        color: '#000',
        backgroundColor: 'transparent',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'serca-condensed-text',
        fontSize: 18,
    },
    picker_invite_select_users_item: {
        color: '#000',
        backgroundColor: 'transparent',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'serca-condensed-text',
        fontSize: 16,
    },
    view_invite_email: {
        width: '100%',
        marginBottom: 100,
    },
    input_email: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        width: '100%',
        borderRadius: 5,
        padding: '12px 0px',
        marginTop: 10,
        fontSize: 24,
        alignItems: 'center',
        textAlign: 'center',
        borderWidth: 1,
    },
    icon_show_hide_password: {
        flexDirection: 'column',
        color: '#000000',
        backgroundColor: '#FFF',
        width: 30,
        borderRadius: 5,
        textAlign: 'right',
        marginTop: '-10.5%',
        marginLeft: '85%',
        marginBottom: 25
    },
    view_author: {
        width: '100%',
        flexDirection: 'row',
        padding: '5px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        borderColor: '#CCC',
        borderWidth: 1,
        borderRadius: 15,
    },
    view_user: {
        flexDirection: 'row',
        padding: '10px',
        margin: '10px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        borderColor: '#CCC',
        borderWidth: 1,
        borderRadius: 15,
    },
    view_user_image: {
        width: 70,
        height: 70,
        backgroundColor: '#06617c',
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    user_img: {
        width: 45,
    },
    view_user_name: {
        width: '100%',
        alignItems: 'flex-start',
        padding: '0px 10px',
    },
    text_user_name: {
        width: '100%',
        flexDirection: 'row',
        paddingLeft: 10,
        color: '#000 !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    button_group_name: {
        width: '100%',
        marginTop: 20,
        flexDirection: 'row',
        borderRadius: 15,
        backgroundColor: 'transparent',
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    text_button_group_name: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 20px',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    back_icon: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#000',
        padding: '10px',
        fontSize: 25,
        alignItems: 'center',
    },
    view_list_exams: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 10px',
        backgroundColor: '#cddee9',
        marginBottom: 10,
    },
    view_exams_action: {
        flexDirection: 'row',
        width: '35%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        padding: '20px 0px',
    },
    view_exams_options: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        padding: '20px',
    },
    view_exams_list: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10,
        padding: '10px',
        backgroundColor: 'transparent',
    },
    view_grid_modal_replace_title: {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 70,
        padding: '10px',
        backgroundColor: 'transparent',
    },
    view_grid_modal_replace: {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    text_button_exam_name: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 20px',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    view_exam_exam: {
        width: '100%',
    },
    view_exam_date_and_type: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    view_examined_at: {
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'space-between'
    },
    view_examined_data: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center'
    },
    text_exam_create_date_title: {
        marginBottom: '10px !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    text_exam_type_title: {
        marginBottom: '10px !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    select_date: {
        flexDirection: 'column',
        width: '100%',
        height: 45,
        borderWidth: 1,
        borderColor: '#CCC',
        padding: '10px',
        borderRadius: 7,
        fontSize: 18,
        marginTop: 10,
        backgroundColor: '#FFFFFF',
    },
    button_examined_at: {
        backgroundColor: 'transparent',
        flexDirection: 'column',
        width: 40,
        height: 45,
        marginLeft: 10,
        marginTop: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    view_exam_exam_classification: {
        width: '100%',
        flexDirection: 'column',
        padding: '5px 0px',
    },
    view_exam_classification_data: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
    },
    text_exam_classification_order: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        fontWeight: 'bold',
        color: '#777',
    },
    text_exam_classification_group: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        fontWeight: 'bold',
        color: '#777',
    },
    text_exam_classification_date: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        fontWeight: 'bold',
        color: '#777',
    },
    button_exam_exam_classification: {
        backgroundColor: '#06617c',
        width: '100%',
        height: 30,
        alignItems: 'center',
        margin: '0px 80px',
        borderRadius: 8,
        justifyContent: 'center'
    },
    buttons_exam_sent: {
        backgroundColor: 'transparent',
    },
    text_exam_sent: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        fontWeight: 'bold',
        color: '#FFF',
    },
    icon_examined_at: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#06617c',
        padding: '5px 0px',
        fontSize: 30,
        alignItems: 'center',
    },
    exam_date: {
        width: '100%',
        flexDirection: 'column',
        padding: '10px 0px',
        borderRadius: 10,
        marginTop: 10,
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#06617c !important',
        }
    },
    picker_select_type: {
        width: '100%',
        flexDirection: 'column',
        padding: '10px 0px',
        borderRadius: 10,
        marginTop: 10,
        backgroundColor: '#FFF',
    },
    picker_select_default: {
        width: '100%',
        backgroundColor: 'transparent',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#06617c !important',
        }
    },
    text_exam_stoniness_title_list: {
        marginTop: 10,
        marginBottom: -10,
        fontWeight: 'bold',
        marginLeft: 70,
    },
    picker_select_list: {
        width: '100%',
        height: 50,
        flexDirection: 'column',
        marginLeft: 70,
        paddingLeft: 10,
        backgroundColor: 'transparent',
    },
    view_exam_localization: {
        width: '100%',
        padding: '10px',
    },
    text_exam_localization_title: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    text_exam_localization_input_title: {
        padding: '5px 0px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
        color: '#AFB2BD',
        backgroundColor: 'transparent',
    },
    text_exam_localization_input_sub_title: {
        padding: '5px 0px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        color: '#000',
        backgroundColor: 'transparent',
    },
    // map_view: {
    //     width: '100%',
    //     height: 400,
    //     margin: '10x 0px',
    //     borderRadius: 10,
    // },
    view_exam_alitude: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    view_exam_description_localization_button: {
        flexDirection: 'column',
        marginTop: 20,
    },
    button_description_localization_button: {
        flexDirection: 'row',
    },
    view_exam_input_base: {
        margin: '5x 0px',
    },
    text_exam_stoniness_title: {
        marginBottom: '10px !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    text_title_subtitle: {
        margin: '15px 0px 10px !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    view_exam_input: {
        backgroundColor: 'transparent',
        marginBottom: 10,
        padding: '0px 10px',
    },
    view_exam_input_utm: {
        height: 60,
        flexDirection: 'row',
        backgroundColor: '#FFF',
        borderRadius: 10,
        marginBottom: 10,
        padding: '0px 10px',
        alignItems: 'center',
        justifyContent: 'space-between',
        shadowOffset: {
            height: 5,
            width: 5
        }
    },
    text_exam_input: {
        marginTop: '10px !important',
        padding: '10px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    input_exam_input: {
        width: '100%',
        flexDirection: 'column',
        borderWidth: 1,
        borderRadius: 10,
        verticalAlign: 'top',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#06617c !important',
        }
    },
    input_exam_input_altitude: {
        flexDirection: 'column',
        width: '90%',
        borderColor: '#06617c',
        borderWidth: 1,
        padding: '10px',
        borderRadius: 10,
        backgroundColor: '#cddee9',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 2,
        fontSize: 20,
        color: '#06617c',
    },
    input_exam_input_localization_description: {
        width: '100%',
        minHeight: 120,
        textAlign: 'left',
        verticalAlign: 'top',
        alignItems: 'center',
        borderColor: '#CCC',
        borderWidth: 1,
        padding: '20px 15px',
        borderRadius: 10,
    },
    input_exam_input_utm: {
        width: 310,
        backgroundColor: '#FFFFFF',
        minHeight: 20,
        textAlign: 'left',
        verticalAlign: 'center',
        alignItems: 'flex-start',
        padding: '20px',
        borderRadius: 10,
    },
    input_exam_name: {
        width: '100%',
        minHeight: 120,
        textAlign: 'left',
        verticalAlign: 'top',
        alignItems: 'center',
        borderColor: '#CCC',
        borderWidth: 1,
        padding: '20px 15px',
        borderRadius: 10,
    },
    icon_exam_input_localization_description: {
        flexDirection: 'column',
    },
    text_exam_name: {
        padding: '5px 0px',
    },
    view_exam_description: {
        marginBottom: 70,
    },
    view_exam_description_buttons: {
        padding: '5px 10px !important',
        borderRadius: '10px !important',
        justifyContent: 'space-evenly',
    },
    view_list_projects: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 10px',
        backgroundColor: '#cddee9',
        marginBottom: 10,
    },
    view_projects_action: {
        marginTop: 10,
        flexDirection: 'row',
        width: '35%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
    },
    view_projects_list: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 70,
        padding: '10px',
        backgroundColor: 'transparent',
    },
    view_exam_horizon: {
        width: '100%',
        padding: '10px 0px',
        alignItems: 'center',
    },
    view_exam_horizon_home: {
    },
    view_exam_morphological: {
        width: '100%',
        flexDirection: 'row',
        padding: '10px 30px',
        alignItems: 'center',
    },
    view_exam_morphological_home: {
    },
    view_exam_physics: {
        width: '100%',
        padding: '10px 30px',
        alignItems: 'center',
    },
    view_exam_physics_home: {
    },
    view_exam_chemistry: {
        width: '100%',
        padding: '10px 30px',
        alignItems: 'center',
    },
    view_exam_chemistry_home: {
    },
    view_exam_horizon_actions: {
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    buttons_horizon: {
        color: '#FFF',
        width: 110,
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'column',
        padding: '40px 10px',
        borderRadius: 10,
        backgroundColor: '#CF7F44',
        alignItems: 'center',
        margin: '0px 5px',
    },
    buttons_horizon_active: {
        color: '#FFF',
        width: 110,
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'column',
        padding: '40px 10px',
        borderRadius: 10,
        backgroundColor: '#06617c',
        alignItems: 'center',
        margin: '0px 5px',
    },
    buttons_horizon_create: {
        height: 50,
        color: '#FFF',
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'column',
        padding: '15px 20px !important',
        borderRadius: 10,
        backgroundColor: '#06617c',
        alignItems: 'center',
        margin: '0px 5px',
    },
    buttons_horizon_create_icon: {
        height: 50,
        color: '#FFF',
        flexDirection: 'column',
        padding: '10px 20px',
        borderRadius: 10,
        backgroundColor: '#06617c',
        alignItems: 'center',
        margin: '0px 5px',
    },
    text_horizon: {
        textAlign: 'center',
        flexDirection: 'column',
        color: '#FFF',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'center',
    },
    view_exam_button_morphological_analysis: {
        width: '100%',
        padding: '10px 30px',
        alignItems: 'center',
    },
    button_morphological_analysis: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
        width: '100%',
        padding: '10px',
        borderRadius: 12,
        backgroundColor: '#06617c',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    text_morphological_analysis: {
        textAlign: 'center',
        flexDirection: 'column',
        color: '#FFF',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 20,
        alignItems: 'center',
    },
    view_box_out: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        justifyContent: 'space-around',
        marginBottom: 20,
    },
    view_box_out_slim: {
        flexDirection: 'row',
        marginBottom: 15,
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    view_box_out_buttons: {
        width: '10%',
        flexDirection: 'column',
    },
    view_box_out_buttons_altitude: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    button_box_out_buttons: {
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'space-around',
    },
    view_box: {
        width: '90%',
        flexDirection: 'row',
        borderRadius: 10,
        padding: '10px',
        backgroundColor: '#F1F2F4',
    },
    view_box_orange: {
        width: 60,
        height: 60,
        flexDirection: 'column',
        backgroundColor: '#06617c',
        borderRadius: 15,
        marginRight: 10,
    },
    view_box_content: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    view_box_content_altitude: {
        width: 130,
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    view_box_content_utm_input: {
        width: '90%',
        flexDirection: 'column',
        backgroundColor: '#FFF',
    },
    view_box_content_utm_button: {
        width: '10%',
        flexDirection: 'column',
        backgroundColor: '#FFF',
    },
    view_box_full: {
        width: '100%',
        
        flexDirection: 'row',
        borderRadius: 10,
        paddingBottom: '10px',
        backgroundColor: 'transparent',
    },
    view_box_content_full: {
        width: '100%',
        flexDirection: 'column',
    },
    map: {
        position: 'relative'
    },
    map_fullscreen: {
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: '100%',
    },
    map_fullscreen_container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#cddee9',
        elevation: 20
    },
    button_map_view: {
        marginTop: '10px !important',
        padding: '0px !important',
    },
    map_view: {
        width: '100%',
        opacity: 0.95
    },
    map_full: {
        height: '100%',
        borderRadius: 20,
    },
    fullscreen_map_btn: {
        backgroundColor: 'transparent',
        position: 'absolute',
        bottom: 10,
        right: 10,
        padding: 15,
        elevation: 3,
        alignItems: 'center',
        alignSelf: 'flex-end',
        justifyContent: 'center',
        borderRadius: 50
    },
    button_register: {
        width: '100%',
        color: '#FFFFFF',
        borderRadius: 5,
        backgroundColor: '#CC6B3B',
        marginTop: 20,
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon_map_view: {
        width: 270,
        height: 270,
        backgroundColor: '#CDCDCDBB',
        position: 'absolute',
        alignItems: 'center',
        margin: '50px',
        opacity: 1,
        borderRadius: 150,
    },
    view_replaces_group_project: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 10px',
    },
    text_replaces_group_project: {
        padding: '10px 0px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    view_centered: {
        width: '100%',
        justifyContent: "flex-end",
        alignItems: "center",
        opacity: 0.55,
        backgroundColor: 'transparent',
        marginBottom: 5,
        margin: '0px 5px',
    },
    view_centered_content: {
        width: '100%',
        opacity: 0.85,
        backgroundColor: '#CDCDCD',
        alignItems: "center",
        justifyContent: "center",
    },
    modal_modal: {
        width: '100%',
        marginBottom: 5,
        margin: '0px 5px',
        backgroundColor: '#cddee9',
        opacity: 1,
    },
    modal_view: {
        opacity: 1,
        width: '100%',
        backgroundColor: "#cddee9",
        borderRadius: 20,
        padding: '20px 30px',
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        marginTop: 20,
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    view_box_buttons: {
        marginTop: '50px !important',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center'
    },
    button_update: {
        width: 120,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "#06617c",
        padding: '10px',
        margin: '0px 20px',
        borderRadius: 15
    },
    button_close: {
        width: 120,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "#CDCDCD",
        padding: '10px',
        margin: '0px 20px',
        borderRadius: 15
    },
    text_style: {
        color: "#000",
        fontWeight: "bold",
        textAlign: "center"
    },
    text_description: {
        width: '100%',
        minHeight: 120,
        textAlign: 'left',
        verticalAlign: 'top',
        alignItems: 'flex-start',
        padding: '20px 0px',
    },
    text_button_update: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#FFF",
        fontWeight: "bold",
        textAlign: "center"
    },
    text_button_close: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000",
        fontWeight: "bold",
        textAlign: "center"
    },
    modal_text: {
        marginBottom: 15,
        textAlign: "center"
    },
    view_system_specialist_modal: {
        width: '100%',
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: 'transparent',
        marginBottom: 5,
        margin: '0px 5px',
    },
    view_system_specialist_modal_content: {
        width: '100%',
        opacity: 0.95,
        backgroundColor: '#cddee9',
        alignItems: "center",
        justifyContent: "center",
    },
    view_system_specialist_modal_box_content: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: "space-evenly",
    },
    view_system_specialist_modal_spacing_1: {
        height: 15,
    },
    view_system_specialist_modal_spacing_2: {
        height: 30,
    },
    text_data_title_exam: {
        flexDirection: 'column',
        padding: '10px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 0.65,
        color: 'gray',
        fontSize: 18,
    },
    text_data_title_classification: {
        flexDirection: 'column',
        padding: '10px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 0.65,
        color: 'gray',
        fontSize: 16,
    },
    text_data_title_project: {
        flexDirection: 'column',
        padding: '10px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 24,
    },
    text_data_title_type: {
        width: 200,
        textAlign: "right",
        flexDirection: 'column',
        padding: '10px 15px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        color: 'gray',
        fontSize: 18,
    },
    text_data_title_value: {
        width: 200,
        textAlign: "left",
        flexDirection: 'column',
        padding: '10px 15px',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        fontWeight: 'bold',
    },
    button_save_exam: {
        width: '100% !important',
        height: '100% !important',
        alignItems: 'center',
        justifyContent: "center",
        backgroundColor: "#06617c !important",
        borderRadius: '10px !important'
    },
    text_save_exam: {
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: "center",
        color: '#FFF !important',
    },
});

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default useStyles;