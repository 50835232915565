import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_physical_value: {
        backgroundColor: 'transparent',
    },
    view_physical_value_title: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
    },
    text_physical_value: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000000 !important",
        fontWeight: "bold",
        textAlign: "center",
        alignItems: 'center',
    },
    grid_item_name_horizon: {
        marginTop: '20px !important',
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        textAlign: "center",
        alignItems: 'center',
        justifyContent: 'center',
    },
    view_physical_value_content: {
        display: 'flex',
        backgroundColor: 'transparent',
        marginBottom: 10,
        justifyContent: 'center',
    },
    typography_input_data_value: {
        color: '#06617c !important',
        backgroundColor: 'transparent',
        borderRadius: 10,
        marginBottom: '20px !important',
        textAlign: 'center',
        verticalAlign: 'center',
        justifyContent: 'center',
    },
    grid_input_data_value: {
        margin: '10px 0px !important',
        backgroundColor: 'transparent',
        borderRadius: 10,
        textAlign: 'center',
        verticalAlign: 'center',
        justifyContent: 'center',
    },
    input_data_value: {
        backgroundColor: 'transparent',
        borderRadius: 10,
        textAlign: 'center',
        verticalAlign: 'center',
        justifyContent: 'center',
        '& .MuiOutlinedInput-input': {
            textAlign: 'center',
            color: '#06617c !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #06617c !important',
        }
    },
    icon_calculator: {
        color: '#06617c !important',
        margin: '5px 10px !important',
        verticalAlign: 'center',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
});

export default useStyles;