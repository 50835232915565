import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        backgroundColor: 'transparent',
    },
    grid_datagrid: {
        marginTop: '15px !important',
        height: 'auto',
    },
    paper: {
        width: '100%',
    },
    datagrid: {
        minHeight: '634px',
    },
    title: {
        margin: '10px 0px 20px 0px'
    },
    data_is_empty: {
        width: '100%',
        display: 'flex',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '50px !important',
    },
    button_create: {
        display: 'flex',
        borderRadius: '10px !important',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        border: '1px solid #06617c !important',
        alignItems: 'center',
        marginBottom: '10px !important',
    },
    grid_group_item: {
        color: '#000000 !important',
        cursor: 'pointer !important',
        '&:hover': {
            color: '#06617c !important',
        }
    },
});

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default useStyles;