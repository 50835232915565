import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_root: {
        width: '100% !important',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    view_project: {
        width: '100% !important',
        padding: '5px !important',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: '#cddee9 !important',
        borderColor: '#06617c !important',
        borderWidth: 1,
        borderRadius: 10,
    },
    view_project_image: {
        width: '50px !important',
        height: '50px !important',
        flexDirection: 'column',
        backgroundColor: '#06617c',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    project_img: {
        width: 38,
    },
    view_project_name: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px 10px',
    },
    view_icon_trash: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    project_name: {
        textAlign: 'center',
        padding: '10px 0px',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
        alignItems: 'flex-start',
    },
    icon_trash: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#06617c',
        padding: '10px',
        fontSize: 20,
        alignItems: 'center',
    },
});

export default useStyles;