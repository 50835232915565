import React from 'react';
import useStyles from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ISystemLogsProject,
    ISystemLogsExams,
    ISystemLogsGroups,
    ISystemLogsHorizon,
    ISystemLogsChemical,
    ISystemLogsPhysical,
} from '../../types';
import ShowLoader from '../../components/ShowLoaderComponent';
import { formatDateToLocaleString } from '../../formatters';
import { useAuth } from "../../contexts/Auth/AuthProvider";
import apiService from '../../services/apiService';
import AuthBase from '../../components/AuthBase';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button } from '@mui/material';

export default function UserLogsPage() {
    let { userId } = useParams();
    const { user } = useAuth();
    const styles = useStyles();
    const navigate = useNavigate();

    const [projectLog, setProjectLog] = React.useState<Array<ISystemLogsProject> | undefined>(undefined);
    const [examsLog, setExamsLog] = React.useState<Array<ISystemLogsExams> | undefined>(undefined);
    const [groupsLog, setGroupsLog] = React.useState<Array<ISystemLogsGroups> | undefined>(undefined);
    const [horizonLog, setHorizonLog] = React.useState<Array<ISystemLogsHorizon> | undefined>(undefined);
    const [chemicalLog, setChemicalLog] = React.useState<Array<ISystemLogsChemical> | undefined>(undefined);
    const [physicalLog, setPhysicalLog] = React.useState<Array<ISystemLogsPhysical> | undefined>(undefined);

    const [loader, setLoader] = React.useState<boolean>(false);

    const columnsProject: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsProject) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            selector: (row: ISystemLogsProject) => row.description,
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsProject) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Nome do Projeto',
            cell: (row: ISystemLogsProject) => (
                <>
                    <Typography>
                        {row.project?.name}
                    </Typography>
                </>
            ),
            ignoreRowClick: true,
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsProject) => (
                <>
                    <Typography>
                        {row.author?.name}
                    </Typography>
                </>
            ),
            ignoreRowClick: true,
        }
        // eslint-disable-next-line
    ], []);

    const columnsExams: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsExams) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            selector: (row: ISystemLogsExams) => row.description,
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsExams) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Nome do Exame',
            cell: (row: ISystemLogsExams) => (
                <>
                    <Typography>
                        {row.exam?.name}
                    </Typography>
                </>
            ),
            ignoreRowClick: true,
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsExams) => (
                <>
                    <Typography>
                        {row.author?.name}
                    </Typography>
                </>
            ),
            ignoreRowClick: true,
        }
        // eslint-disable-next-line
    ], []);

    const columnsGroups: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsGroups) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            selector: (row: ISystemLogsGroups) => row.description,
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsGroups) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Nome do Grupo',
            cell: (row: ISystemLogsGroups) => (
                <>
                    <Typography>
                        {row.group?.name}
                    </Typography>
                </>
            ),
            ignoreRowClick: true,
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsGroups) => (
                <>
                    <Typography>
                        {row.author?.name}
                    </Typography>
                </>
            ),
            ignoreRowClick: true,
        }
        // eslint-disable-next-line
    ], []);

    const columnsHorizons: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsHorizon) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            selector: (row: ISystemLogsHorizon) => row.description,
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsHorizon) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Nome do Horizonte',
            cell: (row: ISystemLogsHorizon) => (
                <>
                    <Typography>
                        {row.horizon?.name}
                    </Typography>
                </>
            ),
            ignoreRowClick: true,
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsHorizon) => (
                <>
                    <Typography>
                        {row.author?.name}
                    </Typography>
                </>
            ),
            ignoreRowClick: true,
        }
        // eslint-disable-next-line
    ], []);

    const columnsChemical: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsChemical) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            selector: (row: ISystemLogsChemical) => row.description,
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsChemical) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsChemical) => (
                <>
                    <Typography>
                        {row.author?.name}
                    </Typography>
                </>
            ),
            ignoreRowClick: true,
        }
        // eslint-disable-next-line
    ], []);

    const columnsPhysical: TableColumn<any>[] = React.useMemo(() => [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: ISystemLogsPhysical) => row.id,
        },
        {
            sortable: true,
            name: 'Descrição',
            selector: (row: ISystemLogsPhysical) => row.description,
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: ISystemLogsPhysical) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            sortable: true,
            name: 'Autor da Modificação',
            width: '200px',
            cell: (row: ISystemLogsPhysical) => (
                <>
                    <Typography>
                        {row.author?.name}
                    </Typography>
                </>
            )
        }
        // eslint-disable-next-line
    ], []);

    function goToBack() {
        // eslint-disable-next-line
        navigate(-1);
    }

    const getUserLog = async (userId: number) => {
        if (user !== undefined) {
            setLoader(true);
            try {
                await apiService.get(
                    'users/logs',
                    { user_id: userId }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setProjectLog(response.data.data.projects);
                        setExamsLog(response.data.data.exams);
                        setHorizonLog(response.data.data.horizons);
                        setChemicalLog(response.data.data.chemicals);
                        setPhysicalLog(response.data.data.physicals);
                        setGroupsLog(response.data.data.groups);
                        setLoader(false);
                    } else {
                        console.log('getUserLog-response-error', response);
                        setProjectLog([]);
                        setExamsLog([]);
                        setHorizonLog([]);
                        setChemicalLog([]);
                        setPhysicalLog([]);
                        setGroupsLog([]);
                        setLoader(false);
                    }
                }).catch((error) => {
                    console.log('getUserLog-error', error.response);
                    setProjectLog([]);
                    setExamsLog([]);
                    setHorizonLog([]);
                    setChemicalLog([]);
                    setPhysicalLog([]);
                    setGroupsLog([]);
                    setLoader(false);
                });
            } catch (e) {
                console.log('getUserLog-error', e);
                setProjectLog([]);
                setExamsLog([]);
                setHorizonLog([]);
                setChemicalLog([]);
                setPhysicalLog([]);
                setGroupsLog([]);
                setLoader(false);
            }
        }
    }

    React.useEffect(() => {
        if (userId !== null && userId !== undefined) {
            getUserLog(parseInt(userId));
        }
        // eslint-disable-next-line
    }, [userId]);

    if (userId !== null && userId !== undefined) {
        return (
            <AuthBase>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    className={styles.grid_root}
                >
                    <Grid item xs={12} className={styles.grid_title}>
                        <Typography>Dados Cadastrados</Typography>
                    </Grid>
                    <Grid item xs={1} className={styles.grid_item_container}>
                        <Button className={styles.button_back_page} onClick={goToBack}>Voltar</Button>
                    </Grid>
                    <Grid item xs={12} className={styles.grid_item_container}>
                        {(loader === true) && (
                            <ShowLoader message='' />
                        )}
                    </Grid>
                    <Grid item xs={12} className={styles.grid_item_container_log}>
                        <Paper className={styles.paper}>
                            {(projectLog !== undefined && projectLog !== [] && projectLog.length > 0 && loader === false) && (
                                <DataTable
                                    title="Log dos Projetos"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsProject}
                                    className={styles.datatable}
                                    data={projectLog}
                                />
                            )}
                        </Paper>
                        {(projectLog !== undefined && projectLog === [] && loader === false) && (
                            <Typography className={styles.data_is_empty}>Usuário não possui nenhum registro de Projeto...</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} className={styles.grid_item_container_log}>
                        <Paper className={styles.paper}>
                            {(examsLog !== undefined && examsLog !== [] && examsLog.length > 0 && loader === false) && (
                                <DataTable
                                    title="Log dos Exames"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsExams}
                                    className={styles.datatable}
                                    data={examsLog}
                                />
                            )}
                        </Paper>
                        {(examsLog !== undefined && examsLog.length === 0 && loader === false) && (
                            <Typography className={styles.data_is_empty}>Usuário não possui nenhum registro de Exame...</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} className={styles.grid_item_container_log}>
                        <Paper className={styles.paper}>
                            {(groupsLog !== undefined && groupsLog !== [] && groupsLog.length > 0 && loader === false) && (
                                <DataTable
                                    title="Log de Grupos"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsGroups}
                                    className={styles.datatable}
                                    data={groupsLog}
                                />
                            )}
                        </Paper>
                        {(groupsLog !== undefined && groupsLog.length === 0 && loader === false) && (
                            <Typography className={styles.data_is_empty}>Usuário não possui nenhum registro de Grupo...</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} className={styles.grid_item_container_log}>
                        <Paper className={styles.paper}>
                            {(horizonLog !== undefined && horizonLog !== [] && horizonLog.length > 0 && loader === false) && (
                                <DataTable
                                    title="Log dos Horizontes"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsHorizons}
                                    className={styles.datatable}
                                    data={horizonLog}
                                />
                            )}
                        </Paper>
                        {(horizonLog !== undefined && horizonLog.length === 0 && loader === false) && (
                            <Typography className={styles.data_is_empty}>Usuário não possui nenhum dado de registro de Horizonte...</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} className={styles.grid_item_container_log}>
                        <Paper className={styles.paper}>
                            {(physicalLog !== undefined && physicalLog !== [] && physicalLog.length > 0 && loader === false) && (
                                <DataTable
                                    title="Log dos dados de Física"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsPhysical}
                                    className={styles.datatable}
                                    data={physicalLog}
                                />
                            )}
                        </Paper>
                        {(columnsPhysical !== undefined && columnsPhysical.length === 0 && loader === false) && (
                            <Typography className={styles.data_is_empty}>Usuário não possui nenhum dado de registro de Física...</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} className={styles.grid_item_container_log}>
                        <Paper className={styles.paper}>
                            {(chemicalLog !== undefined && chemicalLog !== [] && chemicalLog.length > 0 && loader === false) && (
                                <DataTable
                                    title="Log dos dados de Química"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columnsChemical}
                                    className={styles.datatable}
                                    data={chemicalLog}
                                />
                            )}
                        </Paper>
                        {(chemicalLog !== undefined && chemicalLog.length === 0 && loader === false) && (
                            <Typography className={styles.data_is_empty}>Usuário não possui nenhum dado de registro de Química...</Typography>
                        )}
                    </Grid>
                </Grid>
            </AuthBase>
        );
    } else {
        return (<ShowLoader message='' />);
    }
}
