import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_horizon_limit: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'transparent',
    },
    view_horizon_limit_title: {
        backgroundColor: 'transparent',
        marginTop: '10px !important',
        marginBottom: '10px !important',
    },
    text_horizon_limit: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000000",
        fontWeight: "bold",
        textAlign: "center"
    },
    view_horizon_limit_subtitle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_horizon_limit_subtitle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_check_item: {
        width: '100%',
        color: "#000000 !important",
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    view_horizon_limit_content: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginBottom: 10,
        justifyContent: 'space-around',
    },
    view_horizon_limit_min_content: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    view_horizon_limit_max_content: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    grid_input_data_limit: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    input_data_limit: {
        width: '200px',
        height: '55px',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        borderRadius: 10,
        textAlign: 'center',
        verticalAlign: 'center',
        justifyContent: 'center',
        '& .MuiOutlinedInput-input': {
            textAlign: 'center',
            color: '#06617c !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #06617c !important',
        }
    },
    button_checkobox_limit_min_content: {
        width: '100%',
        height: '55px',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginBottom: 10,
    },
    button_checkobox_limit_max_content: {
        width: '100%',
        height: '55px',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginBottom: 5,
    },
    button_checkobox_limit_infinity_content: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginBottom: 5,
    },
    view_horizon_extra_view: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        marginTop: '10px !important',
        marginBottom: '20px !important',
        justifyContent: 'space-around',
    },
});

export default useStyles;