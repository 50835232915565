import React, { useCallback, useEffect, useState } from 'react';
import useStyles, { BootstrapDialog } from './styles';
import {
    IRequestData,
    IFilterHorizons,
    IFilterExams,
    IExam,
    IGroup,
    IProject,
    IGeolocationCoordinate
} from '../../types';
import {
    formatDate,
} from '../../formatters';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import apiService from '../../services/apiService';
import ShowLoader from '../../components/ShowLoaderComponent';
import ProjectItemList from '../../components/ItemListComponent/ProjectItemList';
import GroupItemList from '../../components/ItemListComponent/GroupItemList';
import BootstrapDialogTitle from '../../components/BootstrapDialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Multiselect from 'multiselect-react-dropdown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
// import { toast } from 'react-toastify';
import useAutoSave from '../../hooks/autoSave';

const mapView = require('../../assets/images/btns/map_view.png');
const iconMap = require('../../assets/images/btns/gps.png');
const iconMapError = require('../../assets/images/btns/gps_error.png');
const photoProfile = require('../../assets/images/tab_bar/profile.png');

const containerStyle = {
    width: '400px',
    height: '400px'
};

interface IReceiptProps {
    show: boolean
    examId: string | undefined
    examData: IExam | undefined
    examFiltersData: IFilterExams | undefined
    horizonFiltersData: IFilterHorizons | undefined
    groupsData: Array<IGroup> | undefined
    projectsData: Array<IProject> | undefined
}

export default function ExamDetailsComponent(props: IReceiptProps) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
    });
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [location, setLocation] = useState({
        lat: -3.745,
        lng: -38.523
    });
    const [, setMap] = useState<google.maps.Map | null>(null)
    const [date, setDate] = useState<Date | null>(new Date());
    const [exam, setExam] = useState<IExam | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(undefined);
    const [optionsErosionFilter, setOptionsErosionFilter] = React.useState<Array<any> | undefined>(undefined);
    const [currentLocation, setCurrentLocation] = useState<any | undefined>(undefined);
    const [currentBrowserLocation, setCurrentBrowserLocation] = useState<IGeolocationCoordinate | undefined>(undefined);
    const [viewModalUpdateNameExam, setViewModalUpdateNameExam] = useState<boolean>(false);
    const [viewReplaceGroup, setViewReplaceGroup] = useState<boolean>(false);
    const [viewReplaceProject, setViewReplaceProject] = useState<boolean>(false);
    const [loaderMapView, setLoaderMapView] = useState<boolean>(false);
    const [errorName, setErrorName] = useState<string | undefined>(undefined);
    const [lockData, setlockData] = React.useState<boolean>(false);
    const [examinedAt, setExaminedAt] = useState<string | undefined>(undefined);
    const [groupNameByExam, setGroupNameByExam] = useState<string | undefined>(undefined);
    const [projectNameByExam, setProjectNameByExam] = useState<string | undefined>(undefined);
    const [examType, setExamType] = useState<string | undefined>(undefined);
    const [examLocalizationSuccess, setExamLocalizationSuccess] = useState<boolean | undefined>(undefined);
    const [examLocalizationLatitude, setExamLocalizationLatitude] = useState<any | undefined>(undefined);
    const [examLocalizationLongitude, setExamLocalizationLongitude] = useState<any | undefined>(undefined);
    const [examLocalizationDescription, setExamLocalizationDescription] = useState<string | undefined>(undefined);
    const [examAltitude, setExamAltitude] = useState<string | undefined>(undefined);
    const [examDeclivity, setExamDeclivity] = useState<string | undefined>(undefined);
    const [examLithologyChronology, setExamLithologyChronology] = useState<string | undefined>(undefined);
    const [examSourceMatter, setExamSourceMatter] = useState<string | undefined>(undefined);
    const [examCurrentUsage, setExamCurrentUsage] = useState<string | undefined>(undefined);
    const [examUtmCoordinates, setExamUtmCoordinates] = useState<string | undefined>(undefined);
    const [examStonyness, setExamStonyness] = useState<string | undefined>(undefined);
    const [examRockiness, setExamRockiness] = useState<string | undefined>(undefined);
    const [examLocalRelief, setExamLocalRelief] = useState<string | undefined>(undefined);
    const [examDescribedBy, setExamDescribedBy] = useState<string | undefined>(undefined);
    const [examRegionalReliefStep1, setExamRegionalReliefStep1] = useState<string | undefined>(undefined);
    const [examRegionalReliefStep2, setExamRegionalReliefStep2] = useState<string | undefined>(undefined);
    const [examErosion, setExamErosion] = useState<Array<any>>([]);
    const [examDrainage, setExamDrainage] = useState<string | undefined>(undefined);
    const [examPrimaryVegetation, setExamPrimaryVegetation] = useState<any[]>([]);
    const [loaderBoxReplace, setLoaderBoxReplace] = useState<boolean>(false);
    const [loaderBox, setLoaderBox] = useState<boolean>(false);
    const [messageLoader, setMessageLoader] = useState<string | undefined>(undefined);
    const CreateRequestData = useCallback((examId?: string, exam_data?: IExam) => {
        let request_data: IRequestData = {};
        if (examId && exam_data) {
            request_data['exam_id'] = parseInt(examId);
            if (examinedAt !== undefined && examinedAt !== 'undefined') {
                let tmp_examinedAt = `${examinedAt.split('/')[2]}-${examinedAt.split('/')[1]}-${examinedAt.split('/')[0]}`;
                if (tmp_examinedAt !== "undefined-undefined-" && tmp_examinedAt !== "NaN-NaN-NaN") {
                    request_data['examined_at'] = `${tmp_examinedAt}T12:00:00.000000Z`;
                }
            }
            if (examType !== undefined && examType !== null) {
                request_data['type'] = `${examType}`;
            }
            if (examLocalizationLatitude !== undefined && examLocalizationLatitude !== null) {
                request_data['localization_latitude'] = `${examLocalizationLatitude}`;
            }
            if (examLocalizationLongitude !== undefined && examLocalizationLongitude !== null) {
                request_data['localization_longitude'] = `${examLocalizationLongitude}`;
            }
            if (examAltitude !== undefined && `${exam_data.altitude}` !== examAltitude) {
                request_data['altitude'] = `${examAltitude}`;
            }
            if (examLocalizationDescription !== undefined && examLocalizationDescription !== null) {
                request_data['localization_description'] = examLocalizationDescription;
            }
            if (examDeclivity !== undefined && examDeclivity !== null) {
                request_data['declivity'] = examDeclivity;
            }
            if (examLithologyChronology !== undefined) {
                request_data['lithology_chronology'] = examLithologyChronology;
            }
            if (examSourceMatter !== undefined) {
                request_data['source_subject'] = examSourceMatter;
            }
            if (examCurrentUsage !== undefined) {
                request_data['current_usage'] = examCurrentUsage;
            }
            if (examUtmCoordinates !== undefined) {
                request_data['utm_coordinates'] = `${examUtmCoordinates}`;
            }
            if (examStonyness !== undefined && examStonyness !== null) {
                request_data['stonyness'] = `${(examStonyness === 'Selecione' ? '0' : examStonyness)}`;
            }
            if (examRockiness !== undefined && examRockiness !== null) {
                request_data['rockiness'] = `${(examRockiness === 'Selecione' ? '0' : examRockiness)}`;
            }
            if (examLocalRelief !== undefined && examLocalRelief !== null) {
                request_data['relief_local'] = `${(examLocalRelief === 'Selecione' ? '0' : examLocalRelief)}`;
            }
            if (examRegionalReliefStep1 !== undefined && examRegionalReliefStep1 !== null) {
                request_data['relief_regional_one'] = `${(examRegionalReliefStep1 === 'Selecione' ? '0' : examRegionalReliefStep1)}`;
            }
            if (examRegionalReliefStep2 !== undefined && examRegionalReliefStep2 !== null) {
                request_data['relief_regional_two'] = `${(examRegionalReliefStep2 === 'Selecione' ? '0' : examRegionalReliefStep2)}`;
            }
            if (
                examErosion !== null &&
                examErosion !== undefined &&
                props.examFiltersData !== undefined &&
                props.examFiltersData.erosion !== undefined &&
                examErosion.length > 0
            ) {
                let getErosionData: Array<string> = [];
                props.examFiltersData.erosion.forEach((elementRoot) => {
                    elementRoot.children.forEach((elementItem: { name: any; id: any; }) => {
                        examErosion.forEach((element) => {
                            if (element.group_name === elementRoot.name && element.data_name === elementItem.name) {
                                getErosionData.push(`${elementItem.id}`);
                            }
                        })
                    })
                });

                if (getErosionData.length > 0) {
                    request_data['erosion'] = `${getErosionData.join(',')}`;
                }
            }
            if (examDrainage !== undefined && examDrainage !== null && exam_data.drainage !== examDrainage) {
                request_data['drainage'] = `${examDrainage}`;
            }
            if (
                examPrimaryVegetation !== null &&
                examPrimaryVegetation !== undefined &&
                `${examPrimaryVegetation.join(',')}` !== '' &&
                `${examPrimaryVegetation.join(',')}` !== 'selecione'
            ) {
                request_data['primary_vegetation'] = `${examPrimaryVegetation.join(',')}`;
            }
            if (examDescribedBy !== undefined && examDescribedBy !== null && exam_data.describe_by !== examDescribedBy) {
                request_data['describe_by'] = `${examDescribedBy}`;
            }
        }
        return request_data;
    }, [
        examAltitude,
        examCurrentUsage,
        examDeclivity,
        examDescribedBy,
        examDrainage,
        examErosion,
        examLithologyChronology,
        examLocalRelief,
        examLocalizationDescription,
        examLocalizationLatitude,
        examLocalizationLongitude,
        examPrimaryVegetation,
        examRegionalReliefStep1,
        examRegionalReliefStep2,
        examRockiness,
        examSourceMatter,
        examStonyness,
        examType,
        examUtmCoordinates,
        examinedAt,
        props.examFiltersData
    ]);

    const [RequestData, setRequestData] = useState<IRequestData>();

    useEffect(() => {
        if (props.examId && exam) {
            setRequestData(CreateRequestData(props.examId, exam));
        }
    }, [CreateRequestData, props.examId, exam])
  
    const delayedSave = useCallback(async () => {
        return await apiService.put(
            'exams',
            RequestData
        ).then(() => {
            setlockData(false);
        }).catch((error) => {
            console.log('ERROR: ', error.response);
            setlockData(false);
        });
    }, [RequestData]);

    useAutoSave<IRequestData>(
        'general_description', RequestData, delayedSave
    );

    function setStateLoader(status: boolean, message: string | undefined = undefined) {
        if (status === true) {
            if (message !== undefined) {
                setMessageLoader(message);
            }
            setLoaderBox(true);
        } else {
            setLoaderBox(false);
            setMessageLoader(undefined);
        }
    }

    function validateUpdateName() {
        let error = true;
        setErrorName(undefined);
        if (name === '') {
            error = false;
            setErrorName('Preencha o nome do exame corretamente.');
        }
        return error;
    }

    function showReplaceGroup() {
        setViewReplaceGroup(!viewReplaceGroup);
        setViewReplaceProject(false);
    }

    function showReplaceProject() {
        setViewReplaceProject(!viewReplaceProject);
        setViewReplaceGroup(false);
    }

    function onChangeSelectType(itemValue: any) {
        setExamType(itemValue as string);
    }

    function handleCloseReplaceProject() {
        setViewReplaceProject(false);
        setLoaderBoxReplace(false);
    }

    function handleCloseReplaceGroup() {
        setViewReplaceGroup(false);
        setLoaderBoxReplace(false);
    }

    function onChangeExaminedAt(date: unknown) {
        let currentDate: any = '';
        if (date !== undefined && date !== null) {
            currentDate = date;
            if (!currentDate) {
                return;
            }
            setDate(currentDate);
            setExaminedAt(formatDate(currentDate));
        } else {
            currentDate = exam?.created_at;
            if (!currentDate) {
                return;
            }
            setDate(currentDate);
            setExaminedAt(currentDate);
        }
    }

    // function onChangeLocation(e: MapEvent<{}>) {
    //     const lat = e.nativeEvent.coordinate.latitude as number;
    //     const long = e.nativeEvent.coordinate.longitude as number;
    //     const newLocation = {
    //         latitude: lat,
    //         longitude: long,
    //         latitudeDelta: 0.002,
    //         longitudeDelta: 0.003,
    //     }
    //     setCurrentLocation(newLocation);
    //     setExamLocalizationLatitude(lat);
    //     setExamLocalizationLongitude(long);
    //     setExamUtmCoordinates(`${lat}, ${long}`)
    //     getLocalization();
    //     updateExam();
    // }

    // function updateDataUTM() {
    //     let utmData: any = examUtmCoordinates?.split(',');
    //     setExamLocalizationLatitude((utmData[0]) as string);
    //     setExamLocalizationLongitude(utmData[1] as string);
    //     updateExam();
    // }

    function onChangeSelectStonyness(itemValue: string) {
        setExamStonyness(itemValue);
    }

    function onChangeSelectRockiness(itemValue: string) {
        setExamRockiness(itemValue);
    }

    function onChangeSelectLocalRelief(itemValue: string) {
        setExamLocalRelief(itemValue);
    }

    function onChangeSelectRegionalReliefStep1(itemValue: string) {
        setExamRegionalReliefStep1(itemValue);
    }

    function onChangeSelectRegionalReliefStep2(itemValue: string) {
        setExamRegionalReliefStep2(itemValue);
    }

    function onChangeSelectDrainage(itemValue: string) {
        setExamDrainage(itemValue);
    }

    function onSelectedPrimaryVegetationItemsChange(event: SelectChangeEvent<any[]>) {
        setExamPrimaryVegetation([event.target.value]);
    };

    const onLoad = React.useCallback(function callback(map: google.maps.Map | null) {
        if (map !== null) {
            const bounds = new window.google.maps.LatLngBounds(location);
            map.fitBounds(bounds);
            setMap(map);
        }
    }, [location]);

    const onLoadMarker = () => {
        // console.log('asdasdasd')
    }

    const onUnmount = React.useCallback(function callback(map: google.maps.Map | null) {
        setMap(null);
    }, []);

    function handlePositionReceived(data: any) {
        if (
            data !== undefined && data.coords !== undefined &&
            data.coords.latitude !== undefined && data.coords.longitude !== undefined
        ) {
            setCurrentBrowserLocation({
                lat: data.coords?.latitude,
                lng: data.coords?.longitude,
                altitude: 0.005
            });
            setLocation({
                lat: data.coords?.latitude,
                lng: data.coords?.longitude
            });
        }
    }

    async function addGroupInExam(group: IGroup) {
        if (group !== undefined && props.examId !== undefined && auth !== null && auth.user !== undefined) {
            setLoaderBoxReplace(true);
            try {
                await apiService.put(
                    'exams',
                    {
                        exam_id: parseInt(props.examId),
                        group_id: group.id
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setGroupNameByExam(group.name);
                    }
                    handleCloseReplaceGroup();
                }).catch((error) => {
                    console.log(error);
                    handleCloseReplaceGroup();
                });
            } catch (e) {
                console.log('ERROR: ', e);
                handleCloseReplaceGroup();
            }
        }
    }

    async function addProjectInExam(project: IProject) {
        if (project !== undefined && props.examId !== undefined && auth !== null && auth.user !== undefined) {
            setLoaderBoxReplace(true);
            try {
                await apiService.put(
                    'exams',
                    {
                        exam_id: parseInt(props.examId),
                        project_id: project.id
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setProjectNameByExam(project.name);
                        handleCloseReplaceProject()
                    }
                    setViewReplaceProject(false);
                }).catch((error) => {
                    console.log(error);
                    handleCloseReplaceProject()
                });
            } catch (e) {
                console.log('ERROR: ', e);
                handleCloseReplaceProject()
            }
        }
    }

    async function getLocalization() {
        if (currentLocation === undefined && props.examId !== undefined && exam !== undefined) {
            setLoaderMapView(true);
            if (
                exam.localization_latitude !== null &&
                exam.localization_latitude !== undefined &&
                exam.localization_longitude !== null &&
                exam.localization_longitude !== undefined
            ) {
                setCurrentLocation({
                    latitude: exam.localization_latitude,
                    longitude: exam.localization_longitude,
                    latitudeDelta: exam.altitude,
                    longitudeDelta: exam.altitude,
                });
                setExamLocalizationSuccess(true);
            } else if (
                exam.localization_latitude === undefined &&
                exam.localization_longitude === undefined &&
                currentBrowserLocation !== undefined
            ) {
                setCurrentLocation({
                    latitude: currentBrowserLocation.lat,
                    longitude: currentBrowserLocation.lng,
                    latitudeDelta: currentBrowserLocation.altitude,
                    longitudeDelta: currentBrowserLocation.altitude
                });
                setExamUtmCoordinates(`${exam.localization_latitude}, ${exam.localization_longitude}`);
            } else {
                setCurrentLocation({
                    latitude: -23.54317999489955,
                    longitude: -46.631247103214264,
                    latitudeDelta: 0.002,
                    longitudeDelta: 0.003,
                });
            }
            setExamLocalizationSuccess(true);
        } else {
            console.log('Erro no retorno da localização', exam?.utm_coordinates);
        }
        setLoaderMapView(false);
    }

    async function requestUpdateNameExam() {
        if (props.examId !== undefined && validateUpdateName() === true) {
            setStateLoader(true, `Atualizando o exame '${name}'...`);
            try {
                await apiService.put(
                    'exams',
                    {
                        exam_id: parseInt(props.examId),
                        name: name
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        getExame();
                    }
                    setViewModalUpdateNameExam(false);
                    setStateLoader(false);
                }).catch((error) => {
                    setViewModalUpdateNameExam(false);
                    setStateLoader(false);
                });
            } catch (e) {
                setViewModalUpdateNameExam(false);
                setStateLoader(false);
            }
        }
    }

    // async function updateExam() {
    //     if (auth !== null && auth.user !== undefined && exam !== undefined && props.examId !== undefined) {

    //         // Verificar registros e enviar para a API
    //         let request_data = CreateRequestData(props.examId, exam);
    //         try {
    //             setlockData(true);
    //             await apiService.put(
    //                 'exams',
    //                 request_data
    //             ).then(() => {
    //                 toast.success('Autlizado com sucesso.', {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //                 setlockData(false);
    //             }).catch((error) => {
    //                 console.log('ERROR: ', error.response);
    //                 setlockData(false);
    //             });
    //         } catch (e) {
    //             console.log('ERROR: ', e);
    //             setlockData(false);
    //         }
    //     }
    // }

    async function getExame() {
        if (auth !== null && auth.user !== undefined && exam !== undefined && props.examId !== undefined) {
            try {
                await apiService.get(
                    'exams/details',
                    { exam_id: parseInt(props.examId) }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setExam(response.data.data as IExam);
                    }
                }).catch((error) => {
                    console.log('getExame: ', error.response);
                });
            } catch (e) {
                console.log('getExame: ', e);
            }
        }
    }

    React.useEffect(() => {
        (async () => {
            if (props.show === true && props.examData !== undefined && props.examFiltersData !== undefined) {
                setExam(props.examData);
                let TmpExaminedAt: any = '';
                if (props.examData.examined_at !== null && props.examData.examined_at !== undefined) {
                    TmpExaminedAt = new Date(`${props.examData.examined_at.split('T')[0]}T12:00:00.000000Z`);
                } else if (props.examData.created_at !== null && props.examData.created_at !== undefined) {
                    TmpExaminedAt = new Date(props.examData.created_at);
                }

                setName(props.examData.name);
                setExaminedAt(formatDate(TmpExaminedAt));
                setDate(TmpExaminedAt);

                if (
                    props.examData.group !== undefined && props.examData.group !== null &&
                    props.examData.group.name !== undefined && props.examData.group.name !== null
                ) {
                    setGroupNameByExam(props.examData.group.name);
                }
                if (
                    props.examData.project !== undefined && props.examData.project !== null &&
                    props.examData.project.name !== undefined && props.examData.project.name !== null
                ) {
                    setProjectNameByExam(props.examData.project.name);
                }
                if (props.examData.type !== undefined && props.examData.type !== null) {
                    setExamType(props.examData.type);
                }
                if (props.examData.localization_latitude !== undefined && props.examData.localization_latitude !== null) {
                    setExamLocalizationLatitude(props.examData.localization_latitude);
                }
                if (props.examData.localization_longitude !== undefined && props.examData.localization_longitude !== null) {
                    setExamLocalizationLongitude(props.examData.localization_longitude);
                }
                if (
                    props.examData.utm_coordinates === undefined &&
                    props.examData.localization_latitude !== undefined &&
                    props.examData.localization_latitude !== null &&
                    props.examData.localization_longitude !== undefined &&
                    props.examData.localization_longitude !== null
                ) {
                    setExamUtmCoordinates(`${examLocalizationLatitude}, ${examLocalizationLongitude}`);
                } else if (props.examData.utm_coordinates !== undefined && props.examData.utm_coordinates !== null) {
                    setExamUtmCoordinates(props.examData.utm_coordinates);
                }
                if (props.examData.localization_description !== undefined && props.examData.localization_description !== null) {
                    setExamLocalizationDescription(props.examData.localization_description);
                }
                if (props.examData.altitude !== undefined && props.examData.altitude !== null) {
                    setExamAltitude(props.examData.altitude!.toString());
                } else {
                    setExamAltitude('0');
                }
                if (props.examData.declivity !== undefined && props.examData.declivity !== null) {
                    setExamDeclivity(props.examData.declivity);
                }
                if (props.examData.lithology_chronology !== undefined && props.examData.lithology_chronology !== null) {
                    setExamLithologyChronology(props.examData.lithology_chronology);
                }
                if (props.examData.source_subject !== undefined && props.examData.source_subject !== null) {
                    setExamSourceMatter(props.examData.source_subject);
                }
                if (props.examData.stonyness !== undefined && props.examData.stonyness !== null) {
                    setExamStonyness(props.examData.stonyness);
                }
                if (props.examData.rockiness !== undefined && props.examData.rockiness !== null) {
                    setExamRockiness(props.examData.rockiness);
                }
                if (props.examData.relief_local !== undefined && props.examData.relief_local !== null) {
                    setExamLocalRelief(props.examData.relief_local);
                }
                if (props.examData.relief_regional_one !== undefined && props.examData.relief_regional_one !== null) {
                    setExamRegionalReliefStep1(props.examData.relief_regional_one);
                }
                if (props.examData.relief_regional_two !== undefined && props.examData.relief_regional_two !== null) {
                    setExamRegionalReliefStep2(props.examData.relief_regional_two);
                }
                if (
                    props.examFiltersData.erosion !== undefined &&
                    props.examData.erosion !== undefined && props.examData.erosion !== null &&
                    props.examData.erosion !== '' && props.examData.erosion.split(',').length > 0
                ) {
                    let filtered: Array<any> = [];
                    props.examFiltersData?.erosion?.forEach((elementRoot) => {
                        elementRoot.children?.forEach((elementItem: { id: string | number; name: any; }) => {
                            props.examData?.erosion?.split(',').forEach((element) => {
                                if (element !== undefined && element !== null && element !== '' && `${element}` === `${elementItem.id}`) {
                                    filtered[filtered.length] = {
                                        'group_name': elementRoot.name,
                                        'data_name': elementItem.name,
                                    };
                                }
                            })
                        })
                    });
                    setExamErosion(filtered);
                } else {
                    setExamErosion([]);
                }
                if (props.examData.drainage !== undefined && props.examData.drainage !== null) {
                    setExamDrainage(props.examData.drainage);
                }
                if (props.examData.primary_vegetation !== undefined && props.examData.primary_vegetation !== null && props.examData.primary_vegetation !== 'selecione') {
                    setExamPrimaryVegetation([props.examData.primary_vegetation]);
                } else {
                    setExamPrimaryVegetation(['selecione']);
                }
                if (props.examData.current_usage !== undefined && props.examData.current_usage !== null) {
                    setExamCurrentUsage(props.examData.current_usage);
                }
                if (props.examData.describe_by !== undefined && props.examData.describe_by !== null) {
                    setExamDescribedBy(props.examData.describe_by);
                }
            }
        })();
        // eslint-disable-next-line
    }, [props.examData]);

    useEffect(() => {
        if (props.examFiltersData !== undefined && props.examFiltersData.erosion !== undefined && optionsErosionFilter === undefined) {
            let opt: Array<any> = [];
            props.examFiltersData.erosion.forEach((elementRoot) => {
                elementRoot.children.forEach((elementItem: { name: any; id: any; }) => {
                    opt[opt.length] = {
                        'group_name': elementRoot.name,
                        'data_name': elementItem.name,
                    };
                });
            });
            setOptionsErosionFilter(opt);
        }
        // eslint-disable-next-line
    }, [props.examFiltersData]);

    useEffect(() => {
        navigator.geolocation.watchPosition(handlePositionReceived);
    }, []);

    if (props.show === true) {
        return (
            <Grid container className={styles.grid_root}>
                <Grid item xs={12} className={styles.grid_item}>
                    {(loaderBox === true) && (
                        <ShowLoader message={messageLoader} />
                    )}
                    {(exam !== undefined) ? (
                        <Grid container className={styles.view_exam_exam}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4} className={styles.view_exam_description_buttons}>
                                            <Button onClick={showReplaceGroup} className={styles.view_group}>
                                                <Grid container className={styles.grid_button_groups_exam}>
                                                    <Grid item xs={3} className={styles.view_group_image}>
                                                        <img className={styles.icon_action_replace} alt={`${groupNameByExam}`} src={require('../../assets/images/tab_bar/team.png')} />
                                                    </Grid>
                                                    <Grid item xs={9} className={styles.view_group_name}>
                                                        <Typography className={styles.text_group_name}>
                                                            {(
                                                                groupNameByExam !== undefined && groupNameByExam !== '' ?
                                                                    groupNameByExam :
                                                                    (
                                                                        props.examData?.group?.name !== undefined && props.examData?.group?.name !== '' ?
                                                                            props.examData?.group?.name :
                                                                            '--------------------'
                                                                    )
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4} className={styles.view_exam_description_buttons}>
                                            <Button onClick={showReplaceProject} className={styles.view_group}>
                                                <Grid container className={styles.grid_button_groups_exam}>
                                                    <Grid item xs={3} className={styles.view_group_image}>
                                                        <img className={styles.icon_action_replace} alt={`${projectNameByExam}`} src={require('../../assets/images/tab_bar/projects.png')} />
                                                    </Grid>
                                                    <Grid item xs={9} className={styles.view_group_name}>
                                                        <Typography className={styles.text_group_name}>
                                                            {(
                                                                projectNameByExam !== undefined && projectNameByExam !== '' ?
                                                                    projectNameByExam :
                                                                    (
                                                                        props.examData?.project?.name !== undefined && props.examData?.project?.name !== '' ?
                                                                            props.examData?.project?.name :
                                                                            '--------------------'
                                                                    )
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} className={styles.view_exam_localization}>
                                <Grid container>
                                    <Grid item xs={12} className={styles.view_exam_localization}>
                                        <Typography className={styles.text_exam_create_date_title}>
                                            Data de Criação
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className={styles.exam_date}
                                                value={date}
                                                disableFuture={true}
                                                inputFormat="dd/MM/yyyy"
                                                mask="__/__/____"
                                                disabled={lockData}
                                                onChange={(date: unknown) => {
                                                    onChangeExaminedAt(date);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className={styles.exam_date}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} className={styles.view_exam_localization}>
                                        <Typography className={styles.text_exam_type_title}>
                                            Tipo do Exame
                                        </Typography>
                                        <TextField
                                            className={styles.picker_select_default}
                                            value={examType === undefined ? 'SELECIONE' : examType}
                                            select
                                            disabled={lockData}
                                            defaultValue={'SELECIONE'}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                onChangeSelectType(event.target.value)
                                            }
                                        >
                                            <MenuItem className={styles.picker_invite_select_users_item} key={'0-examType'} value={'SELECIONE'}>Selecione</MenuItem>
                                            <MenuItem className={styles.picker_invite_select_users_item} key={'1-examType'} value={`Trincheira`}>Trincheira</MenuItem>
                                            <MenuItem className={styles.picker_invite_select_users_item} key={'2-examType'} value={`Tradagem`}>Tradagem</MenuItem>
                                            <MenuItem className={styles.picker_invite_select_users_item} key={'3-examType'} value={`Barranco`}>Barranco</MenuItem>
                                        </TextField>
                                    </Grid>
                                    {(props.examFiltersData === undefined) && (
                                        <ShowLoader message={'Carregando...'} />
                                    )}
                                    {(props.examFiltersData !== undefined) && (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12} className={styles.view_exam_localization}>
                                                    <Grid item xs={12} className={styles.view_box_content}>
                                                        <Typography className={styles.text_exam_stoniness_title}>
                                                            Pedregosidade
                                                        </Typography>
                                                        <TextField
                                                            className={styles.picker_select_default}
                                                            value={examStonyness === undefined ? 'Selecione' : examStonyness}
                                                            select
                                                            disabled={lockData}
                                                            defaultValue={'Selecione'}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                onChangeSelectStonyness(`${event.target.value}`)
                                                            }
                                                        >
                                                            <MenuItem className={styles.picker_invite_select_users_item} key={'0-examStonyness'} value={'Selecione'}>Selecione</MenuItem>
                                                            {(props.examFiltersData!.stonyness.map((element, key) => {
                                                                return (
                                                                    <MenuItem className={styles.picker_invite_select_users_item} key={`${key + 1}-examStonyness`} value={`${key + 1}`}>{`${element}`}</MenuItem>
                                                                );
                                                            }))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={styles.view_exam_localization}>
                                                <Grid item xs={12} className={styles.view_box_content}>
                                                    <Typography className={styles.text_exam_stoniness_title}>
                                                        Rochosidade
                                                    </Typography>
                                                    <TextField
                                                        className={styles.picker_select_default}
                                                        value={examRockiness === undefined ? 'Selecione' : examRockiness}
                                                        select
                                                        disabled={lockData}
                                                        defaultValue={'Selecione'}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                            onChangeSelectRockiness(`${event.target.value}`)
                                                        }
                                                    >
                                                        <MenuItem className={styles.picker_invite_select_users_item} key={`0-examRockiness`} value={'Selecione'}>Selecione</MenuItem>
                                                        {(props.examFiltersData!.rockiness.map((element, key) => {
                                                            return (
                                                                <MenuItem className={styles.picker_invite_select_users_item} key={`${key + 1}-examRockiness`} value={`${key + 1}`}>{`${element}`}</MenuItem>
                                                            );
                                                        }))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={styles.view_exam_localization}>
                                                <Grid className={styles.view_box_full}>
                                                    <Grid className={styles.view_box_content}>
                                                        <Typography className={styles.text_exam_stoniness_title}>
                                                            Relevo Local
                                                        </Typography>
                                                        <TextField
                                                            className={styles.picker_select_default}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                onChangeSelectLocalRelief(`${event.target.value}`)
                                                            }
                                                            select
                                                            disabled={lockData}
                                                            defaultValue={'Selecione'}
                                                            value={examLocalRelief === undefined ? 'Selecione' : examLocalRelief}
                                                        >
                                                            <MenuItem className={styles.picker_invite_select_users_item} key={'0-examLocalRelief'} value={'Selecione'}>Selecione</MenuItem>
                                                            {(props.examFiltersData!.relief.map((element, key) => {
                                                                return (
                                                                    <MenuItem className={styles.picker_invite_select_users_item} key={`${key + 1}-examLocalRelief`} value={`${key + 1}`}>{`${element}`}</MenuItem>
                                                                );
                                                            }))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={styles.view_exam_localization}>
                                                <Grid className={styles.view_box_full}>
                                                    <Grid className={styles.view_box_content}>
                                                        <Typography className={styles.text_exam_stoniness_title}>
                                                            Relevo Regional 1
                                                        </Typography>
                                                        <TextField
                                                            className={styles.picker_select_default}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                onChangeSelectRegionalReliefStep1(`${event.target.value}`)
                                                            }
                                                            select
                                                            disabled={lockData}
                                                            defaultValue={'Selecione'}
                                                            value={examRegionalReliefStep1 === undefined ? 'Selecione' : examRegionalReliefStep1}
                                                        >
                                                            <MenuItem className={styles.picker_invite_select_users_item} key={'0-examRegionalReliefStep1'} value={'Selecione'}>Selecione</MenuItem>
                                                            {(props.examFiltersData!.relief.map((element, key) => {
                                                                return (
                                                                    <MenuItem className={styles.picker_invite_select_users_item} key={`${key + 1}-examRegionalReliefStep1`} value={`${key + 1}`}>{`${element}`}</MenuItem>
                                                                );
                                                            }))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={styles.view_exam_localization}>
                                                <Grid className={styles.view_box_full}>
                                                    <Grid className={styles.view_box_content}>
                                                        <Typography className={styles.text_exam_stoniness_title}>
                                                            Relevo Regional 2
                                                        </Typography>
                                                        <TextField
                                                            className={styles.picker_select_default}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                onChangeSelectRegionalReliefStep2(`${event.target.value}`)
                                                            }
                                                            select
                                                            disabled={lockData}
                                                            defaultValue={'Selecione'}
                                                            value={examRegionalReliefStep2 === undefined ? 'Selecione' : examRegionalReliefStep2}
                                                        >
                                                            <MenuItem className={styles.picker_invite_select_users_item} key={'0-examRegionalReliefStep2'} value={'Selecione'}>Selecione</MenuItem>
                                                            {(props.examFiltersData!.relief.map((element, key) => {
                                                                return (
                                                                    <MenuItem className={styles.picker_invite_select_users_item} key={`${key + 1}-examRegionalReliefStep2`} value={`${key + 1}`}>{`${element}`}</MenuItem>
                                                                );
                                                            }))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={styles.view_exam_localization}>
                                                <Grid className={styles.view_box_full}>
                                                    <Grid className={styles.view_box_content}>
                                                        <Typography className={styles.text_exam_stoniness_title}>
                                                            Erosão
                                                        </Typography>
                                                        {(props.examFiltersData!.erosion === undefined) && (
                                                            <ShowLoader message={'Carregando...'} />
                                                        )}
                                                        {(props.examFiltersData!.erosion !== undefined) && (
                                                            <FormControl sx={{ width: '100%' }}>
                                                                <Multiselect
                                                                    id="css_custom"
                                                                    displayValue="data_name"
                                                                    groupBy="group_name"
                                                                    options={optionsErosionFilter}
                                                                    showCheckbox
                                                                    selectedValues={examErosion}
                                                                    style={{
                                                                        chips: {
                                                                            "background": "#06617c"
                                                                        },
                                                                        searchBox: {
                                                                            "border": "1px solid #06617c",
                                                                            "border-radius": "5px",
                                                                            "display": "flex",
                                                                            "height": "56px",
                                                                        },
                                                                        multiselectContainer: {
                                                                            "color": "#000",
                                                                            "font-size": "1rem"
                                                                        }
                                                                    }}
                                                                    onRemove={(selectedList, selectedItem) => {
                                                                        setExamErosion(selectedList as any[]);
                                                                    }}
                                                                    onSelect={(selectedList, selectedItem) => {
                                                                        setExamErosion(selectedList as any[]);
                                                                    }}
                                                                    emptyRecordMsg={"Nenhuma erosão selecionada"}
                                                                    placeholder={"Selecione"}
                                                                    loading={lockData}
                                                                />
                                                            </FormControl>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={styles.view_exam_localization}>
                                                <Grid className={styles.view_box_full}>
                                                    <Grid className={styles.view_box_content}>
                                                        <Typography className={styles.text_exam_stoniness_title}>
                                                            Drenagem
                                                        </Typography>
                                                        <TextField
                                                            className={styles.picker_select_default}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                onChangeSelectDrainage(`${event.target.value}`)
                                                            }
                                                            select
                                                            disabled={lockData}
                                                            defaultValue={'Selecione'}
                                                            value={examDrainage === undefined ? 'Selecione' : examDrainage}
                                                        >
                                                            <MenuItem className={styles.picker_invite_select_users_item} key={'0-examRockiness'} value={'Selecione'}>Selecione</MenuItem>
                                                            {(props.examFiltersData!.drainage.map((element, key) => {
                                                                return (
                                                                    <MenuItem className={styles.picker_invite_select_users_item} key={`${key + 1}-examRockiness`} value={`${key + 1}`}>{`${element}`}</MenuItem>
                                                                );
                                                            }))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={styles.view_exam_localization}>
                                                <Grid container className={styles.view_box_full}>
                                                    <Grid item xs={12} className={styles.view_box_content}>
                                                        <Typography className={styles.text_exam_stoniness_title}>
                                                            Vegetação Primária
                                                        </Typography>
                                                        {(props.examFiltersData!.primary_vegetation === undefined) && (
                                                            <ShowLoader message={'Carregando...'} />
                                                        )}
                                                        {(props.examFiltersData!.primary_vegetation !== undefined) && (
                                                            <FormControl sx={{ width: '100%' }}>
                                                                <Select
                                                                    native
                                                                    className={styles.picker_select_default}
                                                                    disabled={lockData}
                                                                    value={examPrimaryVegetation}
                                                                    onChange={onSelectedPrimaryVegetationItemsChange}
                                                                >
                                                                    <option aria-label="None" key={`0-examPrimaryVegetation`} value="selecione">
                                                                        <em>Selecione</em>
                                                                    </option>
                                                                    {props.examFiltersData!.primary_vegetation.map((element, key) => {
                                                                        return (
                                                                            <optgroup key={`${element?.id}-examPrimaryVegetation`} label={`${element?.name}`}>
                                                                                {element.children?.map((elementChildren: any) => {
                                                                                    return (
                                                                                        <option key={`${elementChildren?.id}-examPrimaryVegetation1`} value={`${elementChildren?.id}`}>
                                                                                            {elementChildren?.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </optgroup>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={4} className={styles.view_exam_localization}>
                                <Grid container>
                                    <Grid item xs={12} className={styles.view_exam_input}>
                                        <Typography className={styles.text_exam_input}>
                                            Situação e Declividade
                                        </Typography>
                                        <TextField
                                            className={styles.input_exam_input}
                                            value={examDeclivity}
                                            placeholder='Declividade'
                                            multiline={true}
                                            minRows={3}
                                            disabled={lockData}
                                            onChange={(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                setExamDeclivity(value.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={styles.view_exam_input}>
                                        <Typography className={styles.text_exam_input}>
                                            Litologia e Cronologia
                                        </Typography>
                                        <TextField
                                            className={styles.input_exam_input}
                                            value={examLithologyChronology}
                                            placeholder='Litologia e Cronologia'
                                            multiline={true}
                                            minRows={3}
                                            disabled={lockData}
                                            onChange={(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                setExamLithologyChronology(value.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={styles.view_exam_input}>
                                        <Typography className={styles.text_exam_input}>
                                            Material de Origem
                                        </Typography>
                                        <TextField
                                            className={styles.input_exam_input}
                                            value={examSourceMatter}
                                            placeholder='Material de Origem'
                                            multiline={true}
                                            minRows={3}
                                            disabled={lockData}
                                            onChange={(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                setExamSourceMatter(value.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={styles.view_exam_input}>
                                        <Typography className={styles.text_exam_input}>
                                            Uso Atual
                                        </Typography>
                                        <TextField
                                            className={styles.input_exam_input}
                                            value={examCurrentUsage}
                                            multiline={true}
                                            minRows={3}
                                            disabled={lockData}
                                            placeholder='Uso Atual'
                                            onChange={(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                setExamCurrentUsage(value.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={styles.view_exam_input}>
                                        <Typography className={styles.text_exam_input}>
                                            Descrito por:
                                        </Typography>
                                        <TextField
                                            className={styles.input_exam_input}
                                            value={examDescribedBy}
                                            multiline={true}
                                            minRows={3}
                                            disabled={lockData}
                                            placeholder='Pessoas que descreveram este solo'
                                            onChange={(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                setExamDescribedBy(value.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} className={styles.view_exam_localization}>
                                <Grid container className={styles.view_exam_localization}>
                                    <Grid item xs={12} className={styles.view_exam_localization}>
                                        <Typography className={styles.text_exam_localization_title}>
                                            Localização
                                        </Typography>
                                        {(loaderMapView === true) && (
                                            <ShowLoader message={messageLoader} />
                                        )}
                                        {(loaderMapView !== true) && (
                                            <>
                                                {(examLocalizationSuccess !== true && currentLocation === undefined) && (
                                                    <Button onClick={getLocalization} className={styles.button_map_view}>
                                                        <>
                                                            <img alt="Localização" className={styles.map_view} src={mapView} />
                                                            {(examLocalizationSuccess !== false) && (
                                                                <img alt="Localização" className={styles.icon_map_view} src={iconMap} />
                                                            )}
                                                            {(examLocalizationSuccess === false) && (
                                                                <img alt="Localização" className={styles.icon_map_view} src={iconMapError} />
                                                            )}
                                                        </>
                                                    </Button>
                                                )}
                                                {(examLocalizationSuccess === true && currentLocation !== undefined) && (
                                                    <Grid className={styles.map}>
                                                        {(isLoaded) && (
                                                            <GoogleMap
                                                                mapContainerStyle={containerStyle}
                                                                center={{
                                                                    lat: currentLocation.latitude,
                                                                    lng: currentLocation.longitude
                                                                }}
                                                                zoom={10}
                                                                onLoad={onLoad}
                                                                onUnmount={onUnmount}
                                                                mapTypeId={'satellite'}
                                                            >
                                                                <Marker
                                                                    onLoad={onLoadMarker}
                                                                    position={{
                                                                        lat: currentLocation.latitude,
                                                                        lng: currentLocation.longitude
                                                                    }}
                                                                />
                                                            </GoogleMap>
                                                        )}
                                                    </Grid>
                                                )}
                                                {(
                                                    examLocalizationSuccess === true && currentLocation === undefined && currentBrowserLocation !== undefined &&
                                                    currentBrowserLocation.lat !== null && currentBrowserLocation.lng !== null
                                                ) && (
                                                        <Grid className={styles.map}>
                                                            {/*
                                                            onClick={(e) => onChangeLocation(e)}
                                                            <Marker coordinate=
                                                                latitude: currentLocation.latitude,
                                                                longitude: currentLocation.longitude,
                                                             />
                                                        */}
                                                            {(isLoaded) && (
                                                                <GoogleMap
                                                                    mapContainerStyle={containerStyle}
                                                                    center={{
                                                                        lat: currentBrowserLocation.lat,
                                                                        lng: currentBrowserLocation.lng
                                                                    }}
                                                                    zoom={10}
                                                                    onLoad={onLoad}
                                                                    onUnmount={onUnmount}
                                                                    mapTypeId={'satellite'}
                                                                >
                                                                    {/* <Marker position={currentLocation}/> */}
                                                                </GoogleMap>
                                                            )}
                                                        </Grid>
                                                    )}
                                                {(examLocalizationSuccess === true && currentLocation === undefined) && (
                                                    <Grid className={styles.map}>
                                                        <Typography className={styles.input_exam_input_altitude}>
                                                            Localização não adquirido
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={styles.view_exam_input}>
                                    <Typography className={styles.text_exam_input}>
                                        Coordenadas (Graus decimais, DD)
                                    </Typography>
                                    <TextField
                                        className={styles.input_exam_input}
                                        value={examUtmCoordinates}
                                        placeholder='Coordenadas (Graus decimais, DD)'
                                        disabled={lockData}
                                        onChange={(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            setExamUtmCoordinates(value.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={styles.view_exam_input}>
                                    <Typography className={styles.text_exam_input}>
                                        Altitude
                                    </Typography>
                                    <TextField
                                        className={styles.input_exam_input}
                                        value={examAltitude}
                                        placeholder='Altitude'
                                        disabled={lockData}
                                        onChange={(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            setExamAltitude(value.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={styles.view_exam_input}>
                                    <Typography className={styles.text_exam_input}>
                                        Descrição da Localização
                                    </Typography>
                                    <TextField
                                        className={styles.input_exam_input}
                                        value={examLocalizationDescription}
                                        placeholder='Detalhe a localização'
                                        disabled={lockData}
                                        multiline={true}
                                        minRows={3}
                                        onChange={(value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            setExamLocalizationDescription(value.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container className={styles.view_author}>
                                    <Grid item xs={12}>
                                        <Typography className={styles.text_title_subtitle}>
                                            Adicionado por:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={styles.view_group_name}>
                                        <Grid container>
                                            <Grid item xs={2} className={styles.view_group_image}>
                                                <img alt={`${exam.author?.name}`} className={styles.user_img} src={photoProfile} />
                                            </Grid>
                                            <Grid item xs={10} className={styles.view_group_name}>
                                                <Typography className={styles.text_user_name}>
                                                    {exam.author?.name}
                                                </Typography>
                                                <Typography className={styles.text_user_name}>
                                                    {exam.author?.email}
                                                </Typography>
                                                <Typography className={styles.text_user_name}>
                                                    {exam.author?.job_position}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <ShowLoader message={messageLoader} />
                    )}
                    <BootstrapDialog
                        onClose={() => setViewModalUpdateNameExam(false)}
                        aria-labelledby="customized-dialog-title"
                        open={viewModalUpdateNameExam}
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setViewModalUpdateNameExam(false)}>
                            Alterar Nome do Grupo
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid container className={styles.modal_view}>
                                <Grid item xs={12} className={styles.view_box_content}>
                                    <Typography className={styles.text_project_input}>
                                        Tem certeza que deseja excluir as mensagens do grupo '{name}'?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className={styles.view_box_content}>
                                    <TextField
                                        className={styles.input_project_name}
                                        value={name}
                                        label='Nome do Grupo'
                                        onChange={(value) => setName(value.target.value)}
                                        error={errorName !== undefined ? true : false}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button className={styles.button_close} onClick={() => setViewModalUpdateNameExam(false)}>
                                <Typography className={styles.text_button_close}>Voltar</Typography>
                            </Button>
                            <Button className={styles.button_update} onClick={() => requestUpdateNameExam()}>
                                <Typography className={styles.text_button_update}>Atualizar</Typography>
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                    <BootstrapDialog
                        onClose={() => handleCloseReplaceGroup()}
                        aria-labelledby="customized-dialog-title"
                        open={viewReplaceGroup}
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleCloseReplaceGroup()}>
                            Trocar de Grupo
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            {(
                                loaderBoxReplace !== true &&
                                exam !== undefined &&
                                props.groupsData !== undefined &&
                                props.groupsData.length > 0
                            ) && (
                                    <Grid container>
                                        <Grid item xs={12} className={styles.view_grid_modal_replace_title}>
                                            <Typography className={styles.text_replaces_group_project}>
                                                Para trocar o grupo {exam.group?.name}, selecione um outro.
                                            </Typography>
                                        </Grid>
                                        {props.groupsData.map((element, key) => {
                                            return (
                                                <Grid item xs={12} className={styles.view_grid_modal_replace}>
                                                    <GroupItemList
                                                        key={`gropu-${element.id}-${element.created_at}`}
                                                        data={element}
                                                        action={addGroupInExam}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                )}
                            {(loaderBoxReplace === true && auth !== undefined) && (
                                <ShowLoader message={messageLoader} />
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseReplaceGroup()}>
                                Cancelar
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                    <BootstrapDialog
                        onClose={() => handleCloseReplaceProject()}
                        aria-labelledby="customized-dialog-title"
                        open={viewReplaceProject}
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleCloseReplaceProject()}>
                            Trocar de Projeto
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            {(
                                loaderBoxReplace !== true &&
                                exam !== undefined &&
                                props.projectsData !== undefined &&
                                props.projectsData.length > 0
                            ) && (
                                    <Grid container>
                                        <Grid item xs={12} className={styles.view_grid_modal_replace_title}>
                                            <Typography className={styles.text_replaces_group_project}>
                                                Para trocar o projeto {exam.project?.name}, selecione um outro.
                                            </Typography>
                                        </Grid>
                                        {props.projectsData.map((element, key) => {
                                            return (
                                                <Grid item xs={12} className={styles.view_grid_modal_replace}>
                                                    <ProjectItemList
                                                        key={`project-${element.id}-${element.created_at}`}
                                                        data={element}
                                                        action={addProjectInExam}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                )}
                            {(loaderBoxReplace === true && auth !== undefined) && (
                                <ShowLoader message={messageLoader} />
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseReplaceProject()}>
                                Cancelar
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                </Grid>
            </Grid >
        );
    } else {
        return (
            <ShowLoader message={'Carregando...'} />
        );
    }
}
