import React from 'react';
import useStyles, { BootstrapDialog } from './styles';
import { useNavigate } from 'react-router-dom';
import { IGroup } from '../../types';
import { formatDateToLocaleString } from '../../formatters';
import apiService from '../../services/apiService';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import AuthBase from '../../components/AuthBase';
import ShowLoader from '../../components/ShowLoaderComponent';
import BootstrapDialogTitle from '../../components/BootstrapDialogTitle';
import DataTable, { TableColumn } from 'react-data-table-component';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

export default function GroupsPage() {
    const styles = useStyles();
    const navigate = useNavigate();
    const auth = React.useContext(AuthContext);
    const [groupId, setGroupId] = React.useState<number>(0);
    const [groupName, setGroupName] = React.useState<string>('');
    const [arGroups, setArGroups] = React.useState<Array<IGroup> | undefined>(undefined);
    const [loadingGeneral, setLoadingGeneral] = React.useState<boolean>(false);
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false);
    const [showDeleteGroup, setShowDeleteGroup] = React.useState<boolean>(false);
    const [showCreateGroup, setShowCreateGroup] = React.useState<boolean>(false);
    const [showEditGroup, setShowEditGroup] = React.useState<boolean>(false);
    const [errorGroupName, setErrorGroupName] = React.useState<boolean>(false);

    const columns: TableColumn<any>[] = [
        {
            sortable: true,
            name: 'Código',
            width: '100px',
            selector: (row: IGroup) => row.id,
        },
        {
            sortable: true,
            name: 'Nome do Grupo',
            cell: (row: IGroup) => (
                <Link
                    className={styles.grid_group_item}
                    underline={'none'}
                    onClick={() => editGroup(row.id)}
                >
                    {`${row.name}`}
                </Link>
            )
        },
        {
            sortable: true,
            name: 'Status',
            width: '120px',
            cell: (row: IGroup) => (
                <strong style={{ color: `${(row.status === '1' ? 'green' : 'red')}` }}>
                    {`${(row.status === '1' ? 'Ativado' : 'Desativado')}`}
                </strong>
            ),
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: IGroup) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            name: 'Opções',
            width: '160px',
            cell: ({ id }) => (
                <>
                    <Tooltip title="Editar Grupo">
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => showModalEditGroup(id)}
                            color="primary"
                        >
                            <CreateIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir Grupo">
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => handleRemoveGroup(id)}
                            color="error"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
        // eslint-disable-next-line
    ];

    const handlesetNewGroupName = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setGroupName(element.target.value);
    };

    function editGroup(groudId: number) {
        navigate(`/grupos/detalhes/${groudId}`);
    }

    function showModalCreateGroup() {
        setGroupId(0);
        setGroupName('');
        setShowCreateGroup(true);
    }

    function showModalEditGroup(groudId: number) {
        if (arGroups !== undefined) {
            setGroupId(groudId);
            // eslint-disable-next-line array-callback-return
            Object.keys(arGroups).some((key) => {
                if (arGroups[parseInt(key)]?.id === groudId) {
                    setGroupName(arGroups[parseInt(key)]?.name);
                }
            });
            setShowEditGroup(true);
            setShowCreateGroup(true);
        }
    }

    function handleRemoveGroup(groudId: number) {
        if (arGroups !== undefined) {
            setGroupId(groudId);
            // eslint-disable-next-line array-callback-return
            Object.keys(arGroups).some((key) => {
                if (arGroups[parseInt(key)]?.id === groudId) {
                    setGroupName(arGroups[parseInt(key)]?.name);
                }
            });
            setShowDeleteGroup(true);
        }
    }

    const handleClose = (loadingGroupsData: boolean) => {
        setShowDeleteGroup(false);
        setShowCreateGroup(false);
        setShowEditGroup(false);
        setErrorGroupName(false);
        setGroupId(0);
        setGroupName('');

        setTimeout(() => {
            if (loadingGroupsData === true) {
                getData();
            }
        }, 600);

        setTimeout(() => {
            setLoadingModal(false);
            setLoadingGeneral(false);
        }, 400);

    };

    async function createGroup() {
        if (groupName.trim() === '') {
            setErrorGroupName(true);
            return false;
        } else {
            setErrorGroupName(false);
        }
        setLoadingModal(true);
        try {
            await apiService.post(
                'groups',
                { name: groupName }
            ).then((response) => {
                if (response !== undefined && response?.status === 200) {
                    handleClose(true);
                } else {
                    console.log(`ERROR createGroup`, response);
                    handleClose(false);
                }
            }).catch((error) => {
                console.log(`ERROR createGroup`, error);
                handleClose(false);
            });
        } catch (e) {
            console.log(`ERROR updateGroup`, e);
            handleClose(false);
        }
    }

    async function updateGroup() {
        if (groupName.trim() === '') {
            setErrorGroupName(true);
            return false;
        } else {
            setErrorGroupName(false);
        }
        setLoadingModal(true);
        try {
            await apiService.put(
                'groups',
                {
                    group_id: groupId,
                    name: `${groupName}`
                }
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    handleClose(true);
                } else {
                    console.log(`ERROR updateGroup`, response);
                    handleClose(false);
                }
            }).catch((error) => {
                console.log(`ERROR updateGroup`, error);
                handleClose(false);
            });
        } catch (e) {
            console.log(`ERROR updateGroup`, e);
            handleClose(false);
        }
    }

    async function removeGroup() {
        if (auth !== undefined && groupId !== undefined && groupId !== null) {
            try {
                setLoadingModal(true);
                await apiService.delete(
                    'groups',
                    { group_id: groupId }
                ).then((response) => {
                    if (response !== undefined && response.status === 200 && response.data !== undefined) {
                        alert('Grudo excluído com sucesso!');
                    }
                    setShowDeleteGroup(false);
                    setLoadingModal(false);
                }).catch((error) => {
                    if (error.response.status === 409) {
                        alert(`Você não pode excluir o grupo pois ele não lhe pertence.`);
                    } else {
                        alert(`Houve um erro com a exclusão do grupo...`);
                    }
                    setShowDeleteGroup(false);
                    setLoadingModal(false);
                });
            } catch (e) {
                alert(`Houve um erro com a exclusão do grupo...`);
                setShowDeleteGroup(false);
                setLoadingModal(false);
            }
        }
    }

    const getData = async () => {
        if (auth !== undefined) {
            await apiService.get('groups', {}).then((response) => {
                if (response !== undefined && response.status === 200) {
                    setArGroups(response.data.data);
                }
            }).catch((error) => {
                setArGroups([]);
            });
        }
    }

    React.useEffect(() => {
        if (arGroups === undefined) {
            getData();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <AuthBase>
            <>
                <Grid container className={styles.grid_root}>
                    <Grid item xs={12} className={styles.grid_datagrid}>
                        <Tooltip title="Criar Novo Grupo">
                            <IconButton
                                className={styles.button_create}
                                size="small"
                                aria-haspopup="true"
                                onClick={() => showModalCreateGroup()}
                                color="primary"
                            >
                                Criar Novo Grupo <AddIcon />
                            </IconButton>
                        </Tooltip>
                        {(arGroups !== undefined && arGroups.length > 0) && (
                            <Paper className={styles.paper}>
                                <DataTable
                                    title="Grupos"
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="600px"
                                    highlightOnHover={true}
                                    pagination={true}
                                    responsive={true}
                                    striped={true}
                                    subHeader={true}
                                    columns={columns}
                                    data={arGroups}
                                    className={styles.datagrid}
                                    disabled={loadingGeneral}
                                />
                            </Paper>
                        )}
                        {(arGroups !== undefined && arGroups.length === 0) && (
                            <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {(arGroups === undefined && arGroups === undefined) && (
                        <ShowLoader message='' />
                    )}
                </Grid>
                {(loadingModal === true) && (
                    <BootstrapDialog
                        open={loadingModal}
                    >
                        <ShowLoader message='' />
                    </BootstrapDialog>
                )}
                {(loadingModal !== true) && (
                    <>
                        <BootstrapDialog
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showDeleteGroup}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                Excluir Grupo
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Typography className={styles.title}>Deseja excluir o grupo <strong>{groupName}</strong>?</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)}>
                                    Não
                                </Button>
                                <Button disabled={loadingModal} onClick={removeGroup}>
                                    Sim
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showCreateGroup}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                {(showEditGroup === true) && (
                                    'Editar Grupo'
                                )}
                                {(showEditGroup !== true) && (
                                    'Criar Novo Grupo'
                                )}
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        value={groupName}
                                        label='Nome do Grupo'
                                        autoFocus
                                        required={true}
                                        onChange={handlesetNewGroupName}
                                        disabled={loadingModal}
                                        error={errorGroupName}
                                    />
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)}>
                                    Cancelar
                                </Button>
                                {(showEditGroup === true) && (
                                    <Button disabled={loadingModal} onClick={updateGroup}>
                                        Salvar
                                    </Button>
                                )}
                                {(showEditGroup !== true) && (
                                    <Button disabled={loadingModal} onClick={createGroup}>
                                        Criar
                                    </Button>
                                )}
                            </DialogActions>
                        </BootstrapDialog>
                    </>
                )}
            </>
        </AuthBase>
    );
}
