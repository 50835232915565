import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import ShowLoader from '../../ShowLoaderComponent';
import {
    IHorizon,
} from '../../../types';
import IconButton from '@mui/material/IconButton';
import MusellColorsTypes, { IMunsellColor } from '../../../types/MunsellColorsTypes';
import { AuthContext } from '../../../contexts/Auth/AuthContext';
import apiService from '../../../services/apiService';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    renewHorizon: (data: Array<IHorizon> | undefined, now?: boolean) => void
}

export default function HorizonNameItem(props: IHorizonProps) {
    const styles = useStyles();
    const auth = React.useContext(AuthContext);
    const [horizon, setHorizon] = useState<IHorizon | undefined>(undefined);
    const [horizonNameData, setHorizonNameData] = React.useState<string | undefined>(undefined);
    const [showDialogDeleteHorizon, setShowDialogDeleteHorizon] = useState<boolean>(false);
    const [loaderBox, setLoaderBox] = useState<boolean>(false);

    let munsellColor: IMunsellColor = {
        matiz: (props.horizon.musell_colors_matriz_one !== null ? props.horizon.musell_colors_matriz_one : "10R"),
        value: (props.horizon.musell_colors_value_one !== null ? props.horizon.musell_colors_value_one : "2,5"),
        chroma: (props.horizon.musell_colors_chroma_one !== null ? props.horizon.musell_colors_chroma_one : "2")
    }
    let horizon_color = `${String(MusellColorsTypes.getMunsellHexColor(munsellColor))}`;

    function changeDeleteHorizon(item: IHorizon) {
        setHorizon(item);
        setShowDialogDeleteHorizon(true);
    }

    function RemoveHorizonBack() {
        setHorizon(undefined);
        setShowDialogDeleteHorizon(false);
    }

    async function updateHorizonName(horizonName: string) {
        if (auth !== undefined && props.examId !== undefined && horizonName !== '') {
            try {
                await apiService.put(
                    'exams/horizon',
                    {
                        horizon_id: props.horizon.id,
                        exam_id: parseInt(props.examId),
                        name: horizonName
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                    }
                }).catch((error) => {
                    console.log('ERROR-updateHorizonName-error:', error);
                });
            } catch (e) {
                console.log('ERROR-updateHorizonName-catch:', e);
            }
        }
    }

    async function deleteHorizon() {
        if (horizon !== undefined && props.examId !== undefined) {
            setLoaderBox(true);
            try {
                await apiService.delete(
                    `exams/horizon?horizon_id=${horizon!.id}`,
                    {
                        'horizon_id': horizon!.id
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                    } else {
                        alert(`Houve um erro com a exclusão do exame '${horizon.name}'...`);
                    }
                    props.renewHorizon(undefined, true);
                    setHorizon(undefined);
                    setHorizonNameData(undefined);
                    setShowDialogDeleteHorizon(false);
                    setLoaderBox(false);
                }).catch((error) => {
                    if (error.response.status === 409) {
                        alert(`Você não pode excluir o exame '${horizon.name}' pois ele não lhe pertence.`);
                    } else {
                        alert(`Houve um erro com a exclusão do exame '${horizon.name}'...`);
                    }
                    setHorizon(undefined);
                    setShowDialogDeleteHorizon(false);
                    setLoaderBox(false);
                });
            } catch (e) {
                alert(`Houve um erro com a exclusão do exame '${horizon.name}'...`);
                setHorizon(undefined);
                setShowDialogDeleteHorizon(false);
                setLoaderBox(false);
            }
        }
    }

    useEffect(() => {
        if (props.horizon !== undefined && props.horizon.name !== null && props.horizon.name !== horizonNameData) {
            setHorizonNameData(props.horizon.name);
        }
        // eslint-disable-next-line
    }, [props.horizon]);

    if (loaderBox === false && props.horizon !== undefined && horizonNameData !== undefined) {
        return (
            <Grid container key={`${props.horizon.id}${props.horizon.name}`} className={styles.view_horizon_root_item}>
                <Grid item xs={6} className={styles.view_item_box_center}>
                    {(showDialogDeleteHorizon !== true && horizon === undefined) && (
                        <Box sx={{ backgroundColor: horizon_color }} className={styles.view_horizon_color_item}>
                            <Grid container>
                                <Grid item xs={12} className={styles.view_horizon_name_top}></Grid>
                                <Grid item xs={12} className={styles.view_horizon_name_item}>
                                    <TextField
                                        className={styles.input_horizon_name}
                                        value={horizonNameData}
                                        placeholder='Nome do Horizonte'
                                        onFocus={() => {
                                            if (horizonNameData === 'Novo Horizonte') {
                                                setHorizonNameData('');
                                            }
                                        }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setHorizonNameData(event.target.value);
                                            updateHorizonName(event.target.value);
                                        }}
                                    />
                                    <IconButton
                                        size="small"
                                        aria-haspopup="true"
                                        onClick={() => changeDeleteHorizon(props.horizon)}
                                        color="error"
                                        className={styles.icon_remove_horizon}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
                {(showDialogDeleteHorizon === true && horizon !== undefined && horizon.id === props.horizon.id) && (
                    <Grid container className={styles.view_horizon_delete}>
                        <Grid item xs={12} className={styles.view_horizon_list_delete}>
                            <Typography className={styles.text_horizon_name}>
                                Tem certeza que deseja excluir o horizonte '{horizon!.name}'?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.view_horizon_action_delete}>
                            <IconButton
                                size="small"
                                aria-haspopup="true"
                                onClick={RemoveHorizonBack}
                                color="default"
                                className={styles.action_decline_icon}
                            >
                                <ClearIcon />
                            </IconButton>
                            <IconButton
                                size="small"
                                aria-haspopup="true"
                                onClick={() => deleteHorizon()}
                                color="success"
                                className={styles.button_actions_transparent}
                            >
                                <CheckIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    } else if (loaderBox === false && props.horizon !== undefined && horizonNameData === undefined) {
        return (
            <ShowLoader message={''} />
        );
    } else {
        return (
            <ShowLoader message={'Carregando...'} />
        );
    }
}