import React from 'react';
import useStyles, { BootstrapDialog } from './styles';
import { IRequestData } from '../../types';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import { useAuth } from "../../contexts/Auth/AuthProvider";
import apiService from '../../services/apiService';
import AuthBase from '../../components/AuthBase';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import CreateIcon from '@mui/icons-material/Create';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import BootstrapDialogTitle from '../../components/BootstrapDialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function ProfilePage() {
    const { user } = useAuth();
    const auth = React.useContext(AuthContext);
    const styles = useStyles();
    const navigate = useNavigate();

    const [nameComplete, setNameComplete] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [jobPosition, setJobPosition] = React.useState<string>('');
    const [about, setAbout] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');

    const [errorNameComplete, setErrorNameComplete] = React.useState<boolean>(false);
    const [errorEmail, setErrorEmail] = React.useState<boolean>(false);
    const [errorJobPosition, setErrorJobPosition] = React.useState<boolean>(false);
    const [errorAbout, setErrorAbout] = React.useState<boolean>(false);
    const [errorPassword, setErrorPassword] = React.useState<boolean>(false);
    const [errorRepeatPassword, setErrorRepeatPassword] = React.useState<boolean>(false);

    const [loadingModal, setLoadingModal] = React.useState<boolean>(false);
    const [showDeleteUser, setShowDeleteUser] = React.useState<boolean>(false);
    const [showDeleteUserConfirmation, setShowDeleteUserConfirmation] = React.useState<boolean>(false);

    const [disableAuthData, setDisableAuthData] = React.useState<boolean>(true);
    const [disablePasswordData, setDisablePasswordData] = React.useState<boolean>(false);

    function clearPasswordsValues() {
        setPassword('');
        setRepeatPassword('');
    }

    function handleAuthorizingUserData() {
        setDisableAuthData(false);
    }

    function validateData() {
        let error = true;
        if (nameComplete === '') {
            error = false;
            setErrorNameComplete(true);
        } else {
            setErrorNameComplete(false);
        }
        if (email === '') {
            error = false;
            setErrorEmail(true);
        } else {
            setErrorEmail(false);
        }
        if (jobPosition === '') {
            error = false;
            setErrorJobPosition(true);
        } else {
            setErrorJobPosition(false);
        }
        if (about === '') {
            error = false;
            setErrorAbout(true);
        } else {
            setErrorAbout(false);
        }

        return error;
    }

    function validatePassword() {
        let error = true;

        if (password !== '' && repeatPassword !== '' && password !== repeatPassword) {
            error = false;
            setErrorPassword(true);
            setErrorRepeatPassword(true);
            clearPasswordsValues();
        } else {
            setErrorPassword(false);
            setErrorRepeatPassword(false);
        }

        if (password === '') {
            error = false;
            setErrorPassword(true);
            clearPasswordsValues();
        } else {
            setErrorPassword(false);
        }

        if (repeatPassword === '') {
            error = false;
            setErrorRepeatPassword(true);
            clearPasswordsValues();
        } else {
            setErrorRepeatPassword(false);
        }

        return error;
    }

    async function handleLogout() {
        await auth?.signOut();
        navigate('/');
        setShowDeleteUserConfirmation(false);
    }

    async function deleteProfile() {
        if (user !== undefined) {
            setLoadingModal(true);
            try {
                await apiService.delete(
                    `profile`,
                    {}
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        setShowDeleteUser(false);
                        setShowDeleteUserConfirmation(true);
                    } else {
                        console.log(`ERROR deleteProfile`, response);
                        setShowDeleteUser(false);
                    }
                }).catch((error) => {
                    console.log(`ERROR deleteProfile`, error);
                    setShowDeleteUser(false);
                });
            } catch (e) {
                console.log(`ERROR deleteProfile`, e);
                setShowDeleteUser(false);
            }
        }
    }

    async function updatePassword() {
        if (validatePassword() !== true) {
            alert('Por favor, preencha os campos de senha corretamente.');
        } else {
            let request_data: IRequestData = {
                user_id: user?.id,
                password: password,
            };
            try {
                await apiService.put(
                    'profile',
                    request_data
                ).then((response) => {
                    if (response) {
                        setDisablePasswordData(false);
                    } else {
                        alert('Erro no login');
                        setDisablePasswordData(false);
                    }
                    clearPasswordsValues();
                }).catch((error) => {
                    setDisablePasswordData(false);
                    clearPasswordsValues();
                });
            } catch (e) {
                setDisablePasswordData(false);
                clearPasswordsValues();
            }
        }
    }

    async function updateUserData() {
        if (validateData() !== true) {
            alert('Por favor, preencha os dados corretamente.');
        } else {
            try {
                let request_data: IRequestData = {
                    user_id: user?.id,
                    name: nameComplete,
                    email: email,
                    about: about,
                    job_position: jobPosition,
                };
                await apiService.put(
                    'profile',
                    request_data
                ).then((response) => {
                    setDisableAuthData(true);
                }).catch((error) => {
                    setDisableAuthData(true);
                });
            } catch (e) {
                setDisableAuthData(true);
            }
        }
    }

    React.useEffect(() => {
        function loadData() {
            if (user !== null) {
                setNameComplete(user.name);
                setEmail(user.email);
                setJobPosition(user.job_position);
                setAbout(user.about);
            }
        }
        return loadData();
        // eslint-disable-next-line
    }, [user]);

    return (
        <AuthBase>
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    className={styles.grid_root}
                >
                    <Grid item xs={12} className={styles.grid_title}>
                        <Typography>Dados Cadastrados</Typography>
                        <br />
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} className={styles.grid_container}>
                            <Grid container>
                                <Grid item xs={12} className={styles.grid_input}>
                                    <FormControl sx={{ width: '35ch' }} variant="outlined">
                                        <TextField
                                            required
                                            value={nameComplete}
                                            label='Nome Completo'
                                            className={styles.input_base}
                                            error={errorNameComplete}
                                            disabled={disableAuthData}
                                            onChange={(value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setNameComplete(value.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className={styles.grid_input}>
                                    <FormControl sx={{ width: '35ch' }} variant="outlined">
                                        <TextField
                                            required
                                            value={email}
                                            label='E-mail'
                                            className={styles.input_base}
                                            error={errorEmail}
                                            disabled={disableAuthData}
                                            onChange={(value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setEmail(value.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className={styles.grid_input}>
                                    <FormControl sx={{ width: '35ch' }} variant="outlined">
                                        <TextField
                                            required
                                            value={jobPosition}
                                            label='Trabalho/Cargo'
                                            className={styles.input_base}
                                            error={errorJobPosition}
                                            disabled={disableAuthData}
                                            onChange={(value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setJobPosition(value.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className={styles.grid_input}>
                                    <FormControl sx={{ width: '35ch' }} variant="outlined">
                                        <TextField
                                            required
                                            value={about}
                                            label='Sobre'
                                            className={styles.input_base}
                                            error={errorAbout}
                                            disabled={disableAuthData}
                                            onChange={(value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setAbout(value.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className={styles.grid_group_buttons}>
                                    {(disableAuthData === true) && (
                                        <LoadingButton
                                            className={styles.button_update}
                                            loadingPosition="end"
                                            endIcon={<CreateIcon className={styles.icon_pen_edit} />}
                                            variant="outlined"
                                            onClick={() => handleAuthorizingUserData()}
                                        >
                                            Editar
                                        </LoadingButton>
                                    )}
                                    {(disableAuthData !== true) && (
                                        <LoadingButton
                                            className={styles.button_update}
                                            loading={disableAuthData}
                                            loadingPosition="end"
                                            endIcon={<CreateIcon className={styles.icon_pen_edit} />}
                                            variant="outlined"
                                            onClick={() => updateUserData()}
                                        >
                                            Atualizar Dados
                                        </LoadingButton>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className={styles.grid_container}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Grid item xs={12} className={styles.grid_input}>
                                    <FormControl sx={{ width: '35ch' }} variant="outlined">
                                        <TextField
                                            required
                                            value={password}
                                            label='Nova Senha'
                                            className={styles.input_base}
                                            error={errorPassword}
                                            disabled={disablePasswordData}
                                            onChange={(value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setPassword(value.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className={styles.grid_input}>
                                    <FormControl sx={{ width: '35ch' }} variant="outlined">
                                        <TextField
                                            required
                                            value={repeatPassword}
                                            label='Confirmação da Nova Senha'
                                            className={styles.input_base}
                                            error={errorRepeatPassword}
                                            disabled={disablePasswordData}
                                            onChange={(value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setRepeatPassword(value.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className={styles.grid_group_buttons}>
                                    <LoadingButton
                                        className={styles.button_update}
                                        loading={disablePasswordData}
                                        loadingPosition="end"
                                        endIcon={<CreateIcon className={styles.icon_pen_edit} />}
                                        variant="outlined"
                                        onClick={() => updatePassword()}
                                    >
                                        Atualizar Senha
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12} className={styles.grid_delete_account}>
                                    <LoadingButton
                                        className={styles.button_delete}
                                        loading={showDeleteUser}
                                        loadingPosition="end"
                                        endIcon={<DeleteOutlineIcon className={styles.icon_delete_account} />}
                                        variant="outlined"
                                        onClick={() => setShowDeleteUser(true)}
                                    >
                                        Excluir conta
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <BootstrapDialog
                    onClose={() => setShowDeleteUser(false)}
                    aria-labelledby="customized-dialog-delete"
                    open={showDeleteUser}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setShowDeleteUser(false)}>
                        Excluir Conta
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography className={styles.title}>Ao excluir a sua conta, <strong>todos os seus dados</strong> vão ser excluídos do sistema.</Typography>
                        <Typography className={styles.title}>Mesmo assim, deseja remover a sua conta?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loadingModal} onClick={deleteProfile}>
                            Sim
                        </Button>
                        <Button onClick={() => setShowDeleteUser(false)}>
                            Não
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
                <BootstrapDialog
                    aria-labelledby="customized-dialog-title-confirm-delete"
                    open={showDeleteUserConfirmation}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleLogout()}>
                        Conta Excluída
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography className={styles.title}>Conta excluída com sucesso!</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleLogout()}>
                            Logout
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </>
        </AuthBase>
    );
}
