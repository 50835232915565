import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        width: '100%',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    grid_title_actions: {
        width: '100%',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    button_action: {
        margin: '10px 0px 20px 10px !important',
    },
    grid_item_container_data: {
        width: '100%',
        backgroundColor: 'transparent',
        alignItems: 'center',
    },
    grid_container_users: {
        width: '100%',
        backgroundColor: 'transparent',
        alignItems: 'center',
    },
    grid_participants: {
        width: '100%',
        padding: '0px 10px !important'
    },
    grid_invitations: {
        width: '100%',
        padding: '0px 10px !important'
    },
    paper: {
        width: '100%',
        padding: '10px !important'
    },
    grid_post: {
        width: '100%',
        padding: '0px 10px !important'
    },
    datagrid: {
        minHeight: '634px',
    },
    title: {
        margin: '10px 10px 20px 0px !important',
        color: '#000 !important',
        fontFamily: 'serca-condensed-text',
        fontSize: 18,
    },
    input_exam_input: {
        width: '100%',
        flexDirection: 'column',
        borderWidth: 1,
        padding: '10px !important',
        borderRadius: 10,
        verticalAlign: 'top',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#06617c !important',
        }
    },
    picker_invite_select_users: {
        color: '#000 !important',
        backgroundColor: '#FFFFFF !important',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'serca-condensed-text',
        fontSize: 18,
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#06617c !important',
        }
    },
    picker_invite_select_users_item: {
        width: '90%',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        color: '#000',
        textAlign: 'center',
        fontFamily: 'serca-condensed-text',
        fontSize: 16,
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#06617c !important',
        }
    },
    button_back_page: {
        color: '#FFF !important',
        width: '100% !important',
        marginTop: '10px !important',
        marginBottom: '10px !important',
        flexDirection: 'column',
        padding: '10px !important',
        borderRadius: '10px !important',
        backgroundColor: '#06617c !important',
        alignItems: 'center',
    },
    grid_item_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        alignContent: 'center',
    },
    data_is_empty: {
        width: '100%',
        display: 'flex',
        backgroundColor: 'transparent',
        color: '#06617c !important',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '50px !important',
    },
    view_user: {
        backgroundColor: 'transparent',
        flexDirection: 'row',
        margin: '10px 0px !important',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        borderRadius: 15,
    },
    view_user_opacity: {
        opacity: 0.50,
        width: '100%',
        overflow: 'hidden',
        flexDirection: 'row',
        margin: '5px 0px !important',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        borderRadius: 15,
    },
    view_user_image: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#06617c',
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
    },
    user_img: {
        width: 55,
        display: 'flex',
    },
    view_user_name: {
        width: '100%',
        backgroundColor: 'transparent',
        alignItems: 'flex-start',
        paddingLeft: '10px !important',
    },
    text_user_name: {
        textAlign: 'left',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        alignItems: 'flex-start',
    },
    button_group_name: {
        marginTop: 20,
        flexDirection: 'row',
        width: '100%',
        borderRadius: 10,
        backgroundColor: '#06617c',
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    text_button_group_name: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 20px !important',
        color: '#FFF',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 20,
        alignItems: 'flex-start',
    },
    back_icon: {
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        color: '#FFF',
        padding: '10px !important',
        fontSize: 25,
        alignItems: 'center',
    },
    view_user_message: {
        cursor: 'pointer !important',
        flexDirection: 'row',
        padding: '10px !important',
        backgroundColor: 'transparent',
        borderColor: '#CCC !important',
        borderWidth: 1,
        borderStartColor: 'transparent',
        borderEndColor: 'transparent',
        borderTopColor: 'transparent',
    },
    view_user_message_image: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#d7d7d7 !important',
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
    },
    user_message_img: {
        width: 55,
        display: 'flex',
    },
    view_user_data: {
        minHeight: 70,
        paddingLeft: 10,
        backgroundColor: 'transparent',
    },
    view_user_message_name: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: 'transparent',
    },
    view_user_message_message: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
    },
    view_input_sent_message: {
        width: '100%',
        alignItems: 'flex-end',
    },
    view_button_sent_message: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px !important',
    },
    input_sent_message: {
        width: '100%',
        marginTop: '10px !important',
        color: '#000000 !important',
        backgroundColor: '#FFFFFF !important',
        fontSize: 16,
        textAlign: 'left',
        borderRadius: 10,
        flexDirection: 'column',
        textAlignVertical: 'top',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#06617c !important',
        }
    },
    button_group_name_input_sent_message: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        borderRadius: 10,
        backgroundColor: '#06617c !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    user_img_message_details: {
        height: 52,
        display: 'flex',
        backgroundColor: '#d7d7d7 !important',
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon_input_sent_message: {
        width: 32,
        height: 30,
        tintColor: '#FFF !important',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        padding: '10px !important',
        alignItems: 'center',
    },
    text_input_sent_message: {
        textAlign: 'center',
        flexDirection: 'column',
        padding: '10px 20px !important',
        color: '#FFF !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 12,
        alignItems: 'flex-start',
    },
    view_user_message_from: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        backgroundColor: 'transparent',
    },
    view_user_message_message_from: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        alignContent: 'flex-end',
        justifyContent: 'flex-end',
        padding: '0px 5px !important',
        borderRadius: 10,
        borderBottomRightRadius: 0,
        backgroundColor: '#EFF1F3 !important',
    },
    view_user_message_date_from: {
        flexDirection: 'row',
        padding: '5px 0px !important',
        marginBottom: 10,
        backgroundColor: 'transparent',
    },
    text_user_message_message_from: {
        textAlign: 'right',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: '5px 2px !important',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    text_user_message_datetime_from: {
        textAlign: 'right',
        padding: '5px 0px !important',
        color: '#000',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
    },
    view_user_message_to: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
    },
    view_user_message_message_to: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px 5px !important',
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        backgroundColor: '#06617c !important',
    },
    text_user_message_message_to: {
        width: '100%',
        textAlign: 'left',
        padding: '1px 0px !important',
        color: '#FFF !important',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    view_user_message_date_to: {
        width: '100%',
        flexDirection: 'row',
        padding: '5px 0px !important',
        marginBottom: 10,
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
    },
    text_user_message_datetime_to: {
        width: '100%',
        textAlign: 'left',
        padding: '1px 0px !important',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 14,
    },
    view_centered: {
        width: '100%',
        justifyContent: "flex-end",
        alignItems: "center",
        opacity: 0.55,
        backgroundColor: 'transparent',
        margin: '0px 5px !important',
    },
    view_centered_content: {
        width: '100%',
        opacity: 0.85,
        backgroundColor: '#CDCDCD !important',
        alignItems: "center",
        justifyContent: "center",
    },
    view_group_message_is_empty: {
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '10px !important',
        marginBottom: '30px !important',
    },
    view_group_users_list: {
        width: '100%',
        height: '100%',
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
    },
    view_group_users_list_chat: {
        width: '100%',
        height: '330px',
        overflowX: 'auto',
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
    },
    text_group_message_is_empty: {
        color: '#06617c !important',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    view_user_message_details: {
        overflow: 'hidden',
        flexDirection: 'row',
        marginTop: '10px !important',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
    },
    view_user_image_message_details: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        backgroundColor: '#CCC',
        borderRadius: 35,
        alignItems: 'center',
        justifyContent: 'center',
    },
    view_user_name_message_details: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '10px !important',
    },
    text_user_name_message_details: {
        textAlign: 'center',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    view_text_select_user: {
        margin: '10px 0px !important',
        textAlign: 'left',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    view_text_input_email: {
        margin: '10px 0px !important',
        textAlign: 'left',
        color: '#000',
        backgroundColor: 'transparent',
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 18,
    },
    view_loading: {
        margin: '10px 0px !important',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
});

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default useStyles;