import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import Base from '../../components/Base';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import authApi from '../../hooks/authApi';
import LoaderComponent from '../../components/LoaderComponent'; 
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function ForgotPage() {
    const navigate = useNavigate();
    const styles = useStyles();
    const api = authApi();
    const auth = React.useContext(AuthContext);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [changePassword, setChangePassword] = useState<string>('');
    const [recoveryAccess, setRecoveryAccess] = useState<string>('');
    const [recoveryCode, setRecoveryCode] = useState<string>('');
    const [errorPassword, setErrorPassword] = useState<string | undefined>(undefined);
    const [errorChangePassword, setErrorChangePassword] = useState<string | undefined>(undefined);

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [forgotStep2, setForgotStep2] = useState<boolean>(false);
    const [forgotStep3, setForgotStep3] = useState<boolean>(false);

    const [loaderBox, setLoaderBox] = useState<boolean>(false);
    const [messageLoader, setMessageLoader] = useState<string | undefined>(undefined);
    const [typeLoader, setTypeLoader] = useState<"error" | "success" | "warning" | "info" | undefined>(undefined);
    const [titleLoader, setTitleLoader] = useState<string | undefined>(undefined);

    function setStateLoader(
        status: boolean,
        typeStatus: "error" | "success" | "warning" | "info" | undefined = undefined,
        message: string | undefined = undefined,
        title: string | undefined = undefined
    ) {
        if (status === true) {
            if (message !== undefined) {
                setMessageLoader(message);
            }
            if (typeStatus !== undefined) {
                setTypeLoader(typeStatus);
            }
            if (title !== undefined) {
                setTitleLoader(title);
            }
            setLoaderBox(true);
        } else {
            setLoaderBox(false);
            setMessageLoader(undefined);
            setTitleLoader(undefined);
        }
    }

    function validatePasswords() {
        let error = false;
        setErrorPassword(undefined);
        setErrorChangePassword(undefined);
        if (password === '') {
            error = true;
            setErrorPassword('Preencha sua senha corretamente.');
        }
        if (changePassword === '') {
            error = true;
            setErrorChangePassword('Preencha sua senha corretamente.');
        }
        if (password !== changePassword) {
            error = true;
            setErrorPassword('As senhas não são identicas.');
            setErrorChangePassword('As senhas não são identicas.');
        }

        return error;
    }

    function LoginAction() {
        navigate('/');
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    async function ForgotPasswordAction() {
        setStateLoader(true);
        await api.forgot(
            username
        ).then((response) => {
            if (response !== undefined && response.status === 201) {
                setStateLoader(false);
                setForgotStep2(true);
            } else {
                setTypeLoader('error');
                setMessageLoader(`${response.data.message}`);
                setTitleLoader('Erro');
                setUsername('');
            }
        }).catch((error) => {
            setTypeLoader('error');
            setMessageLoader(`${error.response.data.message}`);
            setTitleLoader('Erro');
            setUsername('');
        });
    }

    async function AutorizeChangePasswordAction() {
        setStateLoader(true);
        await api.textEntry(
            recoveryCode
        ).then((response) => {
            if (response !== undefined && response.status === 201) {
                setRecoveryCode('');
                setRecoveryAccess(response.data.recovery_access);
                setForgotStep2(false);
                setForgotStep3(true);
                setStateLoader(false);
            } else {
                setTypeLoader('error');
                setMessageLoader(`${response.data.message}`);
                setTitleLoader('Erro');
                setRecoveryCode('');
            }
        }).catch((error) => {
            setTypeLoader('error');
            setMessageLoader(`${error.response.data.message}`);
            setTitleLoader('Erro');
            setRecoveryCode('');
        });
    }

    async function loginChangePassword() {
        setStateLoader(true);
        await auth!.signIn(
            username,
            password
        ).then((response) => {
            setUsername('');
            setPassword('');
            setChangePassword('');
            setRecoveryAccess('');
            setForgotStep2(false);
            setForgotStep3(false);
            if (response) {
                setTypeLoader('success');
                setMessageLoader('Senha altualizada com sucesso!');
                setTitleLoader('Sucesso!');
                navigate('/projetos');
            } else {
                setTypeLoader('error');
                setMessageLoader('Infelizmente não foi possível trocar a senha da sua conta.');
                setTitleLoader('Erro');
                navigate('/entrar');
            }
        }).catch((error) => {
            setTypeLoader('error');
            setMessageLoader('Infelizmente não foi possível trocar a senha da sua conta.');
            setTitleLoader('Erro');
            setPassword('');
            setChangePassword('');
            navigate('/entrar');
        });
    }

    async function ChangePasswordAction() {
        if (validatePasswords() === true) {
            console.log('Para realizar a solicitação da recuperação da conta, é necessário inserir o email corretamente.');
        } else {
            setStateLoader(true);
            await api.changePassword(
                recoveryAccess,
                password,
                changePassword
            ).then(() => {
                setStateLoader(false);
                loginChangePassword();
            }).catch(() => {
                setStateLoader(false);
            });
        }
    }

    return (
        <Base>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    {(loaderBox === true) && (
                        <LoaderComponent
                            show={loaderBox}
                            type={'loader'}
                            status={typeLoader}
                            message={messageLoader}
                            title={titleLoader}
                        />
                    )}
                    {(forgotStep2 === false && forgotStep3 === false) && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Typography className={styles.text_aling}>
                                    Para recuperar o acesso á conta,
                                </Typography>
                                <Typography className={styles.text_aling}>
                                    basta inserir o e-mail cadastrado e solicitar a recuperação.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_input}>
                                <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
                                    <TextField
                                        disabled={loaderBox}
                                        className={styles.input_color}
                                        value={username}
                                        label='seu.email@email.com'
                                        type={'email'}
                                        autoComplete="email"
                                        onChange={(value) => { setUsername(value.target.value) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_button_update}>
                                <br />
                                <Button 
                                    className={styles.button_color_border}
                                    disabled={loaderBox}
                                    onClick={ForgotPasswordAction}
                                >
                                    SOLICITAR
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_button_space}>
                                <br />
                                <br />
                                <Button
                                    className={styles.button_color}
                                    disabled={loaderBox}
                                    onClick={LoginAction}
                                >
                                    LOGIN
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {(forgotStep2 === true && forgotStep3 === false) && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Typography>
                                    Digite o código recebido no e-mail para trocar a senha.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">CODE</InputLabel>
                                    <OutlinedInput
                                        disabled={loaderBox}
                                        className={styles.input_color}
                                        value={recoveryCode}
                                        label='AAAAAA'
                                        onChange={(value) => setRecoveryCode(value.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_button_update}>
                                <Button
                                    className={styles.button_color}
                                    disabled={loaderBox}
                                    onClick={AutorizeChangePasswordAction}
                                >
                                    ENVIAR
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_button_space}>
                                <Button
                                    className={styles.button_color}
                                    disabled={loaderBox}
                                    onClick={LoginAction}
                                >
                                    VOLTAR
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {(forgotStep2 === false && forgotStep3 === true) && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Typography>
                                    Inseria a nova senha de acesso ao SmartSolos.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_input}>
                                <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                                    <OutlinedInput
                                        disabled={loaderBox}
                                        className={styles.input_color}
                                        id="outlined-adornment-password"
                                        value={password}
                                        label='Senha'
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete="current-password"
                                        onChange={(value) => { setPassword(value.target.value) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {(errorPassword !== '') && (
                                        <Typography>{errorPassword}</Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_input}>
                                <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Confirmação de Senha</InputLabel>
                                    <OutlinedInput
                                        disabled={loaderBox}
                                        className={styles.input_color}
                                        id="outlined-adornment-password"
                                        value={changePassword}
                                        label='Confirmação de Senha'
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete=""
                                        onChange={(value) => { setChangePassword(value.target.value) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {(errorChangePassword !== '') && (
                                        <Typography>{errorChangePassword}</Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_button_update}>
                                <Button
                                    className={styles.button_color}
                                    disabled={loaderBox}
                                    onClick={ChangePasswordAction}
                                >
                                    ATUALIZAR
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={styles.grid_button_space}>
                                <Button
                                    className={styles.button_color}
                                    disabled={loaderBox}
                                    onClick={LoginAction}
                                >
                                    CANCELAR
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Base>
    );
}
