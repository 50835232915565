import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import {
    IHorizon,
} from '../../../types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CalculateIcon from '@mui/icons-material/Calculate';

interface IHorizonProps {
    examId: string | undefined
    horizon: IHorizon
    renewHorizon: (data: Array<IHorizon> | undefined) => void
}

export default function HorizonThicknessItem(props: IHorizonProps) {
    const styles = useStyles();
    const [thickness, setThickness] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (props.horizon !== undefined) {
            let maximumThickness: number = 0;
            let minimumThickness: number = 0;

            if (props.horizon.limits_upper_undulating !== undefined && parseInt(props.horizon.limits_upper_undulating) > 0) {
                maximumThickness = parseInt(props.horizon.limits_upper_undulating);
            } else if (props.horizon.limits_upper !== undefined && parseInt(props.horizon.limits_upper) > 0) {
                maximumThickness = parseInt(props.horizon.limits_upper);
            }

            if (props.horizon.limits_lower !== undefined && parseInt(props.horizon.limits_lower) > 0) {
                minimumThickness = parseInt(props.horizon.limits_lower);
            }

            if (maximumThickness <= 0) {
                setThickness(0);
            } else {
                setThickness(maximumThickness - minimumThickness);
            }
        }
        // eslint-disable-next-line
    }, [props.horizon]);

    return (
        <Grid container key={`${props.horizon.id}${props.horizon.name}thickness`} className={styles.view_horizon_thickness}>
            <Grid item xs={12} className={styles.view_horizon_thickness_title}>
                <Grid container>
                    <Grid item xs={12} className={styles.grid_item_name_horizon}>
                        <Typography className={styles.text_horizon_thickness}>
                            <strong>{props.horizon.name}</strong>
                        </Typography>
                        <CalculateIcon className={styles.icon_calculator} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={styles.view_horizon_thickness_content}>
                <Typography className={styles.input_data_thickness}>
                    {`${thickness} cm`}
                </Typography>
            </Grid>
        </Grid>
    );
}