import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    grid_root: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        border: '1px solid #cddee9',
        padding: '20px 25px',
        borderRadius: '10px',
    },
    grid_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        alignContent: 'center',
    },
    grid_title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        marginBotton: '15px',
    },
    grid_input: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        marginTop: '15px !important',
    },
    input_base: {
        marginTop: '10px !important',
    },
    grid_group_buttons: {
        marginTop: '25px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    button_update: {
        backgroundColor: 'transparent',
        color: '#06617c !important',
        borderColor: '#06617c !important',
    },
    icon_pen_edit: {
        backgroundColor: 'transparent',
        color: '#06617c',
    },
    view_centered_content: {
        flex: 1,
        backgroundColor: '#FFF',
        alignItems: "center",
        justifyContent: "center",
        marginTop: 48,
        marginBottom: 48
    },
    bold_text: {
        fontWeight: 'bold'
    },
    small_text: {
        fontSize: '13px !important'
    },
    logo_credits: {
        width: 200,
        height: 300,
    },
    button_close: {
        width: 120,
        alignItems: 'center',
        backgroundColor: "#FFFFFF",
        borderWidth: 1,
        borderColor: '#CDCDCD',
        padding: 10,
        margin: '0px 20px',
        borderRadius: 15,
        letterSpacing: 1,
        fontSize: 16,
        color: "#000",
        fontWeight: "bold",
        textAlign: "center"
    },
    container_button: {
        width: '100%',

        display: 'flex',
        justifyContent: 'flex-end'
    },
    text_center: {
        width: '50%',
        textAlign: 'center',
    }
});

export default useStyles;