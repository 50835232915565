import { makeStyles } from '@material-ui/styles';
import { DefaultTheme } from '@material-ui/styles';

const useStyles = makeStyles<DefaultTheme>({
    view_horizon_character: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    view_horizon_character_title: {
        backgroundColor: 'transparent',
        marginBottom: '10px !important',
    },
    text_horizon_character: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000000 !important",
        fontWeight: "bold",
        textAlign: "center"
    },
    text_horizon_character_data: {
        fontFamily: 'serca-condensed-text',
        letterSpacing: 1,
        fontSize: 16,
        color: "#000000 !important",
        textAlign: "left",
        textTransform: 'none',
    },
    view_horizon_character_subtitle: {
        flexDirection: 'row',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text_horizon_character_subtitle: {
        flexDirection: 'column',
        backgroundColor: 'transparent',
        alignItems: 'center',
    },
    view_horizon_character_content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '5px !important',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        marginBottom: 10,
    },
    view_horizon_character_item: {
        width: '300px',
        padding: '0px 10px !important',
        backgroundColor: '#FFF !important',
        border: '0.5px solid #ffc9ae !important',
        borderRadius: 5,
        margin: '5px !important',
    },
    input_data_character: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'transparent',
        borderColor: '#CDCDCD !important',
        borderWidth: 1,
        padding: '8px 0px !important',
        margin: '0px 5px !important',
        borderRadius: 10,
        textAlign: 'center',
        verticalAlign: 'center'
    },
    button_checkobox_character_content: {
        width: '100%',
        backgroundColor: 'transparent',
        marginBottom: '5px !important',
    },
    view_checkbox_data: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        paddingLeft: '5px !important',
        alignItems: 'center',
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
    },
});

export default useStyles;