import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles, { BootstrapDialog } from './styles';
import { useAuth } from "../../contexts/Auth/AuthProvider";
import { IExam, IProject } from '../../types';
import DataTable, { TableColumn } from 'react-data-table-component';
import apiService from '../../services/apiService';
import { formatDateToLocaleString } from '../../formatters';
import ShowLoader from '../../components/ShowLoaderComponent';
import AuthBase from '../../components/AuthBase';
import BootstrapDialogTitle from '../../components/BootstrapDialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

interface ProjectsExam extends IProject {
    exams: Array<IExam>;
}

export default function ProjectsPage() {
    const { user } = useAuth();
    const styles = useStyles();
    const navigate = useNavigate();
    const [projectId, setProjectId] = React.useState<number>(0);
    const [projectName, setProjectName] = React.useState<string>('');
    const [examId, setExamId] = React.useState<number>(0);
    const [examName, setExamName] = React.useState<string>('');
    const [arProject, setArProject] = React.useState<Array<ProjectsExam> | undefined>(undefined);
    const [arProjetByExams, setArProjetByExams] = React.useState<Map<number, Array<IExam>> | undefined>(undefined);
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false);
    const [loadingGeneral, setLoadingGeneral] = React.useState<boolean>(false);
    const [showCreateExam, setShowCreateExam] = React.useState<boolean>(false);
    const [showCreateProject, setShowCreateProject] = React.useState<boolean>(false);
    const [showEditProject, setShowEditProject] = React.useState<boolean>(false);
    const [showEditExam, setShowEditExam] = React.useState<boolean>(false);
    const [showDeleteProject, setShowDeleteProject] = React.useState<boolean>(false);
    const [showDeleteExam, setShowDeleteExam] = React.useState<boolean>(false);
    const [errorProjectName, setErrorProjectName] = React.useState<boolean>(false);
    const [errorExamName, setErrorExamName] = React.useState<boolean>(false);
    const [loader, setLoader] = React.useState<boolean>(false);

    const columns: TableColumn<any>[] = [
        {
            sortable: true,
            name: 'Código',
            width: '90px',
            selector: (row: IProject) => row.id,
        },
        {
            sortable: true,
            name: 'Nome do Projeto',
            selector: (row: IProject) => row.name,
        },
        {
            sortable: true,
            name: 'Data da Criação',
            width: '160px',
            selector: (row: IProject) => formatDateToLocaleString(`${row.created_at}`),
        },
        {
            name: 'Opções',
            width: '160px',
            cell: ({ id }) => (
                <>
                    <Tooltip title="Adicionar um novo Exame ao projeto">
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => addExamByProject(id)}
                            color="success"
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar Projeto">
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => editProject(id)}
                            color="primary"
                        >
                            <CreateIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir Projeto">
                        <IconButton
                            size="small"
                            aria-haspopup="true"
                            onClick={() => deleteProject(id)}
                            color="error"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    const handleClose = (loadingProjectsData: boolean) => {
        setShowCreateProject(false);
        setShowEditProject(false);
        setShowDeleteProject(false);
        setShowCreateExam(false);
        setShowEditExam(false);
        setShowDeleteExam(false);
        setErrorProjectName(false);
        setErrorExamName(false);
        setProjectId(0);
        setProjectName('');
        setExamId(0);
        setExamName('');

        setTimeout(() => {
            if (loadingProjectsData === true) {
                getProjects();
            }
        }, 600);

        setTimeout(() => {
            setLoader(false);
            setLoadingGeneral(false);
            setLoadingModal(false);
        }, 400);
    };

    const handlesetNewProjectName = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setProjectName(element.target.value);
    };

    const handlesetNewExamName = (element: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setExamName(element.target.value);
    };

    function editProject(projectId: number) {
        if (arProject !== undefined) {
            setProjectId(projectId);
            // eslint-disable-next-line array-callback-return
            Object.keys(arProject).some((key) => {
                if (arProject[parseInt(key)]?.id === projectId) {
                    setProjectName(arProject[parseInt(key)]?.name);
                }
            });
            setShowCreateProject(true);
            setShowEditProject(true);
        }
    }

    function deleteProject(projectId: number) {
        if (arProject !== undefined) {
            setProjectId(projectId);
            // eslint-disable-next-line array-callback-return
            Object.keys(arProject).some((key) => {
                if (arProject[parseInt(key)]?.id === projectId) {
                    setProjectName(arProject[parseInt(key)]?.name);
                }
            });
            setShowDeleteProject(true);
        }
    }

    function addExamByProject(projectId: number) {
        setProjectId(projectId);
        setShowEditExam(false);
        setShowCreateExam(true);
    }

    function editExam(examId: number) {
        navigate(`/projetos/exames/detalhe/${examId}`);
    }

    function deleteExam(examId: number, examName: string) {
        if (arProjetByExams !== undefined) {
            setExamId(examId);
            setExamName(examName);
            setShowDeleteExam(true);
        }
    }

    function showModalCreateProject() {
        setShowCreateProject(true);
    }

    async function createProject() {
        if (projectName.trim() === '') {
            setErrorProjectName(true);
            return false;
        } else {
            setErrorProjectName(false);
        }
        setLoadingModal(true);
        try {
            await apiService.post(
                'project',
                { name: projectName }
            ).then((response) => {
                if (response !== undefined && response?.status === 200) {
                    handleClose(true);
                } else {
                    console.log(`ERROR createProject`, response);
                    handleClose(false);
                }
            }).catch((error) => {
                console.log(`ERROR createProject`, error);
                handleClose(false);
            });
        } catch (e) {
            console.log(`ERROR createProject`, e);
            handleClose(false);
        }
    }

    async function updateProject() {
        if (projectId !== 0) {
            if (projectName.trim() === '') {
                setErrorProjectName(true);
                return false;
            } else {
                setErrorProjectName(false);
            }
            setLoadingModal(true);
            try {
                await apiService.put(
                    'project',
                    {
                        project_id: projectId,
                        name: `${projectName}`
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        handleClose(true);
                    } else {
                        console.log(`ERROR updateProject`, response);
                        handleClose(false);
                    }
                }).catch((error) => {
                    console.log(`ERROR updateProject`, error);
                    handleClose(false);
                });
            } catch (e) {
                console.log(`ERROR updateProject`, e);
                handleClose(false);
            }
        }
    }

    async function removeProject() {
        if (projectId !== 0) {
            setLoadingModal(true);
            try {
                await apiService.delete(
                    `project`,
                    {
                        project_id: projectId
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        handleClose(true);
                    } else {
                        console.log(`ERROR removeProject`, response);
                        handleClose(false);
                    }
                }).catch((error) => {
                    console.log(`ERROR removeProject`, error);
                    handleClose(false);
                });
            } catch (e) {
                console.log(`ERROR removeProject`, e);
                handleClose(false);
            }
        }
    }

    async function createExam() {
        if (examName.trim() === '') {
            setErrorExamName(true);
            return false;
        } else {
            setErrorExamName(false);
        }
        setLoadingModal(true);
        try {
            await apiService.post(
                'exams',
                {
                    project_id: projectId,
                    name: examName
                }
            ).then((response) => {
                if (response !== undefined && response.status === 200) {
                    navigate(`/projetos/exames/detalhe/${response.data.data.id}`);
                } else {
                    console.log(`ERROR createExam`, response);
                    handleClose(false);
                }
            }).catch((error) => {
                console.log(`ERROR createExam`, error);
                handleClose(false);
            });
        } catch (e) {
            console.log(`ERROR createExam`, e);
            handleClose(false);
        }
    }

    async function updateExam() {
        if (examId !== 0) {
            if (examName.trim() === '') {
                setErrorExamName(true);
                return false;
            } else {
                setErrorExamName(false);
            }
            setLoadingModal(true);
            try {
                await apiService.put(
                    'exams',
                    {
                        exam_id: examId,
                        name: `${examName}`
                    }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        handleClose(true);
                    } else {
                        console.log(`ERROR updateExam`, response);
                        handleClose(false);
                    }
                }).catch((error) => {
                    console.log(`ERROR updateExam`, error);
                    handleClose(false);
                });
            } catch (e) {
                console.log(`ERROR updateExam`, e);
                handleClose(false);
            }
        }
    }

    async function removeExam() {
        if (examId !== 0) {
            setLoadingModal(true);
            try {
                await apiService.delete(
                    `exams`,
                    { exam_id: examId }
                ).then((response) => {
                    if (response !== undefined && response.status === 200) {
                        handleClose(true);
                    } else {
                        console.log(`ERROR removeExam`, response);
                        handleClose(false);
                    }
                }).catch((error) => {
                    console.log(`ERROR removeExam`, error);
                    handleClose(false);
                });
            } catch (e) {
                console.log(`ERROR removeExam`, e);
                handleClose(false);
            }
        }
    }

    async function getProjects() {
        setLoader(true);
        try {
            await apiService.get(
                'project',
                {}
            ).then(async (response) => {
                if (response !== undefined && response.status === 200) {
                    let arProjectExams = new Map<number, Array<IExam>>();
                    const resProject = await Promise.all(response.data.data.map(async (element: IProject) => {
                        let ProjectData: IProject = (element as IProject);
                        if (ProjectData !== undefined && ProjectData.id !== undefined) {
                            try {
                                const responseExams = await apiService.get(`project/detail`, { project_id: ProjectData.id });
                                if (responseExams !== undefined && responseExams.status === 200) {
                                    let arExamData: Array<IExam> = (responseExams.data.exams as Array<IExam>);
                                    arProjectExams.set(ProjectData.id, arExamData as Array<IExam>);
                                    return { ...element, exams: responseExams.data.exams }
                                }
                                arProjectExams.set(ProjectData.id, []);
                                return { ...element, exams: [] }
                            } catch (e) {
                                arProjectExams.set(ProjectData.id, []);
                                return { ...element, exams: [] }
                            }
                        }
                    }))
                    setArProject(resProject);
                    setArProjetByExams(arProjectExams);
                    handleClose(false);
                } else {
                    setArProject([]);
                    handleClose(false);
                }
            }).catch((error) => {
                setArProject([]);
                handleClose(false);
            });
        } catch (e) {
            setArProject([]);
            handleClose(false);
        }
    }

    React.useEffect(() => {
        if (user !== null && arProject === undefined) {
            getProjects();
        }
        // eslint-disable-next-line
    }, []);


    const GridExamByProject = ({ projectData }: { projectData: ProjectsExam }): JSX.Element => {
        let defaultJSXElement: JSX.Element = (
            <div>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography>Projeto sem exames vinculados</Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
                <Divider />
            </div>
        );


        if (
            projectData !== undefined && projectData.id !== undefined
        ) {
            let arJSXElements: React.ReactElement[] = [];
            projectData.exams.forEach((element, key) => {
                arJSXElements.push(
                    <div key={`${projectData.id}${key}`}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={styles.grid_root_exam_item}
                        >
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6} className={styles.grid_exam_title_item}>
                                <Link
                                    className={styles.grid_exam_item}
                                    underline={'none'}
                                    onClick={() => editExam(element.id)}
                                >
                                    <Tooltip title={`Código do exame: ${element.id}`}>
                                        <Typography className={styles.grid_exam_item}>{`(${element.id}) `}</Typography>
                                    </Tooltip>
                                </Link>
                                <Link
                                    className={styles.grid_exam_item}
                                    underline={'none'}
                                    onClick={() => editExam(element.id)}
                                >
                                    <Tooltip title={`Nome do exame: ${element.name}`}>
                                        <Typography className={styles.grid_exam_item}>{`${element.name}`}</Typography>
                                    </Tooltip>
                                </Link>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>{formatDateToLocaleString(`${element.created_at}`)}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title="Editar">
                                    <IconButton
                                        size="small"
                                        aria-haspopup="true"
                                        onClick={() => editExam(element.id)}
                                        color="primary"
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Excluir">
                                    <IconButton
                                        size="small"
                                        aria-haspopup="true"
                                        onClick={() => deleteExam(element.id, element.name)}
                                        color="error"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Divider />
                    </div>
                );
            });
            if (arJSXElements.length <= 0) {
                return (<>{defaultJSXElement}</>)
            } else {
                return (
                    <div>
                        {arJSXElements}
                    </div>
                );
            }
        } else {
            return (<>{defaultJSXElement}</>)
        }
    }


    return (
        <AuthBase >
            <Grid container className={styles.grid_root}>
                <Grid item xs={12} className={styles.grid_datagrid}>
                    <Tooltip title="Criar Novo Projeto">
                        <IconButton
                            className={styles.button_create}
                            size="small"
                            aria-haspopup="true"
                            onClick={() => showModalCreateProject()}
                            color="primary"
                        >
                            Criar Novo Projeto <AddIcon />
                        </IconButton>
                    </Tooltip>
                    {(arProject !== undefined && arProject.length > 0 && loader === false) && (
                        <Paper className={styles.paper}>
                            <DataTable
                                title="Projetos"
                                expandOnRowClicked={true}
                                expandableRows={true}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="600px"
                                highlightOnHover={true}
                                pagination={true}
                                responsive={true}
                                striped={true}
                                subHeader={true}
                                columns={columns}
                                data={arProject}
                                disabled={loadingGeneral}
                                expandableRowsComponent={({ data }) => <GridExamByProject projectData={data as ProjectsExam} />}
                            />
                        </Paper>
                    )}
                    {(arProject !== undefined && arProject.length === 0 && loader === false) && (
                        <Typography className={styles.data_is_empty}>Nenhum registro encontrado...</Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {(loader === true) && (
                        <ShowLoader message='' />
                    )}
                </Grid>
                {(loadingModal === true) && (
                    <BootstrapDialog
                        open={loadingModal}
                    >
                        <ShowLoader message='' />
                    </BootstrapDialog>
                )}
                {(loadingModal !== true) && (
                    <>
                        <BootstrapDialog
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showCreateProject}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                {(showEditProject === true) && (
                                    'Editar Projeto'
                                )}
                                {(showEditProject !== true) && (
                                    'Criar Novo Projeto'
                                )}
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        value={projectName}
                                        label='Nome do Projeto'
                                        autoFocus
                                        required={true}
                                        onChange={handlesetNewProjectName}
                                        disabled={loadingModal}
                                        error={errorProjectName}
                                    />
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)}>
                                    Cancelar
                                </Button>
                                {(showEditProject === true) && (
                                    <Button disabled={loadingModal} onClick={updateProject}>
                                        Salvar
                                    </Button>
                                )}
                                {(showEditProject !== true) && (
                                    <Button disabled={loadingModal} onClick={createProject}>
                                        Criar
                                    </Button>
                                )}
                            </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showCreateExam}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                {(showEditExam === true) && (
                                    'Editar Exame'
                                )}
                                {(showEditExam !== true) && (
                                    'Criar Novo Exame'
                                )}
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        value={examName}
                                        label='Nome do Exame'
                                        autoFocus
                                        required={true}
                                        onChange={handlesetNewExamName}
                                        disabled={loadingModal}
                                        error={errorExamName}
                                    />
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)}>
                                    Cancelar
                                </Button>
                                {(showEditExam === true) && (
                                    <Button disabled={loadingModal} onClick={updateExam}>
                                        Salvar
                                    </Button>
                                )}
                                {(showEditExam !== true) && (
                                    <Button disabled={loadingModal} onClick={createExam}>
                                        Criar
                                    </Button>
                                )}
                            </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showDeleteExam}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                Excluir Exame
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Typography className={styles.title}>Deseja excluir o exame <strong>{examName}</strong>?</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)}>
                                    Não
                                </Button>
                                <Button disabled={loadingModal} onClick={removeExam}>
                                    Sim
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                            onClose={() => handleClose(false)}
                            aria-labelledby="customized-dialog-title"
                            open={showDeleteProject}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                                Excluir Projeto
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Typography className={styles.title}>Deseja excluir o projeto <strong>{projectName}</strong>?</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(false)}>
                                    Não
                                </Button>
                                <Button disabled={loadingModal} onClick={removeProject}>
                                    Sim
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                    </>
                )}
            </Grid>
        </AuthBase>
    );
}
